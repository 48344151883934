import { makeStyles, ThemeProvider } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { mergeThemes, selectTheme } from '../../../store/shared/frontendSlice';
import ToolbarLayout2 from './toolbars/ToolbarLayout2';
import DarkToolbarLayout2 from './toolbars/DarkToolbarLayout2';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';

const useStyles = makeStyles((theme) => ({
	darkPaper: {
		backgroundColor: '#000000',
	},
}));

function ToolbarWrapperLayout2(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const classes = useStyles(props);

	if (theme && theme.customStyling && theme.customStyling.header) {
		// TODO - Kopier hele IF blocken for at lave en ny custom styling
		if (theme.customStyling.header === 'dark') {
			return (
				<ThemeProvider theme={mergedTheme}>
					<Paper
						square
						elevation={2}
						classes={{
							root: classes.darkPaper,
						}}
					>
						<DarkToolbarLayout2 parentClasses={classes} mergedTheme={mergedTheme} config={config} />
					</Paper>
				</ThemeProvider>
			);
		}
	}

	return (
		<ThemeProvider theme={mergedTheme}>
			<Paper square elevation={2}>
				<ToolbarLayout2 />
			</Paper>
		</ThemeProvider>
	);
}

export default React.memo(ToolbarWrapperLayout2);
