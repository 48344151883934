import React, { useState, useEffect } from 'react';
import { Button, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Remove, Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { productsListener, ticketTypeListener } from './TicketTypePlainFirestore';
import ReadMore from '../../../../../ui-components/ReadMore';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import StorageImage from '../../../../../ui-components/StorageImage';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 }
}));

function TicketTypePlainPage({ data }) {
	const classes = useStyles();
	const [ticketType, setTicketType] = useState(null);
	const [products, setProducts] = useState([]);
	const [dbProducts, setDbProducts] = useState([]);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		if (data && data.ticketTypeUid) {
			const productsListenerUnsubFunc = productsListener(data.ticketTypeUid, o => setDbProducts(o));
			const ticketTypeListenerUnsubFunc = ticketTypeListener(data.ticketTypeUid, o => setTicketType(o));
			return () => {
				productsListenerUnsubFunc();
				ticketTypeListenerUnsubFunc();
			};
		}

		return () => {};
	}, [data]);

	useEffect(() => {
		const tempProducts = dbProducts.filter(p => p.type === 'ticket');
		let giftProducts = dbProducts.filter(p => p.type === 'giftTicket');
		giftProducts = giftProducts.map(giftProduct => ({
			...tempProducts.find(p => p.id === giftProduct.giftProductUid),
			...giftProduct
		}));
		setProducts([...tempProducts, ...giftProducts].filter(p => p.type === data.productType));
	}, [dbProducts, data.productType]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] = 0;
		});
		setProductsWithAmount(pro);
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);

	const navigate = useNavigate();

	function goToCheckOut() {
		navigate({ pathname: '/cart', products: productsWithAmount });
	}

	function isAllowedToBuy() {
		return Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	return (
		// TODO - Mangler content på tickettype, som skal sættes ind herunder

		<div className={clsx(classes.grid, 'min-h-screen max-w-screen py-48 sm:py-48')}>
			<Grid container spacing={0}>
				{ticketType && ticketType.content && (
					<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16">
						<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
							{ticketType.content.title}
						</Typography>
						<div className="flex flex-col md:flex-row">
							<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
								<StorageImage src={ticketType.content.image} alt="Jorn udstilling" />
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
								<div className="text-left text-16 pb-20">
									<ReadMore
										color={mergedTheme.palette.background.paper}
										showFull={
											<>
												{ticketType.content.content.map(c => {
													if (c.type === 'heading') {
														return <HeadingComponent>{c.content}</HeadingComponent>;
													}
													if (c.type === 'text') {
														return <TextComponent>{c.content}</TextComponent>;
													}
													if (c.type === 'number_list') {
														return (
															<ListComponent
																type="number"
																list={c.content.array}
																heading={c.content.heading}
															/>
														);
													}
													return <span />;
												})}
											</>
										}
										showHalf={
											<>
												{ticketType.content.content
													.filter(c => !c.collapsed)
													.map(c => {
														if (c.type === 'heading') {
															return <HeadingComponent>{c.content}</HeadingComponent>;
														}
														if (c.type === 'text') {
															return <TextComponent>{c.content}</TextComponent>;
														}
														if (c.type === 'number_list') {
															return (
																<ListComponent
																	type="number"
																	list={c.content.array.filter(a => !a.collapsed)}
																	heading={c.content.heading}
																/>
															);
														}
														if (c.type === 'markdown') {
															return <MarkdownComponent>{c.content}</MarkdownComponent>;
														}
														return <span />;
													})}
											</>
										}
									/>
								</div>
							</Grid>
						</div>
					</Paper>
				)}
				<Paper elevation={3} className="mt-48 p-4 pb-28 pt-28 md:p-28 w-full mx-12 md:mx-16">
					<div className="flex flex-col md:flex-row w-full ">
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col text-left px-10 pt-28 md:pt-0 justify-center items-center  mb-36 md:mb-0"
						>
							{ticketType && ticketType.content && ticketType.content.ticket && (
								<Paper elevation={6} className="min-w-2 max-w-256 overflow-hidden">
									<div className="relative">
										<StorageImage
											src={ticketType.content.ticket.image}
											className="object-contain w-full max-w-full"
											alt=""
										/>
										{/* <img */}
										{/*	className="object-contain w-full max-w-full" */}
										{/*	src="assets/images/event-site/stoleJorn.png" */}
										{/*	alt="" */}
										{/* /> */}

										<img
											className="overflow-hidden min-w-256 max-w-288 absolute -bottom-0.3 -left-1 -right-1"
											src="assets/images/event-site/halvcirkel.svg"
											alt=""
										/>
									</div>
									<div className="px-16">
										<Typography variant="h6" className="text-center">
											{ticketType.content.ticket.heading}
										</Typography>
										<Typography className="py-16 italic">
											{ticketType.content.ticket.text}
										</Typography>
										<Typography variant="h6" className="pb-24 text-center">
											Fra{' '}
											{Math.min(
												...products.map(p => {
													const price = p.prices.find(pr => pr.type === 'Sale');
													if (!price) return 0;
													return price.price / 100;
												})
											)}
											,-
										</Typography>
									</div>
								</Paper>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col whitespace-no-wrap px-10 pt-0"
						>
							<Typography variant="h6" className="text-24">
								Køb billet
							</Typography>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Vælg antal gæster
							</Typography>
							<hr />
							{products.map(product => (
								<div className="flex justify-between items-center" key={product.id}>
									<div className="flex justify-between w-full">
										<Typography className="font-bold whitespace-normal">
											{/* className="font-bold text-14 md:text-base"> */}
											{product.name}
										</Typography>
										<Typography className="px-8 font-bold flex items-center">
											{product.prices.find(f => f.type === 'Sale').price / 100},-
										</Typography>
									</div>
									<div className="flex ButtonDiv h-56 items-center">
										<Button
											variant="outlined"
											onClick={() => {
												setProductsWithAmount(oldState => {
													const p = { ...oldState };
													if (p[product.id] !== 0) {
														p[product.id] -= 1;
													}
													return p;
												});
											}}
											className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
										>
											<Remove />
										</Button>
										<div className="flex items-center text-14 font-bold px-10">
											{productsWithAmount[product.id]}
										</div>
										<Button
											variant="outlined"
											onClick={() => {
												setProductsWithAmount(oldState => {
													const p = { ...oldState };
													p[product.id] += 1;
													return p;
												});
											}}
											className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
										>
											<Add />
										</Button>
									</div>
								</div>
							))}
							<hr />
							<div className="flex justify-between w-full">
								<Typography className="font-bold text-20">I alt</Typography>
								<Typography className="font-bold text-20">{`${totalAmount / 100} kr.`}</Typography>
							</div>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className="pt-20 flex flex-col justify-end"
							>
								{/* <Typography className="text-12 whitespace-normal"> */}
								{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor */}
								{/*	incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud */}
								{/*	exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute */}
								{/*	irure dolor in reprehenderit in voluptate velit esse. */}
								{/* </Typography> */}
								<Button
									onClick={goToCheckOut}
									className="w-full rounded-none"
									variant="contained"
									endIcon={<ArrowForwardIcon />}
									color="primary"
									disabled={isAllowedToBuy()}
								>
									KØB BILLET
								</Button>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Grid>
		</div>
	);
}

export default TicketTypePlainPage;
