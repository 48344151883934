import React from 'react';
import TextComponent from '../../../../ui-components/content-components/TextComponent';
import StorageImage from '../../../../ui-components/StorageImage';
import HeadingComponent from '../../../../ui-components/content-components/HeadingComponent';
import ListComponent from '../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../ui-components/content-components/MarkdownComponent';

function MFSContent({ eventGroup }) {
	return (
		<div className="w-full">
			<div className="absolute left-0 right-0 w-full flex items-center justify-center">
				<StorageImage src={eventGroup.content.image} alt="MFS udstilling" />
			</div>
			<div className="h-0" style={{ paddingBottom: '40%' }} />

			<div className="mt-24 mx-12 p-4 w-full text-white">
				<>
					{eventGroup.content.content.map((c, i) => {
						if (c.type === 'heading') {
							return <HeadingComponent key={i}>{c.content}</HeadingComponent>;
						}
						if (c.type === 'text') {
							return <TextComponent key={i}>{c.content}</TextComponent>;
						}
						if (c.type === 'number_list') {
							return (
								<ListComponent
									type="number"
									list={c.content.array}
									heading={c.content.heading}
									key={i}
								/>
							);
						}
						if (c.type === 'markdown') {
							return <MarkdownComponent data={c.content} key={i} />;
						}
						return <span key={i} />;
					})}
				</>
			</div>
		</div>
	);
}

export default MFSContent;
