import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, darken } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MUILink from '@mui/material/Link';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import AccordionSummary from '@mui/material/AccordionSummary';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	subscriptionsListener,
	orderListeners,
	getEventsByUids,
	updateSubscriptionPaymentType,
	selectLoading,
	setLoading,
	cancelSubscriptionPayment,
	createSubscriptionMandate
} from '../../../../../../store/shared/userSlice';
import AccountVerificationNeeded from './AccountVerificationNeeded';
import StorageImage from '../../../../../../ui-components/StorageImage';
import { selectLogos, selectModules, selectSettings } from '../../../../../../store/shared/frontendSlice';
import ConfirmationDialog from '../../../../../../ui-components/ConfirmationDialog';
import { getSubscriptionTypeCurrentEvent } from '../../subscription/SubscriptionFirestore';
import FuseLoading from '@ameroservices-platform/attraction-frontend/fuse/core/FuseLoading';
import LoadingButton from '../../../../../../ui-components/LoadingButton';

const useStyles = makeStyles(theme => ({
	header: {
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.primary.contrastText
	},
	panel: {
		marginBottom: '1rem',
		borderRadius: '16px 16px 16px 16px',
		'&$expanded': {
			marginBottom: '1rem'
		},
		'&:before': {
			backgroundColor: 'unset'
		}
	},
	panelDetail: {
		display: 'block'
	},
	expanded: {},
	root: {
		background: `radial-gradient(${darken(theme.palette.background.paper, 0.05)} 0%, ${
			theme.palette.background.paper
		} 60%)`
	},
	divider: {
		backgroundColor: theme.palette.divider
	}
}));

const formatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

function AccountSubscriptionsPage({ data }) {
	const { pensoPaySubscription, ...flags } = useFlags();
	const modules = useSelector(selectModules);
	const classes = useStyles();
	const dispatch = useDispatch();
	const logos = useSelector(selectLogos);
	const settings = useSelector(selectSettings);
	const loading = useSelector(selectLoading);
	const subscriptions = useSelector(({ shared }) => shared.user.subscriptions);
	const subscriptionOrders = useSelector(({ shared }) => shared.user.subscriptionOrders);
	const user = useSelector(({ shared }) => shared.user.user);
	const userAuth = useSelector(({ auth }) => auth.user);
	const [expanded, setExpanded] = useState(null);
	const [verified, setVerified] = useState(false);
	const [events, setEvents] = useState({});
	const { t } = useTranslation();
	const states = {
		active: { color: 'bg-green-800 text-white', name: t('ACTIVE') },
		renewal: { color: 'bg-green-800 text-white', name: t('AWAITING_RENEWAL') },
		draft: { color: 'bg-gray text-white', name: t('IS_CREATING') },
		payment: { color: 'bg-orange text-white', name: t('AWAITING_PAYMENT') },
		soon: { color: 'bg-yellow-800 text-white', name: t('EXPIRES_SOON') },
		inactive: { color: 'bg-red text-white', name: t('INACTIVE') }
	};
	const [automaticRenewalOpen, setAutomaticRenewalOpen] = useState(null);
	const [cancelAutomaticRenewalOpen, setCancelAutomaticRenewalOpen] = useState(null);
	const [renewalOpen, setRenewalOpen] = useState(null);
	const subscriptionState = useMemo(() => {
		if (!subscriptions) {
			return states.inactive;
		}
		const subscription = subscriptions.find(sub => sub.id === expanded);
		if (subscription && subscription.state && states[subscription.state]) {
			return states[subscription.state];
		}
		return states.inactive;
	}, [expanded, subscriptions]);
	const [currentEvent, setCurrentEvent] = useState(null);
	const renewalTime = useMemo(() => {
		if (currentEvent && currentEvent.start && currentEvent.end) {
			return `${moment(currentEvent.start).format('DD-MM-YYYY')} til ${moment(currentEvent.end).format(
				'DD-MM-YYYY'
			)}`;
		}
		return null;
	}, [currentEvent]);

	useEffect(() => {
		if (expanded && renewalOpen) {
			const subscription = subscriptions.find(sub => sub.id === renewalOpen);
			getSubscriptionTypeCurrentEvent(subscription.subscriptionTypeUid, subscription.currentEvent).then(event => {
				if (event) {
					setCurrentEvent(event);
				} else {
					setCurrentEvent(null);
				}
			});
		}
	}, [subscriptions, renewalOpen, expanded]);

	useEffect(() => {
		if (userAuth && userAuth.claims) {
			setVerified(userAuth.claims.email_verified);
		}
	}, [userAuth]);

	useEffect(() => {
		if (user && user.uid) {
			const unsub = dispatch(subscriptionsListener(user.uid));
			return () => unsub();
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (user && user.uid) {
			const unsubs = dispatch(
				orderListeners(
					user.uid,
					subscriptions.map(s => s.id)
				)
			);
			return () => unsubs.forEach(unsub => unsub());
		}
		return () => {};
	}, [dispatch, user, subscriptions]);

	useEffect(() => {
		if (subscriptions && subscriptions.length > 0) {
			getEventsByUids(subscriptions.map(s => s.currentEvent)).then(es => {
				setEvents(es);
				console.log(es);
			});
		}
	}, [subscriptions]);

	const toggleAccordion = panel => (event, _expanded) => {
		if (!pensoPaySubscription) {
			return;
		}
		setExpanded(_expanded ? panel : false);
	};
	function needRenewal(subscription) {
		return (
			subscription &&
			subscriptionOrders[subscription.id] &&
			subscriptionOrders[subscription.id].payments &&
			subscriptionOrders[subscription.id].payments.length > 0 &&
			subscriptionOrders[subscription.id].payments[0].prepData &&
			subscriptionOrders[subscription.id].payments[0].prepData.link
		);
	}
	// function needCreation(subscription) {
	// 	return subscription && subscription.state === 'draft';
	// }

	async function handleRenewal(subscription, result) {
		if (result) {
			dispatch(setLoading(true));
			const response = await updateSubscriptionPaymentType(subscription.id, 'subscription-card');
			if (response && response.link) {
				window.location.href = response.link;
			}
			dispatch(setLoading(false));
		} else {
			setRenewalOpen(null);
		}
	}

	async function handleAutomaticRenewal(subscription, result) {
		if (result) {
			dispatch(setLoading(true));
			await updateSubscriptionPaymentType(subscription.id, 'subscription-automatic');
		} else {
			setAutomaticRenewalOpen(null);
		}
	}

	async function handleCancelAutomaticRenewal(subscription, result) {
		if (result) {
			dispatch(setLoading(true));
			await cancelSubscriptionPayment(subscription.id);
		}
		setCancelAutomaticRenewalOpen(null);
	}

	useEffect(() => {
		if (pensoPaySubscription && subscriptions && expanded && automaticRenewalOpen) {
			const subscription = subscriptions.find(sub => sub.id === automaticRenewalOpen);
			if (flags.cancelSubscriptionPayment) {
				if (subscription && subscription.payment && subscription.payment.mandates) {
					const mandateToUse = subscription.payment.mandates.find(
						mandate => mandate.state === 'pending' && mandate.prepData && mandate.prepData.link
					);
					if (mandateToUse) {
						window.location.href = mandateToUse.prepData.link;
					}
				}
			} else if (
				subscription &&
				subscription.payment &&
				subscription.payment.mandates &&
				subscription.payment.mandates[0] &&
				subscription.payment.mandates[0].state === 'pending' &&
				subscription.payment.mandates[0].prepData &&
				subscription.payment.mandates[0].prepData.link
			) {
				window.location.href = subscription.payment.mandates[0].prepData.link;
			}
		}
	}, [flags.cancelSubscriptionPayment, pensoPaySubscription, subscriptions, expanded, automaticRenewalOpen]);

	async function handleChangeSubscriptionPaymentCard(subscription) {
		const pendingMandate = subscription.payment.mandates.find(mandate => mandate.state === 'pending');
		if (pendingMandate) {
			window.location.href = pendingMandate.prepData.link;
		} else {
			const mandateResponse = await createSubscriptionMandate(subscription.id);
			window.location.href = mandateResponse.prepData.link;
		}
	}

	if (verified) {
		return (
			<>
				<div className="hidden md:flex items-center justify-between p-20">
					<table className="w-full">
						<thead>
							<tr>
								<td className="w-400">
									<Typography variant="subtitle1">{t('MEMBERSHIP')}</Typography>
								</td>
								<td>
									<Typography variant="subtitle1">{t('STATUS')}</Typography>
								</td>
								<td className="w-76 pr-64">
									<Typography variant="subtitle1">{t('PRICE')}</Typography>
								</td>
								<td className="w-76" />
							</tr>
						</thead>
					</table>
				</div>
				{subscriptions.map(subscription => (
					<Accordion
						classes={{
							root: clsx(classes.panel, 'md:w-xl'),
							expanded: classes.expanded
						}}
						key={subscription.id}
						expanded={expanded === subscription.id}
						onChange={toggleAccordion(subscription.id)}
						elevation={1}
						square
					>
						<AccordionSummary expandIcon={pensoPaySubscription ? <Icon>expand_more</Icon> : null}>
							{expanded !== subscription.id && (
								<table className="w-full">
									<tbody>
										<tr className="md:table-row flex flex-col">
											<td className="md:w-400">
												<div>
													<Typography variant="h6" className="mb-8">
														{subscription.name}
													</Typography>
													<Typography color="textSecondary">
														{t('MEMBERSHIP_NUMBER')}: {subscription.subscriptionNumber}
													</Typography>
													{events && events[subscription.currentEvent] && (
														<Typography color="textSecondary">
															{moment(events[subscription.currentEvent].start).format(
																'DD-MM-YYYY'
															)}
															{' - '}
															{moment(events[subscription.currentEvent].end).format(
																'DD-MM-YYYY'
															)}
														</Typography>
													)}
												</div>
											</td>
											<td>
												<Typography variant="subtitle1">
													{needRenewal(subscription) && subscription.state !== 'payment' ? (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states.soon.color
															)}
														>
															{states.soon.name}
														</span>
													) : states[subscription.state] ? (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states[subscription.state].color
															)}
														>
															{states[subscription.state].name}
														</span>
													) : (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states.inactive.color
															)}
														>
															{states.inactive.name}
														</span>
													)}
												</Typography>
											</td>
											<td className="md:w-76">
												<Typography variant="subtitle1" className="mr-16">
													{subscription.subscriptionPrice
														? formatter.format(subscription.subscriptionPrice / 100)
														: '-'}
												</Typography>
											</td>
											<td className="md:w-76">
												{needRenewal(subscription) && (
													<Button
														component={MUILink}
														href={
															subscriptionOrders[subscription.id].payments[0].prepData
																.link
														}
														color="primary"
														variant="contained"
														target="_blank"
													>
														{subscription.state === 'payment' ? t('PAY') : t('RENEW')}
													</Button>
												)}
												{/* {needCreation(subscription) && ( */}
												{/*	<Button */}
												{/*		component={MUILink} */}
												{/*		href={`/${ */}
												{/*			data && data.subscriptionLink */}
												{/*				? data.subscriptionLink */}
												{/*				: 'medlem' */}
												{/*		}?s=${subscription.id}`} */}
												{/*		color="primary" */}
												{/*		variant="contained" */}
												{/*		target="_blank" */}
												{/*	> */}
												{/*		FÆRDIGGØR */}
												{/*	</Button> */}
												{/* )} */}
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</AccordionSummary>
						{pensoPaySubscription && (
							<AccordionDetails classes={{ root: clsx(classes.root, 'block md:p-88') }}>
								<div className="flex flex-row justify-between items-start">
									<div className="hidden md:flex flex-col">
										<div className="flex items-center mb-80 print:mb-0">
											<StorageImage className="max-w-128" src={logos.header} />

											<div className={clsx(classes.divider, 'mx-48 w-px h-128 print:mx-16')} />

											<div className="max-w-160">
												<Typography color="textSecondary">{settings.title}</Typography>

												{/* {invoice.from.address && ( */}
												{/*	<Typography color="textSecondary">{invoice.from.address}</Typography> */}
												{/* )} */}
												{/* {invoice.from.phone && ( */}
												{/*	<Typography color="textSecondary"> */}
												{/*		<span>Phone:</span> */}
												{/*		{invoice.from.phone} */}
												{/*	</Typography> */}
												{/* )} */}
												{/* {invoice.from.email && ( */}
												{/*	<Typography color="textSecondary"> */}
												{/*		<span>Email:</span> */}
												{/*		{invoice.from.email} */}
												{/*	</Typography> */}
												{/* )} */}
												{/* {invoice.from.website && ( */}
												{/*	<Typography color="textSecondary"> */}
												{/*		<span>Web:</span> */}
												{/*		{invoice.from.website} */}
												{/*	</Typography> */}
												{/* )} */}
											</div>
										</div>

										<div className="flex items-center">
											<div className="flex justify-end items-center w-128 print:w-60">
												<Typography
													variant="h5"
													className="font-light print:text-16"
													color="textSecondary"
												>
													KUNDE
												</Typography>
											</div>

											<div className={clsx(classes.divider, 'mx-48 w-px h-128 print:mx-16')} />

											<div className="w-192">
												{subscription.contactInfo && subscription.contactInfo.billing && (
													<>
														<Typography color="textSecondary">
															{subscription.contactInfo.billing.firstname}{' '}
															{subscription.contactInfo.billing.lastname}
														</Typography>
														{subscription.contactInfo.billing.address && (
															<Typography color="textSecondary">
																{subscription.contactInfo.billing.address}
															</Typography>
														)}
														{subscription.contactInfo.billing.city &&
															subscription.contactInfo.billing.postalCode && (
																<Typography color="textSecondary">
																	{subscription.contactInfo.billing.postalCode}{' '}
																	{subscription.contactInfo.billing.city}
																</Typography>
															)}
														{subscription.contactInfo.billing.phone && (
															<Typography color="textSecondary">
																<span>{t('PHONE')}: </span>
																{subscription.contactInfo.billing.phone}
															</Typography>
														)}
														{subscription.contactInfo.billing.email && (
															<Typography color="textSecondary">
																<span>{t('MAIL')}: </span>
																{subscription.contactInfo.billing.email}
															</Typography>
														)}
													</>
												)}
											</div>
										</div>
									</div>

									<div className="-mt-64 md:w-auto w-full">
										<table>
											<tbody>
												<tr>
													<td className="pb-32" colSpan={2}>
														<Typography className="font-light" variant="h5">
															{subscription.name}
														</Typography>
													</td>
												</tr>

												<tr>
													<td className="text-right">
														<Typography color="textSecondary">
															{t('MEMBERSHIP_NUMBER')}.
														</Typography>
													</td>
													<td className="px-16">
														<Typography>{subscription.subscriptionNumber}</Typography>
													</td>
												</tr>

												<tr>
													<td className="text-right">
														<Typography color="textSecondary">{t('STATUS')}</Typography>
													</td>
													<td className="px-16">
														<Typography>
															<span
																className={clsx(
																	'inline text-12 p-4 rounded truncate',
																	subscriptionState.color
																)}
															>
																{subscriptionState.name}
															</span>
														</Typography>
													</td>
												</tr>

												<tr>
													<td className="text-right">
														<Typography color="textSecondary">{t('DATO')}</Typography>
													</td>
													<td className="px-16">
														<Typography>
															{moment(subscription.createdAt).format('DD-MM-YYYY, HH:mm')}
														</Typography>
													</td>
												</tr>

												<tr>
													<td className="text-right">
														<Typography color="textSecondary">{t('TOTAL')}</Typography>
													</td>
													<td className="px-16">
														<Typography>
															{formatter.format(subscription.subscriptionPrice / 100.0)}
														</Typography>
													</td>
												</tr>

												{events && events[subscription.currentEvent] && (
													<>
														<tr>
															<td className="text-right">
																<Typography color="textSecondary">
																	{t('START')}
																</Typography>
															</td>
															<td className="px-16">
																<Typography>
																	{moment(
																		events[subscription.currentEvent].start
																	).format('DD-MM-YYYY')}
																</Typography>
															</td>
														</tr>

														<tr>
															<td className="text-right">
																<Typography color="textSecondary">
																	{t('END')}
																</Typography>
															</td>
															<td className="px-16">
																<Typography>
																	{moment(
																		events[subscription.currentEvent].end
																	).format('DD-MM-YYYY')}
																</Typography>
															</td>
														</tr>
													</>
												)}
											</tbody>
										</table>
										<div className="mt-64">
											{subscription.state === 'renewal' &&
												subscription.paymentType !== 'subscription-automatic' && (
													<div className="mb-10 w-full">
														<LoadingButton
															fullWidth
															variant="contained"
															color="primary"
															onClick={() => setRenewalOpen(subscription.id)}
															disableLoadingOnClick
														>
															Forny {flags.tempNoteForPaymentOnSubscription && '*'}
														</LoadingButton>
													</div>
												)}
											{!flags.disableAutomaticRenewalButtonOnFrontend &&
												modules &&
												modules.subscriptions &&
												modules.subscriptions.automaticRenewal &&
												['renewal', 'active'].includes(subscription.state) &&
												subscription.paymentType !== 'subscription-automatic' && (
													<div className="mb-10 w-full">
														<LoadingButton
															fullWidth
															variant="contained"
															color="primary"
															onClick={() => setAutomaticRenewalOpen(subscription.id)}
															disableLoadingOnClick
														>
															{t('ACTIVATE_RENEWAL')}{' '}
															{flags.tempNoteForPaymentOnSubscription && '*'}
														</LoadingButton>
													</div>
												)}
											{['renewal', 'active'].includes(subscription.state) &&
												subscription.payment &&
												subscription.payment.mandates &&
												(flags.cancelSubscriptionPayment
													? subscription.payment.mandates.some(
															mandate => mandate.state === 'pending'
													  ) &&
													  !subscription.payment.mandates.some(
															mandate => mandate.state === 'active'
													  )
													: subscription.payment.mandates[0] &&
													  subscription.payment.mandates[0].state === 'pending') &&
												subscription.paymentType === 'subscription-automatic' &&
												modules &&
												modules.subscriptions &&
												modules.subscriptions.automaticRenewal && (
													<div className="w-full">
														<LoadingButton
															fullWidth
															variant="contained"
															color="primary"
															disableLoadingOnClick
															onClick={() => {
																if (flags.cancelSubscriptionPayment) {
																	window.location.href =
																		subscription.payment.mandates.find(
																			mandate => mandate.state === 'pending'
																		).prepData.link;
																} else {
																	window.location.href =
																		subscription.payment.mandates[0].prepData.link;
																}
															}}
														>
															{t('ADD_CREDITCARD')}{' '}
															{flags.tempNoteForPaymentOnSubscription && '*'}
														</LoadingButton>
													</div>
												)}
											{flags.optionToChangeCreditcardOnSubscriptionUserPage &&
												['renewal', 'active'].includes(subscription.state) &&
												subscription.paymentType === 'subscription-automatic' &&
												subscription?.payment?.mandates?.some(
													mandate => mandate.state === 'active'
												) &&
												modules?.subscriptions?.automaticRenewal && (
													<div className="w-full mb-10">
														<LoadingButton
															fullWidth
															variant="contained"
															color="primary"
															onClick={() =>
																handleChangeSubscriptionPaymentCard(subscription)
															}
														>
															{t('ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD')}
														</LoadingButton>
													</div>
												)}
											{flags.cancelSubscriptionPayment &&
												['renewal', 'active'].includes(subscription.state) &&
												subscription.payment &&
												subscription.payment.mandates &&
												subscription.payment.mandates.some(
													mandate => mandate.state === 'active'
												) &&
												subscription.paymentType === 'subscription-automatic' &&
												modules &&
												modules.subscriptions &&
												modules.subscriptions.automaticRenewal && (
													<div className="w-full">
														<LoadingButton
															fullWidth
															variant="contained"
															color="primary"
															disableLoadingOnClick
															onClick={() =>
																setCancelAutomaticRenewalOpen(subscription.id)
															}
														>
															{t('DEACTIVATE_RENEWAL')}
														</LoadingButton>
													</div>
												)}
											<ConfirmationDialog
												open={renewalOpen === subscription.id}
												onClose={result => handleRenewal(subscription, result)}
												okButton={t('GO_TO_PAYMENT')}
												cancelButton={t('CANCEL')}
												disableOk={!renewalTime}
												loading={loading}
												content={
													renewalTime ? (
														`${t('MEMBERSHIP_RENEWAL_FROM')} ${renewalTime}`
													) : (
														<FuseLoading />
													)
												}
											/>
											<ConfirmationDialog
												open={automaticRenewalOpen === subscription.id}
												onClose={result => handleAutomaticRenewal(subscription, result)}
												loading={loading}
												okButton={t('ACTIVATE')}
												cancelButton={t('CANCEL')}
												content={t('AUTOMATIC_RENEWAL_EXPLAINER')}
											/>
											{flags.cancelSubscriptionPayment && (
												<ConfirmationDialog
													open={cancelAutomaticRenewalOpen === subscription.id}
													onClose={result =>
														handleCancelAutomaticRenewal(subscription, result)
													}
													loading={loading}
													okButton={t('DEACTIVATE')}
													cancelButton={t('CANCEL')}
													content={t('DEACTIVATE_RENEWAL_EXPLAINER')}
												/>
											)}
											{flags.tempNoteForPaymentOnSubscription && (
												<div className="w-full">
													<Typography>{t('RENEWAL_WHEN_PAYMENT_CHARGED')}</Typography>
												</div>
											)}
										</div>
									</div>
								</div>
							</AccordionDetails>
						)}
					</Accordion>
				))}
			</>
		);
	}
	return <AccountVerificationNeeded type="medlemskaber" />;
}

export default AccountSubscriptionsPage;
