import React, { lazy } from 'react';

const CheckIn = lazy(() => import('./CheckIn'));
const CheckInEarly = lazy(() => import('./CheckInEarly'));
const CheckInSucess = lazy(() => import('./CheckInSucess'));
const CheckInLate = lazy(() => import('./CheckInLate'));

const CheckInPageConfig = {
	settings: {
		layout: {
			style: 'layout4',
			config: {
				navbar: {
					display: false,
					folded: false,
					position: 'left',
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
					style: 'static',
					position: 'below',
				},
			},
		},
	},
	routes: [
		{
			path: '/checkin',
			element: <CheckIn />,
		},
		{
			path: '/checkinearly',
			element: <CheckInEarly />,
		},
		{
			path: '/checkinsucess',
			element: <CheckInSucess />,
		},
		{
			path: '/checkinlate',
			element: <CheckInLate />,
		},
	],
};

export default CheckInPageConfig;
