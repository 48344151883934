import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';

const useStyles = makeStyles(theme => ({
	halfCircle: {
		bottom: '-0.3em'
	},
	cardTopImage: { height: '9em', objectFit: 'cover' },
	cardDescription: { height: '8em' }
}));

function TicketCard(props) {
	const { contentElementProps } = props;
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<div
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			<Paper elevation={6} className="min-w-2 max-w-256 overflow-hidden flex-grow">
				<div className="relative">
					<img
						className={`w-full max-w-full ${classes.cardTopImage}`}
						src={contentElementProps.imageSrc}
						alt=""
					/>
					{contentElementProps.withHalfCircle !== false && (
						<img
							className={`overflow-hidden min-w-0 max-w-288 absolute -left-1 -right-1 ${classes.halfCircle}`}
							src="assets/images/event-site/halvcirkel.svg"
							alt=""
						/>
					)}
				</div>
				<div className="px-16">
					<Typography variant="h6" className="text-center h-72">
						{contentElementProps.title}
					</Typography>
					<Typography className={`py-16 ${classes.cardDescription}`}>
						{contentElementProps.description}
					</Typography>
					<Button
						className="w-full mb-16"
						variant="contained"
						color="primary"
						endIcon={<ArrowForwardIcon />}
						onClick={() => navigate(contentElementProps.buttonUrl)}
					>
						{contentElementProps.buttonText}
					</Button>
				</div>
			</Paper>
		</div>
	);
}

export default TicketCard;
