import Logo from '../../shared-components/Logo';
import Navigation from '../../shared-components/Navigation';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useLocation } from 'react-router-dom';
import ToolbarLayout2 from './toolbars/ToolbarLayout2';
import { selectMainTheme } from '../../../store/fuse/settingsSlice';
import { mergeThemes, selectCheckoutHeaderUrls, selectTheme } from '../../../store/shared/frontendSlice';
import LogoCheckout from '../../shared-components/LogoCheckout';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';

function NavbarLayout2() {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);

	const location = useLocation();

	const checkoutHeaderUrls = useSelector(selectCheckoutHeaderUrls);

	if (config.hideNavigation || (checkoutHeaderUrls && checkoutHeaderUrls.includes(location.pathname))) {
		return (
			<div className="flex flex-auto items-center w-full max-w-3xl mx-auto container p-0 lg:px-24">
				<div className="flex items-center justify-between w-full">
					<Link to="/">
						<div className="flex flex-shrink-0 items-center px-56 w-256 h-256">
							<LogoCheckout className="" />
						</div>
					</Link>
					<div className="flex px-56 items-center">
						<Typography className="font-bold">
							<LockIcon /> Denne side er krypteret og dine oplysninger vil blive behandlet fortroligt
						</Typography>
					</div>
				</div>
			</div>
		);
	}
	return (
		<ThemeProvider theme={mergedTheme}>
			<div className="flex flex-auto justify-between items-center w-full max-w-3xl mx-auto h-full container p-0 lg:px-24">
				<div className="flex items-center">
					<Link to="/">
						<div className="flex flex-shrink-0 items-center px-56">
							<Logo />
						</div>
					</Link>

					<FuseScrollbars className="flex h-full items-center">
						<Navigation className="w-full" layout="horizontal" />
					</FuseScrollbars>
				</div>

				<div className="flex items-center px-16">
					<ToolbarLayout2 />
				</div>
			</div>
		</ThemeProvider>
	);
}

export default React.memo(NavbarLayout2);
