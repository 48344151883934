import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Popover } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { DateRange } from 'react-date-range';
import { da } from 'react-date-range/dist/locale';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import FuseChipSelect from '@ameroservices-platform/attraction-frontend/fuse/core/FuseChipSelect';

function getLabelTime(dateRange) {
	if (dateRange && dateRange[0].startDate && dateRange[0].endDate) {
		const start = moment(dateRange[0].startDate);
		const end = moment(dateRange[0].endDate);
		return `${start.format('DD-MM-YYYY')} - ${end.format('DD-MM-YYYY')}`;
	}
	return '';
}

function EventFilter({
	categories,
	setCategories,
	categoriesOptions,
	locations,
	setLocations,
	locationOptions,
	selectedDateRange,
	handleChangeFromDatePicker,
	showCategoryFilter,
	showPlaceFilter,
	showDatePicker
}) {
	const [rangePickerAnchorEl, setRangePickerAnchorEl] = useState(null);
	const cleared = useRef(false);
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: null,
			key: 'selection'
		}
	]);

	// const memoCallback = useCallback(() => {
	// 	setSelectedDateRange(dateRange);
	// }, [dateRange, setSelectedDateRange]);

	useEffect(() => {
		if (dateRange && rangePickerAnchorEl === null) {
			if (dateRange[0].endDate !== null && !cleared.current) {
				handleChangeFromDatePicker(dateRange);
			}
		}
	}, [rangePickerAnchorEl, dateRange, handleChangeFromDatePicker]);

	const handleOnClose = () => {
		setRangePickerAnchorEl(null);
	};

	const handleClickOnDatePicer = e => {
		setRangePickerAnchorEl(e.currentTarget);
		cleared.current = false;
	};

	const handleChangeOnDatePicker = item => {
		setDateRange([item.selection]);
	};
	const { t } = useTranslation();

	return (
		<>
			{/* ------------------Kategori filter Start-----------------------*/}
			{showCategoryFilter && (
				<Grid className="xs:px-8 md:px-32" item xs={12} md={4} lg={4}>
					<Typography className="text-center text-16 mt-24 mb-8" component="h2">
						{t('CHOOSE_CATEGORY')}
					</Typography>

					<FuseChipSelect
						className="w-full my-16"
						value={categories}
						onChange={value => setCategories(value)}
						placeholder={t('ALL_CATEGORIES_SELECTED')}
						textFieldProps={{
							label: t('CATEGORIES'),
							InputLabelProps: {
								shrink: true
							},
							variant: 'outlined'
						}}
						options={categoriesOptions}
						isMulti
					/>
				</Grid>
			)}
			{/* ------------------Kategori filter end-----------------------*/}
			{/* ------------------Sted filter Start-----------------------*/}
			{showPlaceFilter && (
				<Grid className="xs:px-8 md:px-32" item xs={12} md={4} lg={4}>
					<Typography className="text-center text-16 mt-24 mb-8" component="h2">
						{t('CHOOSE_LOCATION')}
					</Typography>

					<FuseChipSelect
						className="w-full my-16"
						value={locations}
						onChange={value => setLocations(value)}
						placeholder={t('ALL_LOCATIONS_SELECTED')}
						textFieldProps={{
							label: t('LOCATIONS'),
							InputLabelProps: {
								shrink: true
							},
							variant: 'outlined'
						}}
						options={locationOptions}
						isMulti
					/>
				</Grid>
			)}
			{/* ------------------Sted filter end-----------------------*/}
			{/* ------------------Date picker Start-----------------------*/}
			{showDatePicker && (
				<Grid className="xs:px-8 md:px-32" item xs={12} md={4} lg={4}>
					<Typography className="text-center text-16 mt-24 mb-8" component="h2">
						{t('CHOOSE_DATE')}
					</Typography>

					<Button
						variant="outlined"
						className="w-full h-40 my-8 normal-case font-normal text-16 whitespace-no-wrap"
						classes={{
							root: 'justify-start'
						}}
						onClick={handleClickOnDatePicer}
						endIcon={<CalendarToday />}
					>
						{selectedDateRange && (selectedDateRange[0].startDate || selectedDateRange[0].endDate)
							? getLabelTime(selectedDateRange)
							: t('ALL_DATES_SELECTED')}
					</Button>
					<Popover
						id="popover-range"
						open={!!rangePickerAnchorEl}
						anchorEl={rangePickerAnchorEl}
						onClose={handleOnClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<DateRange locale={da} ranges={dateRange} onChange={item => handleChangeOnDatePicker(item)} />
						<Button
							className="mt-4 w-full"
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								cleared.current = true;
								handleChangeFromDatePicker([
									{
										startDate: null,
										endDate: null,
										key: 'selection'
									}
								]);
								handleOnClose();
							}}
						>
							Ryd
						</Button>
					</Popover>
				</Grid>
			)}
		</>
	);
}

export default EventFilter;
