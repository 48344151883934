import { IconButton, Menu, MenuItem } from '@mui/material';
import Icon from '@mui/material/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { selectModules } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import ConfirmationDialog from '@ameroservices-platform/attraction-frontend/app/ui-components/ConfirmationDialog';
import FuseLoading from '@ameroservices-platform/attraction-frontend/fuse/core/FuseLoading';
import {
	cancelSubscriptionPayment,
	createSubscriptionMandate,
	selectLoading,
	setLoading,
	updateSubscriptionPaymentType
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { getSubscriptionTypeCurrentEvent } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/subscription/SubscriptionFirestore';
import moment from 'moment-timezone';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import _ from 'lodash';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import clsx from 'clsx';

export default function AccountSubscriptionMoreSettings({ contentElementProps, subscription }) {
	const flags = useFlags();
	const modules = useSelector(selectModules);
	const dispatch = useDispatch();
	const loading = useSelector(selectLoading);
	const orders = useSelector(({ shared }) => shared.user.subscriptionOrders?.[subscription?.id]);
	const [open, setOpen] = useState(null);
	const { t } = useTranslation();
	const [automaticRenewalOpen, setAutomaticRenewalOpen] = useState(null);
	const [cancelAutomaticRenewalOpen, setCancelAutomaticRenewalOpen] = useState(null);
	const [renewalOpen, setRenewalOpen] = useState(null);
	const [currentEvent, setCurrentEvent] = useState(null);
	const renewalTime = useMemo(() => {
		if (currentEvent && currentEvent.start && currentEvent.end) {
			return `${moment(currentEvent.start).format('DD-MM-YYYY')} til ${moment(currentEvent.end).format(
				'DD-MM-YYYY'
			)}`;
		}
		return null;
	}, [currentEvent]);

	const latestOrder = useMemo(() => {
		if (!orders) {
			return null;
		}
		const _orders = _.cloneDeep(orders);
		const sortedOrders = _orders?.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});
		return sortedOrders?.[0];
	}, [orders]);

	async function handleRenewal(result) {
		if (result) {
			dispatch(setLoading(true));
			const response = await updateSubscriptionPaymentType(subscription.id, 'subscription-card');
			if (response && response.link) {
				window.location.href = response.link;
			}
			dispatch(setLoading(false));
		} else {
			setRenewalOpen(null);
		}
	}

	async function handleAutomaticRenewal(result) {
		if (result) {
			dispatch(setLoading(true));
			await updateSubscriptionPaymentType(subscription.id, 'subscription-automatic');
		} else {
			setAutomaticRenewalOpen(null);
		}
	}

	async function handleCancelAutomaticRenewal(result) {
		if (result) {
			dispatch(setLoading(true));
			await cancelSubscriptionPayment(subscription.id);
		}
		setCancelAutomaticRenewalOpen(null);
	}

	useEffect(() => {
		if (flags.pensoPaySubscription && subscription && automaticRenewalOpen) {
			if (flags.cancelSubscriptionPayment) {
				if (subscription && subscription.payment && subscription.payment.mandates) {
					const mandateToUse = subscription.payment.mandates.find(
						mandate => mandate.state === 'pending' && mandate.prepData && mandate.prepData.link
					);
					if (mandateToUse) {
						window.location.href = mandateToUse.prepData.link;
					}
				}
			} else if (
				subscription &&
				subscription.payment &&
				subscription.payment.mandates &&
				subscription.payment.mandates[0] &&
				subscription.payment.mandates[0].state === 'pending' &&
				subscription.payment.mandates[0].prepData &&
				subscription.payment.mandates[0].prepData.link
			) {
				window.location.href = subscription.payment.mandates[0].prepData.link;
			}
		}
	}, [flags.cancelSubscriptionPayment, flags.pensoPaySubscription, subscription, automaticRenewalOpen]);

	useEffect(() => {
		if (renewalOpen) {
			getSubscriptionTypeCurrentEvent(subscription.subscriptionTypeUid, subscription.currentEvent).then(event => {
				if (event) {
					setCurrentEvent(event);
				} else {
					setCurrentEvent(null);
				}
			});
		}
	}, [subscription, renewalOpen]);

	async function handleChangeSubscriptionPaymentCard() {
		const pendingMandate = subscription.payment.mandates.find(mandate => mandate.state === 'pending');
		if (pendingMandate) {
			window.location.href = pendingMandate.prepData.link;
		} else {
			const mandateResponse = await createSubscriptionMandate(subscription.id);
			window.location.href = mandateResponse.prepData.link;
		}
	}

	const options = useMemo(() => {
		const _options = [];
		if (subscription.state === 'renewal' && subscription.paymentType !== 'subscription-automatic') {
			_options.push('renew');
		}
		if (
			!flags.disableAutomaticRenewalButtonOnFrontend &&
			modules &&
			modules.subscriptions &&
			modules.subscriptions.automaticRenewal &&
			['renewal', 'active'].includes(subscription.state) &&
			subscription.paymentType !== 'subscription-automatic' &&
			subscription.paymentType !== 'free' &&
			subscription.paymentType !== 'test' &&
			subscription.subscriptionPrice !== 0
		) {
			_options.push('activateAutomaticRenewal');
		}
		if (
			['renewal', 'active'].includes(subscription.state) &&
			subscription.payment &&
			subscription.payment.mandates &&
			(flags.cancelSubscriptionPayment
				? subscription.payment.mandates.some(mandate => mandate.state === 'pending') &&
				  !subscription.payment.mandates.some(mandate => mandate.state === 'active')
				: subscription.payment.mandates[0] && subscription.payment.mandates[0].state === 'pending') &&
			subscription.paymentType === 'subscription-automatic' &&
			modules &&
			modules.subscriptions &&
			modules.subscriptions.automaticRenewal
		) {
			_options.push('addCreditCard');
		}
		if (
			flags.optionToChangeCreditcardOnSubscriptionUserPage &&
			['renewal', 'active'].includes(subscription.state) &&
			subscription.paymentType === 'subscription-automatic' &&
			subscription?.payment?.mandates?.some(mandate => mandate.state === 'active') &&
			modules?.subscriptions?.automaticRenewal
		) {
			_options.push('changeCard');
		}
		if (
			flags.cancelSubscriptionPayment &&
			['renewal', 'active'].includes(subscription.state) &&
			subscription.payment &&
			subscription.payment.mandates &&
			subscription.payment.mandates.some(mandate => mandate.state === 'active') &&
			subscription.paymentType === 'subscription-automatic' &&
			modules &&
			modules.subscriptions &&
			modules.subscriptions.automaticRenewal
		) {
			_options.push('deactivateRenewal');
		}
		return _options;
	}, [subscription, modules, flags.cancelSubscriptionPayment, flags.optionToChangeCreditcardOnSubscriptionUserPage]);

	const isTextLong = useMemo(() => {
		if (options.length != 1) {
			return false;
		}
		const option = options[0];
		const translateMap = {
			renew: 'RENEW',
			activateAutomaticRenewal: 'ACTIVATE_RENEWAL',
			addCreditCard: 'ADD_CREDITCARD',
			changeCard: 'ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD',
			deactivateRenewal: 'DEACTIVATE_RENEWAL'
		};
		return t(translateMap[option]).length >= 18;
	}, [options, t]);

	function handleAddCreditCard() {
		if (flags.cancelSubscriptionPayment) {
			window.location.href = subscription.payment.mandates.find(
				mandate => mandate.state === 'pending'
			).prepData.link;
		} else {
			window.location.href = subscription.payment.mandates[0].prepData.link;
		}
	}

	return (
		<>
			<div className={'flex flex-col items-center md:items-end gap-10'}>
				{options.length === 1 && (
					<>
						{options.includes('renew') && (
							<Button
								onClick={() => setRenewalOpen(subscription.id)}
								startIcon={<AddCircleOutlineIcon />}
								variant={'contained'}
							>
								{t('RENEW')}
							</Button>
						)}
						{options.includes('activateAutomaticRenewal') && (
							<Button
								onClick={() => setAutomaticRenewalOpen(subscription.id)}
								startIcon={<AutorenewIcon />}
								variant={'outlined'}
							>
								{t('ACTIVATE_RENEWAL')}
							</Button>
						)}
						{options.includes('addCreditCard') && (
							<Button onClick={handleAddCreditCard} startIcon={<AddCardIcon />} variant={'contained'}>
								{t('ADD_CREDITCARD')}
							</Button>
						)}
						{options.includes('changeCard') && (
							<Button
								onClick={() => handleChangeSubscriptionPaymentCard()}
								startIcon={<CreditCardIcon />}
								variant={'outlined'}
							>
								{t('ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD')}
							</Button>
						)}
						{options.includes('deactivateRenewal') && (
							<Button
								onClick={() => setCancelAutomaticRenewalOpen(subscription.id)}
								startIcon={<CancelIcon />}
								variant={'outlined'}
							>
								{t('DEACTIVATE_RENEWAL')}
							</Button>
						)}
					</>
				)}
				{options.length >= 2 && (
					<IconButton onClick={event => setOpen(event.currentTarget)}>
						<Icon>more_horiz</Icon>
					</IconButton>
				)}

				{contentElementProps.showAddToWallet &&
					flags.walletImplementation &&
					subscription?.organisationUid &&
					subscription?.customerUid &&
					latestOrder?.id && (
						<div
							className={clsx(
								'flex gap-10 items-center md:items-start',
								isTextLong ? 'flex-row' : 'flex-col'
							)}
						>
							<Button
								className={'p-0'}
								onClick={() =>
									window.open(
										`https://europe-west3-attraction-prod.cloudfunctions.net/apiApi/attraction/iosWallet/${subscription.organisationUid}/${subscription.customerUid}/${latestOrder.id}`,
										'_blank'
									)
								}
							>
								<img className={'h-32'} src={t('ADD_TO_APPLE_WALLET_LOGO')} />
							</Button>
							<Button
								className={'p-0'}
								onClick={() =>
									window.open(
										`https://europe-west3-attraction-prod.cloudfunctions.net/apiApi/attraction/androidWallet/${subscription.organisationUid}/${subscription.customerUid}/${latestOrder.id}`,
										'_blank'
									)
								}
							>
								<img className={'h-32'} src={t('ADD_TO_GOOGLE_WALLET_LOGO')} />
							</Button>
						</div>
					)}
			</div>
			<Menu open={!!open} anchorEl={open} onClose={() => setOpen(false)}>
				{options.includes('renew') && (
					<MenuItem onClick={() => setRenewalOpen(subscription.id)}>
						<ListItemIcon>
							<AddCircleOutlineIcon />
						</ListItemIcon>
						<ListItemText>Forny</ListItemText>
					</MenuItem>
				)}
				{options.includes('activateAutomaticRenewal') && (
					<MenuItem onClick={() => setAutomaticRenewalOpen(subscription.id)}>
						<ListItemIcon>
							<AutorenewIcon />
						</ListItemIcon>
						<ListItemText>{t('ACTIVATE_RENEWAL')}</ListItemText>
					</MenuItem>
				)}
				{options.includes('addCreditCard') && (
					<MenuItem onClick={handleAddCreditCard}>
						<ListItemIcon>
							<AddCardIcon />
						</ListItemIcon>
						<ListItemText>{t('ADD_CREDITCARD')}</ListItemText>
					</MenuItem>
				)}
				{options.includes('changeCard') && (
					<MenuItem onClick={() => handleChangeSubscriptionPaymentCard()}>
						<ListItemIcon>
							<CreditCardIcon />
						</ListItemIcon>
						<ListItemText>{t('ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD')}</ListItemText>
					</MenuItem>
				)}
				{options.includes('deactivateRenewal') && (
					<MenuItem onClick={() => setCancelAutomaticRenewalOpen(subscription.id)}>
						<ListItemIcon>
							<CancelIcon />
						</ListItemIcon>
						<ListItemText>{t('DEACTIVATE_RENEWAL')}</ListItemText>
					</MenuItem>
				)}
			</Menu>
			<ConfirmationDialog
				open={renewalOpen}
				onClose={result => handleRenewal(result)}
				okButton={t('GO_TO_PAYMENT')}
				cancelButton={t('CANCEL')}
				disableOk={!renewalTime}
				loading={loading}
				content={renewalTime ? `${t('MEMBERSHIP_RENEWAL_FROM')} ${renewalTime}` : <FuseLoading />}
			/>
			<ConfirmationDialog
				open={automaticRenewalOpen}
				onClose={result => handleAutomaticRenewal(result)}
				loading={loading}
				okButton={t('ACTIVATE')}
				cancelButton={t('CANCEL')}
				content={t('AUTOMATIC_RENEWAL_EXPLAINER')}
			/>
			{flags.cancelSubscriptionPayment && (
				<ConfirmationDialog
					open={cancelAutomaticRenewalOpen}
					onClose={result => handleCancelAutomaticRenewal(result)}
					loading={loading}
					okButton={t('DEACTIVATE')}
					cancelButton={t('CANCEL')}
					content={t('DEACTIVATE_RENEWAL_EXPLAINER')}
				/>
			)}
		</>
	);
}
