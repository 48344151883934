import React from 'react';
import { useSelector } from 'react-redux';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import Markdown from '../../../../../../ui-components/Markdown';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function Text(props) {
	const { contentElementProps } = props;
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	// To make sure old pages with fontSize = 'base', does not change after fixing font size.
	const fontSize = contentElementProps.fontSize === 'base' ? '14' : contentElementProps.fontSize;

	return (
		<div
			className={`w-full break-${contentElementProps.wordBreak} ${contentElementProps.tailwindClasses} text-${
				contentElementProps.textAlign
			} ${contentElementProps.smTextAlign ? `sm:text-${contentElementProps.smTextAlign}` : ``} ${
				contentElementProps.mdTextAlign ? `md:text-${contentElementProps.mdTextAlign}` : ``
			} ${contentElementProps.lgTextAlign ? `lg:text-${contentElementProps.lgTextAlign}` : ``} ${
				contentElementProps.xlTextAlign ? `xl:text-${contentElementProps.xlTextAlign}` : ``
			} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			style={{
				color: `${getColor(contentElementProps.color, contentElementProps.customColor, mergedTheme)}`
			}}
		>
			<Markdown
				source={contentElementProps.markdown.replace(/\n/gi, '&nbsp;\n\n')}
				isCmsComponent
				linkColor={contentElementProps.linkColor}
				fontSize={fontSize}
				lineHeight={contentElementProps.lineHeight}
				letterSpacing={contentElementProps.letterSpacing}
				fontFamily={contentElementProps.fontFamily}
			/>
		</div>
	);
}

export default Text;
