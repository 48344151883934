import { combineReducers } from '@reduxjs/toolkit';
import auth from '../auth/store';
import fuse from './fuse';
import shared from './shared';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		shared,
		...asyncReducers
	});

export default createReducer;
