import { ThemeProvider } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navbarCloseMobile } from '../../../../../store/fuse/navbarSlice';
import NavbarMobileToggleFab from '../../../../shared-components/NavbarMobileToggleFab';
import DarkNavbarLayout2 from './DarkNavbarLayout2';
import DarkNavbarMobileLayout2 from './DarkNavbarMobileLayout2';

function DarkHeader({ parentClasses, mergedTheme, config }) {
	const dispatch = useDispatch();
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	return (
		<>
			<ThemeProvider theme={mergedTheme}>
				<Hidden mdDown>
					{config.hideNavigation ? (
						<Paper
							className={clsx(parentClasses.navbarDark, parentClasses.checkOutNav)}
							square
							elevation={2}
						>
							<DarkNavbarLayout2 />
						</Paper>
					) : (
						<Paper className={clsx(parentClasses.navbarDark)} square elevation={2}>
							<DarkNavbarLayout2 />
						</Paper>
					)}
				</Hidden>

				<Hidden lgUp>
					<Drawer
						anchor="left"
						variant="temporary"
						open={navbar.mobileOpen}
						classes={{
							paper: parentClasses.navbarMobile,
						}}
						onClose={(ev) => dispatch(navbarCloseMobile())}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<DarkNavbarMobileLayout2 />
					</Drawer>
				</Hidden>
			</ThemeProvider>

			{config.navbar.display && !config.toolbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleFab />
				</Hidden>
			)}
		</>
	);
}

export default DarkHeader;
