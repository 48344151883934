import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const getEventGroups = async (eventGroupUids, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	const docs = await Promise.all(eventGroupUids.map(uid => db.collection('eventGroups').doc(uid).get()));
	const eventGroups = {};

	docs.forEach(doc => {
		const data = doc.data();
		if (data.showOnWeb === null || data.showOnWeb === undefined || data.showOnWeb === true) {
			eventGroups[doc.id] = {
				...data,
				id: doc.id
			};
		}
	});
	callback(eventGroups);
};

export const getEventGroupSummarys = async eventGroupUid => {
	const db = firebaseService.getOrganisationRootDB();
	const docs = await db.collection('eventGroups').doc(eventGroupUid).collection('groupSummaryByMonth').get();
	const summarys = [];

	docs.forEach(doc =>
		summarys.push({
			...doc.data(),
			id: doc.id,
			parent: doc.ref.parent.parent.id
		})
	);
	return summarys;
};

export const getProducts = async (ticketTypeIds, eventGroupUid) => {
	if (!ticketTypeIds || ticketTypeIds.length === 0) {
		return [];
	}
	const db = firebaseService.getOrganisationRootDB();
	const query = await db
		.collection('products')
		.where('ticketTypeUid', 'in', ticketTypeIds)
		.where('deleted', '==', false)
		.where('state', '==', 'active')
		.where('showOnWeb', '==', true)
		.get();
	const products = [];
	query.forEach(doc => {
		const docData = doc.data();
		products.push({
			...docData,
			id: doc.id,
			eventGroupUid
		});
	});
	products.sort((p1, p2) => p1.order - p2.order);
	return products;
};
