import React, {lazy} from 'react';

const CmsContentPage = lazy(() => import('./cmsContent/cmsContentPage/CmsContentPage'));

const ContentPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/:uri',
			element: <CmsContentPage />
		}
	]
};

export default ContentPageConfig;
