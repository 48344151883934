import React from 'react';
import { Skeleton } from '@mui/material';
import { Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { findPricesForProduct } from '../../../../../../utilities';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});
function SubscriptionPrice(props) {
	const flags = useFlags();
	const { contentElementProps } = props;
	const [products, setProducts] = React.useState(null);
	const [subscriptionType, setSubscriptionType] = React.useState(null);

	React.useEffect(() => {
		if (subscriptionType) {
			const unsubFuncs = Object.values(subscriptionType.selectedProducts).map(selectedProduct => {
				return firebaseService
					.getOrganisationRootDB()
					.collection('products')
					.doc(selectedProduct.product)
					.onSnapshot(doc => {
						setProducts(oldState => ({ ...oldState, [doc.id]: doc.data() }));
					});
			});
			return () => unsubFuncs.forEach(func => func());
		}
		return () => {};
	}, [subscriptionType]);

	React.useEffect(() => {
		if (contentElementProps && contentElementProps.subscriptionTypeUid) {
			const unsubFunc = firebaseService
				.getOrganisationRootDB()
				.collection('subscriptionTypes')
				.doc(contentElementProps.subscriptionTypeUid)
				.onSnapshot(doc => {
					setSubscriptionType({ ...doc.data(), id: doc.id });
				});
			return () => unsubFunc();
		}
		return () => {};
	}, [contentElementProps]);

	const price = React.useMemo(() => {
		if (!products) {
			return null;
		}
		if (!subscriptionType.samePriceAsAllProducts) {
			return subscriptionType.subscriptionPrice / 100;
		}
		return Object.values(products).reduce(
			(acc, cur) => acc + findPricesForProduct(cur, null, new Date(), flags.displayPeriodePrices) / 100,
			0
		);
	}, [subscriptionType, products, flags.displayPeriodePrices]);

	return (
		<Typography
			className={`w-full ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{products && (
				<>
					{price % 1 === 0 && <>{price},-</>}
					{price % 1 !== 0 && <>{moneyFormatter.format(price)}</>}
				</>
			)}
			{!products && <Skeleton />}
		</Typography>
	);
}

export default SubscriptionPrice;
