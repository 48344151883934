import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import StorageImage from '@ameroservices-platform/attraction-frontend/app/ui-components/StorageImage';
import { useSelector } from 'react-redux';
import { selectLogos } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			width: 100,
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function Logo() {
	const classes = useStyles();
	const logos = useSelector(selectLogos);

	return (
		<div className={clsx(classes.root, 'flex items-center')}>
			<StorageImage alt="logo" className="logo-icon" src={logos.header} />
			{/* <img className="logo-icon" src="assets/images/logos/jorn-logo.png" alt="logo" /> */}
		</div>
	);
}

export default Logo;
