import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import EventFilter from '../../../../../ui-components/EventFilter';
import {
	getEventGroups,
	getEventGroupSummarys,
	getProducts
} from '../manual-event-overview/ManualEventOverviewFirestore';
import ManualEventTableRow from './ManualEventTableRow';
import { getList, selectGoogleTagManager, selectLists } from '../../../../../store/shared/frontendSlice';
import { tagManagerViewItemList } from '../../../../../services/googleTagManagerService/googleTagManagerService';

const sortViewingGroupsByMonthAndYear = (a, b) => {
	const aYear = Number(a[0].split('-')[0]);
	const aMonth = Number(a[0].split('-')[1]);
	const bYear = Number(b[0].split('-')[0]);
	const bMonth = Number(b[0].split('-')[1]);
	if (aYear === bYear) {
		return aMonth - bMonth;
	}
	return aYear - bYear;
};

const sortByMonth = (a, b) => {
	const monthA = a.month.split('-').map(n => Number.parseInt(n, 10));
	const monthB = b.month.split('-').map(n => Number.parseInt(n, 10));
	if (monthA[1] === monthB[1]) {
		if (monthA[0] === monthB[0]) {
			return 0;
		}
		if (monthA[0] > monthB[0]) {
			return 1;
		}
		if (monthA[0] < monthB[0]) {
			return -1;
		}
	}
	if (monthA[1] > monthB[1]) {
		return 1;
	}
	return -1;
};
const sortByPrice = (a, b) => {
	const priceA = a.prices.find(price => price.type === 'Sale').price;
	const priceB = b.prices.find(price => price.type === 'Sale').price;
	if (priceA === priceB) {
		return 0;
	}
	if (priceA > priceB) {
		return 1;
	}
	return -1;
};

const filterForFinishedEvents = summery => {
	const today = new Date();
	const filteredSummery = Object.entries(summery.dates).reduce((acc, [key, date]) => {
		const timesFiltered = Object.entries(date.times).reduce((timesAcc, [timesKey, time]) => {
			const endTime = time.end.toDate().getTime();

			if (endTime > today.getTime()) {
				timesAcc[timesKey] = time;
			}
			return timesAcc;
		}, {});

		acc[key] = {
			...date,
			times: timesFiltered
		};
		return acc;
	}, {});

	return {
		...summery,
		dates: filteredSummery
	};
};

function ManualEventTablePage({ data, loadingFinished }) {
	const { usingAttribute } = data;
	const lists = useSelector(selectLists);
	const [eventGroups, setEventGroups] = useState(null);
	const [viewingEventGroups, setViewingEventGroups] = useState(null);
	const [viewingEvents, setViewingEvents] = useState(null);
	const [categoriesOptions, setCategoriesOptions] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [locations, setLocations] = useState([]);
	const [selectedDateRange, setSelectedDateRange] = useState([
		{
			startDate: null,
			endDate: null,
			key: 'selection'
		}
	]);
	const [openMonth, setOpenMonth] = useState({});

	const googleTagManager = useSelector(selectGoogleTagManager);
	useEffect(() => {
		if (googleTagManager && viewingEvents && Object.values(viewingEvents).length > 0) {
			const items = [];
			Object.values(viewingEvents).forEach(eventArray => {
				eventArray.forEach(event => {
					items.push({
						item_id: event.eventUid,
						item_name: event.originalEventName || event.name,
						item_type: event.type
					});
				});
			});
			tagManagerViewItemList(items);
		}
	}, [googleTagManager, viewingEvents]);

	useEffect(() => {
		const ids = getList(lists, data.eventGroups).eventGroup;
		getEventGroups(ids, async eventGroupsData => {
			const eventGroupSummaries = await Promise.all(
				Object.values(eventGroupsData).map(async eg => getEventGroupSummarys(eg.id))
			);

			const onlyEventGroupsWithEvents = eventGroupSummaries.filter(group => {
				return group.length > 0;
			});

			const parentUidsOfEventGroupsWithEvents = onlyEventGroupsWithEvents
				.map(eventGroup => {
					const value = eventGroup.map(eg => {
						return eg.parent;
					});
					return [...new Set(value)];
				})
				.reduce((acc, cur) => {
					return acc.concat(cur);
				}, []);

			const hello = parentUidsOfEventGroupsWithEvents.reduce((acc, cur) => {
				acc[cur] = eventGroupsData[cur];
				return acc;
			}, {});

			const _eventGroups = { ...hello };
			onlyEventGroupsWithEvents.forEach(_eventGroupsSummaries => {
				_eventGroupsSummaries.sort(sortByMonth);

				_eventGroupsSummaries = _eventGroupsSummaries.map(filterForFinishedEvents);

				const firstSummary = _eventGroupsSummaries[0];
				const lastSummary = _eventGroupsSummaries[_eventGroupsSummaries.length - 1];

				const eventGroupUid = firstSummary.parent;
				const startMonth = firstSummary.month.split('-');
				const endMonth = lastSummary.month.split('-');
				// eslint-disable-next-line prefer-destructuring
				_eventGroups[eventGroupUid].startDate = moment({
					year: Number.parseInt(startMonth[1], 10),
					month: Number.parseInt(startMonth[0], 10) - 1,
					day: Number.parseInt(Object.keys(firstSummary.dates)[0], 10),
					hour: 0,
					minute: 0,
					second: 0
				});
				const endDates = Object.keys(lastSummary.dates);
				_eventGroups[eventGroupUid].endDate = moment({
					year: Number.parseInt(endMonth[1], 10),
					month: Number.parseInt(endMonth[0], 10) - 1,
					day: Number.parseInt(endDates[endDates.length - 1], 10),
					hour: 0,
					minute: 0,
					second: 0
				});

				_eventGroups[eventGroupUid].locations = firstSummary.locations;
				_eventGroups[eventGroupUid].attributes = firstSummary.attributes;
				_eventGroups[eventGroupUid].groupSummaries = _eventGroupsSummaries;
			});

			const products = await Promise.all(
				Object.values(_eventGroups).map(async eg => getProducts(eg.ticketTypes, eg.id))
			);

			products.forEach(_products => {
				const firstProduct = _products[0];

				if (!firstProduct) return;

				const { eventGroupUid } = firstProduct;
				_products.sort(sortByPrice);

				const minPrice = firstProduct.prices.find(p => p.type === 'Sale');

				_eventGroups[eventGroupUid].minPrice = minPrice ? minPrice.price : 0;
				_eventGroups[eventGroupUid].products = _products;
			});

			// eslint-disable-next-line no-restricted-syntax
			for (const eventGroupUid in _eventGroups) {
				if ({}.hasOwnProperty.call(_eventGroups, eventGroupUid)) {
					if (!_eventGroups[eventGroupUid].products || !_eventGroups[eventGroupUid].products.length)
						delete _eventGroups[eventGroupUid];
				}
			}

			setEventGroups(Object.values(_eventGroups));
		}).catch(err => console.error(err));
	}, [lists, data.eventGroups]);

	// Updating options for categories
	useEffect(() => {
		if (eventGroups) {
			const catOptions = eventGroups
				.filter(f => {
					if (!f.groupSummaries[0].attributes) return false;
					const attributeGroup = f.groupSummaries[0].attributes.find(att => att.id === usingAttribute);
					return !!attributeGroup;
				})
				.reduce((acc, cur) => {
					const attributeGroup = cur.groupSummaries[0].attributes.find(att => att.id === usingAttribute);
					attributeGroup.values.forEach(attribute => {
						if (!acc.find(f => f.value === attribute.id)) {
							acc.push({ value: attribute.id, label: attribute.name });
						}
					});
					return acc;
				}, []);
			setCategoriesOptions([...catOptions]);
		}
	}, [eventGroups, usingAttribute]);

	// Updating options for locations
	useEffect(() => {
		if (eventGroups) {
			const locOptions = eventGroups.reduce((acc, cur) => {
				if (!cur.groupSummaries[0].locations) return acc;
				cur.groupSummaries[0].locations.forEach(location => {
					if (!acc.find(f => f.value === location.id)) {
						acc.push({ value: location.id, label: location.name });
					}
				});
				return acc;
			}, []);
			setLocationOptions([...locOptions]);
		}
	}, [eventGroups]);

	// Filter eventGroup array by locations & categories
	useEffect(() => {
		if (eventGroups) {
			let tempEventGroups = eventGroups;
			if (locations.length > 0) {
				tempEventGroups = tempEventGroups.filter(eventGroup =>
					eventGroup.locations.find(loc => locations.find(l => l.value === loc.id))
				);
			}
			if (categories.length > 0) {
				tempEventGroups = tempEventGroups.filter(eventGroup => {
					const attributeGroup = eventGroup.attributes.find(a => a.id === usingAttribute);
					if (attributeGroup) {
						return attributeGroup.values.find(attribute => categories.find(c => c.value === attribute.id));
					}
					return false;
				});
			}
			if (selectedDateRange && (selectedDateRange[0].startDate || selectedDateRange[0].endDate)) {
				const start = moment(selectedDateRange[0].startDate);
				const end = moment(selectedDateRange[0].endDate);
				tempEventGroups = tempEventGroups.filter(eventGroup => {
					const startDate = moment(eventGroup.startDate);
					const endDate = moment(eventGroup.endDate);
					return (
						start.isBetween(startDate, endDate) ||
						end.isBetween(startDate, endDate) ||
						(start.isBefore(endDate) && start.isAfter(startDate)) ||
						(start.isBefore(startDate) && end.isAfter(endDate)) ||
						startDate.isSame(start) ||
						startDate.isSame(end) ||
						endDate.isSame(start) ||
						endDate.isSame(end)
					);
				});
			}
			setViewingEventGroups(tempEventGroups);
		}
	}, [eventGroups, locations, categories, selectedDateRange, usingAttribute]);

	useEffect(() => {
		const tempEvents = {};
		if (viewingEventGroups) {
			viewingEventGroups.forEach(eventGroup => {
				eventGroup.groupSummaries.forEach(groupSummary => {
					Object.values(groupSummary.dates).forEach(date => {
						Object.values(date.times).forEach(time => {
							const start = time.start.toDate();
							const end = time.end.toDate();
							const key = `${start.getFullYear()}-${start.getMonth()}`;
							if (!tempEvents[key]) {
								tempEvents[key] = [];
							}
							tempEvents[key].push({
								...time,
								startRaw: start,
								start: moment(start),
								end: moment(end),
								eventGroupUid: eventGroup.id,
								ticketTypes: eventGroup.ticketTypes,
								locations:
									groupSummary.locations.length > 0
										? groupSummary.locations.map(loc => loc.name).join(', ')
										: '',
								hideTicketAmountAbovePercent: eventGroup.hideTicketAmountAbovePercent,
								showTextOnPercentage: eventGroup.showTextOnPercentage,
								textOnTicketAmount: eventGroup.textOnTicketAmount
							});
						});
					});
				});
			});
		}
		Object.keys(tempEvents).forEach(key => {
			tempEvents[key].sort((a, b) => a.startRaw - b.startRaw);
		});
		setViewingEvents(tempEvents);
		loadingFinished();
	}, [viewingEventGroups, loadingFinished]);

	// console.log('viewingEvents', viewingEvents);
	// console.log('viewingEventGroups', viewingEventGroups);

	useEffect(() => {
		if (viewingEvents) {
			const [firstMonthKey] = Object.keys(viewingEvents);
			if (firstMonthKey) {
				setOpenMonth(oldState => {
					if (!Object.values(oldState).includes(true)) {
						return {
							[firstMonthKey]: true
						};
					}
					return oldState;
				});
			}
		}
	}, [viewingEvents]);

	const handleChangeFromDatePicker = dateRange => {
		setSelectedDateRange(dateRange);
	};

	function getNameOfMonth(month) {
		const date = month.split('-');
		return moment(`${Number.parseInt(date[0], 10)} ${Number.parseInt(date[1], 10) + 1}`, 'YYYY M').format(
			'MMMM YYYY'
		);
	}

	return (
		<div className="max-w-3xl my-48 sm:my-48 mx-12 md:mx-16 lg:min-w-2xl flex justify-center">
			<Paper elevation={3} className="p-4 py-28 md:p-28 flex flex-row w-full">
				<Grid container className="">
					<Grid className="max-h-32" item xs={12} xl={12}>
						<Typography variant="h5" className="text-center">
							{data.content ? data.content.heading : ''}
						</Typography>
					</Grid>
					{/* ------------------Kategori filter start-----------------------*/}
					<EventFilter
						selectedDateRange={selectedDateRange}
						handleChangeFromDatePicker={handleChangeFromDatePicker}
						locations={locations}
						setLocations={setLocations}
						locationOptions={locationOptions}
						categories={categories}
						setCategories={setCategories}
						categoriesOptions={categoriesOptions}
					/>
					{/* ------------------Date picker end-----------------------*/}
					{viewingEvents &&
						Object.entries(viewingEvents)
							.sort(sortViewingGroupsByMonthAndYear)
							.map(([month, events]) => (
								<React.Fragment key={month}>
									<Grid item xs={12} md={12} className="px-8">
										<Typography
											onClick={() => {
												setOpenMonth(oldState => {
													const tempState = { ...oldState };
													tempState[month] = !tempState[month];
													return tempState;
												});
											}}
											variant="h6"
											className="pl-8 flex items-center hover:cursor-pointer"
										>
											<Icon>{openMonth[month] ? 'arrow_drop_down' : 'arrow_right'}</Icon>{' '}
											{getNameOfMonth(month)}
										</Typography>
									</Grid>

									{openMonth[month] && (
										<>
											{/* --------------------- Begivenhed container start----------------------- */}

											{events.map(event => (
												<React.Fragment key={event.eventUid}>
													<ManualEventTableRow
														event={event}
														readMoreLinkTo={data.readMoreLinkTo}
														readMoreButtonColor={data.readMoreButtonColor}
														buyButtonColor={data.buyButtonColor}
													/>
												</React.Fragment>
											))}
											{/* ---------------------Begivenhed container end----------------------- */}
										</>
									)}
								</React.Fragment>
							))}
				</Grid>
			</Paper>
		</div>
	);
}

export default ManualEventTablePage;
