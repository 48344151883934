import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const getProduct = async productUid => {
	const db = firebaseService.getOrganisationRootDB();
	const doc = await db.collection('products').doc(productUid).get();
	const data = doc.data();
	if (!data || data.deleted || data.state === 'inactive') {
		return null;
	}
	return { ...data, id: doc.id };
};

export const orderListener = (customerUid, orderUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.onSnapshot(q => {
			callback({ ...q.data(), id: q.id });
		});
};

export const moveSubscription = async data => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsMoveSubscription', data);
	return resp.data;
};

export const createSubscription = async subscriptionTypeUid => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsCreateSubscription', {
		subscriptionTypeUid,
		frontend: true
	});
	return resp.data;
};

export const validateRebooking = async (ticketNumbers, controlNumber) => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerTicketsValidateRebooking', {
		ticketNumbers,
		controlNumber
	});
	return resp.data;
};

export const applyCode = async (subscriptionUid, code) => {
	const resp = await firebaseService.callFunctionByName(
		'organisationCustomerSubscriptionsApplyCode',
		{
			subscriptionUid,
			code
		},
		true
	);
	return resp.data;
};
export const removeCode = async (subscriptionUid, code) => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsRemoveCode', {
		subscriptionUid,
		code
	});
	return resp.data;
};
export const confirmSubscription = async (subscriptionUid, paymentSubscription, gift, options) => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsConfirmSubscription', {
		subscriptionUid,
		gift,
		paymentSubscription,
		options,
		frontend: true
	});
	return resp.data;
};
export const updateSubscription = async (customerUid, subscriptionUid, data) => {
	const result = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsUpdateSubscription', {
		...data,
		customerUid,
		subscriptionUid
	});
	return result.data;
};
export const getSubscriptionTypeCurrentEvent = async (subscriptionTypeUid, currentEvent = null) => {
	const result = await firebaseService.callFunctionByName(
		'organisationSubscriptionTypeGetSubscriptionTypeCurrentEvent',
		{
			subscriptionTypeUid,
			currentEvent
		}
	);
	return result.data;
};
