import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Paper, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Add, ArrowForward, Remove } from '@mui/icons-material';
import moment from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import {
	eventGroupListener,
	groupSummaryListener,
	productsListener
} from '../../../types/manual-event-detail/ManualEventDetailFirestore';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { productGroupsListener } from '../../../types/event-group-date-time-selector/EventGroupDateTimeSelectorFirestore';
import ProductTotalSection from '../shared/ProductSection';
import ProductGroupRow from '../shared/ProductGroupRow';
import ProductRow from '../shared/ProductRow';
import { findPricesForProduct, getLocaleFromData } from '../../../../../../utilities';

/* const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 }
})); */

const sortByTime = (a, b) => {
	return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
};

function filterForDisclaimers(product) {
	return !!product.disclaimer;
}

function ManualEventTicketSelector(props) {
	const { contentElementProps } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		badge: {
			backgroundColor: _theme.palette.error.main,
			color: _theme.palette.getContrastText(_theme.palette.error.main)
		},
		number: {
			backgroundColor: _theme.palette.primary.main,
			color: _theme.palette.getContrastText(_theme.palette.error.main)
		},
		root: {
			position: 'relative'
		},
		grid: { flexGrow: 1 },
		buyButton: {
			color: `${getColor(
				contentElementProps.buyButtonTextColor,
				contentElementProps.customBuyButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buyButtonBackgroundColor,
				contentElementProps.customBuyButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buyButtonTextColorHover,
					contentElementProps.customBuyButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buyButtonBackgroundColorHover,
					contentElementProps.customBuyButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [products, setProducts] = useState([]);
	const [showingProducts, setShowingProducts] = useState([]);
	const [groupSummaries, setGroupSummaries] = useState([]);
	const [events, setEvents] = useState([]);
	const [eventGroup, setEventGroup] = useState(null);
	const [ticketCount, setTicketCount] = useState(0);
	const [queries, setQueries] = useState({});
	const [selectedGroupBy, setSelectedGroupBy] = useState({});
	const [disclaimers, setDisclaimers] = useState({});
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [productGroups, setProductGroups] = useState({});
	const { t } = useTranslation();

	const location = useLocation();

	const flags = useFlags();
	const groupByRefs = useRef();

	useEffect(() => {
		let productGroupsListenerUnsubFuncs = [];
		if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			eventGroup &&
			eventGroup.ticketTypes &&
			eventGroup.ticketTypes.length > 0
		) {
			productGroupsListenerUnsubFuncs = productGroupsListener(eventGroup.ticketTypes, (id, item) => {
				setProductGroups(oldState => {
					return { ...oldState, [id]: item };
				});
			});
		}
		return () => {
			productGroupsListenerUnsubFuncs.forEach(f => f());
		};
	}, [eventGroup, flags.maxAmountOfProductPerProductGroupInCart]);

	useEffect(() => {
		const q = location.search.substring(1);
		const splitted = q.split('&');
		const tempQueries = {};
		splitted.forEach(split => {
			const keyValue = split.split('=');
			// eslint-disable-next-line prefer-destructuring
			tempQueries[keyValue[0]] = keyValue[1];
		});
		setQueries(tempQueries);
	}, [location.search]);

	useEffect(() => {
		if (queries && queries.e) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(queries.e, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [queries, queries.e]);

	useEffect(() => {
		if (eventGroup) {
			const summariesListenerUnsubFunc = groupSummaryListener(eventGroup.id, o => setGroupSummaries(o));
			return () => summariesListenerUnsubFunc();
		}
		return () => {};
	}, [eventGroup]);

	useEffect(() => {
		if (groupSummaries) {
			const now = moment().toDate();
			const tempEvents = [];
			groupSummaries.forEach(summary => {
				Object.values(summary.dates).forEach(date => {
					Object.values(date.times).forEach(time => {
						const start = moment(time.start.toDate()).toDate();
						const end = moment(time.end.toDate()).toDate();
						if (end > now) {
							tempEvents.push({ ...time, start, end });
						}
					});
				});
			});
			tempEvents.sort(sortByTime);
			tempEvents.splice(30);
			setEvents(tempEvents);
		}
	}, [groupSummaries]);

	useEffect(() => {
		if (
			eventGroup &&
			eventGroup.ticketTypes.length > 0 &&
			contentElementProps !== undefined &&
			contentElementProps !== null
		) {
			const productsListenerUnsubFunc = productsListener(eventGroup.ticketTypes, productList => {
				const copyOfProducts = productList;
				if (!flags.disclaimerAndSortOnGroupedProducts) {
					const productsWithDisclaimer = productList.filter(filterForDisclaimers);
					const indexes = {};
					let index = 0;
					productsWithDisclaimer.forEach((product, i) => {
						if (
							product.disclaimer &&
							(indexes[
								flags.multiLanguageFunctions
									? getLocaleFromData(product, 'disclaimer')
									: product.disclaimer
							] === undefined ||
								indexes[
									flags.multiLanguageFunctions
										? getLocaleFromData(product, 'disclaimer')
										: product.disclaimer
								] === null)
						) {
							indexes[
								flags.multiLanguageFunctions
									? getLocaleFromData(product, 'disclaimer')
									: product.disclaimer
							] = index;
							index += 1;
						}
					});
					productsWithDisclaimer.forEach((product, i) => {
						if (product.disclaimer) {
							product.disclaimerIndex =
								indexes[
									flags.multiLanguageFunctions
										? getLocaleFromData(product, 'disclaimer')
										: product.disclaimer
								];
						}
					});
					setDisclaimers(indexes);
				}
				setProducts(copyOfProducts);
			});
			return () => {
				productsListenerUnsubFunc();
			};
		}
		if (eventGroup && eventGroup.ticketTypes.length > 0) {
			const productsListenerUnsubFunc = productsListener(eventGroup.ticketTypes, o => setProducts(o));
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, contentElementProps, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	const disclaimersForShowingProducts = useMemo(() => {
		if (!flags.disclaimerAndSortOnGroupedProducts) {
			return null;
		}
		const productsWithDisclaimer = showingProducts.filter(filterForDisclaimers);
		const indexes = {};
		let index = 0;
		productsWithDisclaimer.forEach((product, i) => {
			if (
				product.disclaimer &&
				(indexes[
					flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
				] === undefined ||
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					] === null)
			) {
				indexes[product.disclaimer] = index;
				index += 1;
			}
		});
		productsWithDisclaimer.forEach((product, i) => {
			if (product.disclaimer) {
				product.disclaimerIndex =
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					];
			}
		});
		return indexes;
	}, [showingProducts, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] =
				location &&
				location.state &&
				location.state.productsWithAmount &&
				location.state.productsWithAmount[product.id]
					? location.state.productsWithAmount[product.id]
					: 0;
		});
		setProductsWithAmount(pro);
	}, [products, location]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const location = eventGroup && eventGroup.locations ? eventGroup.locations[0] : null;
				const price = findPricesForProduct(product, location, null, flags.displayPeriodePrices);
				totals += price * productsWithAmount[pKey];
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products, eventGroup, flags.displayPeriodePrices]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		const groupByProductGroups = Object.values(productGroups).filter(pg => pg.groupByName);
		if (groupByProductGroups.length > 0) {
			const newProducts = products.filter(p => !groupByProductGroups.some(pg => pg.id === p.ticketTypeUid));
			const productsWithGroupBy = products.filter(p =>
				groupByProductGroups.some(pg => pg.id === p.ticketTypeUid)
			);
			const groupBy = productsWithGroupBy.reduce((accProducts, curProduct) => {
				if (!accProducts[curProduct.ticketTypeUid]) {
					accProducts[curProduct.ticketTypeUid] = [];
				}
				accProducts[curProduct.ticketTypeUid].push(curProduct);
				return accProducts;
			}, {});
			Object.entries(groupBy).forEach(([key, value]) => {
				const prices = value.reduce((acc, cur) => {
					const p = cur.prices.find(pr => pr.type === 'Sale');
					if (p) {
						acc.push(p.price);
					} else {
						acc.push(0);
					}
					return acc;
				}, []);
				prices.sort((a, b) => a - b);
				const lowestPrice = prices[0];
				const highestPrice = prices[prices.length - 1];
				const options = [...value].sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
				newProducts.push({
					id: key,
					name: flags.multiLanguageFunctions
						? getLocaleFromData(productGroups[key], 'groupByName')
						: productGroups[key].groupByName,
					lowestPrice,
					highestPrice,
					samePrice: lowestPrice === highestPrice,
					type: 'productGroup',
					items: value.reduce((acc, cur) => {
						acc[cur.id] = cur;
						return acc;
					}, {}),
					options,
					order: flags.disclaimerAndSortOnGroupedProducts ? productGroups[key].groupBySort : 9999,
					disclaimer: flags.disclaimerAndSortOnGroupedProducts
						? flags.multiLanguageFunctions
							? getLocaleFromData(productGroups[key], 'groupByDisclaimer')
							: productGroups[key].groupByDisclaimer
						: null
				});
			});
			newProducts.sort((p1, p2) => p1.order - p2.order);
			setShowingProducts(newProducts);
		} else {
			setShowingProducts(products);
		}
	}, [products, productGroups, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	const updateProductAmount = (id, amount) => {
		setProductsWithAmount(oldState => ({
			...oldState,
			[id]: amount
		}));
	};

	const toolTipTitleAdd = (product, variant) => {
		if (variant) {
			if (
				flags.maxAmountOfProductPerProductGroupInCart &&
				selectedGroupBy[product.id] &&
				selectedGroupBy[product.id].ticketTypeUid &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid] &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount &&
				productsWithAmount[selectedGroupBy[product.id].id] >=
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
			) {
				return `Du kan maksimalt købe ${
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
				} af dette produkt`;
			}
		} else if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			product.ticketTypeUid &&
			productGroups[product.ticketTypeUid] &&
			productGroups[product.ticketTypeUid].maxTickets &&
			productGroups[product.ticketTypeUid].maxTickets.amount &&
			productsWithAmount[product.id] >= productGroups[product.ticketTypeUid].maxTickets.amount
		) {
			return `Du kan maksimalt købe ${productGroups[product.ticketTypeUid].maxTickets.amount} af dette produkt`;
		}
		return '';
	};

	const toolTipTitleRemove = product => {
		if (flags.minimumAmountOfProduct && product.minimumAmount) {
			return `Du skal minimum købe ${product.minimumAmount} af dette produkt`;
		}
		return '';
	};

	const free = useMemo(() => {
		return showingProducts.every(product => {
			if (!product.prices) {
				return false;
			}
			const _price = product.prices.find(p => p.type === 'Sale');

			return _price.price === 0;
		});
	}, [products, showingProducts]);

	const hasProducts = useMemo(() => {
		if (!flags.eventGroupsWithoutProducts) {
			return true;
		}
		return showingProducts && showingProducts.length > 0;
	}, [products, flags.eventGroupsWithoutProducts, showingProducts]);

	return (
		<div className={clsx(classes.grid, 'max-w-screen py-48 sm:py-48 lg:min-w-2xl')}>
			<Grid container spacing={0}>
				{/* ---------------Vælg billetter start-----------------*/}
				{(!flags.eventGroupsWithoutProducts || hasProducts) && (
					<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16 w-full">
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className="flex flex-col whitespace-no-wrap px-10 pt-0"
						>
							<Typography variant="h6" className="font-bold whitespace-no-wrap pt-10 pb-4">
								{t('CHOOSE_TICKETS')}
							</Typography>
							{flags.productsOnManualEvensRefactor && (
								<ProductTotalSection
									classes={classes}
									totalAmount={totalAmount}
									disclaimers={
										(flags.disclaimerAndSortOnGroupedProducts
											? disclaimersForShowingProducts
											: disclaimers) || null
									}
									highlightedDisclaimer={highlightedDisclaimer}
								>
									{showingProducts.map(product => {
										if (product.type === 'productGroup') {
											return (
												<ProductGroupRow
													key={product.id}
													product={product}
													productGroups={productGroups}
													productsWithAmount={productsWithAmount}
													setProductsWithAmount={setProductsWithAmount}
													groupByRefs={groupByRefs}
													setHighlightedDisclaimer={setHighlightedDisclaimer}
													updateProductAmount={updateProductAmount}
													selectedGroupBy={selectedGroupBy}
													setSelectedGroupBy={setSelectedGroupBy}
													addButtonToolTip={toolTipTitleAdd(product, true)}
													removeButtonToolTip={toolTipTitleRemove(product)}
												/>
											);
										}
										return (
											<ProductRow
												key={product.id}
												productGroups={productGroups}
												product={product}
												classes={classes}
												amount={productsWithAmount[product.id]}
												setHighlightedDisclaimer={setHighlightedDisclaimer}
												priceForProduct={findPricesForProduct(
													product,
													null,
													null,
													flags.displayPeriodePrices
												)}
												addButtonToolTip={toolTipTitleAdd(product, false)}
												removeButtonToolTip={toolTipTitleRemove(product)}
												updateProductAmount={updateProductAmount}
												showDisclaimer
											/>
										);
									})}
								</ProductTotalSection>
							)}

							{!flags.productsOnManualEvensRefactor && (
								<>
									{products.map(product => (
										<div className="flex justify-between items-center" key={product.id}>
											<div className="flex justify-between w-full">
												<Typography
													className={`font-bold whitespace-normal cursor-default ${classes.text}`}
													onMouseOver={() => {
														if (
															product.disclaimerIndex !== null &&
															product.disclaimerIndex !== undefined
														) {
															setHighlightedDisclaimer(product.disclaimerIndex);
														}
													}}
													onMouseLeave={() => {
														if (
															product.disclaimerIndex !== null &&
															product.disclaimerIndex !== undefined
														) {
															setHighlightedDisclaimer(null);
														}
													}}
												>
													{/* className="font-bold text-14 md:text-base"> */}
													{product.name}
													&nbsp;
													{product.disclaimer ? '*'.repeat(product.disclaimerIndex + 1) : ''}
												</Typography>
												<Typography
													className={`px-8 font-bold flex items-center ${classes.text}`}
												>
													{product.prices.find(f => f.type === 'Sale').price / 100},-
												</Typography>
											</div>
											<div className="flex ButtonDiv h-56 items-center">
												<Button
													variant="outlined"
													onClick={() => {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															if (p[product.id] !== 0) {
																p[product.id] -= 1;
															}
															return p;
														});
													}}
													className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none ${classes.text}`}
												>
													<Remove />
												</Button>
												<div
													className={`flex items-center text-14 font-bold px-10 ${classes.text}`}
												>
													{productsWithAmount[product.id]}
												</div>

												<Tooltip
													title={
														flags.maxAmountOfProductPerProductGroupInCart &&
														product.ticketTypeUid &&
														productGroups[product.ticketTypeUid] &&
														productGroups[product.ticketTypeUid].maxTickets &&
														productGroups[product.ticketTypeUid].maxTickets.amount &&
														productsWithAmount[product.id] >=
															productGroups[product.ticketTypeUid].maxTickets.amount
															? `Du kan maksimalt købe ${
																	productGroups[product.ticketTypeUid].maxTickets
																		.amount
															  } af dette produkt`
															: ''
													}
												>
													<Button
														variant="outlined"
														onClick={() => {
															if (flags.maxAmountOfProductPerProductGroupInCart) {
																const { maxTickets } =
																	productGroups[product.ticketTypeUid];
																setProductsWithAmount(oldState => {
																	const p = { ...oldState };
																	if (
																		maxTickets &&
																		maxTickets.amount &&
																		maxTickets.amount <= p[product.id]
																	) {
																		p[product.id] = maxTickets.amount;
																	} else {
																		p[product.id] += 1;
																	}
																	return p;
																});
															} else {
																setProductsWithAmount(oldState => {
																	const p = { ...oldState };
																	p[product.id] += 1;
																	return p;
																});
															}
														}}
														className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none ${classes.text}`}
													>
														<Add />
													</Button>
												</Tooltip>
											</div>
										</div>
									))}

									<hr />
									<div className="flex justify-between w-full">
										<Typography className="font-bold text-20">{t('TOTAL')}</Typography>
										<Typography className="font-bold text-20">{`${
											totalAmount / 100
										} kr.`}</Typography>
									</div>
									<hr />
									<div className="flex flex-col mt-10">
										{Object.entries(disclaimers).map(([disclaimer, index]) => (
											<Typography
												key={index}
												className={`${
													highlightedDisclaimer === index ? 'text-14 font-bold' : 'text-12'
												} leading-6`}
												variant="body1"
												style={{ wordWrap: 'break-word' }}
											>
												<span className="mr-4">{'*'.repeat(index + 1)}</span>
												{disclaimer}
											</Typography>
										))}
									</div>
								</>
							)}
						</Grid>
					</Paper>
				)}

				{/* ---------------Vælg billetter end-----------------*/}

				{/* ---------------Liste over events start-----------------*/}
				<Grid className={clsx(hasProducts && 'py-24')} item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Paper className="p-10 md:p-28 mx-12 md:mx-16">
						<Typography variant="h6" className="pb-16">
							{eventGroup ? eventGroup.name : ''}
						</Typography>
						{events
							.map(event => ({ ...event, start: moment(event.start), end: moment(event.end) }))
							.map(event => (
								<ManuelEventTicketSelectorEventRow
									event={event}
									classes={classes}
									free={free}
									hasProducts={hasProducts}
									contentElementProps={contentElementProps}
									eventGroup={eventGroup}
									productsWithAmount={productsWithAmount}
									ticketCount={ticketCount}
								/>
							))}
					</Paper>
				</Grid>
				{/* ---------------Liste over events end-----------------*/}
			</Grid>
		</div>
	);
}

function ManuelEventTicketSelectorEventRow({
	event,
	classes,
	free,
	contentElementProps,
	eventGroup,
	productsWithAmount,
	ticketCount,
	hasProducts
}) {
	const flags = useFlags();
	const { t } = useTranslation();
	const navigate = useNavigate();

	function goToCheckOut() {
		navigate('/cart', { state: { event: event.eventUid, products: productsWithAmount } });
	}
	const soldOut = React.useMemo(() => event && event.available <= 0, [event]);
	const showAvailable = React.useMemo(() => {
		const hideAbove = event.maxTickets / (100 / event.hideTicketAmountAbovePercent);
		return hideAbove >= event.available;
	}, [event]);
	const showSoldOut = React.useMemo(
		() => flags.soldOutTextOnManuelEventTable && !showAvailable && soldOut,
		[flags.soldOutTextOnManuelEventTable, showAvailable, soldOut]
	);

	const isAllowedToBuy = React.useMemo(
		() =>
			event.available >= ticketCount &&
			event.available > 0 &&
			(!flags.disableBuyButtonIfNoTicketsIsSelected || ticketCount > 0),
		[event, ticketCount, flags.disableBuyButtonIfNoTicketsIsSelected]
	);

	return (
		<React.Fragment key={event.eventUid}>
			<div className="flex flex-col py-12 md:py-0 md:flex-row md:items-center md:justify-between">
				<div className="flex flex-row items-center">
					<div className={clsx(classes.number, 'flex w-64 h-64 justify-center items-center flex-col')}>
						<Typography className="font-bold">{event.start.format('MMM').toUpperCase()}</Typography>
						<Typography className="font-bold text-18">{event.start.format('DD')}</Typography>
						<Typography className="font-bold">{event.start.format('YYYY')}</Typography>
					</div>
					<div className="flex flex-col pl-12">
						<Typography>{event.start.format('dddd [-] HH:mm')}</Typography>
						<Typography className="font-bold">{event.name}</Typography>
					</div>
				</div>
				{(!flags.eventGroupsWithoutProducts || hasProducts) && (
					<div className="w-full md:w-256 flex flex-col items-center">
						<Tooltip
							title={
								flags.disableBuyButtonIfNoTicketsIsSelected && !isAllowedToBuy
									? t('CHOOSE_TICKETS_BEFORE_BUY')
									: ''
							}
						>
							<span className="w-full">
								<Button
									className={`w-full mt-6 rounded-none max-h-44 ${classes.buyButton}`}
									variant="contained"
									color="primary"
									disabled={!isAllowedToBuy}
									onClick={goToCheckOut}
								>
									<div className="flex flex-col">
										<span className="text-14 font-bold">
											{showSoldOut && (contentElementProps.soldOutButtonText || t('SOLD_OUT'))}
											{!showSoldOut && flags.customTextWhenEventIsFreeOnManualEventTable && (
												<>
													{free
														? contentElementProps.freeButtonText ||
														  t('BUY_TICKET_BUTTON_FREE')
														: contentElementProps.buyButtonText || t('BUY_TICKET_BUTTON')}
												</>
											)}
											{!showSoldOut &&
												!flags.customTextWhenEventIsFreeOnManualEventTable &&
												t('BUY_TICKET_BUTTON')}{' '}
											{!showSoldOut && <ArrowForward />}
										</span>

										{showAvailable && (
											<span className="text-12 whitespace-no-wrap font-bold">
												{eventGroup.showTextOnPercentage ? (
													<>
														{eventGroup.textOnTicketAmount && event.available > 0
															? eventGroup.textOnTicketAmount
															: t('SOLD_OUT')}
													</>
												) : (
													<>
														{event.available > 0
															? `${event.available} ${t('TICKETS_LEFT')}`
															: t('SOLD_OUT')}
													</>
												)}
											</span>
										)}
									</div>
								</Button>
							</span>
						</Tooltip>
					</div>
				)}
			</div>
			<hr className="my-0 md:my-10" />
		</React.Fragment>
	);
}

export default ManualEventTicketSelector;
