import React from 'react';

function Messenger(props) {
	return (
		<svg
			id="Bold"
			enableBackground="new 0 0 24 24"
			height="50"
			viewBox="0 0 24 24"
			width="50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				style={{ fill: props.color }}
				d="m0 11.111c0 3.496 1.744 6.615 4.471 8.652v4.237l4.086-2.242c1.09.301 2.245.465 3.442.465 6.627 0 12-4.974 12-11.111.001-6.137-5.372-11.112-11.999-11.112s-12 4.974-12 11.111zm10.734-3.112 3.13 3.259 5.887-3.259-6.56 6.962-3.055-3.258-5.963 3.259z"
			/>
		</svg>
	);
}
export default Messenger;
