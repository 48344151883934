import React, { lazy } from 'react';

const MailConfirmedPage = lazy(() => import('./MailConfirmedPage'));

const MailConfirmedPageConfig = {
	routes: [
		{
			path: '/account/mail-confirmed',
			element: <MailConfirmedPage />,
		},
	],
};

export default MailConfirmedPageConfig;
