import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { sharedTailwindClasses } from '../../shared/sharedTailwindClasses';

function Accordion(props) {
	const { contentElementProps } = props;
	const { contentElementLinkedNodes } = props;

	return (
		<MuiAccordion
			defaultExpanded={contentElementProps.defaultExpanded}
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{contentElementLinkedNodes &&
				contentElementLinkedNodes.accordionSummary &&
				props.getCmsContentElement(contentElementLinkedNodes.accordionSummary)}

			{contentElementLinkedNodes &&
				contentElementLinkedNodes.accordionDetails &&
				props.getCmsContentElement(contentElementLinkedNodes.accordionDetails)}
		</MuiAccordion>
	);
}

export default Accordion;
