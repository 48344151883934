import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { layoutElementLanguageListener, layoutElementListener } from '../../ContentFirestore';
import CmsContentElement from '../CmsContentElement';
import allLanguages from '../cmsComponents/shared/allLanguages';

function CmsLayoutElement({ layoutElementType, layoutElementOld }) {
	const { i18n } = useTranslation();

	const [layoutElement, setLayoutElement] = useState(null);
	const [layoutElementLanguage, setLayoutElementLanguage] = useState(null);
	const [layoutElementContent, setLayoutElementContent] = useState(null);
	const [currentLanguage, setCurrentLanguage] = useState(null);

	useEffect(() => {
		const _currentLanguage = Object.values(allLanguages).find(language => {
			return language.isoCode === i18n.language;
		});
		setCurrentLanguage(_currentLanguage);
	}, [i18n.language]);

	useEffect(() => {
		const layoutElementListenerUnsubFunc = layoutElementListener(layoutElementType, _layoutElement =>
			setLayoutElement(_layoutElement)
		);
		return () => {
			layoutElementListenerUnsubFunc();
		};
	}, [layoutElementType]);

	useEffect(() => {
		if (layoutElement && layoutElement.id && currentLanguage) {
			const layoutElementLanguageListenerUnsubFunc = layoutElementLanguageListener(
				layoutElement.id,
				currentLanguage.isoCode,
				_layoutElementLanguage => setLayoutElementLanguage(_layoutElementLanguage)
			);
			return () => {
				layoutElementLanguageListenerUnsubFunc();
			};
		}

		return () => {};
	}, [layoutElement, currentLanguage]);

	useEffect(() => {
		if (layoutElementLanguage && layoutElementLanguage.content) {
			setLayoutElementContent(layoutElementLanguage.content);
		} else if (layoutElement && layoutElement.content) {
			setLayoutElementContent(layoutElement.content);
		} else {
			setLayoutElementContent(null);
		}
	}, [layoutElementLanguage, layoutElement]);

	return (
		<>
			{layoutElementContent ? (
				<>
					<CmsContentElement contentId="ROOT" content={layoutElementContent} />
				</>
			) : (
				<>
					{layoutElementOld && layoutElementOld.content ? (
						<CmsContentElement contentId="ROOT" content={layoutElementOld.content} />
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
}

export default CmsLayoutElement;
