import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function GridItem(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<Grid
			item
			zeroMinWidth={contentElementProps.zeroMinWidth}
			alignContent={contentElementProps.alignContent}
			alignItems={contentElementProps.alignItems}
			xs={contentElementProps.xs}
			sm={contentElementProps.sm}
			md={contentElementProps.md}
			lg={contentElementProps.lg}
			xl={contentElementProps.xl}
			className={`${contentElementProps.tailwindClasses} ${
				contentElementProps.zIndex && `z-${contentElementProps.zIndex}`
			} ${contentElementProps.minHeight && `min-h-${contentElementProps.minHeight}`} ${
				contentElementProps.minWidth && `min-w-${contentElementProps.minWidth}`
			} ${contentElementProps.smMinWidth ? `sm:min-w-${contentElementProps.smMinWidth}` : ``} ${
				contentElementProps.mdMinWidth ? `md:min-w-${contentElementProps.mdMinWidth}` : ``
			} ${contentElementProps.lgMinWidth ? `lg:min-w-${contentElementProps.lgMinWidth}` : ``} ${
				contentElementProps.xlMinWidth ? `xl:min-w-${contentElementProps.xlMinWidth}` : ``
			} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			style={{
				backgroundColor: `${getColor(
					contentElementProps.backgroundColor,
					contentElementProps.customColor,
					mergedTheme
				)}`
			}}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</Grid>
	);
}

export default GridItem;
