const sortByMonth = (a, b) => {
	const monthA = a.month.split('-').map(n => Number.parseInt(n, 10));
	const monthB = b.month.split('-').map(n => Number.parseInt(n, 10));
	if (monthA[1] === monthB[1]) {
		if (monthA[0] === monthB[0]) {
			return 0;
		}
		if (monthA[0] > monthB[0]) {
			return 1;
		}
		if (monthA[0] < monthB[0]) {
			return -1;
		}
	}
	if (monthA[1] > monthB[1]) {
		return 1;
	}
	return -1;
};

export default sortByMonth;
