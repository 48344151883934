import React from 'react';
import Typography from '@mui/material/Typography';

function HeaderComponent(props) {
	return (
		<Typography className="py-24" variant="h4">
			{props.data}
		</Typography>
	);
}

export default HeaderComponent;
