import React, { useEffect, useState } from 'react';

function MultiLanguageButton(props) {
	const { contentElementLinkedNodes } = props;

	const [currentLanguage, setCurrentLanguage] = useState(null);

	useEffect(() => {
		const currentLanguageLocalStorage = localStorage.getItem('language')
			? JSON.parse(localStorage.getItem('language'))
			: { isoCode: 'da' };
		if (currentLanguageLocalStorage) {
			setCurrentLanguage(currentLanguageLocalStorage);
		}
	}, []);

	return (
		<>
			{contentElementLinkedNodes &&
				currentLanguage &&
				currentLanguage.isoCode &&
				contentElementLinkedNodes[currentLanguage.isoCode] &&
				props.getCmsContentElement(contentElementLinkedNodes[currentLanguage.isoCode])}
		</>
	);
}

export default MultiLanguageButton;
