import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIButton from '@mui/material/Button';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'inline',
		position: 'relative'
	},
	buttonSuccess: {
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: green[700]
		}
	},
	fabProgress: {
		color: green[500],
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	button: {}
}));

const LoadingButton = React.forwardRef((props, ref) => {
	const classes = useStyles(props);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	const handleButtonClick = () => {
		if (!loading && !props.disabled) {
			if (props.onClick) {
				props.onClick();
			}
			if (!props.disableLoadingOnClick) {
				setLoading(true);
			}
		}
	};

	const Button = props.buttonComponent || MUIButton;

	return (
		<div className={classes.wrapper}>
			<Button
				ref={ref}
				className={props.buttonComponent ? classes.button : clsx(classes.button, 'rounded-none')}
				style={props.style}
				variant={props.variant ? props.variant : 'contained'}
				color={props.color ? props.color : 'primary'}
				disabled={loading || props.disabled}
				onClick={handleButtonClick}
				startIcon={props.startIcon}
				endIcon={props.endIcon}
				type={props.type || 'button'}
				fullWidth={props.fullWidth || false}
				classes={props.buttonClasses}
			>
				{props.title || props.children}
			</Button>
			{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
		</div>
	);
});

export default LoadingButton;
