import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Add, Remove } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { AppBar, ButtonGroup } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import { productsListener } from '../manual-event-detail/ManualEventDetailFirestore';

function ManualEventTableProductAmount({ open, handleClose, event, readMoreLinkTo }) {
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [products, setProducts] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [error, setError] = useState(null);

	const navigate = useNavigate();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		if (open && event && event.ticketTypes.length > 0) {
			const productsListenerUnsubFunc = productsListener(event.ticketTypes, o => setProducts(o));
			return () => productsListenerUnsubFunc();
		}
		return () => {};
	}, [open, event]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] = 0;
		});
		setProductsWithAmount(pro);
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);
	useEffect(() => {
		let amount = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			amount += productsWithAmount[pKey];
		});
		if (event && amount > event.available) {
			setError(true);
		} else {
			setError(false);
		}
	}, [productsWithAmount, event]);

	return (
		<ThemeProvider theme={mergedTheme}>
			<Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
				<AppBar position="static">
					<Toolbar className="flex w-full">
						<Typography variant="subtitle1" color="inherit">
							Vælg dine billetter
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent>
					{event &&
						products &&
						products.map(product => (
							<div className="flex justify-between items-center" key={product.id}>
								<div className="flex justify-between w-full">
									<Typography className="font-bold whitespace-normal">{product.name}</Typography>
									<Typography className="px-8 font-bold flex items-center">
										{product.prices.find(f => f.type === 'Sale').price / 100},-
									</Typography>
								</div>
								<div className="flex ButtonDiv h-56 items-center">
									<Button
										variant="outlined"
										onClick={() => {
											setProductsWithAmount(oldState => {
												const p = { ...oldState };
												if (p[product.id] !== 0) {
													p[product.id] -= 1;
												}
												return p;
											});
										}}
										className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
									>
										<Remove />
									</Button>
									<div className="flex items-center text-14 font-bold px-10">
										{productsWithAmount[product.id]}
									</div>
									<Button
										variant="outlined"
										onClick={() => {
											setProductsWithAmount(oldState => {
												const p = { ...oldState };
												p[product.id] += 1;
												return p;
											});
										}}
										className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
									>
										<Add />
									</Button>
								</div>
							</div>
						))}

					<hr />
					<div className="flex justify-between w-full mt-12">
						<Typography className="font-bold text-20">I alt</Typography>
						<Typography className="font-bold text-20">{`${totalAmount / 100} kr.`}</Typography>
					</div>
				</DialogContent>
				<DialogActions className="justify-between p-8">
					<div className="ml-20">
						<Typography color="error">
							{error && event && <>Du kan maksimum bestille {event.available} billetter på denne dag.</>}
						</Typography>
					</div>
					<ButtonGroup>
						<Button variant="outlined" color="secondary" onClick={handleClose}>
							Annuller
						</Button>
						{event && (
							<Button
								variant={error ? 'contained' : 'outlined'}
								color="primary"
								onClick={() => {
									navigate(readMoreLinkTo.replace(':eventGroupUid', event.eventGroupUid), {
										productsWithAmount
									});
									handleClose();
								}}
							>
								Se flere datoer
							</Button>
						)}
						<Button
							variant="contained"
							color="primary"
							disabled={!!error}
							onClick={() =>
								navigate('/cart', {
									state: {
										event: event.eventUid, products: productsWithAmount
									},
								})
							}
						>
							Køb billetter
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}

export default ManualEventTableProductAmount;
