import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { selectOrderLines } from '../../../../../../store/shared/cartSlice';
import followProductVisualView from '../../../../../../ui-components/states/followProductVisualView';

function ShoppingBasketButton(props) {
	const { contentElementProps } = props;

	const flags = useFlags();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const orderLines = useSelector(selectOrderLines);

	const useStyles = makeStyles(_theme => ({
		badge: {
			color: `${getColor(
				contentElementProps.badgeTextColor,
				contentElementProps.customBadgeTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.badgeBackgroundColor,
				contentElementProps.customBadgeBackgroundColor,
				mergedTheme
			)}`
		}
	}));

	const classes = useStyles();

	return (
		<Button component={Link} to="/cart">
			<Badge
				classes={{ badge: classes.badge }}
				max={99}
				badgeContent={
					orderLines
						? orderLines
								.filter(ol => {
									if (flags.discountCodes && ol.lineType === 'discountCode') {
										return false;
									}
									return (
										!flags.followProduct ||
										ol.lineType !== 'followProduct' ||
										(ol.lineType === 'followProduct' &&
											ol.visualRelation === followProductVisualView.NONE)
									);
								})
								.reduce((acc, cur) => acc + cur.qty, 0)
						: 0
				}
			>
				<ShoppingBasketIcon
					style={{
						color: `${getColor(
							contentElementProps.shoppingBasketIconColor,
							contentElementProps.customShoppingBasketIconColor,
							mergedTheme
						)}`
					}}
				/>
			</Badge>
		</Button>
	);
}

export default ShoppingBasketButton;
