import React from 'react';
import MuiButton from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';

function Button(props) {
	const { contentElementProps } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		root: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
			backgroundColor: `${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.textColorHover,
					contentElementProps.customTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.backgroundColorHover,
					contentElementProps.customBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();

	const navigate = useNavigate();
	const location = useLocation();

	const baseUrl = window.location.href.replace(location.pathname, '');
	const baseUrlWithoutHttpOrHttps = baseUrl.includes('http://')
		? baseUrl.replace('http://', '')
		: baseUrl.includes('https://')
		? baseUrl.replace('https://', '')
		: baseUrl;

	function handleOnClick() {
		const pathname = contentElementProps.href.startsWith('/')
			? contentElementProps.href
			: contentElementProps.href.startsWith(baseUrlWithoutHttpOrHttps)
			? contentElementProps.href.replace(baseUrlWithoutHttpOrHttps, '')
			: contentElementProps.href.replace(baseUrl, '');

		const internalRedirectDataKey = contentElementProps.internalRedirectDataKey || undefined;
		const internalRedirectData =
			(contentElementProps.internalRedirectData && JSON.parse(contentElementProps.internalRedirectData)) ||
			undefined;

		if (internalRedirectDataKey && internalRedirectData) {
			navigate(pathname, { state: { [internalRedirectDataKey]: internalRedirectData } });
		} else {
			navigate(pathname);
		}
	}

	function isUrlInternalRedirect() {
		return (
			contentElementProps.href.startsWith('/') ||
			contentElementProps.href.startsWith(baseUrl) ||
			contentElementProps.href.startsWith(baseUrlWithoutHttpOrHttps)
		);
	}

	return (
		<MuiButton
			role="button"
			variant={contentElementProps.variant}
			endIcon={contentElementProps.endIcon ? <Icon>{contentElementProps.endIcon}</Icon> : false}
			fullWidth={contentElementProps.fullWidth}
			href={isUrlInternalRedirect() ? undefined : contentElementProps.href}
			size={contentElementProps.size}
			startIcon={contentElementProps.startIcon ? <Icon>{contentElementProps.startIcon}</Icon> : false}
			className={`${contentElementProps.tailwindClasses} ${
				contentElementProps.fontSize ? `text-${contentElementProps.fontSize}` : ``
			} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			classes={{ root: classes.root }}
			onClick={isUrlInternalRedirect() ? handleOnClick : undefined}
		>
			{contentElementProps.text}
		</MuiButton>
	);
}

export default Button;
