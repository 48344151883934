import { useMemo } from 'react';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Add, Remove } from '@mui/icons-material';
import { getLocaleFromData } from '@ameroservices-platform/attraction-frontend/app/utilities';
import { useTranslation } from 'react-i18next';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});
function ListOfProductsCartProductRow({ product, productGroup, amount, contentElementProps, onAdd, onRemove }) {
	const flags = useFlags();
	const { t } = useTranslation();

	function handleAdd(product) {
		onAdd(product);
	}

	function handleRemove(product) {
		onRemove(product);
	}
	const toolTipTitleAdd = useMemo(() => {
		if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			productGroup &&
			productGroup.maxTickets &&
			productGroup.maxTickets.amount &&
			amount >= productGroup.maxTickets.amount
		) {
			return `${t('YOU_CAN_MAX_BUY')} ${productGroup.maxTickets.amount} ${t('OF_THIS_PRODUCT')}`;
		}
		return '';
	}, [flags.maxAmountOfProductPerProductGroupInCart, productGroup, amount, t]);

	const toolTipTitleRemove = useMemo(() => {
		if (flags.minimumAmountOfProduct && product && product.minimumAmount) {
			return `${t('NEED_TO_BUY_MINIMUM_OF')} ${product.minimumAmount} ${t('OF_THIS_PRODUCT')}`;
		}
		return '';
	}, [flags.minimumAmountOfProduct, product, t]);

	return (
		<div className="flex items-center justify-between">
			<Typography className={contentElementProps.productTailwindClasses}>
				{getLocaleFromData(product, 'name')}
			</Typography>
			<div className={'flex items-center'}>
				<div className="flex items-center mr-20">
					<Typography className="mr-10 font-bold whitespace-normal cursor-default">
						{product && (
							<>
								{product.currentPrice % 1 === 0 && <>{product.currentPrice},-</>}
								{product.currentPrice % 1 !== 0 && <>{moneyFormatter.format(product.currentPrice)}</>}
							</>
						)}
						{!product && <Skeleton />}
					</Typography>
					<div className="flex h-56 items-center">
						<Tooltip title={toolTipTitleRemove}>
							<Button
								variant="outlined"
								disabled={!product}
								onClick={() => handleRemove(product)}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
							>
								<Remove />
							</Button>
						</Tooltip>
						<div className="flex items-center text-14 font-bold px-10">{amount || '0'}</div>

						<Tooltip title={toolTipTitleAdd}>
							<Button
								variant="outlined"
								disabled={!product}
								onClick={() => handleAdd(product)}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
							>
								<Add />
							</Button>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ListOfProductsCartProductRow;
