const subscriptionState = {
	ACTIVE: 'active',
	TERMINATED: 'terminated',
	PAYMENT: 'payment',
	RENEWAL: 'renewal',
	RENEWAL_FAILED: 'renewalFailed',
	RESERVED: 'reserved',
	DRAFT: 'draft',
	CANCELED: 'canceled',
	UNKNOWN: 'unknown',
};

export const subscriptionStateTranslate = {
	active: 'Aktiv',
	terminated: 'Opsagt',
	payment: 'Mangler Betaling',
	[subscriptionState.RENEWAL]: 'Afventer Fornyelse',
	[subscriptionState.RENEWAL_FAILED]: 'Fornyelse Mislykkedes',
	reserved: 'Reserveret',
	draft: 'Kladde',
	canceled: 'Annulleret',
	unknown: 'Ukendt',
};

export default subscriptionState;
