import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';
import StorageImage from '../../../../../ui-components/StorageImage';

const useStyles = makeStyles({
	halfCircle: {
		bottom: '-0.3em'
	},
	cardTopImage: { height: '9em', objectFit: 'cover' },
	cardDescription: { height: '8em' }
});

function Frontpage({ data }) {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<div className="max-w-3xl w-full px-12 my-48 xl:px-0">
			<Paper elevation={3} className="px-4 py-28 md:px-28 w-full mb-48">
				{data.content && (
					<>
						<Typography variant="h1" className="font-bold text-28 mb-20">
							{data.content.title}
						</Typography>
						<div className="flex flex-col">
							<div className="text-left text-16 mb-20">
								{data.content.image && (
									<StorageImage src={data.content.image} className="float-left mr-20" alt="" />
								)}
								{data.content.content &&
									data.content.content.map((c, i) => {
										if (c.type === 'heading') {
											return <HeadingComponent key={i}>{c.content}</HeadingComponent>;
										}
										if (c.type === 'text') {
											return <TextComponent key={i}>{c.content}</TextComponent>;
										}
										if (c.type === 'number_list') {
											return (
												<ListComponent
													key={i}
													type="number"
													list={c.content.array}
													heading={c.content.heading}
												/>
											);
										}
										if (c.type === 'markdown') {
											return <MarkdownComponent key={i} data={c.content} />;
										}
										return <span key={i} />;
									})}
							</div>
						</div>
					</>
				)}
			</Paper>

			<Paper elevation={3} className="px-4 py-28 md:px-28 w-full">
				<Grid container spacing={2}>
					<Grid item xs={12} xl={12}>
						<Typography variant="h1" className="font-bold text-center text-28 mb-20">
							{data.content.cardsTitle}
						</Typography>
					</Grid>
					{data.content.cards &&
						data.content.cards.map(card => (
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={card.id}>
								<div className="flex flex-col md:flex-row w-full items-center justify-center">
									<Paper elevation={6} className="min-w-2 max-w-256 overflow-hidden flex-grow">
										<div className="relative">
											<StorageImage
												src={card.image ? card.image : ''}
												className={`w-full max-w-full ${classes.cardTopImage}`}
												alt=""
											/>

											<img
												className={`overflow-hidden min-w-0 max-w-288 absolute -left-1 -right-1 ${classes.halfCircle}`}
												src="assets/images/event-site/halvcirkel.svg"
												alt=""
											/>
										</div>
										<div className="px-16">
											<Typography variant="h6" className="text-center h-72">
												{card.title ? card.title : ''}
											</Typography>
											<Typography className={`py-16 ${classes.cardDescription}`}>
												<MarkdownComponent data={card.content ? card.content : ''} />
											</Typography>

											<Button
												className="w-full mb-16"
												variant="contained"
												color="primary"
												endIcon={<ArrowForwardIcon />}
												onClick={() => navigate(card.linkTo)}
											>
												Læs mere
											</Button>
										</div>
									</Paper>
								</div>
							</Grid>
						))}
				</Grid>
			</Paper>
		</div>
	);
}

export default Frontpage;
