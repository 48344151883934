import React from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import StorageImage from '../../../../../ui-components/StorageImage';
import ReadMore from '../../../../../ui-components/ReadMore';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';

function PapirCenteredContent({ data }) {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<div className="max-w-3xl w-full my-48 mx-12 md:mx-16 px-12 md:px-16 xl:px-0">
			<Grid container spacing={0}>
				<Paper elevation={3} className="w-full mb-48 px-4 py-28 md:px-28">
					{data && data.content && (
						<>
							<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
								{data.content.title}
							</Typography>
							<div className="flex flex-col md:flex-row">
								<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
									<StorageImage src={data.content.image} alt="Jorn udstilling" />
								</Grid>
								<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
									<div className="text-left text-16 pb-20">
										<ReadMore
											color={mergedTheme.palette.background.paper}
											showFull={
												<>
													{data.content.content.map((c, i) => {
														if (c.type === 'heading') {
															return (
																<HeadingComponent key={i}>{c.content}</HeadingComponent>
															);
														}
														if (c.type === 'text') {
															return <TextComponent key={i}>{c.content}</TextComponent>;
														}
														if (c.type === 'number_list') {
															return (
																<ListComponent
																	key={i}
																	type="number"
																	list={c.content.array}
																	heading={c.content.heading}
																/>
															);
														}
														if (c.type === 'markdown') {
															return (
																<MarkdownComponent
																	key={i}
																	linkColor={data.content.linkColor}
																	data={c.content}
																/>
															);
														}
														return <span key={i} />;
													})}
												</>
											}
											showHalf={
												<>
													{data.content.content
														.filter(c => !c.collapsed)
														.map((c, i) => {
															if (c.type === 'heading') {
																return (
																	<HeadingComponent key={i}>
																		{c.content}
																	</HeadingComponent>
																);
															}
															if (c.type === 'text') {
																return (
																	<TextComponent key={i}>{c.content}</TextComponent>
																);
															}
															if (c.type === 'number_list') {
																return (
																	<ListComponent
																		key={i}
																		type="number"
																		list={c.content.array.filter(a => !a.collapsed)}
																		heading={c.content.heading}
																	/>
																);
															}
															if (c.type === 'markdown') {
																return <MarkdownComponent key={i} data={c.content} />;
															}
															return <span key={i} />;
														})}
												</>
											}
										/>
									</div>
								</Grid>
							</div>
						</>
					)}
				</Paper>
			</Grid>
		</div>
	);
}

export default PapirCenteredContent;
