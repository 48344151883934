import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import Languages from '@cospired/i18n-iso-languages';
import FlagIcon from '../shared/FlagIcon';
import { selectLanguages, selectModules } from '../../../../../../store/shared/frontendSlice';
import allLanguages from '../shared/allLanguages';

function LanguageSwitcher() {
	const flags = useFlags();

	Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/da.json'));
	Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'));
	Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/de.json'));
	Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/no.json'));
	Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/sv.json'));

	const [currentLanguage, setCurrentLanguage] = useState(null);
	const [menu, setMenu] = useState(null);
	const languages = useSelector(selectLanguages);

	const { i18n } = useTranslation();

	const modules = useSelector(selectModules);

	useEffect(() => {
		const _currentLanguage = Object.values(allLanguages).find(language => {
			return language.isoCode === i18n.language;
		});
		setCurrentLanguage(_currentLanguage);
	}, [i18n.language]);

	useEffect(() => {
		const languageList = Object.values(languages);
		setTimeout(() => {
			if (languageList.length > 0 && !languageList.some(language => language.isoCode === i18n.language)) {
				const enLanguage = languageList.find(language => language.isoCode === 'en');
				const daLanguage = languageList.find(language => language.isoCode === 'da');
				console.log("Changing language due to '" + i18n.language + "' is missing language in language list");
				if (enLanguage) {
					handleLanguageChange(enLanguage);
				} else if (daLanguage) {
					handleLanguageChange(daLanguage);
				} else {
					handleLanguageChange(languageList[0]);
				}
			}
		}, 1000);
	}, [languages]);

	const langMenuClick = event => {
		setMenu(event.currentTarget);
	};

	const langMenuClose = () => {
		setMenu(null);
	};

	async function handleLanguageChange(language) {
		const _currentLanguage = {
			isoCode: language.isoCode,
			title: language.title,
			flag: language.flag
		};
		localStorage.setItem('language', JSON.stringify(_currentLanguage));
		await i18n.changeLanguage(_currentLanguage.isoCode);
		langMenuClose();
		window.location.reload();
	}

	return (
		<>
			{flags.multiLanguageCmsFrontend && modules.language && (
				<>
					{languages && currentLanguage && (
						<>
							<Button className="h-40 w-64" onClick={langMenuClick}>
								<FlagIcon className="" flagCountryCode={currentLanguage.flag} />

								<Typography className="mx-4 font-bold" color="textSecondary">
									{currentLanguage.isoCode}
								</Typography>
							</Button>

							<Popover
								open={Boolean(menu)}
								anchorEl={menu}
								onClose={langMenuClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center'
								}}
								classes={{
									paper: 'py-8'
								}}
							>
								{Object.values(languages).map(language => (
									<MenuItem
										className="mt-8"
										key={language.isoCode}
										onClick={() => handleLanguageChange(language)}
									>
										<ListItemIcon className="min-w-40">
											<FlagIcon className="" flagCountryCode={language.flag} />
										</ListItemIcon>
										<ListItemText
											className="capitalize"
											primary={Languages.getName(language.isoCode, language.isoCode)}
										/>
									</MenuItem>
								))}
							</Popover>
						</>
					)}
				</>
			)}
		</>
	);
}

export default LanguageSwitcher;
