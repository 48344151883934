import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from '@ameroservices-platform/attraction-frontend/app/store/fuse/messageSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const submitLoginWithToken =
	({ token }) =>
	async dispatch => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.signInWithCustomToken(token)
			.then(() => {
				return dispatch(loginSuccess());
			})
			.catch(error => {
				const tokenErrorCodes = ['auth/invalid-custom-token'];
				const response = [];

				if (tokenErrorCodes.includes(error.code)) {
					response.push({
						type: 'token',
						message: error.message
					});
				} else {
					console.error(error);
				}

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(loginError(response));
			});
	};

export const submitLoginWithFireBase =
	({ username, password }) =>
	async dispatch => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.signInWithEmailAndPassword(username, password)
			.then(() => {
				return dispatch(loginSuccess());
			})
			.catch(error => {
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled',
					'auth/missing-email'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
				const usernameErrorTranslate = {
					'auth/email-already-in-use': 'Emailen er allerede i brug',
					'auth/invalid-email': 'Emailen er ikke korrekt',
					'auth/operation-not-allowed': 'Ukendt fejl',
					'auth/user-not-found': 'Brugeren er ikke fundet',
					'auth/user-disabled': 'Brugeren er suspenderet'
				};
				const passwordErrorTranslate = {
					'auth/weak-password': 'Adgangskoden er for svag. Benyt en adgangskode med mere end 8 tegn',
					'auth/wrong-password': 'Forkert adgangskode'
				};

				const response = {
					username: usernameErrorCodes.includes(error.code) ? error.code : null,
					password: passwordErrorCodes.includes(error.code) ? error.code : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(loginError(response));
			});
	};
export const forgotPasswordWithFunction =
	({ username }) =>
	async dispatch => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		dispatch(setForgotPasswordLoading(true));
		await firebaseService.callFunctionByName('organisationCustomerSendResetCustomerPassword', {
			email: username
		});
		dispatch(setForgotPasswordLoading(false));
		return dispatch(forgotPasswordSuccess());
	};

export const forgotPasswordWithFireBase =
	({ username }) =>
	async dispatch => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.sendPasswordResetEmail(username)
			.then(() => {
				return dispatch(forgotPasswordSuccess());
			})
			.catch(error => {
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const usernameErrorTranslate = {
					'auth/email-already-in-use': 'Emailen er allerede i brug',
					'auth/invalid-email': 'Emailen er ikke korrekt',
					'auth/operation-not-allowed': 'Ukendt fejl',
					'auth/user-not-found': 'Brugeren er ikke fundet',
					'auth/user-disabled': 'Brugeren er suspenderet'
				};

				const response = {
					username: usernameErrorCodes.includes(error.code) ? usernameErrorTranslate[error.code] : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(forgotPasswordError(response));
			});
	};

const initialState = {
	success: false,
	error: {
		username: null,
		password: null
	},
	forgotPasswordSuccess: false,
	forgotPasswordError: {
		username: null
	},
	forgotPasswordLoading: false
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = {};
		},
		loginError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		},
		forgotPasswordSuccess: (state, action) => {
			state.forgotPasswordSuccess = true;
		},
		setForgotPasswordLoading: (state, action) => {
			state.forgotPasswordLoading = action.payload;
		},
		forgotPasswordError: (state, action) => {
			state.forgotPasswordSuccess = false;
			state.forgotPasswordError = action.payload;
		},
		resetErrors: state => {
			state.error = {
				username: null,
				password: null
			};
		}
	},
	extraReducers: {}
});

export const {
	resetErrors,
	loginSuccess,
	loginError,
	forgotPasswordSuccess,
	forgotPasswordError,
	setForgotPasswordLoading
} = loginSlice.actions;

export default loginSlice.reducer;
