import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import Facebook from '../../../../../../ui-components/social-media/Facebook';
import Twitter from '../../../../../../ui-components/social-media/Twitter';
import Instagram from '../../../../../../ui-components/social-media/Instagram';
import Messenger from '../../../../../../ui-components/social-media/Messenger';
import Youtube from '../../../../../../ui-components/social-media/Youtube';
import Vimeo from '../../../../../../ui-components/social-media/Vimeo';
import Whatsapp from '../../../../../../ui-components/social-media/Whatsapp';
import Linkedin from '../../../../../../ui-components/social-media/Linkedin';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function SocialMediaIcon(props) {
	const { contentElementProps } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const href = contentElementProps.link.startsWith('https://')
		? contentElementProps.link
		: `https://${contentElementProps.link}`;

	const color = getColor(contentElementProps.color, contentElementProps.customColor, mergedTheme);

	function getSocialMediaSvg(type) {
		if (type === 'facebook') {
			return <Facebook color={color} />;
		}
		if (type === 'twitter') {
			return <Twitter color={color} />;
		}
		if (type === 'instagram') {
			return <Instagram color={color} />;
		}
		if (type === 'messenger') {
			return <Messenger color={color} />;
		}
		if (type === 'youtube') {
			return <Youtube color={color} />;
		}
		if (type === 'vimeo') {
			return <Vimeo color={color} />;
		}
		if (type === 'whatsapp') {
			return <Whatsapp color={color} />;
		}
		if (type === 'linkedin') {
			return <Linkedin color={color} />;
		}
		return null;
	}

	return (
		<Tooltip title={contentElementProps.type} placement="top">
			<IconButton
				className="w-48 h-48 mx-8"
				component="a"
				href={href}
				target="_blank"
				rel="noreferrer noopener"
				role="button"
			>
				{getSocialMediaSvg(contentElementProps.type)}
			</IconButton>
		</Tooltip>
	);
}

export default SocialMediaIcon;
