import React, {lazy} from 'react';

const UserPhoneActivationPage = lazy(() => import('./UserPhoneActivationPage'))

const UserPhoneActivationPageConfig = {
	routes: [
		{
			path: '/account/phone-activate',
			element: <UserPhoneActivationPage />
		}
	]
};

export default UserPhoneActivationPageConfig;
