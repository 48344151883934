import _ from 'lodash';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Paper, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Add, Remove } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme, selectGoogleTagManager } from '../../../../../../../store/shared/frontendSlice';
import {
	eventGroupListener,
	productGroupsListener,
	productsListener
} from '../../../../types/event-group-date-time-selector/EventGroupDateTimeSelectorFirestore';
import { groupSummaryListener } from '../../../../types/event-group-date-selector/EventGroupDateSelectorFirestore';
import getColor from '../../shared/getColor';
import EventGroupTimeSlot from './EventGroupTimeSlot';

import { findPricesForProduct, getLocaleFromData } from '../../../../../../../utilities';
import { tagManagerViewItemList } from '../../../../../../../services/googleTagManagerService/googleTagManagerService';
import ProductRow from '../../shared/ProductRow';
import ProductTotalSection from '../../shared/ProductSection';
import ProductGroupRow from '../../shared/ProductGroupRow';

let locale = 'da-DK';
const language = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { isoCode: 'da' };
if (language && language.isoCode && language.isoCode !== 'da') {
	locale = language.isoCode;
}

function EventGroupDateTimeSelector(props) {
	const { contentElementProps } = props;
	const flags = useFlags();
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		stepNumberIcon: {
			color: `${getColor(
				contentElementProps.stepNumberIconTextColor,
				contentElementProps.customStepNumberIconTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.stepNumberIconBackgroundColor,
				contentElementProps.customStepNumberIconBackgroundColor,
				mergedTheme
			)}`
		},
		text: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
		},
		button: {
			color: `${getColor(
				contentElementProps.buttonTextColor,
				contentElementProps.customButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buttonBackgroundColor,
				contentElementProps.customButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buttonTextColorHover,
					contentElementProps.customButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buttonBackgroundColorHover,
					contentElementProps.customButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		timePickerButton: {
			color: `${getColor(
				contentElementProps.dateTimePickerTextColor,
				contentElementProps.customDateTimePickerTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.dateTimePickerBackgroundColor,
				contentElementProps.customDateTimePickerBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`
			}
		},
		timePickerButtonActive: {
			color: `${getColor(
				contentElementProps.dateTimePickerTextColorActive,
				contentElementProps.customDateTimePickerTextColorActive,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.dateTimePickerBackgroundColorActive,
				contentElementProps.customDateTimePickerBackgroundColorActive,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.dateTimePickerTextColorActive,
					contentElementProps.customDateTimePickerTextColorActive,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColorActive,
					contentElementProps.customDateTimePickerBackgroundColorActive,
					mergedTheme
				)}`
			}
		},
		buyButton: {
			color: `${getColor(
				contentElementProps.buyButtonTextColor,
				contentElementProps.customBuyButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buyButtonBackgroundColor,
				contentElementProps.customBuyButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buyButtonTextColorHover,
					contentElementProps.customBuyButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buyButtonBackgroundColorHover,
					contentElementProps.customBuyButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		grid: { flexGrow: 1 },

		calendarPicker: {
			width: '100%',
			overflow: 'hidden',
			'& .react-calendar__navigation': {
				display: 'flex'
			},

			// All days as a group
			// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
			'& .react-calendar__navigation__arrow': {
				width: '70px',
				height: '40px',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: 'transparent'
			},
			'& .react-calendar__navigation__label': {
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: 'transparent'
			},

			// Weekdays - man, tir, ons...
			'& .react-calendar__month-view__weekdays__weekday abbr': {
				textDecoration: 'none',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
			},

			// All days individual
			'& .react-calendar__month-view__days__day': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`,
				outline: '2px solid #FDFBF7',
				'& abbr': {
					display: 'none'
				},
				'& div': {
					padding: '10px'
				}
			},

			// Active button (selected date)
			'& .react-calendar__tile.react-calendar__tile--active': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColorActive,
					contentElementProps.customDateTimePickerBackgroundColorActive,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColorActive,
					contentElementProps.customDateTimePickerTextColorActive,
					mergedTheme
				)}`
			},

			// Disabled buttons (previous dates)
			'& button:disabled.react-calendar__month-view__days__day': {
				opacity: '0.3'
			},

			// Todays date
			'& .react-calendar__tile--now': {}
		}
	}));

	const now = moment().toDate();
	const [openBox1, setOpenBox1] = useState(true);
	const [openBox2, setOpenBox2] = useState(false);
	const [openBox3, setOpenBox3] = useState(false);
	const [openSlotCat, setOpenSlotCat] = useState(null);
	const [slotCategories, setSlotCategories] = useState([]);
	const [ticketCount, setTicketCount] = useState(0);
	const [eventGroup, setEventGroup] = useState(null);
	const [groupSummary, setGroupSummary] = useState(null);
	const [year, setYear] = useState(now.getFullYear());
	const [month, setMonth] = useState(now.getMonth() + 1);
	const [productGroups, setProductGroups] = useState({});
	const [products, setProducts] = useState([]);
	const [showingProducts, setShowingProducts] = useState([]);
	const [date, setDate] = useState(null);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [slots, setSlots] = useState({});
	const [gridAttr, setGridAttr] = useState({});
	const [disclaimers, setDisclaimers] = useState({});
	const [selectedGroupBy, setSelectedGroupBy] = useState({});
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);

	const forceShowTime = contentElementProps.forceShowTime === false ? contentElementProps.forceShowTime : true;
	const [calculateShowTime, setCalculateShowTime] = useState(false);
	const [customColors, setCustomColors] = useState({});

	const classes = useStyles();
	const navigate = useNavigate();
	const defaultTimesplitMargin = contentElementProps.minimumValueForSplitTime
		? contentElementProps.minimumValueForSplitTime
		: 2;

	const funcCustomColors = makeStyles(() => customColors);
	const customClasses = funcCustomColors();
	const groupByRefs = useRef();
	const googleTagManager = useSelector(selectGoogleTagManager);

	useEffect(() => {
		if (googleTagManager && groupSummary) {
			const items = Object.values(groupSummary.dates)
				.map(gsValue => {
					return Object.values(gsValue.times);
				})
				.reduce((acc, cur) => acc.concat(cur), [])
				.map(event => {
					return {
						item_id: event.eventUid,
						item_name: event.originalEventName || event.name
					};
				});
			tagManagerViewItemList(items);
		}
	}, [googleTagManager, groupSummary]);

	useEffect(() => {
		if (groupSummary) {
			const colors = [];
			Object.keys(groupSummary.dates).forEach(dateKey => {
				Object.keys(groupSummary.dates[dateKey].times).forEach(timeKey => {
					colors.push({
						key: `${groupSummary.month}-${dateKey}`,
						activeColor: groupSummary.dates[dateKey].times[timeKey].activeColor,
						defaultColor: groupSummary.dates[dateKey].times[timeKey].defaultColor
					});
				});
			});

			const obj = {};
			colors.forEach(color => {
				obj[`custom-${color.key}`] = {
					backgroundColor: `${color.defaultColor} !important`,
					'&.react-calendar__tile--active': {
						backgroundColor: `${color.activeColor} !important`
					}
				};
			});
			setCustomColors(obj);
		}
	}, [groupSummary]);

	useEffect(() => {
		if (date && groupSummary) {
			const todaysGroupSummary = groupSummary.dates[date.getDate()];
			if (todaysGroupSummary && Object.values(todaysGroupSummary.times).length <= defaultTimesplitMargin) {
				setSlotCategories([{ title: 'Heldag', from: moment('00:00', 'HH:mm'), to: moment('23:59', 'HH:mm') }]);
			} else {
				setSlotCategories([
					{ title: t('NIGHT'), from: moment('00:00', 'HH:mm'), to: moment('05:59', 'HH:mm') },
					{ title: t('MORNING'), from: moment('06:00', 'HH:mm'), to: moment('08:59', 'HH:mm') },
					{ title: t('BEFORE_MIDDAY'), from: moment('09:00', 'HH:mm'), to: moment('11:59', 'HH:mm') },
					{ title: t('AFTERNOON'), from: moment('12:00', 'HH:mm'), to: moment('17:59', 'HH:mm') },
					{ title: t('EVENING'), from: moment('18:00', 'HH:mm'), to: moment('23:59', 'HH:mm') }
				]);
			}
		}
	}, [groupSummary, date, defaultTimesplitMargin, t]);

	useEffect(() => {
		let attr = {
			xs: 12,
			sm: 12,
			md: 4,
			lg: 4,
			xl: 4
		};
		if (forceShowTime === false || calculateShowTime === false) {
			attr = {
				xs: 12,
				sm: 12,
				md: 6,
				lg: 6,
				xl: 6
			};
		}
		setGridAttr(attr);
	}, [forceShowTime, calculateShowTime]);

	// This useEffect will true to auto select

	useEffect(() => {
		if (forceShowTime === false && groupSummary) {
			const dateForUse = date || new Date();
			const timeslotsForToday = groupSummary.dates[dateForUse.getDate()]
				? groupSummary.dates[dateForUse.getDate()].times
				: findDefaultGroupSummaryDates(groupSummary.dates);

			const isAvaiableInTimeslot = Object.values(timeslotsForToday).map(value => {
				return value.available >= ticketCount;
			});

			const showPickTime = isAvaiableInTimeslot.some(slot => slot === true);
			if (Object.keys(timeslotsForToday).length > 1 && showPickTime) {
				setCalculateShowTime(true);
				setSelectedEvent(null);
			} else {
				setCalculateShowTime(false);
				if (timeslotsForToday && date) {
					const [event] = Object.values(timeslotsForToday);
					const eventStartTime = event.start.toDate();
					const isSameDay = moment(date).isSame(eventStartTime, 'day');
					if (isSameDay) {
						setSelectedEvent(event.eventUid);
					} else {
						setSelectedEvent(null);
					}
				}
			}
		} else if (date) {
			setSelectedEvent(null);
		}
	}, [forceShowTime, groupSummary, date, ticketCount]);

	useEffect(() => {
		if (date && groupSummary) {
			const s = {};
			let first = null;
			slotCategories.forEach(slotCat => {
				s[slotCat.title] = _.orderBy(
					groupSummary.dates[date.getDate()]
						? Object.values(groupSummary.dates[date.getDate()].times).map(time => {
								const st = time.start.toDate();
								return {
									...time,
									start: st,
									end: time.end.toDate(),
									startFilter: moment(`${st.getHours()}:${st.getMinutes()}`, 'HH:mm'),
									showTextOnPercentage: eventGroup.showTextOnPercentage,
									textOnTicketAmount: eventGroup.textOnTicketAmount
								};
						  })
						: [],
					['start']
				).filter(
					slot =>
						slot.startFilter.isBetween(slotCat.from, slotCat.to) ||
						slot.startFilter.isSame(slotCat.from) ||
						slot.startFilter.isSame(slotCat.to)
				);
				if (s[slotCat.title].length > 0 && !first) {
					first = slotCat.title;
				}
			});
			setSlots(s);
			setOpenSlotCat(first);
		}
	}, [groupSummary, date, slotCategories, eventGroup]);

	useEffect(() => {
		if (contentElementProps && contentElementProps.eventGroupUid) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(contentElementProps.eventGroupUid, o =>
				setEventGroup(o)
			);
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [contentElementProps]);

	useEffect(() => {
		if (
			eventGroup &&
			eventGroup.ticketTypes.length > 0 &&
			contentElementProps !== undefined &&
			contentElementProps !== null
		) {
			const productsListenerUnsubFunc = productsListener(
				eventGroup.ticketTypes,
				contentElementProps.productType,
				productList => {
					const copyOfProducts = productList;

					if (!flags.disclaimerAndSortOnGroupedProducts) {
						const productsWithDisclaimer = productList.filter(filterForDisclaimers);
						const indexes = {};
						let index = 0;
						productsWithDisclaimer.forEach((product, i) => {
							if (
								product.disclaimer &&
								(indexes[
									flags.multiLanguageFunctions
										? getLocaleFromData(product, 'disclaimer')
										: product.disclaimer
								] === undefined ||
									indexes[product.disclaimer] === null)
							) {
								indexes[
									flags.multiLanguageFunctions
										? getLocaleFromData(product, 'disclaimer')
										: product.disclaimer
								] = index;
								index += 1;
							}
						});
						productsWithDisclaimer.forEach((product, i) => {
							if (product.disclaimer) {
								product.disclaimerIndex =
									indexes[
										flags.multiLanguageFunctions
											? getLocaleFromData(product, 'disclaimer')
											: product.disclaimer
									];
							}
						});
						setDisclaimers(indexes);
					}
					setProducts(copyOfProducts);
				}
			);
			const productGroupsListenerUnsubFuncs = productGroupsListener(eventGroup.ticketTypes, (id, item) => {
				setProductGroups(oldState => {
					return { ...oldState, [id]: item };
				});
			});
			return () => {
				productsListenerUnsubFunc();
				productGroupsListenerUnsubFuncs.forEach(f => f());
			};
		}

		return () => {};
	}, [
		eventGroup,
		contentElementProps,
		flags.maxAmountOfProductPerProductGroupInCart,
		flags.disclaimerAndSortOnGroupedProducts,
		flags.multiLanguageFunctions
	]);

	const disclaimersForShowingProducts = useMemo(() => {
		if (!flags.disclaimerAndSortOnGroupedProducts) {
			return null;
		}
		const productsWithDisclaimer = showingProducts.filter(filterForDisclaimers);
		const indexes = {};
		let index = 0;
		productsWithDisclaimer.forEach((product, i) => {
			if (
				product.disclaimer &&
				(indexes[
					flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
				] === undefined ||
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					] === null)
			) {
				indexes[flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer] =
					index;
				index += 1;
			}
		});
		productsWithDisclaimer.forEach((product, i) => {
			if (product.disclaimer) {
				product.disclaimerIndex =
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					];
			}
		});
		return indexes;
	}, [showingProducts, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	useEffect(() => {
		if (eventGroup && year && month) {
			const productsListenerUnsubFunc = groupSummaryListener(eventGroup.id, month, year, o => setGroupSummary(o));
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, year, month]);

	useEffect(() => {
		setProductsWithAmount(oldState => {
			const newState = {};
			products.forEach(product => {
				if (oldState[product.id] !== null && oldState[product.id] !== undefined) {
					newState[product.id] = oldState[product.id];
				} else {
					newState[product.id] = 0;
				}
			});
			return newState;
		});
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const location = eventGroup && eventGroup.locations ? eventGroup.locations[0] : null;
				const price = findPricesForProduct(product, location, date, flags.displayPeriodePrices);
				totals += price * productsWithAmount[pKey];
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products, eventGroup, date, flags.displayPeriodePrices]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		if (ticketCount > 0) {
			setOpenBox2(true);
		}
		if (ticketCount === 0 && date) {
			setDate(null);
			setSelectedEvent(null);
		}
	}, [ticketCount, date]);

	useEffect(() => {
		const groupByProductGroups = Object.values(productGroups).filter(pg => pg.groupByName);
		if (groupByProductGroups.length > 0) {
			const newProducts = products.filter(p => !groupByProductGroups.some(pg => pg.id === p.ticketTypeUid));
			const productsWithGroupBy = products.filter(p =>
				groupByProductGroups.some(pg => pg.id === p.ticketTypeUid)
			);
			const groupBy = productsWithGroupBy.reduce((accProducts, curProduct) => {
				if (!accProducts[curProduct.ticketTypeUid]) {
					accProducts[curProduct.ticketTypeUid] = [];
				}
				accProducts[curProduct.ticketTypeUid].push(curProduct);
				return accProducts;
			}, {});
			Object.entries(groupBy).forEach(([key, value]) => {
				const prices = value.reduce((acc, cur) => {
					const p = cur.prices.find(pr => pr.type === 'Sale');
					if (p) {
						acc.push(p.price);
					} else {
						acc.push(0);
					}
					return acc;
				}, []);
				prices.sort((a, b) => a - b);
				const lowestPrice = prices[0];
				const highestPrice = prices[prices.length - 1];
				const options = [...value].sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
				newProducts.push({
					id: key,
					name: flags.multiLanguageFunctions
						? getLocaleFromData(productGroups[key], 'groupByName')
						: productGroups[key].groupByName,
					lowestPrice,
					highestPrice,
					samePrice: lowestPrice === highestPrice,
					type: 'productGroup',
					items: value.reduce((acc, cur) => {
						acc[cur.id] = cur;
						return acc;
					}, {}),
					options,
					order: flags.disclaimerAndSortOnGroupedProducts ? productGroups[key].groupBySort : 9999,
					disclaimer: flags.disclaimerAndSortOnGroupedProducts
						? flags.multiLanguageFunctions
							? getLocaleFromData(productGroups[key], 'groupByDisclaimer')
							: productGroups[key].groupByDisclaimer
						: null
				});
			});
			newProducts.sort((p1, p2) => p1.order - p2.order);
			setShowingProducts(newProducts);
		} else {
			setShowingProducts(products);
		}
	}, [products, productGroups, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	function getTimeOnEvent(start, end) {
		const s = moment(start);
		const e = moment(end);
		if (s.minutes() !== 0 || e.minutes() !== 0) {
			return `${s.format('HH:mm')} - ${e.format('HH:mm')}`;
		}
		return `${s.format('HH')} - ${e.format('HH')}`;
	}

	function goToCheckOut() {
		navigate('/cart', {
			state: {
				event: selectedEvent,
				products: productsWithAmount,
				location: eventGroup.locations && eventGroup.locations[0] ? eventGroup.locations[0] : null
			}
		});
	}

	function findDefaultGroupSummaryDates(groupSummaryDates) {
		const dates = Object.keys(groupSummaryDates);
		if (dates.length > 0) {
			return groupSummaryDates[dates[0]].times;
		}
		return {};
	}

	function isAllowedToBuy() {
		return !selectedEvent || Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	function showAvailable(max, amount) {
		const hideAbove = max / (100 / eventGroup.hideTicketAmountAbovePercent);
		return hideAbove >= amount;
	}

	const selectedEventTime = useMemo(() => {
		if (!groupSummary || !selectedEvent || !flags.minorFixes) {
			return null;
		}
		let event = null;
		Object.values(groupSummary.dates).every(_date => {
			Object.values(_date.times).every(_time => {
				if (selectedEvent === _time.eventUid) {
					event = _time;
					return false;
				}
				return true;
			});
			return !event;
		});
		if (event) {
			const start = moment(event.start.toDate());
			const end = moment(event.end.toDate());
			if (start.isSame(end, 'day')) {
				return `${start.format('DD-MM-YYYY HH:mm')} - ${end.format('HH:mm')}`;
			}
			return `${start.format('DD-MM-YYYY HH:mm')} - ${end.format('DD-MM-YYYY HH:mm')}`;
		}
		return null;
	}, [groupSummary, selectedEvent, flags.minorFixes]);

	return (
		<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto')}>
			<Grid container spacing={0}>
				<Paper elevation={3} className="px-4 py-28 md:px-28 w-full">
					<div className="flex flex-col md:flex-row w-full ">
						<Grid item {...gridAttr} className="flex flex-col px-10 pt-0">
							<div
								className={clsx(
									classes.stepNumberIcon,
									'font-bold flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								1
							</div>
							<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
								{t('HOW_MANY')}
							</Typography>
							<Button
								onClick={() => {
									setOpenBox1(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
							>
								{t('CHOOSE_QUANTITY')} <Icon>{openBox1 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox1 && (
								<>
									{flags.productsOnEventSelectorRefactor && (
										<Box1
											eventGroup={eventGroup}
											classes={classes}
											date={date}
											groupByRefs={groupByRefs}
											disclaimers={
												flags.disclaimerAndSortOnGroupedProducts
													? disclaimersForShowingProducts
													: disclaimers
											}
											productGroups={productGroups}
											productsWithAmount={productsWithAmount}
											setProductsWithAmount={setProductsWithAmount}
											products={products}
											showingProducts={showingProducts}
											totalAmount={totalAmount}
										/>
									)}
									{!flags.productsOnEventSelectorRefactor && (
										<>
											{showingProducts.map(product =>
												product.type === 'productGroup' ? (
													<div className="w-full" key={product.id}>
														<div className="flex justify-between items-center w-full">
															<div className="flex justify-between w-full items-center h-56">
																<Typography
																	className="font-bold whitespace-normal cursor-default"
																	onMouseOver={() => {
																		if (
																			product.disclaimerIndex !== null &&
																			product.disclaimerIndex !== undefined
																		) {
																			setHighlightedDisclaimer(
																				product.disclaimerIndex
																			);
																		}
																	}}
																	onMouseLeave={() => {
																		if (
																			product.disclaimerIndex !== null &&
																			product.disclaimerIndex !== undefined
																		) {
																			setHighlightedDisclaimer(null);
																		}
																	}}
																>
																	{/* className="font-bold text-14 md:text-base"> */}
																	{flags.multiLanguageFunctions
																		? getLocaleFromData(product, 'name')
																		: product.name}
																	&nbsp;
																	{product.disclaimer
																		? '*'.repeat(product.disclaimerIndex + 1)
																		: ''}
																</Typography>
																<Typography className="px-8 font-bold flex items-center">
																	{selectedGroupBy[product.id] &&
																	product.items[selectedGroupBy[product.id].id]
																		? `${
																				product.items[
																					selectedGroupBy[product.id].id
																				].prices.find(f => f.type === 'Sale')
																					.price / 100
																		  },-`
																		: product.samePrice
																		? `${product.lowestPrice / 100},-`
																		: `${product.lowestPrice / 100} - ${
																				product.highestPrice / 100
																		  },-`}
																</Typography>
															</div>
															<div className="flex ButtonDiv h-56 items-center">
																{/* eslint-disable-next-line */}
																<div
																	className="flex items-center w-full"
																	onClick={() => {
																		if (
																			(!selectedGroupBy[product.id] ||
																				!selectedGroupBy[product.id].id) &&
																			groupByRefs.current[product.id]
																		) {
																			groupByRefs.current[product.id].focus();
																		}
																	}}
																>
																	<Button
																		variant="outlined"
																		disabled={
																			!selectedGroupBy[product.id] ||
																			!selectedGroupBy[product.id].id
																		}
																		onClick={() => {
																			setProductsWithAmount(oldState => {
																				const p = { ...oldState };
																				if (
																					p[
																						selectedGroupBy[product.id].id
																					] !== 0
																				) {
																					p[
																						selectedGroupBy[product.id].id
																					] -= 1;
																				}
																				return p;
																			});
																		}}
																		className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
																	>
																		<Remove />
																	</Button>
																	<div className="flex items-center text-14 font-bold px-10">
																		{selectedGroupBy[product.id] &&
																		productsWithAmount[
																			selectedGroupBy[product.id].id
																		]
																			? productsWithAmount[
																					selectedGroupBy[product.id].id
																			  ]
																			: '0'}
																	</div>

																	<Tooltip
																		title={
																			flags.maxAmountOfProductPerProductGroupInCart &&
																			selectedGroupBy[product.id] &&
																			selectedGroupBy[product.id].ticketTypeUid &&
																			productGroups[
																				selectedGroupBy[product.id]
																					.ticketTypeUid
																			] &&
																			productGroups[
																				selectedGroupBy[product.id]
																					.ticketTypeUid
																			].maxTickets &&
																			productGroups[
																				selectedGroupBy[product.id]
																					.ticketTypeUid
																			].maxTickets.amount &&
																			productsWithAmount[
																				selectedGroupBy[product.id].id
																			] >=
																				productGroups[
																					selectedGroupBy[product.id]
																						.ticketTypeUid
																				].maxTickets.amount
																				? `${t('YOU_CAN_MAX_BUY')} ${
																						productGroups[
																							selectedGroupBy[product.id]
																								.ticketTypeUid
																						].maxTickets.amount
																				  } ${t('OF_THIS_PRODUCT')}`
																				: ''
																		}
																	>
																		<Button
																			variant="outlined"
																			disabled={
																				!selectedGroupBy[product.id] ||
																				!selectedGroupBy[product.id].id
																			}
																			onClick={() => {
																				if (
																					flags.maxAmountOfProductPerProductGroupInCart
																				) {
																					const { maxTickets } =
																						productGroups[
																							selectedGroupBy[product.id]
																								.ticketTypeUid
																						];
																					setProductsWithAmount(oldState => {
																						const p = { ...oldState };
																						if (
																							maxTickets &&
																							maxTickets.amount &&
																							maxTickets.amount <=
																								p[
																									selectedGroupBy[
																										product.id
																									].id
																								]
																						) {
																							p[
																								selectedGroupBy[
																									product.id
																								].id
																							] = maxTickets.amount;
																						} else {
																							p[
																								selectedGroupBy[
																									product.id
																								].id
																							] += 1;
																						}
																						return p;
																					});
																				} else {
																					setProductsWithAmount(oldState => {
																						const p = { ...oldState };
																						p[
																							selectedGroupBy[
																								product.id
																							].id
																						] += 1;
																						return p;
																					});
																				}
																			}}
																			className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
																		>
																			<Add />
																		</Button>
																	</Tooltip>
																</div>
															</div>
														</div>
														<div className="mr-92 pr-8 -mt-8 mb-10">
															<Autocomplete
																className="w-full"
																fullWidth
																id="groupBySelected"
																name="groupBySelected"
																options={product.options}
																value={selectedGroupBy[product.id] || null}
																onChange={async (event, newValue) => {
																	if (
																		selectedGroupBy[product.id] &&
																		selectedGroupBy[product.id].id
																	) {
																		setProductsWithAmount(oldState => ({
																			...oldState,
																			[selectedGroupBy[product.id].id]: 0
																		}));
																	}
																	setSelectedGroupBy(oldState => ({
																		...oldState,
																		[product.id]: newValue
																	}));
																}}
																getOptionLabel={option => {
																	return option && option.name ? option.name : '-';
																}}
																size="small"
																renderInput={params => (
																	<TextField
																		{...params}
																		label={t('SELECT_FROM_LIST')}
																		variant="outlined"
																		inputRef={r => {
																			if (r) {
																				groupByRefs.current = {
																					...groupByRefs.current,
																					[product.id]: r
																				};
																			}
																		}}
																	/>
																)}
																openOnFocus
																required
															/>
														</div>
													</div>
												) : (
													<div className="flex justify-between items-center" key={product.id}>
														<div className="flex justify-between w-full">
															<Typography
																className={`font-bold whitespace-normal cursor-default ${classes.text}`}
																onMouseOver={() => {
																	if (
																		product.disclaimerIndex !== null &&
																		product.disclaimerIndex !== undefined
																	) {
																		setHighlightedDisclaimer(
																			product.disclaimerIndex
																		);
																	}
																}}
																onMouseLeave={() => {
																	if (
																		product.disclaimerIndex !== null &&
																		product.disclaimerIndex !== undefined
																	) {
																		setHighlightedDisclaimer(null);
																	}
																}}
															>
																{/* className="font-bold text-14 md:text-base"> */}
																{flags.multiLanguageFunctions
																	? getLocaleFromData(product, 'name')
																	: product.name}
																&nbsp;
																{product.disclaimer
																	? '*'.repeat(product.disclaimerIndex + 1)
																	: ''}
															</Typography>
															<Typography
																className={`px-8 font-bold flex items-center ${classes.text}`}
															>
																{findPricesForProduct(
																	product,
																	eventGroup,
																	date,
																	flags.displayPeriodePrices
																) / 100}
																,-
															</Typography>
														</div>
														<div className="flex ButtonDiv h-56 items-center">
															<Button
																variant="outlined"
																onClick={() => {
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		if (p[product.id] !== 0) {
																			p[product.id] -= 1;
																		}
																		return p;
																	});
																}}
																className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none ${classes.text}`}
															>
																<Remove />
															</Button>
															<div
																className={`flex items-center text-14 font-bold px-10 ${classes.text}`}
															>
																{productsWithAmount[product.id]}
															</div>

															<Tooltip
																title={
																	flags.maxAmountOfProductPerProductGroupInCart &&
																	product.ticketTypeUid &&
																	productGroups[product.ticketTypeUid] &&
																	productGroups[product.ticketTypeUid].maxTickets &&
																	productGroups[product.ticketTypeUid].maxTickets
																		.amount &&
																	productsWithAmount[product.id] >=
																		productGroups[product.ticketTypeUid].maxTickets
																			.amount
																		? `${t('YOU_CAN_MAX_BUY')} ${
																				productGroups[product.ticketTypeUid]
																					.maxTickets.amount
																		  } ${t('OF_THIS_PRODUCT')}`
																		: ''
																}
															>
																<Button
																	variant="outlined"
																	onClick={() => {
																		if (
																			flags.maxAmountOfProductPerProductGroupInCart
																		) {
																			const { maxTickets } =
																				productGroups[product.ticketTypeUid];
																			setProductsWithAmount(oldState => {
																				const p = { ...oldState };
																				if (
																					maxTickets &&
																					maxTickets.amount &&
																					maxTickets.amount <= p[product.id]
																				) {
																					p[product.id] = maxTickets.amount;
																				} else {
																					p[product.id] += 1;
																				}
																				return p;
																			});
																		} else {
																			setProductsWithAmount(oldState => {
																				const p = { ...oldState };
																				p[product.id] += 1;
																				return p;
																			});
																		}
																	}}
																	className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none ${classes.text}`}
																>
																	<Add />
																</Button>
															</Tooltip>
														</div>
													</div>
												)
											)}
											<hr />
											<div className="flex justify-between w-full my-6">
												<Typography className={`font-bold text-20 ${classes.text}`}>
													{t('TOTAL')}
												</Typography>
												<Typography className={`font-bold text-20 ${classes.text}`}>{`${
													totalAmount / 100
												} kr.`}</Typography>
											</div>
											<hr />
											<div className="flex flex-col mt-10">
												{Object.entries(
													flags.disclaimerAndSortOnGroupedProducts
														? disclaimersForShowingProducts
														: disclaimers
												).map(([disclaimer, index]) => (
													<Typography
														key={index}
														className={`${
															highlightedDisclaimer === index
																? 'text-14 font-bold'
																: 'text-12'
														} leading-6`}
														variant="body1"
														style={{ wordWrap: 'break-word' }}
													>
														<span className="mr-4">{'*'.repeat(index + 1)}</span>
														{disclaimer}
													</Typography>
												))}
											</div>
										</>
									)}
								</>
							)}
						</Grid>
						<Grid item {...gridAttr} className="flex flex-col text-left px-10 pt-32 md:pt-0">
							<div
								className={clsx(
									classes.stepNumberIcon,
									'font-bold flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								2
							</div>
							<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
								{t('VISIT_DATE')}
							</Typography>
							<Button
								onClick={() => {
									setOpenBox2(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
							>
								{t('CHOOSE_DATE')} <Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox2 &&
								(ticketCount > 0 ? (
									<>
										<Calendar
											locale={locale || 'da-DK'}
											next2Label={null}
											prev2Label={null}
											prevLabel={<ArrowBackIcon />}
											nextLabel={<ArrowForwardIcon />}
											minDetail="month"
											onChange={value => {
												setDate(value);
												setOpenBox3(true);
											}}
											value={date}
											showNeighboringMonth={false}
											minDate={new Date()}
											className={clsx(classes.calendarPicker, 'text-center')}
											tileDisabled={tileData => {
												if (ticketCount === 0) {
													return true;
												}
												const d = tileData.date.getDate();
												if (groupSummary && groupSummary.dates[d]) {
													if (groupSummary.dates[d].availableAtSameTime >= ticketCount) {
														return false;
													}
												}
												if (
													date &&
													tileData.date.getFullYear() === date.getFullYear() &&
													tileData.date.getMonth() === date.getMonth() &&
													tileData.date.getDate() === date.getDate()
												) {
													setDate(null);
													setSelectedEvent(null);
												}
												return true;
											}}
											onActiveStartDateChange={dateData => {
												setDate(null);
												setMonth(dateData.activeStartDate.getMonth() + 1);
												setYear(dateData.activeStartDate.getFullYear());
											}}
											tileContent={d => {
												let tooltip = '';
												if (
													groupSummary &&
													groupSummary.dates &&
													groupSummary.dates[d.date.getDate()]
												) {
													tooltip = flags.multiLanguageFunctions
														? getLocaleFromData(
																groupSummary.dates[d.date.getDate()],
																'displayName'
														  )
														: groupSummary.dates[d.date.getDate()].displayName || '';
												}
												return (
													<Tooltip title={tooltip}>
														<div aria-label={moment(d.date).format('DD[.] MMMM YYYY')}>
															{d.date.getDate()}
														</div>
													</Tooltip>
												);
											}}
											tileClassName={d => {
												if (
													customClasses[
														`custom-${
															d.date.getMonth() + 1
														}-${d.date.getFullYear()}-${d.date.getDate()}`
													]
												) {
													return [
														customClasses[
															`custom-${
																d.date.getMonth() + 1
															}-${d.date.getFullYear()}-${d.date.getDate()}`
														]
													];
												}
												return [];
											}}
										/>
										{flags.addedTimeslotTextToCalendarIfNoTimeslotsIsVisible &&
											!forceShowTime &&
											!calculateShowTime && (
												<Typography
													className={clsx(
														'text-10 pl-6 pt-4',
														contentElementProps.timeslotTailwindClasses
													)}
												>
													{contentElementProps.timeslotText}
												</Typography>
											)}
									</>
								) : (
									<Typography
										className={`font-bold whitespace-no-wrap text-center mt-12 ${classes.text}`}
									>
										{t('CHOOSE_TICKETS_BEFORE')}
									</Typography>
								))}
						</Grid>
						{(forceShowTime || calculateShowTime) && (
							<Grid item {...gridAttr} className="flex flex-col w-full px-10 pt-32 md:pt-0">
								<div
									className={clsx(
										classes.stepNumberIcon,
										'font-bold flex items-center justify-center rounded-full h-28 w-28'
									)}
								>
									3
								</div>
								<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
									{t('VISIT_TIME')}
								</Typography>

								<div>
									<Button
										onClick={() => {
											setOpenBox3(oldstate => !oldstate);
										}}
										color="secondary"
										variant="contained"
										className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
									>
										{t('CHOOSE_TIME')} <Icon>{openBox3 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
									</Button>
								</div>
								{openBox3 &&
									(groupSummary && date && groupSummary.dates[date.getDate()] ? (
										<Grid className="mt-4" spacing={1} container>
											<EventGroupTimeSlot
												openSlotCat={openSlotCat}
												selectedEvent={selectedEvent}
												setOpenSlotCat={setOpenSlotCat}
												setSelectedEvent={setSelectedEvent}
												showAvailable={showAvailable}
												slotCategories={slotCategories}
												slots={slots}
												ticketCount={ticketCount}
												getTimeOnEvent={getTimeOnEvent}
												now={now}
												parentClasses={classes}
											/>
											<Typography
												className={clsx(
													'text-10 pl-6 pt-4',
													contentElementProps.timeslotTailwindClasses
												)}
											>
												{contentElementProps.timeslotText}
											</Typography>
										</Grid>
									) : (
										<Typography
											className={`font-bold whitespace-no-wrap text-center mt-12 ${classes.text}`}
										>
											{t('CHOOSE_DATE_BEFORE')}
										</Typography>
									))}
							</Grid>
						)}
					</div>
					<Grid container direction="row" justifyContent="flex-end" className="">
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="px-10 pt-20">
							<Button
								onClick={goToCheckOut}
								className={`w-full rounded-none ${classes.buyButton}`}
								variant="contained"
								endIcon={<ArrowForwardIcon />}
								color="primary"
								disabled={isAllowedToBuy()}
							>
								{t('BUY_TICKET_BUTTON')}
							</Button>
							{flags.minorFixes && !(forceShowTime || calculateShowTime) && !!selectedEventTime && (
								<Typography className="text-13 mt-4" color="textSecondary">
									{t('SELECTED_TIME')} {selectedEventTime}
								</Typography>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
}

export default EventGroupDateTimeSelector;

const Box1 = ({
	showingProducts,
	products,
	productsWithAmount,
	groupByRefs,
	classes,
	productGroups,
	setProductsWithAmount,
	disclaimers,
	totalAmount,
	eventGroup,
	date
}) => {
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [selectedGroupBy, setSelectedGroupBy] = useState({});
	const flags = useFlags();
	const { t } = useTranslation();

	const toolTipTitleAdd = (product, variant) => {
		if (variant) {
			if (
				flags.maxAmountOfProductPerProductGroupInCart &&
				selectedGroupBy[product.id] &&
				selectedGroupBy[product.id].ticketTypeUid &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid] &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount &&
				productsWithAmount[selectedGroupBy[product.id].id] >=
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
			) {
				return `${t('YOU_CAN_MAX_BUY')} ${
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
				} ${t('OF_THIS_PRODUCT')}`;
			}
		} else if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			product.ticketTypeUid &&
			productGroups[product.ticketTypeUid] &&
			productGroups[product.ticketTypeUid].maxTickets &&
			productGroups[product.ticketTypeUid].maxTickets.amount &&
			productsWithAmount[product.id] >= productGroups[product.ticketTypeUid].maxTickets.amount
		) {
			return `${t('YOU_CAN_MAX_BUY')} ${productGroups[product.ticketTypeUid].maxTickets.amount} ${t(
				'OF_THIS_PRODUCT'
			)}`;
		} else if (
			flags.maximumAmountOfProduct &&
			product.maximumAmount &&
			productsWithAmount[product.id] >= product.maximumAmount
		) {
			return `${t('YOU_CAN_MAX_BUY')} ${product.maximumAmount} ${t('OF_THIS_PRODUCT')}`;
		}
		return '';
	};

	const toolTipTitleRemove = product => {
		if (flags.minimumAmountOfProduct && product.minimumAmount) {
			return `${t('YOU_CAN_MIN_BUY')} ${product.minimumAmount} ${t('OF_THIS_PRODUCT')}`;
		}
		return '';
	};

	const updateProductAmount = (id, amount) => {
		setProductsWithAmount(oldState => ({
			...oldState,
			[id]: amount
		}));
	};

	const location = eventGroup && eventGroup.locations ? eventGroup.locations[0] : null;

	return (
		<ProductTotalSection
			classes={classes}
			totalAmount={totalAmount}
			disclaimers={disclaimers}
			highlightedDisclaimer={highlightedDisclaimer}
		>
			{showingProducts.map(product => {
				if (product.type === 'productGroup') {
					return (
						<ProductGroupRow
							key={product.id}
							product={product}
							productGroups={productGroups}
							productsWithAmount={productsWithAmount}
							setProductsWithAmount={setProductsWithAmount}
							groupByRefs={groupByRefs}
							setHighlightedDisclaimer={setHighlightedDisclaimer}
							updateProductAmount={updateProductAmount}
							selectedGroupBy={selectedGroupBy}
							setSelectedGroupBy={setSelectedGroupBy}
							addButtonToolTip={toolTipTitleAdd(product, true)}
							removeButtonToolTip={toolTipTitleRemove(product)}
							date={date}
						/>
					);
				}
				return (
					<ProductRow
						key={product.id}
						productGroups={productGroups}
						product={product}
						classes={classes}
						amount={productsWithAmount[product.id]}
						setHighlightedDisclaimer={setHighlightedDisclaimer}
						priceForProduct={findPricesForProduct(product, location, date, flags.displayPeriodePrices)}
						addButtonToolTip={toolTipTitleAdd(product, false)}
						removeButtonToolTip={toolTipTitleRemove(product)}
						updateProductAmount={updateProductAmount}
						showDisclaimer
					/>
				);
			})}
		</ProductTotalSection>
	);
};
