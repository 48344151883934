const discountCodeDiscountCalculateType = Object.freeze({
	TOTAL: 'total',
	SELECTED_PRODUCTS: 'selectedProducts',
	TOTAL_PRODUCTS: 'totalProducts',
	PER_PRODUCT: 'perProduct'
});

export const discountCodeDiscountCalculateTypeTranslate = {
	[discountCodeDiscountCalculateType.TOTAL]: 'Udregn rabat på baggrund af kurv-totalen',
	[discountCodeDiscountCalculateType.SELECTED_PRODUCTS]: 'Udregn rabat på baggrund af de tilladte produkter',
	[discountCodeDiscountCalculateType.TOTAL_PRODUCTS]: 'Udregn rabat på baggrund af alle tilladte produkter i kurv',
	[discountCodeDiscountCalculateType.PER_PRODUCT]: 'Udregn rabat for hvert tilladt produkt i kurv'
};

export default discountCodeDiscountCalculateType;
