import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

const productsListener = (productList, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.where('uid', 'in', productList || [])
		.where('showOnWeb', '==', true)
		.onSnapshot(
			query => {
				const products = [];
				query.forEach(doc => {
					const docData = doc.data();
					products.push({
						...docData,
						id: doc.id
					});
				});
				callback(products);
			},
			error => {
				console.log(error);
			}
		);
};

const productListener = (id, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.doc(id)
		.onSnapshot(
			doc => {
				callback({ ...doc.data(), id: doc.id });
			},
			error => {
				console.log(error);
			}
		);
};
export { productsListener, productListener };
