import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIcon } from '@mui/material';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../store/shared/frontendSlice';
import { navbarToggleMobile } from '../../../../../../../store/fuse/navbarSlice';
import getColor from '../../shared/getColor';
import { ReactComponent as MenuLogo } from './menu.svg';

function NavbarMobileToggleButton(props) {
	const { contentElementProps } = props;

	const dispatch = useDispatch();

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		icon: {
			color: `${getColor(contentElementProps.iconColor, contentElementProps.customIconColor, mergedTheme)}`
		}
	}));
	const classes = useStyles();

	return (
		<IconButton
			className={`h-${contentElementProps.height} w-${contentElementProps.width} p-0`}
			onClick={ev => dispatch(navbarToggleMobile())}
			color="inherit"
			disableRipple
		>
			{contentElementProps.icon === 'menuText' ? (
				<SvgIcon className={`h-44 w-44 ${classes.icon}`}>
					<MenuLogo />
				</SvgIcon>
			) : (
				<Icon className={`${classes.icon}`}>
					{contentElementProps.icon === 'menu'
						? 'menu'
						: mergedTheme.direction === 'ltr'
						? 'arrow_back'
						: 'arrow_forward'}
				</Icon>
			)}
		</IconButton>
	);
}

export default NavbarMobileToggleButton;
