import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import BrokenImage from '@mui/icons-material/BrokenImage';
import { makeStyles } from '@mui/styles';

const defaultProps = {
	animationDuration: 3000,
	aspectRatio: 1,
	color: common.white,
	disableError: false,
	disableSpinner: false,
	disableTransition: false,
	errorIcon: <BrokenImage style={{ width: 48, height: 48, color: grey[300] }} />,
	loading: <CircularProgress size={48} />,
};

const CustomImage = (_props) => {
	let props = { ..._props };
	Object.entries(defaultProps).forEach(([key, value]) => {
		if (!props.hasOwnProperty(key)) {
			props[key] = value;
		}
	});

	const [state, setState] = useState({
		imageError: false,
		imageLoaded: false,
		src: props.src,
	});
	const imageRef = useRef(null);
	// Equivalent to getDerivedStateFromProps
	useEffect(() => {
		setState({
			imageError: false,
			imageLoaded: false,
			src: props.src,
		});
	}, [props.src]);

	// Equivalent to componentDidMount
	useEffect(() => {
		const img = imageRef.current;
		if (img && img.complete) {
			if (img.naturalWidth === 0) {
				handleImageError();
			} else {
				handleLoadImage();
			}
		}
	}, []);
	const handleLoadImage = (e) => {
		setState((prevState) => ({ ...prevState, imageLoaded: true, imageError: false }));
		if (props.onLoad) {
			props.onLoad(e);
		}
	};
	const handleImageError = (e) => {
		if (props.src) {
			setState((prevState) => ({ ...prevState, imageError: true }));
			if (props.onError) {
				props.onError(e);
			}
		}
	};

	const getStyles = () => {
		const {
			animationDuration,
			aspectRatio,
			cover,
			color,
			imageStyle,
			disableTransition,
			iconContainerStyle,
			style,
		} = props;

		const imageTransition = !disableTransition && {
			opacity: state.imageLoaded ? 1 : 0,
			filterBrightness: state.imageLoaded ? 100 : 0,
			filterSaturate: state.imageLoaded ? 100 : 20,
			transition: `
        filterBrightness ${animationDuration * 0.75}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        filterSaturate ${animationDuration}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        opacity ${animationDuration / 2}ms cubic-bezier(0.4, 0.0, 0.2, 1)`,
		};

		const styles = {
			root: {
				backgroundColor: color,
				paddingTop: `calc(1 / ${aspectRatio} * 100%)`,
				position: 'relative',
				...style,
			},
			image: {
				width: '100%',
				height: '100%',
				position: 'absolute',
				objectFit: cover ? 'cover' : 'inherit',
				top: 0,
				left: 0,
				...imageTransition,
				...imageStyle,
			},
			iconContainer: {
				width: '100%',
				height: '100%',
				position: 'absolute',
				top: 0,
				left: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				pointerEvents: 'none',
				...iconContainerStyle,
			},
		};

		return styles;
	};

	// Define getStyles function here (same as in the class component)
	const styles = getStyles(); // Call the getStyles function

	return (
		<div style={styles.root} onClick={props.onClick}>
			{state.src && (
				<img
					src={state.src}
					ref={imageRef}
					style={styles.image}
					onLoad={handleLoadImage}
					onError={handleImageError}
				/>
			)}
			<div style={styles.iconContainer}>
				{!props.disableSpinner && !state.imageLoaded && !state.imageError && props.loading}
				{!props.disableError && state.imageError && props.errorIcon}
			</div>
		</div>
	);
};
export default CustomImage;
