import { combineReducers } from '@reduxjs/toolkit';
import cartSlice from './cartSlice';
import frontendSlice from './frontendSlice';
import userSlice from './userSlice';

const sharedReducers = combineReducers({
	cart: cartSlice,
	frontend: frontendSlice,
	user: userSlice
});

export default sharedReducers;
