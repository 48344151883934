import React, {lazy} from 'react';

const UserActivationPage = lazy(() => import('./UserActivationPage'))

const UserActivationPageConfig = {
	routes: [
		{
			path: '/account/activate',
			element: <UserActivationPage />
		}
	]
};

export default UserActivationPageConfig;
