import React from 'react';
import Typography from '@mui/material/Typography';

function TextComponent(props) {
	return (
		<Typography
			className="pb-8"
			dangerouslySetInnerHTML={{
				__html: props && props.children ? props.children.replaceAll('\\n', '</br>') : ''
			}}
		/>
	);
}

export default TextComponent;
