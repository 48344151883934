import React from 'react';
import LargeFaqPage from './types/faq/large_faq/LargeFaqPage';
import SmallFaqPage from './types/faq/small_faq/SmallFaqPage';
import Pricing3Page from './types/pricing/pricing_3/Pricing3Page';
import PlainPage from './types/plain/PlainPage';
import PricingListPage from './types/pricing/pricing_list/PricingListPage';
import EventGroupDateTimeSelectorPage from './types/event-group-date-time-selector/EventGroupDateTimeSelectorPage';
import HeaderContentComponent from './types/components/header-content/HeaderContentComponent';
import HeaderComponent from './types/components/header/HeaderComponent';
import ContentComponent from './types/components/content/ContentComponent';
// eslint-disable-next-line import/no-cycle
import Cart from './types/cart/cart/Cart';
import EventGroupDateSelectorPage from './types/event-group-date-selector/EventGroupDateSelectorPage';
import TicketTypePlainTicketPage from './types/ticket-type-plain/TicketTypePlainPage';
// import MarkdownComponent from './types/components/markdown/MarkdownComponent';
import ManualEventDetailPage from './types/manual-event-detail/ManualEventDetailPage';
import ManualEventOverviewPage from './types/manual-event-overview/ManualEventOverviewPage';
import ManualEventTablePage from './types/manual-event-table/ManualEventTablePage';
import FullWidthEventContent from './types/event-content/FullWidthEventContent';
import PapirCenteredEventContent from './types/event-content/PapirCenteredEventContent';
import EventGroupDateSelectorComponent from './types/event-group-date-selector/EventGroupDateSelectorComponent';
import EventGroupDateTimeSelectorComponent from './types/event-group-date-time-selector/EventGroupDateTimeSelectorComponent';
import EventGroupDateTimeSelectorComponentCMS from './types/event-group-date-time-selector/EventGroupDateTimeSelectorComponentCMS';
import RebookingPage from './types/rebooking/RebookingPage';
import Frontpage from './types/frontpage/Frontpage';
import PapirCenteredContent from './types/event-content/PapirCenteredContent';
import MarkdownComponent from '../../../ui-components/content-components/MarkdownComponent';
import LoginPage from './types/user/login/LoginPage';
import RegisterPage from './types/user/register/RegisterPage';
import LogoutPage from './types/user/logout/LogoutPage';
import AccountPage from './types/user/account/AccountPage';
// eslint-disable-next-line import/no-cycle
import Subscription from './types/subscription/Subscription';
import AddToCartButton from './types/components/button/AddToCartButton';
import LocationSelectorComponentCMS from './types/event-group-date-time-selector/LocationSelectorComponentCMS';
import ControlCodePage from './types/control-code/ControlCodePage';

export const customLoading = ['manual_event_table'];

const TypesSettings = {
	cart: data => <Cart data={data} />, // takes {image: string}
	login: data => <LoginPage data={data} />,
	logout: data => <LogoutPage data={data} />,
	register: data => <RegisterPage data={data} />,
	account: data => <AccountPage data={data} />,
	large_single_questions: data => <LargeFaqPage data={{ questions: data }} />,
	large_groups_questions: data => <LargeFaqPage data={{ groups: data }} />,
	small_single_questions: data => <SmallFaqPage data={{ questions: data }} />,
	small_groups_questions: data => <SmallFaqPage data={{ groups: data }} />,
	pricing_3: data => <Pricing3Page data={data} />,
	pricing_list: data => <PricingListPage data={data} />,
	plain: data => <PlainPage data={data} />,
	full_width_event_content: data => <FullWidthEventContent data={data} />,
	paper_centered_event_content: data => <PapirCenteredEventContent data={data} />,
	paper_centered_content: data => <PapirCenteredContent data={data} />,
	event_group_date_time: (data, queries) => <EventGroupDateTimeSelectorPage data={data} queries={queries} />, // takes eventGroupUid as data
	event_group_date: data => <EventGroupDateSelectorPage data={data} />, // takes eventGroupUid as data (all timeslots must start at 00:00)
	event_group_date_component: data => <EventGroupDateSelectorComponent data={data} />,
	event_group_date_time_component: (data, queries) => (
		<EventGroupDateTimeSelectorComponent data={data} queries={queries} />
	),
	event_group_date_time_componentCMS: (data, queries) => (
		<EventGroupDateTimeSelectorComponentCMS data={data} queries={queries} />
	),
	location_componentCMS: (data, queries) => <LocationSelectorComponentCMS data={data} queries={queries} />,
	header: data => <HeaderComponent data={data} />,
	header_content: data => <HeaderContentComponent data={data} />,
	content: data => <ContentComponent data={data} />,
	markdown: props => <MarkdownComponent {...props} />,
	ticket_type_plain: data => <TicketTypePlainTicketPage data={data} />, // takes ticketTypeUid as data
	manual_event_overview: data => <ManualEventOverviewPage data={data} />, // takes eventGroupUids as data
	manual_event_detail: (data, queries) => <ManualEventDetailPage data={data} queries={queries} />,
	manual_event_table: (data, queries, loadingFinished) => (
		<ManualEventTablePage data={data} loadingFinished={loadingFinished} />
	),
	rebooking: (data, queries) => <RebookingPage data={data} queries={queries} />,
	frontpage: data => <Frontpage data={data} />,
	control_code: (data, queries) => <ControlCodePage data={data} queries={queries} />,
	subscription: (data, queries) => <Subscription data={data} queries={queries} />,
	add_to_cart: props => <AddToCartButton {...props} />
};
export default TypesSettings;
