import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle, Autocomplete, Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import {
	selectCustomerId,
	selectOrder,
	selectOrderDraft,
	selectOrderId,
	selectOrderLines,
	submitOrder,
	updateOrderDraft
} from '@ameroservices-platform/attraction-frontend/app/store/shared/cartSlice';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tagManagerBeginCheckOut } from '@ameroservices-platform/attraction-frontend/app/services/googleTagManagerService/googleTagManagerService';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectCountries,
	selectGoogleTagManager,
	selectModules,
	selectPostalCodes,
	selectRequiredOrderFields
} from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import requiredContactInfoOnOrder from '@ameroservices-platform/attraction-frontend/app/ui-components/states/requiredContactInfoOnOrder';
import Button from '@mui/material/Button';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import productType from '@ameroservices-platform/shared/enums/productType';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Chip from '@mui/material/Chip';
import CartInformationEmailField from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/CartInformationEmailField';
import {
	resetErrors,
	submitLoginWithFireBase
} from '@ameroservices-platform/attraction-frontend/app/auth/store/loginSlice';
import { sendConfirmationMail } from '@ameroservices-platform/attraction-frontend/app/auth/store/registerSlice';
import { useSearchParams } from 'react-router-dom';
import { getSubscriptionTypeCurrentEvent } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/subscription/SubscriptionFirestore';
import moment from 'moment-timezone';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export default function CartInformation({ onGoBack, contentElementProps }) {
	const flags = useFlags();
	const dispatch = useDispatch();
	const customerId = useSelector(selectCustomerId);
	const orderId = useSelector(selectOrderId);
	const orderDraft = useSelector(selectOrderDraft);
	const order = useSelector(selectOrder);
	const orderLines = useSelector(selectOrderLines);
	const googleTagManager = useSelector(selectGoogleTagManager);
	const requiredOrderFields = useSelector(selectRequiredOrderFields);
	const countries = useSelector(selectCountries);
	const postalCodes = useSelector(selectPostalCodes);
	const user = useSelector(({ auth }) => auth.user);
	const modules = useSelector(selectModules);
	const login = useSelector(({ auth }) => auth.login);
	const [searchParams, setSearchParams] = useSearchParams();
	const [expanded, setExpanded] = useState(['billing']);
	const [emailResultCheck, setEmailResultCheck] = useState(null);
	const [confirmationEmailState, setConfirmationEmailState] = useState(null);
	const { resetField, handleSubmit, watch, setValue, control, formState, reset } = useForm({
		mode: 'onChange',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			postalCode: '',
			city: '',
			address: '',
			countryCode: null,
			newsletterOptIn: false,
			informations: []
		}
	});
	const { isValid, isSubmitting, errors } = formState;
	const form = watch();
	const { t } = useTranslation();
	const onlyOpenOnce = useRef({});
	const [subscriptionPeriods, setSubscriptionPeriods] = useState({});

	const isUserNotLoggedIn = useMemo(() => user && (user.isAnonymous || !user.role || user.role.length === 0), [user]);

	const confirmEmailField = useMemo(
		() => flags.retypeEmailInCart && contentElementProps && contentElementProps.confirmEmailField,
		[flags.retypeEmailInCart, contentElementProps]
	);

	const shownFields = useMemo(() => {
		if (flags.requiredFieldsOnOrderContactInfoFrontend && requiredOrderFields) {
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_REQUIRED) {
				return ['firstname', 'lastname', 'email'];
			}
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_POSTAL_REQUIRED) {
				return ['firstname', 'lastname', 'email', 'postalCode'];
			}
			if (requiredOrderFields === requiredContactInfoOnOrder.ONLY_FIRST_LAST_EMAIL_SHOWN_AND_REQUIRED) {
				return ['firstname', 'lastname', 'email'];
			}
		}
		return ['firstname', 'lastname', 'email', 'phone', 'postalCode', 'city', 'address', 'countryCode'];
	}, [flags.requiredFieldsOnOrderContactInfoFrontend, requiredOrderFields]);

	const requiredFields = useMemo(() => {
		if (flags.requiredFieldsOnOrderContactInfoFrontend && requiredOrderFields) {
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_REQUIRED) {
				return ['firstname', 'lastname', 'email'];
			}
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_POSTAL_REQUIRED) {
				return ['firstname', 'lastname', 'email', 'postalCode'];
			}
			if (requiredOrderFields === requiredContactInfoOnOrder.ONLY_FIRST_LAST_EMAIL_SHOWN_AND_REQUIRED) {
				return ['firstname', 'lastname', 'email'];
			}
		}
		return ['firstname', 'lastname', 'email', 'phone', 'postalCode', 'city', 'address', 'countryCode'];
	}, [flags.requiredFieldsOnOrderContactInfoFrontend, requiredOrderFields]);

	const isFormValid = useMemo(() => {
		if (
			flags.salesToOtherCountries &&
			(!flags.requiredFieldsOnOrderContactInfoFrontend || requiredFields.includes('countryCode')) &&
			!form.countryCode
		) {
			return false;
		}
		if (
			form.informations.some(
				information =>
					!information.useSameAsBilling && ['firstname', 'lastname'].some(field => !information[field])
			)
		) {
			return false;
		}
		return (
			isValid &&
			(!confirmEmailField ||
				(confirmEmailField && form.email && form.confirmEmail && form.email === form.confirmEmail))
		);
	}, [isValid, form, confirmEmailField]);

	const orderLinesWithSubscription = useMemo(() => {
		return orderLines
			?.filter(orderLine => orderLine.productType === productType.SUBSCRIPTION)
			.reduce((acc, cur) => {
				for (let i = 0; i < cur.qty; i++) {
					acc.push({ ...cur, number: i });
				}
				return acc;
			}, []);
	}, [orderLines]);

	useEffect(() => {
		if (postalCodes[form.postalCode]?.name) {
			setValue('city', postalCodes[form.postalCode].name);
		}
	}, [form.postalCode, postalCodes, setValue]);

	useEffect(() => {
		document.querySelector('#scroll-id').scrollIntoView();
	}, []);

	async function onSubmit() {
		if (googleTagManager) {
			tagManagerBeginCheckOut(orderLines);
		}
		await dispatch(submitOrder());
	}

	useEffect(() => {
		if (!customerId || !orderId) {
			return;
		}
		const dataToUpdate = {};
		Object.keys(form).forEach(key => {
			if (form[key] && key !== 'password') {
				if (key === 'newsletterOptIn') {
					dataToUpdate[key] = form[key];
				} else if (key === 'informations') {
					dataToUpdate[key] = form[key];
				} else {
					dataToUpdate[`contactInfo.billing.${key}`] = form[key];
				}
			}
		});
		dispatch(updateOrderDraft(customerId, orderId, JSON.parse(JSON.stringify(dataToUpdate))));
	}, [form, dispatch, customerId, orderId]);

	useEffect(() => {
		if (orderDraft && orderDraft.contactInfo && orderDraft.contactInfo.billing) {
			const ci = orderDraft.contactInfo.billing;
			reset({
				...ci,
				newsletterOptIn: orderDraft.newsletterOptIn ?? false,
				informations: orderLinesWithSubscription.map(orderLine => {
					const informationSaved = orderDraft?.informations?.find(
						info => info.orderLineUid === orderLine.id && info.orderLineNumber === orderLine.number
					);
					return {
						orderLineUid: orderLine.id,
						orderLineNumber: orderLine.number,
						firstname: informationSaved?.firstname ?? '',
						lastname: informationSaved?.lastname ?? '',
						useSameAsBilling: informationSaved?.useSameAsBilling ?? false
					};
				})
			});
		}
	}, [orderDraft, reset]);

	useEffect(() => {
		if (requiredFields.every(field => form[field])) {
			const nextOrderLine = orderLinesWithSubscription[0];
			if (nextOrderLine) {
				if (onlyOpenOnce.current[`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`]) {
					return;
				}
				onlyOpenOnce.current[`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`] = true;
				setExpanded(oldState => {
					if (oldState.includes(`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`)) {
						return oldState;
					}
					return [...oldState, `orderLine-${nextOrderLine.id}-${nextOrderLine.number}`];
				});
			}
		}
	}, [requiredFields, orderLinesWithSubscription, form]);

	useEffect(() => {
		const subscriptionTypeUids = orderLinesWithSubscription.map(orderLine => orderLine.subscriptionTypeUid);
		const uniqueSubscriptionTypeUids = subscriptionTypeUids.filter(
			(value, index, self) => self.indexOf(value) === index
		);
		Promise.all(
			uniqueSubscriptionTypeUids.map(subscriptionTypeUid =>
				getSubscriptionTypeCurrentEvent(subscriptionTypeUid).then(event => {
					if (event) {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: event
						}));
					} else {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: null
						}));
					}
				})
			)
		);
	}, [orderLinesWithSubscription]);

	useEffect(() => {
		orderLinesWithSubscription.forEach((orderLine, index) => {
			if (
				_.get(form, 'informations.' + index + '.useSameAsBilling') ||
				['firstname', 'lastname'].every(field => _.get(form, 'informations.' + index + '.' + field))
			) {
				const nextOrderLine = orderLinesWithSubscription[index + 1];
				if (nextOrderLine) {
					if (onlyOpenOnce.current[`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`]) {
						return;
					}
					onlyOpenOnce.current[`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`] = true;
					setExpanded(oldState => {
						if (oldState.includes(`orderLine-${nextOrderLine.id}-${nextOrderLine.number}`)) {
							return oldState;
						}
						return [...oldState, `orderLine-${nextOrderLine.id}-${nextOrderLine.number}`];
					});
				}
			}
		});
	}, [orderLinesWithSubscription, form]);

	function handleNewsletterOptIn(event) {
		setValue('newsletterOptIn', event.target.checked);
	}
	const handleAccordionChange = panel => (event, isExpanded) => {
		setExpanded(oldState => {
			if (oldState.includes(panel) && !isExpanded) {
				return oldState.filter(x => x !== panel);
			}
			return [...oldState, panel];
		});
	};

	useEffect(() => {
		if (login.success) {
			window.location.href = `/cart?c=${customerId}&o=${orderId}&n=true`;
		}
	}, [login, customerId, orderId]);

	function handleLogin() {
		if (!emailResultCheck?.emailConfirmed) {
			setConfirmationEmailState(false);
			sendConfirmationMail(form.email).then(() => setConfirmationEmailState(true));
		} else {
			localStorage.setItem(
				'cart',
				JSON.stringify({
					orderLines: orderLines
				})
			);
			dispatch(submitLoginWithFireBase({ username: form.email, password: form.password }));
		}
	}

	function handleEmailResult(result) {
		setEmailResultCheck(result);
		dispatch(resetErrors());
	}

	return (
		<div className={'pt-40'} id={'scroll-id'}>
			<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Accordion disableGutters elevation={0} square expanded>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<div className="px-10 md:px-24 py-10">
								<Typography variant="h6" className="">
									{t('CART_BILLING_INFORMATION')}
								</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div className="px-2 py-10 md:px-24">
								<Grid container spacing={{ xs: 2, md: 3 }} className="sm:max-w-screen">
									{login.error && (login.error.username || login.error.password) && (
										<Grid item xs={12}>
											<Alert severity={'error'}>
												<AlertTitle>{t('AUTH_ERROR_TITLE')}</AlertTitle>
												{!!login.error.username &&
													t(
														login.error.username
															.toUpperCase()
															.replace('/', '_')
															.replaceAll('-', '_')
													)}
												{!!login.error.password &&
													t(
														login.error.password
															.toUpperCase()
															.replace('/', '_')
															.replaceAll('-', '_')
													)}
												{/*const usernameErrorCodes = [*/}
												{/*'auth/email-already-in-use',*/}
												{/*'auth/invalid-email',*/}
												{/*'auth/operation-not-allowed',*/}
												{/*'auth/user-not-found',*/}
												{/*'auth/user-disabled',*/}
												{/*'auth/missing-email'*/}
												{/*];*/}
												{/*const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];*/}
											</Alert>
										</Grid>
									)}
									<Grid item xs={confirmEmailField ? 6 : 12}>
										<TextFieldForm
											control={control}
											id="email"
											type="text"
											name="email"
											label={t('EMAIL')}
											validations={{
												isEmail: true
											}}
											validationErrors={{
												isEmail: t('EMAIL_ERROR')
											}}
											fullWidth
											required={
												!flags.requiredFieldsOnOrderContactInfoFrontend ||
												requiredFields.includes('email')
											}
											enableTrim
											disabled={!isUserNotLoggedIn}
										/>
										{isUserNotLoggedIn && orderLinesWithSubscription.length > 0 && (
											<CartInformationEmailField
												email={form.email}
												onResult={handleEmailResult}
											/>
										)}
									</Grid>
									{confirmEmailField && (
										<Grid item xs={6}>
											<TextFieldForm
												control={control}
												id="confirmEmail"
												type="text"
												name="confirmEmail"
												label={t('CONFIRM_EMAIL')}
												validations={{
													isEmail: true,
													equalsField: 'email'
												}}
												validationErrors={{
													isEmail: t('EMAIL_ERROR'),
													equalsField: t('CONFIRM_EMAIL_ERROR')
												}}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('email')
												}
												enableTrim
												disabled={!isUserNotLoggedIn}
											/>
										</Grid>
									)}
									{isUserNotLoggedIn &&
										emailResultCheck?.emailExisting &&
										!emailResultCheck?.emailChecking && (
											<>
												<Grid item xs={12}>
													{emailResultCheck?.emailConfirmed && (
														<TextFieldForm
															control={control}
															id="password"
															type="password"
															name="password"
															label={t('PASSWORD')}
															fullWidth
															required
															className={'mb-20'}
														/>
													)}
													<LoadingButton
														variant={'contained'}
														className={'w-full'}
														onClick={handleLogin}
														loading={confirmationEmailState === false}
														disabled={confirmationEmailState === true}
													>
														{emailResultCheck?.emailConfirmed
															? t('LOGIN')
															: t('SEND_CONFIRMATION_MAIL')}
													</LoadingButton>
												</Grid>
												<Grid item xs={12}>
													<hr />
												</Grid>
											</>
										)}
									{shownFields.includes('firstname') && (
										<Grid item xs={6} sm={6}>
											<TextFieldForm
												control={control}
												id="firstname"
												name="firstname"
												label={t('FIRST_NAME')}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('FIRST_NAME_MIN_2_CHAR')
												}}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('firstname')
												}
											/>
										</Grid>
									)}
									{shownFields.includes('lastname') && (
										<Grid item xs={6} sm={6}>
											<TextFieldForm
												control={control}
												id="lastname"
												name="lastname"
												label={t('LAST_NAME')}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('LAST_NAME_MIN_2_CHAR')
												}}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('lastname')
												}
											/>
										</Grid>
									)}
									{shownFields.includes('address') && (
										<Grid item xs={12} sm={12}>
											<TextFieldForm
												control={control}
												id="address"
												type="text"
												name="address"
												label={t('ADDRESS')}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('ADDRESS_MIN_2_CHAR')
												}}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('address')
												}
											/>
										</Grid>
									)}

									{shownFields.includes('postalCode') && (
										<Grid item xs={6} sm={6}>
											<TextFieldForm
												control={control}
												id="postalCode"
												type="text"
												name="postalCode"
												label={t('POSTAL_CODE')}
												validations={
													!flags.salesToOtherCountries || form.countryCode === 'DK'
														? {
																minLength: 3,
																maxLength: 4
														  }
														: {
																minLength: 3,
																maxLength: 8
														  }
												}
												validationErrors={
													!flags.salesToOtherCountries || form.countryCode === 'DK'
														? {
																minLength: t('POSTAL_CODE_LENGTH'),
																maxLength: t('POSTAL_CODE_LENGTH')
														  }
														: {
																minLength: t('POSTAL_CODE_LENGTH_INT'),
																maxLength: t('POSTAL_CODE_LENGTH_INT')
														  }
												}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('postalCode')
												}
											/>
										</Grid>
									)}
									{shownFields.includes('city') && (
										<Grid item xs={6} sm={6}>
											<TextFieldForm
												control={control}
												id="city"
												type="text"
												name="city"
												label={t('CITY')}
												validations={{
													minLength: 1
												}}
												validationErrors={{
													minLength: t('CITY_MIN_2_CHAR')
												}}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('city')
												}
											/>
										</Grid>
									)}
									{shownFields.includes('phone') && (
										<Grid item xs={12}>
											<TextFieldForm
												control={control}
												id="phone"
												type="text"
												name="phone"
												label={t('PHONE_NR')}
												validations={{
													minLength: 6,
													maxLength: 20,
													validate: value => {
														if (isValidPhoneNumber(value, 'DK')) {
															return true;
														}
														return t('PHONE_INVALID_REMEMBER_COUNTRY_CODE');
													}
												}}
												validationErrors={
													!flags.salesToOtherCountries || form.countryCode === 'DK'
														? {
																minLength: t('PHONE_NR_MIN_2_CHAR'),
																maxLength: t('PHONE_NR_MIN_2_CHAR')
														  }
														: {
																minLength: t('PHONE_NR_MIN_2_CHAR_INT'),
																maxLength: t('PHONE_NR_MIN_2_CHAR_INT')
														  }
												}
												fullWidth
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('phone')
												}
											/>
										</Grid>
									)}
									{shownFields.includes('countryCode') && flags.salesToOtherCountries && (
										<Grid item xs={12}>
											<Autocomplete
												fullWidth
												id="countryCode"
												name="countryCode"
												options={Object.keys(countries)}
												value={form.countryCode}
												onChange={(event, newValue) => {
													setValue('countryCode', newValue);
													dispatch(
														updateOrderDraft(customerId, orderId, {
															[`contactInfo.billing.countryCode`]: newValue
														})
													);
												}}
												getOptionLabel={option => {
													if (!countries[option]) {
														return '-';
													}
													const { name } = countries[option];
													return name || '-';
												}}
												renderInput={params => <TextField {...params} label={t('COUNTRY')} />}
												openOnFocus
												required={
													!flags.requiredFieldsOnOrderContactInfoFrontend ||
													requiredFields.includes('countryCode')
												}
											/>
										</Grid>
									)}

									<Grid item xs={12}>
										{flags.emailSubscription &&
											flags.showNewsletterOptInCart &&
											modules &&
											(modules.mailchimp || modules.newsletterSignup) &&
											isUserNotLoggedIn && (
												<FormControlLabel
													className="whitespace-no-wrap"
													control={
														<Checkbox
															name="newsletterOptIn"
															id="newsletterOptIn"
															checked={form.newsletterOptIn}
															onChange={handleNewsletterOptIn}
															color="primary"
														/>
													}
													label={
														<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
															{flags.newsletterSignupCustomText && (
																<>
																	{contentElementProps.newsletterSignupText ||
																		t('NEWSLETTER_SIGNUP')}
																</>
															)}
															{!flags.newsletterSignupCustomText && (
																<>{t('NEWSLETTER_SIGNUP')}</>
															)}
														</Typography>
													}
												/>
											)}
									</Grid>
								</Grid>
							</div>
						</AccordionDetails>
					</Accordion>
					{orderLinesWithSubscription.map((orderLine, index) => {
						const errorFields =
							_.get(errors, 'informations.' + index + '.firstname') ||
							_.get(errors, 'informations.' + index + '.lastname');
						const missingFields =
							!_.get(form, 'informations.' + index + '.useSameAsBilling') &&
							(!_.get(form, 'informations.' + index + '.firstname') ||
								!_.get(form, 'informations.' + index + '.lastname'));
						return (
							<Accordion
								disableGutters
								elevation={0}
								square
								expanded={expanded.includes(`orderLine-${orderLine.id}-${orderLine.number}`)}
								onChange={handleAccordionChange(`orderLine-${orderLine.id}-${orderLine.number}`)}
								key={`orderLine-${orderLine.id}-${orderLine.number}`}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1-content"
									id="panel1-header"
								>
									<div>
										<div className="px-10 md:px-24 py-10 flex flex-col gap-6 md:flex-row md:items-center md:gap-16">
											<Typography variant="h6" className="">
												{t('CART_CARDHOLDER')} #{index + 1} {t('CART_CARDHOLDER_FOR')}{' '}
												{orderLine.name}
											</Typography>
											{errorFields && (
												<Chip label={t('CART_ERROR_FIELDS')} size={'small'} color={'error'} />
											)}
											{missingFields && (
												<Chip label={t('CART_ERROR_MISSING_FIELDS')} size={'small'} />
											)}
										</div>
										{subscriptionPeriods[orderLine.subscriptionTypeUid] && (
											<Typography
												className="font-medium px-10 md:px-24"
												variant="subtitle2"
												color="textSecondary"
											>
												{t('SUBSCRIPTION_VALID_FROM')}{' '}
												{moment(
													subscriptionPeriods[orderLine.subscriptionTypeUid].start
												).format('dddd DD-MM-YYYY')}
												{' - '}
												{moment(subscriptionPeriods[orderLine.subscriptionTypeUid].end).format(
													'dddd DD-MM-YYYY'
												)}
											</Typography>
										)}
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<div className="px-2 py-10 md:px-24">
										<Grid container spacing={{ xs: 2, md: 3 }} className="sm:max-w-screen">
											<Grid item xs={12}>
												<FormControlLabel
													className="whitespace-no-wrap"
													control={
														<Checkbox
															name="useSameAsBilling"
															id={'useSameAsBilling-' + index}
															checked={
																!!_.get(
																	form,
																	'informations.' + index + '.useSameAsBilling'
																)
															}
															onChange={event => {
																setValue(
																	'informations.' + index + '.useSameAsBilling',
																	event.target.checked,
																	{
																		shouldValidate: true
																	}
																);
																resetField('informations.' + index + '.firstname', {
																	shouldValidate: true,
																	defaultValue: ''
																});
																resetField('informations.' + index + '.lastname', {
																	shouldValidate: true,
																	defaultValue: ''
																});
															}}
															color="primary"
														/>
													}
													label={t('CART_SAME_NAMES_AS_BILLING')}
													disabled={
														!_.get(form, 'informations.' + index + '.useSameAsBilling') &&
														orderLinesWithSubscription.some((orderLine, index) =>
															_.get(form, 'informations.' + index + '.useSameAsBilling')
														)
													}
												/>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldForm
													control={control}
													id={'informations.' + index + '.firstname'}
													name={'informations.' + index + '.firstname'}
													label={t('FIRST_NAME')}
													validations={{
														minLength: 2
													}}
													validationErrors={{
														minLength: t('FIRST_NAME_MIN_2_CHAR')
													}}
													fullWidth
													disabled={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
													}
													placeholder={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
															? form.firstname
															: ''
													}
													InputLabelProps={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
															? { shrink: true }
															: null
													}
												/>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldForm
													control={control}
													id={'informations.' + index + '.lastname'}
													name={'informations.' + index + '.lastname'}
													label={t('LAST_NAME')}
													validations={{
														minLength: 2
													}}
													validationErrors={{
														minLength: t('LAST_NAME_MIN_2_CHAR')
													}}
													fullWidth
													disabled={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
													}
													placeholder={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
															? form.lastname
															: ''
													}
													InputLabelProps={
														!!_.get(form, 'informations.' + index + '.useSameAsBilling')
															? { shrink: true }
															: null
													}
												/>
											</Grid>
										</Grid>
									</div>
								</AccordionDetails>
							</Accordion>
						);
					})}
					<div className={'flex justify-between mt-20 p-24'}>
						<Button
							startIcon={<NavigateBefore />}
							variant="outlined"
							color="primary"
							className={'w-full md:w-256'}
							onClick={() => onGoBack()}
						>
							{t('GO_BACK')}
						</Button>
						<LoadingButton
							endIcon={<NavigateNext />}
							variant="contained"
							color="primary"
							className={'w-full md:w-256'}
							type={'submit'}
							disabled={!isFormValid}
							loading={isSubmitting}
						>
							{t('CART_PAY_BUTTON')} {moneyFormatter.format((order?.totals?.total ?? 0) / 100)}{' '}
						</LoadingButton>
					</div>
				</form>
			</Paper>
		</div>
	);
}
