const flexsdkMembercardButtons = {
	printCard: 'Print kort',
	showControlcode: 'Se kontrolkode',
	addEntry: 'Tilføj entré',
	addEntryAndGuest: 'Tilføj entré + gæst',
	newMembercardNumber: 'Nyt kortnummer',
	resendTicketNumber: 'Gensend kortnummer'
};

export default flexsdkMembercardButtons;
