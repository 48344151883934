import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { eventGroupListener } from '../event-group-date-selector/EventGroupDateSelectorFirestore';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';
import StorageImage from '../../../../../ui-components/StorageImage';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';

const useStyles = makeStyles(theme => ({
	'full-width': {
		width: '100vw',
		maxWidth: '100vw',
		minHeight: '300px',
		marginLeft: '-50vw',
		left: '50%',
		right: '50%',
		position: 'relative',
		objectFit: 'cover'
	}
}));

function FullWidthEventContent(props) {
	const [eventGroup, setEventGroup] = useState(null);
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		if (props.data) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(props.data, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [props.data]);

	return (
		<>
			{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
				<>
					<div className="">
						<StorageImage
							src={eventGroup.content.image}
							alt="MFS udstilling"
							className={clsx(classes['full-width'])}
						/>
					</div>

					<div className="max-w-3xl w-full mx-auto mt-24" style={{ color: mergedTheme.palette.footer.text }}>
						<div className="mx-12 md:mx-16 p-4">
							{eventGroup.content.content.map((c, i) => {
								if (c.type === 'heading') {
									return <HeadingComponent key={i}>{c.content}</HeadingComponent>;
								}
								if (c.type === 'text') {
									return <TextComponent key={i}>{c.content}</TextComponent>;
								}
								if (c.type === 'number_list') {
									return (
										<ListComponent
											type="number"
											list={c.content.array}
											heading={c.content.heading}
											key={i}
										/>
									);
								}
								if (c.type === 'markdown') {
									return <MarkdownComponent {...c.content} key={i} />;
								}
								return <span key={i} />;
							})}
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default FullWidthEventContent;
