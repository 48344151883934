import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { eventGroupListener } from './EventGroupDateTimeSelectorFirestore';
import MFSContent from '../../../../../fuse-layouts/layout2/components/content/MFSContent';
import DefaultContent from '../../../../../fuse-layouts/layout2/components/content/DefaultContent';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import EventGroupDateTimeSelectorComponent from './EventGroupDateTimeSelectorComponent';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 }
}));

function EventGroupDateTimeSelectorPage({ data, queries }) {
	const classes = useStyles();
	const [eventGroup, setEventGroup] = useState(null);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		if (data && data.eventGroupUid) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(data.eventGroupUid, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}
		if (queries && queries.e) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(queries.e, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [data, queries]);

	return (
		<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto my-48')}>
			<Grid container spacing={0}>
				{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
					<>
						{theme &&
						theme.customStyling &&
						theme.customStyling.content &&
						theme.customStyling.content === 'mfs' ? (
							<MFSContent eventGroup={eventGroup} />
						) : (
							<DefaultContent eventGroup={eventGroup} mergedTheme={mergedTheme} />
						)}
					</>
				)}
				<EventGroupDateTimeSelectorComponent data={data} queries={queries} />
			</Grid>
		</div>
	);
}

export default EventGroupDateTimeSelectorPage;
