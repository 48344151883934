import React from 'react';
import CustomStepper from '../../../../../../../ui-components/CustomStepper';
import { useTranslation } from 'react-i18next';

function SubscriptionStepper({ activeStep }) {
	const { t } = useTranslation();

	return (
		<CustomStepper
			activeStep={activeStep}
			alternativeLabel
			steps={[
				{ label: `${t('LOGIN')} / ${t('CREATE_USER')}` },
				{ label: t('CONFIRM_EMAIL') },
				{ label: t('ACCOUNT_INFO') },
				{ label: t('PAYMENT') }
			]}
		/>
	);
}
export default SubscriptionStepper;
