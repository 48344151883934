import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteOutline } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import { Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../../../../ui-components/ConfirmationDialog';

import {
	deleteOrderLine,
	getEventsByUids,
	selectAllOrderLinesLoading,
	selectEvents,
	selectFinished,
	selectIdsLoading,
	selectOrder,
	selectOrderDraftLoading,
	selectOrderLines,
	selectOrderLinesLoading,
	selectOrderLoading,
	selectTotalsLoading,
	updateAmountOnOrderLine
} from '../../../../../../store/shared/cartSlice';
import SpinnerLoading from '../../../../../../ui-components/SpinnerLoading';
import CartAmountField from './CartAmountField';

const useStyles = makeStyles(theme => ({
	numberInput: {
		'& div': {
			'&:before': {
				borderBottom: 'none'
			},
			'&:after': {
				borderBottom: 'none'
			},
			'&:hover': {
				'&:before': {
					borderBottom: 'none !important'
				},
				'&:after': {
					borderBottom: 'none !important'
				}
			}
		},
		'& input::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'& input': {
			width: '26px',
			backgroundColor: theme.palette.secondary.light,
			padding: '10px',
			zIndex: '100',
			boxShadow: 'none',
			textAlign: 'center'
		}
	},
	buttonLeft: {
		borderBottomRightRadius: '0',
		borderTopRightRadius: '0',
		boxShadow: 'none',
		minWidth: 'auto',
		backgroundColor: theme.palette.secondary.light
	},
	buttonRight: {
		borderBottomLeftRadius: '0',
		borderTopLeftRadius: '0',
		boxShadow: 'none',
		minWidth: 'auto',
		backgroundColor: theme.palette.secondary.light
	},
	spinner: {
		color: theme.palette.primary.main
	}
}));

function getEventTime(s, e) {
	const start = moment(s);
	const end = moment(e);
	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
	}
	return `${start.format('dddd DD-MM-YYYY, HH:mm')} - ${end.format('dddd DD-MM-YYYY, HH:mm')}`;
}

// ******************************************************************************************************************
// When making changes, consider if changes should also be made in CartContentOverviewWithFollowProduct
// ******************************************************************************************************************

function CartContentOverviewOld(props) {
	const { maxAmountOfProductPerProductGroupInCart, minimumAmountOfProduct } = useFlags();
	const dispatch = useDispatch();
	const classes = useStyles();
	const moneyFormatter = new Intl.NumberFormat('da-DK', {
		style: 'currency',
		currency: 'DKK',
		minimumFractionDigits: 2
	});
	const allOrderLinesLoading = useSelector(selectAllOrderLinesLoading);
	const orderLoading = useSelector(selectOrderLoading);
	const order = useSelector(selectOrder);
	const orderLines = useSelector(selectOrderLines);
	const events = useSelector(selectEvents);
	const idsLoading = useSelector(selectIdsLoading);
	const orderDraftLoading = useSelector(selectOrderDraftLoading);
	const orderLinesLoading = useSelector(selectOrderLinesLoading);
	const totalsLoading = useSelector(selectTotalsLoading);
	const finished = useSelector(selectFinished);
	const [confirmDeleteId, setConfirmDeleteId] = useState(null);
	const [disabled] = useState(props.disabled || false);
	const [totals, setTotals] = useState(null);
	const { t } = useTranslation();

	function onAmountChange(orderLine, amount) {
		if (finished) return;
		if (amount === 0) {
			setConfirmDeleteId(orderLine.id);
		} else {
			dispatch(updateAmountOnOrderLine(orderLines, orderLine.id, amount));
		}
	}

	function handleDelete(val) {
		if (val && !finished) {
			dispatch(deleteOrderLine(confirmDeleteId));
		}
		setConfirmDeleteId(null);
	}

	useEffect(() => {
		if (order) {
			setTotals(order.totals);
		}
	}, [order]);

	useEffect(() => {
		const eventUids = orderLines
			.filter(value => value.eventUid)
			.map(m => m.eventUid)
			.filter((value, index, self) => self.indexOf(value) === index);
		if (eventUids.length > 0) {
			dispatch(getEventsByUids(eventUids));
		}

		return () => {};
	}, [orderLines, dispatch]);

	return (
		<div>
			<ConfirmationDialog
				content={t('REMOVE_FROM_CART')}
				keepMounted
				open={confirmDeleteId !== null}
				onClose={handleDelete}
				cancelButton={t('CANCEL_BUTTON')}
				okButton={t('OK_BUTTON')}
			/>
			<Paper elevation={3} className="mb-48 mx-12 md:mx-0 pr-12 relative">
				{orderLoading || allOrderLinesLoading || idsLoading || orderDraftLoading ? (
					<div className="absolute w-full h-full bg-gray-700 bg-opacity-25 z-50">
						<div className="flex justify-center items-center h-full">
							<SpinnerLoading title={t('LOADING')} />
						</div>
					</div>
				) : (
					''
				)}
				<Link color="primary" to="/">
					<Typography color="textPrimary" className="p-24 font-bold">
						<ArrowBackIcon /> {t('GO_BACK')}
					</Typography>
				</Link>
				<Typography variant="h6" className="p-24">
					{t('YOUR_CART')}
				</Typography>
				<Table className="simple">
					<TableHead>
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>Produkt</TableCell>
							<Hidden only={['xs', 'sm', 'md']}>
								<TableCell align="center">{t('PER_UNIT')}</TableCell>
							</Hidden>
							<TableCell align="center">{t('QUANTITY')}</TableCell>
							<TableCell align="right">{t('TOTAL_PRICE')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className="">
						{orderLines.map(ol => (
							<TableRow key={ol.id}>
								<TableCell className={disabled ? '' : classes.tdLeft}>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{ol.name}
									</Typography>
									{ol.eventUid && ol.productType !== 'giftTicket' && (
										<>
											<Typography
												className="font-medium"
												variant="subtitle2"
												color="textSecondary"
											>
												{events[ol.eventUid]
													? events[ol.eventUid].displayName ||
													  events[ol.eventUid].name ||
													  t('UNKOWN_EVENT')
													: t('LOADING_EVENT')}
											</Typography>
											{events[ol.eventUid] && (
												<Typography
													className="font-medium"
													variant="subtitle2"
													color="textSecondary"
												>
													{getEventTime(events[ol.eventUid].start, events[ol.eventUid].end)}
												</Typography>
											)}
										</>
									)}
								</TableCell>
								<Hidden only={['xs', 'sm', 'md']}>
									<TableCell align="center">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{moneyFormatter.format(ol.unitPrice / 100)}
										</Typography>
									</TableCell>
								</Hidden>
								<TableCell className="" align="center">
									{disabled && ol.qty}
									{!disabled && (
										<>
											{maxAmountOfProductPerProductGroupInCart || minimumAmountOfProduct ? (
												<CartAmountField
													className={clsx(
														classes.numberInput,
														'xs:ml-64 sm:ml-64 md:ml-64 lg:ml-64 rounded-none w-42 h-32'
													)}
													defaultValue={ol.qty}
													type="number"
													disabled={finished}
													onBlur={ev => {
														const a = Number.parseInt(ev.currentTarget.value, 10);
														if (!Number.isNaN(a)) {
															onAmountChange(ol, a);
														}
													}}
												/>
											) : (
												<TextField
													className={clsx(
														classes.numberInput,
														'xs:ml-64 sm:ml-64 md:ml-64 lg:ml-64 rounded-none w-42 h-32'
													)}
													defaultValue={ol.qty}
													type="number"
													disabled={finished}
													onBlur={ev => {
														const a = Number.parseInt(ev.currentTarget.value, 10);
														if (!Number.isNaN(a)) {
															onAmountChange(ol, a);
														}
													}}
												/>
											)}
											<Hidden only={['xs']}>
												<Tooltip title={t('REMOVE_PRODUCT_FROM_CART')}>
													<Button onClick={() => onAmountChange(ol, 0)} disabled={finished}>
														<DeleteOutline />
													</Button>
												</Tooltip>
											</Hidden>
										</>
									)}
								</TableCell>
								<Hidden only={['md', 'lg', 'xl']}>
									<TableCell className="" align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{orderLinesLoading.includes(ol.id) ? (
												<CircularProgress size={24} className={classes.spinner} />
											) : (
												moneyFormatter.format(ol.linePrice / 100)
											)}
										</Typography>
									</TableCell>
								</Hidden>
								<Hidden only={['xs', 'sm']}>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{orderLinesLoading.includes(ol.id) ? (
												<CircularProgress size={24} className={classes.spinner} />
											) : (
												moneyFormatter.format(ol.linePrice / 100)
											)}
										</Typography>
									</TableCell>
								</Hidden>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Table className="simple mt-32">
					<TableBody>
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									Subtotal
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									{totalsLoading ? (
										<CircularProgress size={24} className={classes.spinner} />
									) : (
										moneyFormatter.format(totals ? totals.subtotal / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
						{totals && totals.getByTaxPercent ? (
							Object.keys(totals.getByTaxPercent).map(key => (
								<TableRow key={key}>
									<TableCell className={disabled ? '' : classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{t('TAX')} ({key / 100}%)
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<CircularProgress size={24} className={classes.spinner} />
											) : (
												moneyFormatter.format(totals.getByTaxPercent[key] / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell className={disabled ? '' : classes.tdLeft}>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{t('TAX')}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{orderLinesLoading.includes('totals') ? (
											<CircularProgress size={24} className={classes.spinner} />
										) : (
											moneyFormatter.format(totals ? totals.tax / 100.0 : 0)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>
								<Typography className="font-light" variant="h4" color="textSecondary">
									{t('TOTAL')}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-light" variant="h4" color="textSecondary">
									{totalsLoading ? (
										<CircularProgress size={24} className={classes.spinner} />
									) : (
										moneyFormatter.format(totals ? totals.total / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			{/* <div className="flex flex-col items-center justify-center p-12"> */}
			{/* 	<div className="m-12"> */}
			{/* 		<Icon className="text-96" color="action"> */}
			{/* 			sentiment_dissatisfied */}
			{/* 		</Icon> */}
			{/* 	</div> */}

			{/* 	<Typography variant="h5" className="text-center mb-16"> */}
			{/* 		Øv */}
			{/* 	</Typography> */}

			{/* 	<Typography className="text-center mb-16 w-full" color="textSecondary"> */}
			{/* 		Du har intet i din kurv endnu. */}
			{/* 	</Typography> */}

			{/* 	<div className="flex flex-col items-center justify-center pt-12 pb-24"> */}
			{/* 		<Button */}
			{/*			variant="contained" */}
			{/*			className="rounded-none" */}
			{/*			color="primary" */}
			{/*			component={Link} */}
			{/*			to="/apps/pricing" */}
			{/*		> */}
			{/*			Tilføj produkter */}
			{/*		</Button> */}
			{/*	</div> */}
			{/* </div> */}
		</div>
	);
}

export default CartContentOverviewOld;
