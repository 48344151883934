import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Button, ButtonGroup } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import getColor from '../../shared/getColor';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../store/shared/frontendSlice';
import ManualEventTableProductAmount from './ManualEventTableProductAmount';

function ManualEventTableRow({ event, contentElementProps, eventGroups, month }) {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const {
		imageOnManuelEventTable,
		customTextWhenEventIsFreeOnManualEventTable,
		soldOutTextOnManuelEventTable,
		eventGroupsWithoutProducts
	} = useFlags();

	const { t } = useTranslation();
	const useStyles = makeStyles(_theme => ({
		dateBlock: {
			color: `${getColor(
				contentElementProps.dateBlockTextColor,
				contentElementProps.customDateBlockTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.dateBlockBackgroundColor,
				contentElementProps.customDateBlockBackgroundColor,
				mergedTheme
			)}`
		},
		readMoreButton: {
			color: `${getColor(
				contentElementProps.readMoreButtonTextColor,
				contentElementProps.customReadMoreButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.readMoreButtonBackgroundColor,
				contentElementProps.customReadMoreButtonBackgroundColor,
				mergedTheme
			)}`,
			borderColor: `${getColor(
				contentElementProps.readMoreButtonBorderColor,
				contentElementProps.customReadMoreButtonBorderColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.readMoreButtonTextColorHover,
					contentElementProps.customReadMoreButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.readMoreButtonBackgroundColorHover,
					contentElementProps.customReadMoreButtonBackgroundColorHover,
					mergedTheme
				)}`,
				borderColor: `${getColor(
					contentElementProps.readMoreButtonBorderColorHover,
					contentElementProps.customReadMoreButtonBorderColorHover,
					mergedTheme
				)}`
			}
		},
		buyButton: {
			color: `${getColor(
				contentElementProps.buyButtonTextColor,
				contentElementProps.customBuyButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buyButtonBackgroundColor,
				contentElementProps.customBuyButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buyButtonTextColorHover,
					contentElementProps.customBuyButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buyButtonBackgroundColorHover,
					contentElementProps.customBuyButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(null);
	const [linkToExternalSite, setLinkToExternalSite] = useState();
	const [imageError, setImageError] = useState(false);

	useEffect(() => {
		if (event && event.useLinkToExternalSite && event.linkToExternalSite) {
			if (event.linkToExternalSite.startsWith('http')) {
				setLinkToExternalSite(event.linkToExternalSite);
			} else if (event.linkToExternalSite.startsWith('www')) {
				setLinkToExternalSite(`https://${event.linkToExternalSite}`);
			} else {
				setLinkToExternalSite(`https://www.${event.linkToExternalSite}`);
			}
		}
	}, [event, event.useLinkToExternalSite, event.linkToExternalSite]);

	const imageSrc = useMemo(() => {
		if (!imageOnManuelEventTable || !eventGroups || !contentElementProps || !contentElementProps.showImageByEvent) {
			return null;
		}
		if (imageError) {
			return '/assets/images/examples/placeholder.jpg';
		}
		const eventGroup = eventGroups.find(_eventGroup => _eventGroup.id === event.eventGroupUid);
		if (!eventGroup) {
			return null;
		}
		return `https://storage.googleapis.com/${
			eventGroup.overviewContent && eventGroup.overviewContent.image
				? eventGroup.overviewContent.image
				: eventGroup.content && eventGroup.content.image
				? eventGroup.content.image
				: ''
		}`;
	}, [contentElementProps, imageOnManuelEventTable, eventGroups, event, imageError]);

	const free = useMemo(() => {
		if (!customTextWhenEventIsFreeOnManualEventTable) {
			return false;
		}
		if (!eventGroups) {
			return false;
		}
		const eventGroup = eventGroups.find(_eventGroup => _eventGroup.id === event.eventGroupUid);
		if (!eventGroup) {
			return false;
		}
		let _month = month.split('-');
		_month = `${Number.parseInt(_month[1], 10) + 1}-${_month[0]}`;
		const groupSummary = eventGroup.groupSummaries.find(_groupSummary => _groupSummary.month === _month);
		if (!groupSummary) {
			return false;
		}
		return !!groupSummary.free;
	}, [event, eventGroups, month, customTextWhenEventIsFreeOnManualEventTable]);

	const hasProducts = useMemo(() => {
		if (!eventGroupsWithoutProducts) {
			return true;
		}
		const eventGroup = eventGroups.find(_eventGroup => _eventGroup.id === event.eventGroupUid);
		return eventGroup && eventGroup.products && eventGroup.products.length;
	}, [eventGroups, event, eventGroupsWithoutProducts]);

	const soldOut = React.useMemo(() => event && event.available <= 0, [event]);
	const showAvailable = React.useMemo(() => {
		const hideAbove = event.maxTickets / (100 / event.hideTicketAmountAbovePercent);
		return hideAbove >= event.available;
	}, [event]);
	const showSoldOut = React.useMemo(
		() => soldOutTextOnManuelEventTable && !showAvailable && soldOut,
		[soldOutTextOnManuelEventTable, showAvailable, soldOut]
	);

	return (
		<Grid item xs={12} md={12} className="px-8 max-w-full" key={event.eventUid}>
			<div className="flex flex-col py-12 md:py-0 md:flex-row md:items-center md:justify-between">
				<div className="flex flex-row items-center">
					<div className={clsx(classes.dateBlock, 'text-center px-20 w-64 mr-0 col-span-2 sm:col-span-1')}>
						<Typography className="font-bold">{event.start.format('MMM').toUpperCase()}</Typography>
						<Typography className="font-bold text-16">{event.start.format('DD')}</Typography>
						<Typography className="font-bold">{event.start.format('YYYY')}</Typography>
					</div>

					{imageOnManuelEventTable && contentElementProps.showImageByEvent && imageSrc && (
						<div className="flex ml-12">
							<img
								className="object-fill max-w-64 h-full"
								alt="arrangement"
								src={imageSrc}
								onError={() => {
									setImageError(true);
								}}
							/>
						</div>
					)}
					<div className="col-span-5 sm:col-span-6 flex flex-col justify-center ml-28 md:ml-0 pl-12">
						<Typography className="whitespace-no-wrap"> {event.start.format('dddd [-] HH:mm')}</Typography>
						<Typography className="font-bold truncate" title={event.name}>
							{event.name}
						</Typography>
						<Typography className="font-bold truncate" title={event.locations}>
							{event.locations}
						</Typography>
					</div>
				</div>
				<div className="w-full md:w-320 flex flex-col items-center">
					<ButtonGroup className="w-full">
						{contentElementProps.showReadMoreButton && (
							<Button
								role="button"
								target={event.useLinkToExternalSite && event.linkToExternalSite ? '_blank' : undefined}
								className={`rounded-none mt-6 ${classes.readMoreButton} ${
									!eventGroupsWithoutProducts || hasProducts ? 'w-2/5' : 'w-full'
								}`}
								variant="outlined"
								href={
									event.useLinkToExternalSite && event.linkToExternalSite
										? linkToExternalSite
										: undefined
								}
								onClick={
									event.useLinkToExternalSite && event.linkToExternalSite
										? undefined
										: () =>
												navigate(
													contentElementProps.readMoreLinkTo.replace(
														':eventGroupUid',
														event.eventGroupUid
													)
												)
								}
							>
								{t('READ_MORE')}
							</Button>
						)}
						{(!eventGroupsWithoutProducts || hasProducts) && (
							<Button
								className={`w-3/5 mt-6 rounded-none max-h-44 ${classes.buyButton}`}
								variant="contained"
								onClick={() => setOpenDialog(event)}
								disabled={soldOut}
							>
								<div className="flex flex-col max-w-full min-h-28">
									<span className="text-14 font-bold">
										{showSoldOut && (contentElementProps.soldOutButtonText || t('SOLD_OUT'))}
										{!showSoldOut && customTextWhenEventIsFreeOnManualEventTable && (
											<>
												{free
													? contentElementProps.freeButtonText || t('BUY_TICKET_FREE')
													: contentElementProps.buyButtonText || t('BUY_TICKET')}
											</>
										)}
										{!showSoldOut &&
											!customTextWhenEventIsFreeOnManualEventTable &&
											t('BUY_TICKET')}
										{!showSoldOut && <ArrowForward />}
									</span>

									{showAvailable && (
										<span className="text-12 whitespace-no-wrap font-bold">
											{event.showTextOnPercentage ? (
												<>
													{event.textOnTicketAmount && event.available > 0
														? event.textOnTicketAmount
														: t('SOLD_OUT')}
												</>
											) : (
												<>
													{event.available > 0
														? `${event.available} ${t('TICKETS_LEFT')}`
														: t('SOLD_OUT')}
												</>
											)}
										</span>
									)}
								</div>
							</Button>
						)}
					</ButtonGroup>
				</div>
			</div>
			<ManualEventTableProductAmount
				open={!!openDialog}
				event={openDialog}
				handleClose={() => setOpenDialog(null)}
				readMoreLinkTo={contentElementProps.readMoreLinkTo}
				contentElementProps={contentElementProps}
				free={free}
			/>
			<hr className="my-0 md:my-10" />
		</Grid>
	);
}

export default ManualEventTableRow;
