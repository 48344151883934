import React from 'react';
import { useSelector } from 'react-redux';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import Markdown from '../../../../../../ui-components/Markdown';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function MultiLanguageTextElement(props) {
	const { contentElementProps, contentElementParentProps } = props;
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<div
			className={`w-full break-${contentElementParentProps.wordBreak} ${contentElementParentProps.tailwindClasses} text-${
				contentElementParentProps.textAlign
			} ${contentElementParentProps.smTextAlign ? `sm:text-${contentElementParentProps.smTextAlign}` : ``} ${
				contentElementParentProps.mdTextAlign ? `md:text-${contentElementParentProps.mdTextAlign}` : ``
			} ${contentElementParentProps.lgTextAlign ? `lg:text-${contentElementParentProps.lgTextAlign}` : ``} ${
				contentElementParentProps.xlTextAlign ? `xl:text-${contentElementParentProps.xlTextAlign}` : ``
			} ${sharedTailwindClasses({
				...contentElementParentProps
			})}`}
			style={{
				color: `${getColor(contentElementParentProps.color, contentElementParentProps.customColor, mergedTheme)}`
			}}
		>
			<Markdown
				source={contentElementProps.markdown.replace(/\n/gi, '&nbsp;\n\n')}
				isCmsComponent
				linkColor={contentElementParentProps.linkColor}
				fontSize={contentElementParentProps.fontSize}
				lineHeight={contentElementParentProps.lineHeight}
				letterSpacing={contentElementParentProps.letterSpacing}
				fontFamily={contentElementParentProps.fontFamily}
			/>
		</div>
	);
}

export default MultiLanguageTextElement;
