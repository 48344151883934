import { Card, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import { useSearchParams } from 'react-router-dom';

function LandingPage({ data, loading, validated, onValidate }) {
	const flags = useFlags();
	const [ticketNumbers, setTicketNumbers] = useState(['']);
	const [controlNumber, setControlNumber] = useState('');
	const [email, setEmail] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('tickets')) {
			try {
				setTicketNumbers(searchParams.get('tickets').split(','));
			} catch (e) {
				console.log(e);
			}
		}
		if (searchParams.get('control')) {
			setControlNumber(searchParams.get('control'));
		}
		if (searchParams.get('email')) {
			setEmail(searchParams.get('email'));
		}
		if (searchParams.get('tickets') && searchParams.get('control') && searchParams.get('email')) {
			runOnValidate(
				searchParams.get('control'),
				searchParams.get('tickets').split(','),
				searchParams.get('email')
			);
		}
		// eslint-disable-next-line
	}, [searchParams]);

	function runOnValidate(c, t, e) {
		onValidate(c, t, e);
	}

	function addNewTicketNumber() {
		setTicketNumbers(oldState => {
			const newState = [...oldState];
			newState.push('');
			return newState;
		});
	}
	function removeNewTicketNumber(index) {
		setTicketNumbers(oldState => {
			const newState = [...oldState];
			newState.splice(index, 1);
			return newState;
		});
	}

	return (
		<FuseAnimateAdapter animation="transition.expandIn">
			<Card className="max-w-md mx-auto my-48">
				<CardContent className="flex flex-col items-center justify-center p-32">
					<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
						<div className="relative mx-8">
							<Icon className="text-72" color="primary">
								{data?.icon || 'book'}
							</Icon>
						</div>

						<div className="mx-8">
							<Typography variant="h6" className="mb-8">
								{data?.landingTitle || 'Indløs din gave'}
							</Typography>
							{data?.landingText ? (
								<Typography color="textSecondary">{data.landingText}.</Typography>
							) : (
								<>
									<Typography color="textSecondary">
										Indløs din gave, ved at indtaste billetnummer, kontrolnummer samt din email.
										Billet- og kontrolnummeret finder du på din{ticketNumbers.length > 1 ? 'e' : ''}{' '}
										billet
										{ticketNumbers.length > 1 ? 'ter' : ''}.
									</Typography>
									<Typography color="textSecondary">
										Din{ticketNumbers.length > 1 ? 'e' : ''} nye billet
										{ticketNumbers.length > 1 ? 'ter' : ''} vil blive sendt til den email adresse du
										indtaster herunder
									</Typography>
								</>
							)}
						</div>
					</div>

					{validated === false && (
						<Alert severity="error" className="mt-24">
							<AlertTitle>Der skete en fejl</AlertTitle> Det indtastede kontrolnummer og billetnummer
							matcher ikke, eller billetten er ikke længere gyldig.
						</Alert>
					)}

					<div className="flex flex-col justify-center w-full mt-32">
						<TextField
							className="mb-16"
							label="Kontrolkode"
							name="controlNumber"
							variant="outlined"
							required
							fullWidth
							value={controlNumber}
							onChange={e => setControlNumber(e.currentTarget.value)}
						/>
						<TextField
							label="Email"
							placeholder="Billetterne bliver sendt til denne email"
							name="email"
							variant="outlined"
							required
							fullWidth
							value={email}
							onChange={e => setEmail(e.currentTarget.value)}
						/>
					</div>
					<div className="flex flex-col justify-center w-full">
						<Typography className="text-center mt-12 mb-12 h3 font-bold">
							{data?.ticketsTitle || ''}
						</Typography>
						{Object.entries(ticketNumbers).map(([key, value], index) => (
							<div className="flex items-center mb-16" key={index}>
								<TextField
									label="Billetnummer"
									name="ticketNumber"
									variant="outlined"
									required
									fullWidth
									value={ticketNumbers[index]}
									onChange={e => {
										const v = e.currentTarget.value;
										setTicketNumbers(oldState => {
											const newState = [...oldState];
											newState[index] = v;
											return newState;
										});
									}}
									autoFocus={index === ticketNumbers.length - 1}
								/>
								{index !== 0 && (
									<IconButton className="text-3xl ml-2" onClick={e => removeNewTicketNumber(index)}>
										<Icon>delete</Icon>
									</IconButton>
								)}
							</div>
						))}
						{(!flags.subscriptionGiftTicket || data.multiple) && (
							<div className="flex items-center mb-16 opacity-50">
								<TextField
									label="Billetnummer"
									name="ticketNumber"
									variant="outlined"
									required
									fullWidth
									onFocus={addNewTicketNumber}
								/>
								<IconButton className="text-3xl ml-2">
									<Icon>add</Icon>
								</IconButton>
							</div>
						)}
						<div className="mx-auto mt-16">
							<LoadingButton
								variant="contained"
								color="primary"
								className="w-224"
								onClick={() => {
									runOnValidate(controlNumber, ticketNumbers, email);
								}}
								loading={loading}
							>
								Indløs
							</LoadingButton>
						</div>
					</div>
				</CardContent>
			</Card>
		</FuseAnimateAdapter>
	);
}

export default LandingPage;
