import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

export default function AddToCartButton(props) {
	const { productUid, buttonText, buttonTextColor, buttonBgColor } = props;
	const navigate = useNavigate();

	const useStyles = makeStyles(() => ({
		root: {
			backgroundColor: `${buttonBgColor}`,
			color: `${buttonTextColor}`
		}
	}));

	const classes = useStyles();

	function goToCheckOut() {
		navigate('/cart', {
			state: {
				products: {
					[productUid]: 1
				}
			},
		})
	}
	return (
		<Button
			onClick={goToCheckOut}
			className={clsx(classes.root, 'w-256 rounded-none')}
			variant="contained"
			endIcon={<ArrowForwardIcon />}
		>
			{buttonText}
		</Button>
	);
}
