import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import NavbarMobileToggleButton from '../../../shared-components/NavbarMobileToggleButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectOrderLines } from '../../../../store/shared/cartSlice';
import { selectLogos, selectModules } from '../../../../store/shared/frontendSlice';
import UserMenu from '../../../shared-components/UserMenu';
import followProductVisualView from '@ameroservices-platform/shared/enums/followProductVisualView';

function ToolbarLayout2() {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const modules = useSelector(selectModules);
	const orderLines = useSelector(selectOrderLines);
	const logos = useSelector(selectLogos);
	const flags = useFlags();

	return (
		<Toolbar className="container p-0 lg:px-24 justify-between">
			{config.navbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-64 h-64 p-0" />
				</Hidden>
			)}
			<Hidden lgUp>
				<div className="mx-8">
					<Link to="/" className="flex">
						<img
							alt="logo"
							className="w-full max-w-160"
							src={'https://storage.googleapis.com/' + logos.header}
						/>
					</Link>
				</div>
			</Hidden>

			<div className="flex">
				<Button component={Link} to="/cart">
					<Badge
						color="secondary"
						max={99}
						badgeContent={
							orderLines
								? orderLines
										.filter(
											(ol) =>
												!flags.followProduct ||
												ol.lineType !== 'followProduct' ||
												(ol.lineType === 'followProduct' &&
													ol.visualRelation === followProductVisualView.NONE)
										)
										.reduce((acc, cur) => acc + cur.qty, 0)
								: 0
						}
					>
						<ShoppingBasketIcon color="primary" />
					</Badge>
				</Button>
				{modules.frontendUsers && <UserMenu />}
			</div>
		</Toolbar>
	);
}

export default React.memo(ToolbarLayout2);
