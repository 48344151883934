import { ThemeProvider } from '@mui/material/styles';
import { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { Style } from 'react-style-tag';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

function FuseTheme(props) {
	const flags = useFlags();
	const direction = useSelector(({ fuse }) => fuse.settings.defaults.direction);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const [style, setStyle] = useState('');

	if (theme && theme.typography && theme.typography.fontFamily) {
		const fontF = document.getElementById('fontF');
		if (fontF) {
			if (theme.typography.fontType === 'google') {
				let { fontFamily } = theme.typography;
				fontFamily = fontFamily.replace(/\s/, '+');
				fontF.setAttribute(
					'href',
					`https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap`
				);
			} else if (theme.typography.fontType === 'uploaded') {
				fontF.remove();
			}
		}
	}

	useEnhancedEffect(() => {
		document.body.dir = direction;
	}, [direction]);

	useEffect(() => {
		if (
			theme &&
			theme.typography &&
			theme.typography.fontFamily &&
			theme.typography.fontLocation &&
			theme.typography.fontType === 'uploaded'
		) {
			let styleStr = '';
			if (theme.typography.extraFonts) {
				styleStr += Object.entries(theme.typography.extraFonts)
					.filter(([uid, font]) => font.fontFamily && font.fontWeight && font.fontLocation)
					.map(
						([uid, font]) => `
								@font-face {
									font-family: ${font.fontFamily};
									src: url('https://storage.googleapis.com/${font.fontLocation}');
									font-weight: ${font.fontWeight};
								}
							`
					)
					.join('');
			}
			styleStr += `
								@font-face {
									font-family: ${theme.typography.fontFamily};
									src: url('https://storage.googleapis.com/${theme.typography.fontLocation}');
									font-weight: 400;
								}`;
			setStyle(styleStr);
		}
	}, [theme]);

	// console.warn('FuseTheme:: rendered',mainTheme);
	return (
		<ThemeProvider theme={mergedTheme}>
			{theme &&
				theme.typography &&
				theme.typography.fontFamily &&
				theme.typography.fontLocation &&
				theme.typography.fontType === 'uploaded' && (
					<>
						{flags.supportOfMultipleFonts ? (
							<Style>{style}</Style>
						) : (
							<Style>
								{`
								@font-face {
									font-family: ${theme.typography.fontFamily};
									src: url('https://storage.googleapis.com/${theme.typography.fontLocation}');
									font-weight: bold;
								}
							`}
							</Style>
						)}
					</>
				)}
			{props.children}
		</ThemeProvider>
	);
}

export default memo(FuseTheme);
