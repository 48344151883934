import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavbarMobileToggleButton from '../../../../shared-components/NavbarMobileToggleButton';
import React from 'react';
import { useSelector } from 'react-redux';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';
import { selectModules } from '../../../../../store/shared/frontendSlice';
import UserMenu from '../../../../shared-components/UserMenu';
import { selectOrderLines } from '@ameroservices-platform/attraction-frontend/app/store/shared/cartSlice';

const useStyles = makeStyles((theme) => ({
	root: {
		color: '#FFFFFF',
	},
}));

function DarkToolbarLayout2(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const modules = useSelector(selectModules);
	const orderLines = useSelector(selectOrderLines);

	const classes = useStyles(props);

	return (
		<Toolbar className="container p-0 lg:px-24">
			{config.navbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-64 h-64 p-0" />
				</Hidden>
			)}

			<div className="flex flex-1">
				<Button component={Link} to="/cart">
					<Badge
						max={99}
						badgeContent={orderLines ? orderLines.length : 0}
						classes={{
							root: classes.root,
						}}
					>
						<ShoppingBasketIcon />
					</Badge>
				</Button>
				{modules.frontendUsers && <UserMenu />}
			</div>
		</Toolbar>
	);
}

export default React.memo(DarkToolbarLayout2);
