import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import { sendConfirmationEmail } from '../../../../../../../store/shared/userSlice';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

function AccountVerificationNeeded({ type }) {
	const flags = useFlags();
	const user = useSelector(({ shared }) => shared.user.user);
	const userAuth = useSelector(({ auth }) => auth.user);
	const [loading, setLoading] = useState(false);
	const [confirmationDisabled, setConfirmationDisabled] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		if (userAuth && userAuth.claims && !userAuth.claims.email_verified) {
			handleConfirmationMail(true);
		}
		// eslint-disable-next-line
	}, [userAuth]);

	useEffect(() => {
		if (
			flags.autoRefreshTokenWhenEmailVerified &&
			user &&
			user.email_verified === true &&
			userAuth &&
			userAuth.claims &&
			!userAuth.claims.email_verified
		) {
			firebaseService.refreshToken();
		}
	}, [userAuth, user, flags.autoRefreshTokenWhenEmailVerified]);

	async function handleConfirmationMail(autoSend = false) {
		setConfirmationDisabled(true);
		setLoading(true);
		await dispatch(sendConfirmationEmail(autoSend));
		setLoading(false);
		setTimeout(() => {
			setConfirmationDisabled(false);
		}, 10000);
	}

	return (
		<FuseAnimateAdapter animation="transition.expandIn">
			<Card className="w-full">
				<CardContent className="flex flex-col items-center justify-center p-32">
					<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
						<div className="relative mx-8">
							<Avatar className="w-72 h-72" src={user && user.data ? user.data.photoURL : ''}>
								{user && user.data && user.data.displayName
									? user.data.displayName.substring(0, 1)
									: '?'}
							</Avatar>
							<Icon className="text-32 absolute right-0 bottom-0" color="error">
								lock
							</Icon>
						</div>

						<div className="mx-8">
							<Typography variant="h6" className="mb-8">
								{t('EMAIL_MUST_BE_CONFIRMED')}
							</Typography>
							<Typography color="textSecondary">{t('CONFIRM_MAIL_TO_SHOW_ORDERS')}</Typography>
							<Typography color="textSecondary">{t('EMAIL_SENT')}</Typography>
						</div>
					</div>

					<div className="mx-auto mt-24">
						<LoadingButton
							variant="contained"
							color="primary"
							className="w-320"
							aria-label={t('SEND_CONFIRMATION_EMAIL')}
							onClick={handleConfirmationMail}
							disabled={confirmationDisabled}
							loading={loading}
						>
							{t('RESEND_CONFIRMATION_EMAIL')}
						</LoadingButton>
					</div>

					<div className="flex flex-col items-center justify-center pt-32 pb-24">
						<Link className="font-medium" to="/logout">
							{t('ARE_YOU_NOT')}{' '}
							{user && user.data ? user.data.displayName : t('LOGGED_IN_WITH_CORRECT_USER')}?
						</Link>
					</div>
				</CardContent>
			</Card>
		</FuseAnimateAdapter>
	);
}

export default AccountVerificationNeeded;
