import FuseNavigation from '@ameroservices-platform/attraction-frontend/fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectNavigation } from '@ameroservices-platform/attraction-frontend/app/store/fuse/navigationSlice';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	horizontalNavigation: {
		'& .list-item': {
			'& .list-item-text': {
				padding: '0',
				'& .list-item-text-primary': { fontWeight: 'bold' }
			},
			'&.active': {
				backgroundColor: theme.palette.primary.main,
				color: `${theme.palette.primary.contrastText}!important`
			}
		}
	},
	verticalNavigation: {
		'& .list-item': {
			'& .list-item-text': {
				padding: '0',
				'& .list-item-text-primary': { fontWeight: 'bold' }
			},
			'&.active': {
				backgroundColor: theme.palette.primary.main,
				color: `${theme.palette.primary.contrastText}!important`
			}
		}
	}
}));

function Navigation(props) {
	const classes = useStyles(props);
	const navigation = useSelector(selectNavigation);

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={navigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
			classes={{
				horizontalNavigation: classes.horizontalNavigation,
				verticalNavigation: classes.verticalNavigation
			}}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical',
};

export default memo(Navigation);
