import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import daDK from './languages/da-DK';
import enGB from './languages/en-GB';
import deDE from './languages/de-DE';
import foFO from './languages/fo-FO';
import nbNO from './languages/nb-NO';
import svSE from './languages/sv-SE';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	da: { translation: daDK },
	en: { translation: enGB },
	de: { translation: deDE },
	fo: { translation: foFO },
	nb: { translation: nbNO },
	sv: { translation: svSE }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'da',
		fallbackLng: 'da',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
