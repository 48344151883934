import { Card, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import LoadingButton from '../../../../../ui-components/LoadingButton';
import sendControlCode from './ControlCodeFirestore';

const State = Object.freeze({
	IDLE: 'idle',
	ERROR: 'error',
	SUCCESS: 'success',
	LOADING: 'Loading'
});

function ControlCodePage({ queries }) {
	const [state, setState] = useState(State.IDLE);
	const [ticketNumber, setTicketNumber] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		if (queries.ticketNumber) {
			setTicketNumber(queries.ticketNumber);
		}
		if (queries.email) {
			setEmail(queries.email);
		}
	}, [queries]);

	const transitionFromIdelToLoading = () => {
		setState(State.LOADING);
	};

	const transitionFromLoadingToError = () => {
		setState(State.ERROR);
	};

	const transitionFromLoadingToSuccess = () => {
		setState(State.SUCCESS);
	};

	const handleSendCode = async () => {
		transitionFromIdelToLoading();
		await sendControlCode(ticketNumber, email, dataResponse => {
			if (dataResponse.success) {
				transitionFromLoadingToSuccess();
			} else {
				setMessage(dataResponse.data);
				transitionFromLoadingToError();
			}
		});
	};

	const resetComponent = () => {
		setState(State.IDLE);
		setMessage('');
	};

	return (
		<FuseAnimateAdapter animation="transition.expandIn">
			<Card className="max-w-md mx-auto my-48">
				<CardContent className="flex flex-col items-center justify-center p-32">
					{state === State.SUCCESS && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										check
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8 text-center">
										{t('CODE_IS_SENT_TO')}
										{email}
									</Typography>
								</div>
							</div>
						</>
					)}
					{state === State.ERROR && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										close
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8 text-center">
										{t('ERROR')}
									</Typography>
									<Typography>{message}</Typography>
								</div>
							</div>
							<div className="mx-auto mt-16">
								<Button
									variant="contained"
									color="primary"
									className="w-224"
									onClick={() => {
										resetComponent();
									}}
								>
									{t('TRY_AGAIN')}
								</Button>
							</div>
						</>
					)}
					{(state === State.IDLE || state === State.LOADING) && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										book
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('SEND_CONTROL_CODE')}
									</Typography>
								</div>
							</div>

							<div className="flex flex-col justify-center w-full mt-32">
								<TextField
									className="mb-16"
									label="Email"
									name="email"
									variant="outlined"
									required
									fullWidth
									value={email}
									onChange={e => setEmail(e.currentTarget.value)}
								/>
								<TextField
									label={t('TICKET_NUM')}
									name="ticketNumber"
									variant="outlined"
									required
									fullWidth
									value={ticketNumber}
									onChange={e => setTicketNumber(e.currentTarget.value)}
								/>
							</div>
							<div className="mx-auto mt-16">
								<LoadingButton
									variant="contained"
									color="primary"
									className="w-224"
									onClick={async () => {
										await handleSendCode();
									}}
									loading={state === State.LOADING}
								>
									{t('SEND_CODE')}
								</LoadingButton>
							</div>
						</>
					)}
				</CardContent>
			</Card>
		</FuseAnimateAdapter>
	);
}

export default ControlCodePage;
