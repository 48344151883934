import React from 'react';
import MuiButton from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';

function MultiLanguageButtonElement(props) {
	const { contentElementProps, contentElementParentProps } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles((_theme) => ({
		root: {
			color: `${getColor(contentElementParentProps.textColor, contentElementParentProps.customTextColor, mergedTheme)}`,
			backgroundColor: `${getColor(
				contentElementParentProps.backgroundColor,
				contentElementParentProps.customBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementParentProps.textColorHover,
					contentElementParentProps.customTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementParentProps.backgroundColorHover,
					contentElementParentProps.customBackgroundColorHover,
					mergedTheme
				)}`,
			},
		},
	}));

	const classes = useStyles();

	const navigate = useNavigate();
	const location = useLocation();

	const baseUrl = window.location.href.replace(location.pathname, '');
	const baseUrlWithoutHttpOrHttps = baseUrl.includes('http://')
		? baseUrl.replace('http://', '')
		: baseUrl.includes('https://')
		? baseUrl.replace('https://', '')
		: baseUrl;

	function handleOnClick() {
		const pathname = contentElementParentProps.href.startsWith('/')
			? contentElementParentProps.href
			: contentElementParentProps.href.startsWith(baseUrlWithoutHttpOrHttps)
			? contentElementParentProps.href.replace(baseUrlWithoutHttpOrHttps, '')
			: contentElementParentProps.href.replace(baseUrl, '');

		const internalRedirectDataKey = contentElementParentProps.internalRedirectDataKey || undefined;
		const internalRedirectData =
			(contentElementParentProps.internalRedirectData && JSON.parse(contentElementParentProps.internalRedirectData)) ||
			undefined;

		if (internalRedirectDataKey && internalRedirectData) {
			navigate(pathname, { state: { [internalRedirectDataKey]: internalRedirectData } });
		} else {
			navigate(pathname);
		}
	}

	function isUrlInternalRedirect() {
		return (
			contentElementParentProps.href.startsWith('/') ||
			contentElementParentProps.href.startsWith(baseUrl) ||
			contentElementParentProps.href.startsWith(baseUrlWithoutHttpOrHttps)
		);
	}

	return (
		<MuiButton
			role="button"
			variant={contentElementParentProps.variant}
			endIcon={contentElementParentProps.endIcon ? <Icon>{contentElementParentProps.endIcon}</Icon> : false}
			fullWidth={contentElementParentProps.fullWidth}
			href={isUrlInternalRedirect() ? undefined : contentElementParentProps.href}
			size={contentElementParentProps.size}
			startIcon={contentElementParentProps.startIcon ? <Icon>{contentElementParentProps.startIcon}</Icon> : false}
			className={`${contentElementParentProps.tailwindClasses} ${
				contentElementParentProps.fontSize ? `text-${contentElementParentProps.fontSize}` : ``
			} ${sharedTailwindClasses({
				...contentElementParentProps,
			})}`}
			classes={{ root: classes.root }}
			onClick={isUrlInternalRedirect() ? handleOnClick : undefined}
		>
			{contentElementProps.text}
		</MuiButton>
	);
}

export default MultiLanguageButtonElement;
