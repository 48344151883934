import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
	header: {
		height: 600,
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.primary.contrastText
	},
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	}
}));

function PricingListPage() {
	const classes = useStyles();

	return (
		<div>
			<div className={clsx(classes.header, 'flex')}>
				<div className="p-24 w-full max-w-2xl mx-auto">
					<div className="text-center my-128 mx-24">
						<FuseAnimateAdapter animation="transition.slideUpIn" duration={400} delay={100}>
							<Typography variant="h2" color="inherit" className="font-light">
								Billet Priser
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter duration={400} delay={600}>
							<Typography
								variant="subtitle1"
								color="inherit"
								className="opacity-75 mt-16 mx-auto max-w-512"
							>
								The most advanced customer support tools with a simple and affordable pricing. And you
								can always try for 30 days, free!
							</Typography>
						</FuseAnimateAdapter>
					</div>
				</div>
			</div>

			<div className="-mt-192">
				<div className="w-full max-w-2xl mx-auto">
					<div
						className="flex items-center justify-center flex-wrap"
					>
						<div className="w-full max-w-320 sm:w-1/3 p-12">
							<Card className="relative rounded-8">
								<div className="pt-48 pb-24 text-center">
									<Typography variant="subtitle1" color="inherit" className="text-20 font-medium">
										ENTRÉ
									</Typography>
								</div>

								<CardContent className="text-center p-0">
									<div className="flex flex-col">
										<div className="flex justify-center mb-8">
											<Typography className="text-56 mx-4 font-light leading-none">4</Typography>
											<Typography variant="h5" color="textSecondary" className="font-medium">
												,-
											</Typography>
										</div>
										<Typography color="textSecondary" className="font-medium text-16">
											PR. PERSON
										</Typography>
									</div>

									<div className="flex flex-col p-32">
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											<span>Forlystelser</span>
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											Skibe
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">100%</span>
											Sjov
										</Typography>
									</div>
								</CardContent>

								<div className="flex justify-center pb-32">
									<Button variant="contained" color="secondary" className="w-128">
										KØB
									</Button>
								</div>
							</Card>
						</div>

						<div className="w-full max-w-320 sm:w-1/3 p-12">
							<Card className="relative rounded-8" raised>
								<div className="absolute top-0 inset-x-0 flex justify-center">
									<div className={clsx(classes.badge, 'py-4 px-8')}>
										<Typography variant="caption" color="inherit">
											ANBEFALET
										</Typography>
									</div>
								</div>

								<div className="pt-48 pb-24 text-center">
									<Typography variant="subtitle1" color="inherit" className="text-20 font-medium">
										ÅRSKORT
									</Typography>
								</div>

								<CardContent className="text-center p-0">
									<div className="flex flex-col">
										<div className="flex justify-center mb-8">
											<Typography className="text-56 mx-4 font-light leading-none">
												299
											</Typography>
											<Typography variant="h5" color="textSecondary" className="font-medium">
												,-
											</Typography>
										</div>
										<Typography color="textSecondary" className="font-medium text-16">
											PR. PERSON
										</Typography>
									</div>

									<div className="flex flex-col p-32">
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											<span>Forlystelser</span>
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											Skibe
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">100%</span>
											Sjov
										</Typography>
									</div>
								</CardContent>

								<div className="flex justify-center pb-32">
									<Button variant="contained" color="secondary" className="w-128">
										KØB
									</Button>
								</div>
							</Card>
						</div>

						<div className="w-full max-w-320 sm:w-1/3 p-12">
							<Card className="relative rounded-8">
								<div className="pt-48 pb-24 text-center">
									<Typography variant="subtitle1" color="inherit" className="text-20 font-medium">
										FORNY ÅRSKORT
									</Typography>
								</div>

								<CardContent className="text-center p-0">
									<div className="flex flex-col">
										<div className="flex justify-center mb-8">
											<Typography className="text-56 mx-4 font-light leading-none">
												499
											</Typography>
											<Typography variant="h5" color="textSecondary" className="font-medium">
												,-
											</Typography>
										</div>
										<Typography color="textSecondary" className="font-medium text-16">
											PR. PERSON
										</Typography>
									</div>

									<div className="flex flex-col p-32">
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											<span>Forlystelser</span>
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">10</span>
											Skibe
										</Typography>
										<Typography variant="subtitle1" className="mb-8">
											<span className="font-bold mx-4">100%</span>
											Sjov
										</Typography>
									</div>
								</CardContent>

								<div className="flex justify-center pb-32">
									<Button variant="contained" color="secondary" className="w-128">
										KØB
									</Button>
								</div>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PricingListPage;
