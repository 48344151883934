import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import SubscriptionContent from './SubscriptionContent';
import { useLocation } from 'react-router-dom';

function SubscriptionCart(props) {
	console.log({ props });
	const location = useLocation();
	const [queries, setQueries] = useState({});

	useEffect(() => {
		const q = location.search.substring(1);
		const splitted = q.split('&');
		const tempQueries = {};
		splitted.forEach(split => {
			const keyValue = split.split('=');
			// eslint-disable-next-line prefer-destructuring
			tempQueries[keyValue[0]] = keyValue[1];
		});
		setQueries(tempQueries);
	}, [location.search]);
	return (
		<div className="flex-none mx-auto sm:px-48">
			<SubscriptionContent data={props.contentElementProps} queries={queries} />
		</div>
	);
}

export default SubscriptionCart;
