import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import TypesSettings from '../../../TypesConfig';

function Content(props) {
	const { contentElementProps } = props;
	// const { queries } = props;
	const location = useLocation();
	const [queries, setQueries] = useState({});

	useEffect(() => {
		if (!TypesSettings[contentElementProps.type]) {
			throw new Error(`Type '${contentElementProps.type}' does not exist in apps/content/TypesConfig.js`);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const q = location.search.substring(1);
		const splitted = q.split('&');
		const tempQueries = {};
		splitted.forEach(split => {
			const keyValue = split.split('=');
			// eslint-disable-next-line prefer-destructuring
			tempQueries[keyValue[0]] = keyValue[1];
		});
		setQueries(tempQueries);
	}, [location.search]);

	return (
		<>
			{TypesSettings[contentElementProps.type] &&
				TypesSettings[contentElementProps.type](
					contentElementProps.data
						? contentElementProps.data instanceof Object
							? contentElementProps.data
							: JSON.parse(contentElementProps.data)
						: {},
					queries,
					() => {}
				)}
		</>
	);
}

export default Content;
