const organisationType = Object.freeze({
	ATTRACTION: 'attraction',
	SHIP_FROM_STORE: 'shipFromStore',
	LOPPE: 'loppe',
	ECOMMERCE_INTEGRATION: 'ecommerceIntegration',
});

export const organisationTypeTranslate = Object.freeze({
	[organisationType.ATTRACTION]: 'Oplevelser',
	[organisationType.SHIP_FROM_STORE]: 'Ship From Store',
	[organisationType.LOPPE]: 'Loppemodul',
	[organisationType.ECOMMERCE_INTEGRATION]: 'Ecommerce Integration',
});

export default organisationType;
