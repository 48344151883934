import React from 'react';

function Youtube(props) {
	return (
		<svg
			id="Bold"
			enableBackground="new 0 0 24 24"
			height="50"
			viewBox="0 0 24 24"
			width="50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				style={{ fill: props.color }}
				d="m23.469 5.929.03.196c-.29-1.029-1.073-1.823-2.068-2.112l-.021-.005c-1.871-.508-9.4-.508-9.4-.508s-7.51-.01-9.4.508c-1.014.294-1.798 1.088-2.083 2.096l-.005.021c-.699 3.651-.704 8.038.031 11.947l-.031-.198c.29 1.029 1.073 1.823 2.068 2.112l.021.005c1.869.509 9.4.509 9.4.509s7.509 0 9.4-.509c1.015-.294 1.799-1.088 2.084-2.096l.005-.021c.318-1.698.5-3.652.5-5.648 0-.073 0-.147-.001-.221.001-.068.001-.149.001-.23 0-1.997-.182-3.951-.531-5.846zm-13.861 9.722v-7.293l6.266 3.652z"
			/>
		</svg>
	);
}
export default Youtube;
