import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Card, TextField, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../../../ui-components/LoadingButton';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});
function FlexVoucherValidator(props) {
	const [state, setState] = useState(null);
	const [loading, setLoading] = useState(false);
	const [flexVoucher, setFlexVoucher] = useState({ Balance: 500, Expires: '2023-09-28' });
	const [code, setCode] = useState('');
	const { t } = useTranslation();
	const { contentElementProps } = props;

	async function handleCheckCode() {
		setLoading(true);
		const response = await firebaseService.callFunctionByName(
			'organisationCustomerTicketsValidateGiftCardNumber',
			{ code },
			true
		);
		if (response && response.data) {
			setState(true);
			setFlexVoucher(response.data);
		} else {
			setState(false);
		}
		setLoading(false);
	}

	return (
		<div
			className={`min-w-320 ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			<Card className="max-w-md mx-auto my-48">
				<CardContent className="flex flex-col items-center justify-center p-32">
					{state === true && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										check
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('ON_THE_GIFT_CARD')} {code}
									</Typography>
									<Typography variant="h6" className="mb-8">
										{t('THE_BALANCE_IS')} {moneyFormatter.format(flexVoucher.Amount)}
									</Typography>
								</div>
							</div>
							<div className="mx-auto mt-16">
								<Button
									variant="contained"
									color="primary"
									className="w-224"
									onClick={() => {
										setState(null);
									}}
								>
									{t('TRY_NEW_CARD')}
								</Button>
							</div>
						</>
					)}
					{state === false && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										close
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('ERROR')}
									</Typography>
									<Typography>
										{t('THE_GIFT_CARD')} {code} {t('DOES_NOT_EXIST_OR_EXPIRED')}
									</Typography>
								</div>
							</div>
							<div className="mx-auto mt-16">
								<Button
									variant="contained"
									color="primary"
									className="w-224"
									onClick={() => {
										setState(null);
									}}
								>
									{t('TRY_AGAIN')}
								</Button>
							</div>
						</>
					)}
					{state !== true && state !== false && (
						<>
							<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										local_activity
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('CHECK_GIFT_CARD')}
									</Typography>
								</div>
							</div>

							<div className="flex flex-col justify-center w-full mt-32">
								<TextField
									className="mb-16"
									label="Gavekortskode"
									name="code"
									variant="outlined"
									required
									fullWidth
									value={code}
									onChange={e => setCode(e.currentTarget.value)}
								/>
							</div>
							<div className="mx-auto mt-16">
								<LoadingButton
									variant="contained"
									color="primary"
									className="w-224"
									onClick={async () => {
										await handleCheckCode();
									}}
									disabled={!code}
									loading={loading}
								>
									{t('CHECK_CODE')}
								</LoadingButton>
							</div>
						</>
					)}
				</CardContent>
			</Card>
		</div>
	);
}

export default FlexVoucherValidator;
