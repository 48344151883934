import React from 'react';
// eslint-disable-next-line import/no-cycle
import SubscriptionContent from './SubscriptionContent';

function Subscription(props) {
	return (
		<div className="flex-none mx-auto sm:px-48">
			<SubscriptionContent data={props.data} queries={props.queries} />
		</div>
	);
}

export default Subscription;
