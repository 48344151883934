import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import React from 'react';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLocaleFromData } from '../../../../../../../utilities';

function getEventTime(s, e) {
	const start = moment(s);
	const end = moment(e);
	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
	}
	return `${start.format('dddd DD-MM-YYYY, HH:mm')} - ${end.format('dddd DD-MM-YYYY, HH:mm')}`;
}

const ProductNameCell = ({ orderLine, multiLanguageFunctions, events, subscriptionPeriods }) => {
	const { t } = useTranslation();
	const { optionToHideEventPeriodInCart } = useFlags();

	return (
		<div className="min-h-72 sm:min-h-0 h-full">
			<Typography className="font-medium" variant="subtitle1" color="textSecondary">
				{multiLanguageFunctions ? getLocaleFromData(orderLine, 'name') : orderLine.name}
			</Typography>
			{subscriptionPeriods[orderLine.subscriptionTypeUid] && (
				<Typography className="font-medium" variant="subtitle2" color="textSecondary">
					{moment(subscriptionPeriods[orderLine.subscriptionTypeUid].start).format('dddd DD-MM-YYYY')}
					{' - '}
					{moment(subscriptionPeriods[orderLine.subscriptionTypeUid].end).format('dddd DD-MM-YYYY')}
				</Typography>
			)}
			{orderLine.eventUid && orderLine.productType !== 'giftTicket' && (
				<>
					<Typography className="font-medium" variant="subtitle2" color="textSecondary">
						{!multiLanguageFunctions && (
							<>
								{events[orderLine.eventUid]
									? events[orderLine.eventUid].displayName ||
									  events[orderLine.eventUid].name ||
									  t('UNKOWN_EVENT')
									: t('LOADING_EVENT')}
							</>
						)}
						{multiLanguageFunctions && (
							<>
								{events[orderLine.eventUid]
									? getLocaleFromData(events[orderLine.eventUid], 'displayName') ||
									  getLocaleFromData(events[orderLine.eventUid], 'name') ||
									  t('UNKOWN_EVENT')
									: t('LOADING_EVENT')}
							</>
						)}
					</Typography>
					{events[orderLine.eventUid] &&
						(!optionToHideEventPeriodInCart ||
							orderLine.showEventPeriodInCart === null ||
							orderLine.showEventPeriodInCart === undefined ||
							orderLine.showEventPeriodInCart === true) && (
							<Typography className="font-medium" variant="subtitle2" color="textSecondary">
								{getEventTime(events[orderLine.eventUid].start, events[orderLine.eventUid].end)}
							</Typography>
						)}
				</>
			)}
		</div>
	);
};
export default ProductNameCell;
