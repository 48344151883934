import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { eventGroupLanguageListener, eventGroupListener } from '../../../ContentFirestore';
import { getParameterByName } from '../../../../../../utilities';
import allLanguages from '../shared/allLanguages';
import StorageImage from '../../../../../../ui-components/StorageImage';
import HeadingComponent from '../../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../../ui-components/content-components/MarkdownComponent';

function EventGroupContent(props) {
	const { showOldContentOnCmsEventGroupContent } = useFlags();
	const location = useLocation();

	const { i18n } = useTranslation();
	const flags = useFlags();

	const [eventGroup, setEventGroup] = useState(null);
	const [eventGroupId, setEventGroupId] = useState(null);
	const [eventGroupLanguage, setEventGroupLanguage] = useState(null);
	const [eventGroupContent, setEventGroupContent] = useState(null);
	const [currentLanguage, setCurrentLanguage] = useState(null);

	useEffect(() => {
		const _currentLanguage = Object.values(allLanguages).find(language => {
			return language.isoCode === i18n.language;
		});
		setCurrentLanguage(_currentLanguage);
	}, [i18n.language]);

	useEffect(() => {
		const _eventGroupId = getParameterByName('e', location.search);
		if (_eventGroupId) {
			setEventGroupId(_eventGroupId);
			const eventGroupsListenerUnsubFunc = eventGroupListener(_eventGroupId, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [location.pathname, location.search]);

	useEffect(() => {
		if (eventGroup && eventGroup.id && currentLanguage) {
			const eventGroupLanguageListenerUnsubFunc = eventGroupLanguageListener(
				eventGroup.id,
				currentLanguage.isoCode,
				_eventGroupLanguage => setEventGroupLanguage(_eventGroupLanguage)
			);
			return () => {
				eventGroupLanguageListenerUnsubFunc();
			};
		}

		return () => {};
	}, [eventGroup, currentLanguage]);

	useEffect(() => {
		if (eventGroupLanguage && eventGroupLanguage.content) {
			setEventGroupContent(eventGroupLanguage.content);
		} else if (eventGroup && eventGroup.content) {
			setEventGroupContent(eventGroup.content);
		} else {
			setEventGroupContent(null);
		}
	}, [eventGroupLanguage, eventGroup]);

	return (
		<>
			{showOldContentOnCmsEventGroupContent &&
				eventGroupId &&
				eventGroup &&
				eventGroup.content &&
				!eventGroup.content.ROOT && (
					<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16 mb-24">
						<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
							{eventGroup && eventGroup.content ? eventGroup.content.title : ''}
						</Typography>
						<div className="flex flex-col md:flex-row">
							<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
								<StorageImage
									src={eventGroup && eventGroup.content ? eventGroup.content.image : ''}
									alt=""
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
								<div className="text-left text-16 pb-20">
									{eventGroup &&
										eventGroup.content &&
										eventGroup.content.content &&
										eventGroup.content.content.map((c, index) => {
											if (c.type === 'heading') {
												return <HeadingComponent key={index}>{c.content}</HeadingComponent>;
											}
											if (c.type === 'text') {
												return <TextComponent key={index}>{c.content}</TextComponent>;
											}
											if (c.type === 'number_list') {
												return (
													<ListComponent
														key={index}
														type="number"
														list={c.content.array}
														heading={c.content.heading}
													/>
												);
											}
											if (c.type === 'markdown') {
												return <MarkdownComponent key={index} data={c.content} />;
											}
											return <span key={index} />;
										})}
								</div>
							</Grid>
						</div>
					</Paper>
				)}
			{flags.multiLanguageCmsFrontend ? (
				<>
					{eventGroupContent &&
						eventGroupContent.ROOT &&
						eventGroupContent.ROOT.nodes &&
						eventGroupContent.ROOT.nodes.length > 0 &&
						eventGroupContent.ROOT.nodes.map(node => {
							return props.getCmsContentElement(node, eventGroupContent);
						})}
				</>
			) : (
				<>
					{eventGroupId &&
						eventGroup &&
						eventGroup.content &&
						eventGroup.content.ROOT &&
						eventGroup.content.ROOT.nodes &&
						eventGroup.content.ROOT.nodes.length > 0 &&
						eventGroup.content.ROOT.nodes.map(node => {
							return props.getCmsContentElement(node, eventGroup.content);
						})}
				</>
			)}
		</>
	);
}

export default EventGroupContent;
