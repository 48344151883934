import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/performance';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import { Observable } from 'rxjs';
import moment from 'moment-timezone';

const rerouteFunctions = {
	organisationEventGroupEventScheduler: 'organisationEventGroupEventSchedulerEventSchedulerHandler',
	organisationEventGroup: 'organisationEventGroupEventGroupHandler',
	organisationLocation: 'organisationLocationLocationHandler',
	organisationCustomerOrder: 'organisationCustomerOrderOrderHandler',
	organisationCustomerCart: 'organisationCustomerCartCartHandler',
	organisationCustomerSubscriptions: 'organisationCustomerSubscriptionsSubscriptionsHandler',
	organisationOrganisationUser: 'organisationOrganisationUserOrganisationUserHandler',
	user: 'userUserHandler',
	content: 'contentContentHandler',
	organisationFlexposLink: 'organisationFlexposLinkFlexposLinkHandler',
	organisationCustomerTickets: 'organisationCustomerTicketsTicketsHandler',
	organisationProduct: 'organisationProductProductHandler',
	bigQuery: 'bigQueryBigQueryHandler',
	organisationCreateOrganisation: 'organisationOrganisationHandler',
	organisationSubscriptionTypeCreateSubscriptionType: 'organisationOrganisationHandler',
	organisationSubscriptionTypeGetSubscriptionTypeCurrentEvent: 'organisationOrganisationHandler',
	organisationMailTemplatesUpdateMailTemplate: 'organisationOrganisationHandler',
	organisationIncrementalNumbersUpdateIncrementalNumber: 'organisationOrganisationHandler',
	organisationCustomerCreateCustomer: 'organisationOrganisationHandler',
	organisationCustomerCreateSubCustomer: 'organisationOrganisationHandler',
	organisationCustomerSendConfirmationMail: 'organisationOrganisationHandler',
	organisationCustomerConfirmEmail: 'organisationOrganisationHandler',
	organisationCustomerVerifyCustomer: 'organisationOrganisationHandler',
	organisationCustomerSendSubscriptionConfirmationMail: 'organisationOrganisationHandler',
	organisationCustomerUpdateCustomer: 'organisationOrganisationHandler',
	organisationCustomerActivateUser: 'organisationOrganisationHandler',
	organisationCustomerUpdateCustomerByToken: 'organisationOrganisationHandler',
	organisationCustomerGetUserByToken: 'organisationOrganisationHandler',
	organisationCustomerCheckCustomerEmail: 'organisationOrganisationHandler',
	organisationCustomerSendResetCustomerPassword: 'organisationOrganisationHandler',
	organisationCustomerResetCustomerPassword: 'organisationOrganisationHandler',
	organisationCustomerCreateCustomerByAnonymous: 'organisationOrganisationHandler'
};

class FirebaseService {
	init(success, firebaseOverwrite = null) {
		this.forceOnAuthStateChangedObservers = [];
		this.forceOnAuthStateChangedObservable = new Observable(observer => {
			this.forceOnAuthStateChangedObservers.push(observer);
		});

		const isLocalEmulator = window.location.hostname === 'localhost';
		if (firebase.apps.length) {
			return;
		}
		fetch('/__/firebase/init.json').then(async response => {
			const config = await response.json();
			// console.log('config', config);
			if (isLocalEmulator) config.appId = '1:747189088654:web:d7048b789433705061b4df';

			if (firebaseOverwrite) {
				this.db = firebaseOverwrite.firestore || null;
				this.auth = firebaseOverwrite.auth || null;
				this.functions = firebaseOverwrite.functions || null;
				this.storage = firebaseOverwrite.storage || null;
			} else {
				firebase.initializeApp(config);
				this.db = firebase.firestore();
				this.auth = firebase.auth();
				this.functions = firebase.app().functions('europe-west3');
				this.performance = firebase.performance();
				this.storage = firebase.storage();
				this.analytics = firebase.analytics();
				this.analytics.setAnalyticsCollectionEnabled(false);

				if (isLocalEmulator) {
					this.db.settings({
						host: '127.0.0.1:8080',
						ssl: false
					});
					this.functions.useFunctionsEmulator('http://localhost:5001');
				}
			}

			success(true);
		});
	}

	setOrganisationId = organisationId => {
		this.currentOrganisationId = organisationId || null;
	};

	setTenantId = tenantId => {
		// console.log(tenantId);
		this.auth.tenantId = tenantId;
	};

	getAuth = () => {
		return this.auth;
	};

	getUserData = async userId => {
		if (!firebase.apps.length) {
			return false;
		}
		// return new Promise((resolve, reject) => {
		// const docRef = this.db.collection('users').doc(userId);
		const docRef = this.getOrganisationRootDB().collection('customers').doc(userId);

		try {
			const userDoc = await docRef.get();
			const userDocData = userDoc.exists ? userDoc.data() : {};

			const idTokenResult = await this.auth.currentUser.getIdTokenResult();
			console.log({ idTokenResult });

			let role = 'Guest';
			if (
				idTokenResult.claims.role &&
				(idTokenResult.signInProvider !== 'anonymous' || idTokenResult.claims.role === 'flexposUser')
			) {
				role = idTokenResult.claims.role;
			}

			return {
				uid: userId,
				...userDocData,
				claims: idTokenResult.claims || {},
				role: [role],
				isAnonymous: idTokenResult.signInProvider === 'anonymous'
			};
		} catch (e) {
			console.log('getUserData error:', e);
			if (window.location.hostname === 'localhost') {
				// eslint-disable-next-line no-alert
				alert('Kan ikke hente bruger data - Firebase emulatoren er formentlig slukket.');
			}
			return {};
		}

		// });
	};

	updateUserData = user => {
		if (!firebase.apps.length) {
			return false;
		}
		// return this.db.collection('users').doc(user.uid).set(user);
		return this.getOrganisationRootDB().collection('customers').doc(user.uid).set(
			{
				uid: user.uid,
				from: user.from,
				data: user.data,
				deleted: false,
				isAnonymous: user.isAnonymous,
				isAssociated: false
			},
			{ merge: true }
		);
	};

	onAuthStateChanged = callback => {
		if (!this.auth) {
			return;
		}

		// console.log('onAuthStateChanged before subscribed ');
		this.forceOnAuthStateChangedObservable.subscribe({
			next(authObj) {
				// console.log('onAuthStateChanged Next');
				callback(authObj);
			}
		});

		this.auth.onAuthStateChanged(callback);
	};

	signOut = () => {
		if (!this.auth) {
			return;
		}
		this.auth.signOut().then(() => {
			setTimeout(() => {
				window.location.reload();
			}, 250);
		});
	};

	// signInWithCustomToken = token => {
	// 	firebase
	// 		.auth()
	// 		.signInWithCustomToken(token)
	// 		.catch(function (error) {
	// 			// const errorCode = error.code;
	// 			// const errorMessage = error.message;
	// 			console.log('customToken login failed');
	// 		});
	// };

	sendEmailVerification = async () => {
		await this.auth.currentUser.sendEmailVerification();
		return true;
	};

	requestAuthPromoteToAmeroAdmin = async () => {
		await this.functions.httpsCallable('authPromoteToAmeroAdmin')();
		return true;
	};

	refreshToken = async () => {
		if (!this.auth || !this.auth.currentUser) {
			return false;
		}
		await this.auth.currentUser.getIdToken(true);
		// console.log('onAuthStateChanged, refreshToken', this.forceOnAuthStateChangedObservers.length);
		this.forceOnAuthStateChangedObservers.forEach(forceOnAuthStateChangedObserver => {
			forceOnAuthStateChangedObserver.next(this.auth.currentUser);
		});

		return true;
	};

	getUsers = async callback => {
		await this.getOrganisationRootDB()
			.collection('users')
			.onSnapshot(querySnapshot => {
				const u = [];
				querySnapshot.forEach(doc => {
					const docData = doc.data();
					u.push({
						...docData,
						id: doc.id
					});
				});
				callback(u);
			});
	};

	getRootDB = () => {
		return this.db;
	};

	getOrganisationId = () => {
		return this.currentOrganisationId;
	};

	setSignedSearchKey = signedSearchKey => {
		this.signedSearchKey = signedSearchKey || null;
	};

	getSignedSearchKey = () => {
		return this.signedSearchKey;
	};

	getOrganisationRootDB = () => {
		return this.db.collection('organisations').doc(this.getOrganisationId());
	};

	getContentRootDB = () => {
		return this.db.collection('content').doc(this.getOrganisationId());
	};

	getAuditLogRootDB = () => {
		const oganisationId = this.getOrganisationId();
		return this.db.collection('auditLogs').doc(oganisationId);
	};

	getFunctions = () => {
		return this.functions;
	};

	callFunctionByName = async (functionName, data, throwError) => {
		return this._callFunctionByNameHelper(functionName, data, throwError, 0, 500, moment());
	};

	_waitFor = ms => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	};

	_callFunctionByNameHelper = async (functionName, data, throwError, tries, tick, started) => {
		try {
			const reroute = Object.keys(rerouteFunctions).find(r => functionName.startsWith(r));
			if (reroute && rerouteFunctions[reroute]) {
				const callable = this.functions.httpsCallable(rerouteFunctions[reroute]);
				return await callable({ functionToUse: functionName, data: { ...data } });
			}
			const callable = this.functions.httpsCallable(functionName);
			return await callable(data);
		} catch (e) {
			console.error({ functionName, code: e.code, message: e.message, details: e.details });
			if (['unknown', 'resource-exhausted', 'internal', 'unavailable', 'data-loss'].includes(e.code)) {
				if (moment().diff(started, 'seconds') <= 60 && tries <= 7) {
					console.log(`Error; Try #${tries}, waiting ${tick}ms before trying again`);
					await this._waitFor(tick);
					return this._callFunctionByNameHelper(functionName, data, throwError, tries + 1, tick * 2, started);
				}
			}
			if (throwError) {
				throw e;
			}
			// eslint-disable-next-line no-alert
			// alert('Der skete en ukendt fejl, prøv igen eller kontakt Amero Support på 77 34 34 80');
			return false;
		}
	};

	getStorage = () => {
		return this.storage;
	};

	getProjectId = () => {
		return this.storage && this.storage.app && this.storage.app.options_ && this.storage.app.options_.projectId
			? this.storage.app.options_.projectId
			: 'attraction-prod';
	};
}

const instance = new FirebaseService();

export default instance;
