import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert, Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AccountTabInformation from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountTabInformation';
import AccountTabSubscriptions from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountTabSubscriptions';
import Hidden from '@mui/material/Hidden';
import AlertTitle from '@mui/material/AlertTitle';
import { sendConfirmationEmail } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles(theme => ({
	header: {
		background: 'none',
		color: theme.palette.text.primary
	},
	listItem: {
		color: 'inherit!important',
		textDecoration: 'none!important',
		// height: 40,
		// width: 'calc(100% - 16px)',
		// borderRadius: '0 20px 20px 0',
		// paddingLeft: 24,
		// paddingRight: 12,
		'&.active': {
			backgroundColor: theme.palette.secondary.main,
			color: `${theme.palette.secondary.contrastText}!important`,
			pointerEvents: 'none',
			'& .list-item-icon': {
				color: 'inherit'
			}
		},
		'& .list-item-icon': {
			marginRight: 16
		}
	}
}));
function Account({ contentElementProps }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const selectedTab = searchParams.get('tab')?.toUpperCase() || 'SUBSCRIPTIONS';
	const navigate = useNavigate();
	const user = useSelector(({ auth }) => auth.user);
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [confirmationDisabled, setConfirmationDisabled] = useState(false);
	const [confirmationLoading, setConfirmationLoading] = useState(false);

	useEffect(() => {
		if (user?.isAnonymous) {
			navigate('/login');
		}
	}, [user, navigate]);

	useEffect(() => {
		if (user?.email_verified === true && user?.claims && !user?.claims?.email_verified) {
			firebaseService.refreshToken();
		}
	}, [user]);

	function handleTabChange(event, value) {
		navigate(`/account?tab=${value.toLowerCase()}`);
	}

	async function handleSendConfirmationMail() {
		setConfirmationDisabled(true);
		setConfirmationLoading(true);
		await dispatch(sendConfirmationEmail());
		setConfirmationLoading(false);
		setTimeout(() => {
			setConfirmationDisabled(false);
		}, 60000);
	}

	return (
		<Grid container spacing={3} className={'pt-48'}>
			<Grid item xs={12} md={3}>
				<Paper className={'py-24 px-6'}>
					<Typography className="mb-20 pl-32 hidden md:block" variant="h5">
						{t('ACCOUNT_YOUR_ACCOUNT')}
					</Typography>
					<Hidden smUp>
						<Tabs
							orientation="vertical"
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="inherit"
							variant="scrollable"
							scrollButtons={false}
							className="w-full px-24 -mx-4"
							classes={{
								indicator: 'flex justify-center bg-transparent w-full h-full'
							}}
							TabIndicatorProps={{
								children: (
									<Box
										sx={{ bgcolor: 'text.disabled' }}
										className="w-full h-full rounded-full opacity-20 "
									/>
								)
							}}
						>
							{['SUBSCRIPTIONS', 'INFORMATION'].map(value => (
								<Tab
									className="text-14 font-semibold min-w-64 mx-4 px-12 items-start"
									disableRipple
									label={t('ACCOUNT_TAB_' + value)}
									value={value}
								/>
							))}
						</Tabs>
					</Hidden>
					<Hidden smDown mdUp>
						<Tabs
							orientation="horizontal"
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="inherit"
							variant="scrollable"
							scrollButtons={false}
							className="w-full px-24 -mx-4"
							classes={{
								indicator: 'flex justify-center bg-transparent w-full h-full'
							}}
							TabIndicatorProps={{
								children: (
									<Box
										sx={{ bgcolor: 'text.disabled' }}
										className="w-full h-full rounded-full opacity-20 "
									/>
								)
							}}
						>
							{['SUBSCRIPTIONS', 'INFORMATION'].map(value => (
								<Tab
									className="text-14 font-semibold min-w-64 mx-4 px-12 items-start"
									disableRipple
									label={t('ACCOUNT_TAB_' + value)}
									value={value}
								/>
							))}
						</Tabs>
					</Hidden>
					<Hidden mdDown>
						<Tabs
							orientation="vertical"
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="inherit"
							variant="scrollable"
							scrollButtons={false}
							className="w-full px-24 -mx-4"
							classes={{
								indicator: 'flex justify-center bg-transparent w-full h-full'
							}}
							TabIndicatorProps={{
								children: (
									<Box
										sx={{ bgcolor: 'text.disabled' }}
										className="w-full h-full rounded-full opacity-20 "
									/>
								)
							}}
						>
							{['SUBSCRIPTIONS', 'INFORMATION'].map(value => (
								<Tab
									className="text-14 font-semibold min-w-64 mx-4 px-12 items-start"
									disableRipple
									label={t('ACCOUNT_TAB_' + value)}
									value={value}
								/>
							))}
						</Tabs>
					</Hidden>
				</Paper>
			</Grid>
			<Grid item xs={12} md={9}>
				{user?.claims && !user.claims?.email_verified && (
					<Alert
						severity={'warning'}
						className={'mb-20'}
						action={
							<LoadingButton
								variant={'contained'}
								onClick={handleSendConfirmationMail}
								loading={confirmationLoading}
								disabled={confirmationDisabled}
							>
								{t('RESEND_CONFIRMATION_EMAIL')}
							</LoadingButton>
						}
					>
						<AlertTitle>{t('ACCOUNT_NEEDED_EMAIL_CONFIRMATION')}</AlertTitle>
						{t('ACCOUNT_EMAIL_NOT_CONFIRMED_YET')}
					</Alert>
				)}
				{selectedTab === 'INFORMATION' && <AccountTabInformation />}
				{selectedTab === 'SUBSCRIPTIONS' && (
					<AccountTabSubscriptions contentElementProps={contentElementProps} />
				)}
			</Grid>
		</Grid>
	);
}

export default Account;
