import React from 'react';
import Button from '@mui/material/Button';
import ReactMailchimpSubscribe from 'react-mailchimp-subscribe';
import { useTranslation } from 'react-i18next';

function MailchimpSubscribe(props) {
	const { contentElementProps } = props;

	return (
		<ReactMailchimpSubscribe
			url={contentElementProps.url}
			render={({ subscribe, status, message }) => (
				<SignUpForm status={status} message={message} onValidated={formData => subscribe(formData)} />
			)}
		/>
	);
}

export default MailchimpSubscribe;

function SignUpForm({ status, message, onValidated }) {
	const emailRef = React.useRef(null);
	const fnameRef = React.useRef(null);
	const lnameRef = React.useRef(null);
	const { t } = useTranslation();
	const submit = () =>
		emailRef &&
		fnameRef &&
		lnameRef &&
		emailRef.current.value.indexOf('@') > -1 &&
		onValidated({
			EMAIL: emailRef.current.value,
			FNAME: fnameRef.current.value,
			LNAME: lnameRef.current.value
		});

	return (
		<div>
			<div className="mb-10">
				<input
					type="email"
					placeholder={t('MAIL')}
					ref={emailRef}
					className="w-full p-10 rounded-4 text-black border border-black"
					required
				/>
			</div>
			<div className="mb-10">
				<input
					type="text"
					placeholder={t('FIRST_NAME')}
					ref={fnameRef}
					className="w-full p-10 rounded-4 text-black border border-black"
					required
				/>
			</div>
			<div className="mb-10">
				<input
					type="text"
					placeholder={t('LAST_NAME')}
					ref={lnameRef}
					className="w-full p-10 rounded-4 text-black border border-black"
					required
				/>
			</div>
			{status === 'sending' && <div>{t('SIGN_UP_STATUS')}</div>}
			{status === 'error' && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />}
			{status === 'success' && <div style={{ color: 'green' }} dangerouslySetInnerHTML={{ __html: message }} />}

			<Button
				type="submit"
				variant="contained"
				className="bg-white hover:bg-white rounded-none mt-12 w-full text-black"
				onClick={submit}
			>
				{t('SIGN_UP_BUTTON')}
			</Button>
			<div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
				<input type="text" name="b_6e921d5931517d4e724914e6d_1cab1e0863" tabIndex="-1" value="" />
			</div>
		</div>
	);
}
