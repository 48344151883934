import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faqsGroupListener, faqsQuestionsListener } from '../FaqFirestore';
import MarkdownComponent from '../../../../../../ui-components/content-components/MarkdownComponent';

function SmallFaqPage(props) {
	const [data, setData] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		if (props.data.groups) {
			const faqsListenerUnsubFunc = faqsGroupListener(props.data.groups, o => setData(o));
			return () => faqsListenerUnsubFunc();
		}
		const faqsListenerUnsubFunc = faqsQuestionsListener(props.data.questions, o => setData(o));
		return () => faqsListenerUnsubFunc();
	}, [props.data.groups, props.data.questions]);

	return (
		<div>
			<div className="flex flex-col items-center py-96 text-center sm:ltr:text-left sm:rtl:text-right max-w-xl mx-auto">
				<Typography variant="h4" className="pb-32 font-light">
					{t('FAQ_HEADER')}
				</Typography>

				<div className="flex flex-wrap w-full">
					{data.map(faq => (
						<div className="w-full sm:w-1/2 p-24" key={faq.id}>
							<Typography className="text-20 mb-8">{faq.question}</Typography>
							<MarkdownComponent data={faq.answer} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default SmallFaqPage;
