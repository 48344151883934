import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import ImageLoading from './ImageLoading';

function AvatarWebcam({ src, onAvatarChange, disabled }) {
	const [showDialog, setShowDialog] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);
	const [fileUploadList, setFileUploadList] = useState([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	function handleSave() {
		onAvatarChange(imageUrl);
		setShowDialog(false);
		setImageUrl(null);
	}

	function handleChange() {
		setImageUrl(null);
	}

	function uploadFile(blobOrFile, url) {
		return new Promise(resolve => {
			const xhr = new XMLHttpRequest();
			xhr.open('PUT', url, true);
			xhr.responseType = 'blob';
			xhr.onload = () => {
				resolve(true);
			};
			// xhr.upload.onprogress = e => {
			// 	if (e.lengthComputable) {
			// 		console.log((e.loaded / e.total) * 100);
			// 	}
			// };
			xhr.send(blobOrFile);
			xhr.onerror = e => {
				console.error(e);
				resolve(false);
			};
		});
	}

	function handleClose() {
		setShowDialog(false);
		setImageUrl(null);
	}

	function onDrop(acceptedFiles) {
		setLoading(true);
		let newFileUploadList = acceptedFiles
			.map(element => {
				return { fileName: element.name, status: 0 };
			})
			.concat(fileUploadList);
		setFileUploadList(newFileUploadList);

		acceptedFiles.forEach((file, index) => {
			(async () => {
				const result = await firebaseService
					.callFunctionByName(
						'contentFileUpload',
						{
							frontend: true
						},
						true
					)
					.then(data => {
						const { url, pathName } = data.data;
						uploadFile(file, url)
							.then(() => {
								setLoading(false);
								setImageUrl(
									`https://storage.googleapis.com/${firebaseService.getProjectId()}.appspot.com/${pathName}`
								);
							})
							.catch(e => {
								console.error(e);
							});
					})
					.catch(e => {
						console.error(e);
					});
				newFileUploadList = [...newFileUploadList];
				newFileUploadList[index].status = result ? 1 : -1;
				setFileUploadList(newFileUploadList);
			})();
		});
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: 'image/*',
		maxFiles: 1,
		multiple: false
	});

	return (
		<>
			<ImageLoading src={src} />
			<Button className="rounded-none px-40" onClick={() => setShowDialog(true)} disabled={disabled}>
				{t('CHANGE_IMAGE')}
			</Button>
			<Dialog maxWidth="sm" fullWidth open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogContent className="flex justify-around">
					{imageUrl ? (
						<ImageLoading src={imageUrl} fullWidth />
					) : (
						<div
							className="m-32 w-full text-center flex justify-center items-center border-dashed border rounded border-8 border-black h-320 cursor-pointer"
							{...getRootProps()}
						>
							<input {...getInputProps()} />
							{loading ? (
								<div>
									<CircularProgress size={64} className="mb-20" />
									<Typography variant="subtitle1" color="textPrimary">
										{t('UPLOADING_PROFILE_PICTURE')}
									</Typography>
								</div>
							) : (
								<div>
									<Icon className="text-96" color="action">
										publish
									</Icon>
									{isDragActive ? (
										<Typography variant="subtitle1" color="textPrimary">
											{t('RELEASE_FILES_HERE')}
										</Typography>
									) : (
										<Typography variant="subtitle1" color="textPrimary">
											{t('DRAG_OR_CLICK_HERE')}
										</Typography>
									)}
								</div>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
						{t('CANCEL')}
					</Button>
					<Button disabled={!imageUrl || loading} onClick={handleChange} color="primary" variant="outlined">
						{t('CHANGE_IMAGE')}
					</Button>
					<Button disabled={!imageUrl || loading} onClick={handleSave} color="primary" variant="contained">
						{t('SELECT')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default AvatarWebcam;
