import React from 'react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { sharedTailwindClasses } from '../../shared/sharedTailwindClasses';

function AccordionDetails(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	return (
		<MuiAccordionDetails
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiAccordionDetails>
	);
}

export default AccordionDetails;
