import ticketTypeType from '@ameroservices-platform/shared/enums/ticketTypeType';

const productType = Object.freeze({
	GIFT_TICKET: 'giftTicket',
	TICKET: 'ticket',
	FEE: 'fee',
	GIFT_SUBSCRIPTION: 'giftSubscription',
	UNKNOWN: 'unknown',
	SUBSCRIPTION_TICKET: 'subscriptionTicket',
	SUBSCRIPTION: 'subscription',
	EVENT_TICKET: 'eventTicket',
	ENTRANCE_TICKET: 'entranceTicket',
	GIFT_CARD: 'giftCard',
	VOUCHER: 'voucher'
});

export const productTypeTranslate = Object.freeze({
	giftTicket: 'Gavebillet',
	ticket: 'Billet',
	fee: 'Gebyr',
	[productType.GIFT_SUBSCRIPTION]: 'Gavemedlemskab',
	unknown: 'Ukendt',
	[productType.SUBSCRIPTION_TICKET]: 'Medlemskort',
	[productType.SUBSCRIPTION]: 'Medlemskab',
	[productType.EVENT_TICKET]: 'Begivenhedsbillet',
	[productType.ENTRANCE_TICKET]: 'Entrébillet',
	[productType.GIFT_CARD]: 'Gavekort',
	[productType.VOUCHER]: 'Kupon'
});

export default productType;

export const productTypeFilter = ([key, text], options) => {
	const { ticketTypeType: _ticketTypeType, flags } = options || {};
	if (_ticketTypeType === ticketTypeType.TICKETS) {
		return [productType.TICKET, productType.GIFT_TICKET].includes(key);
	}
	if (flags && flags.productGroupTypes && _ticketTypeType === ticketTypeType.ENTRANCE_TICKETS) {
		return key === productType.ENTRANCE_TICKET;
	}
	if (flags && flags.productGroupTypes && _ticketTypeType === ticketTypeType.EVENT_TICKETS) {
		return key === productType.EVENT_TICKET;
	}
	if (_ticketTypeType === ticketTypeType.SUBSCRIPTION_GIFTS) {
		return key === productType.GIFT_SUBSCRIPTION;
	}
	if (_ticketTypeType === ticketTypeType.FEES) {
		return key === productType.FEE;
	}
	if (_ticketTypeType === ticketTypeType.GIFT_CARDS) {
		return key === productType.GIFT_CARD;
	}
	if (_ticketTypeType === ticketTypeType.SUBSCRIPTION_TICKETS) {
		if (flags && flags.productGroupTypes) {
			return [productType.SUBSCRIPTION_TICKET, productType.SUBSCRIPTION].includes(key);
		}
		return key === productType.TICKET;
	}
	if (_ticketTypeType === ticketTypeType.VOUCHERS) {
		return key === productType.VOUCHER;
	}
	return true;
};
