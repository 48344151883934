import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '@ameroservices-platform/attraction-frontend/app/auth/store/userSlice';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { makeStyles } from '@mui/styles';

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const [userMenu, setUserMenu] = useState(null);
	const [displayName, setDisplayName] = useState('');

	const useStyles = makeStyles(t => ({
		badge: {
			backgroundColor: mergedTheme.palette.primary.main
		}
	}));
	const classes = useStyles();
	const navigate = useNavigate();

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	useEffect(() => {
		if (user.data && user.data.displayName) return setDisplayName(user.data.displayName);
		if (user.data && user.data.email) return setDisplayName(user.data.email);

		return setDisplayName('Gæst');
	}, [user]);

	return (
		<>
			<Button className="min-h-40" onClick={userMenuClick} color="inherit">
				{user.data.photoURL ? (
					<Avatar className="mx-4" alt="user photo" src={user.data.photoURL} />
				) : (
					<Avatar className="mx-4" classes={{ colorDefault: classes.badge }}>
						<Icon>person</Icon>
					</Avatar>
				)}
				<div className="hidden md:flex flex-col mx-4 items-end ml-10">
					<Typography component="span" className="normal-case font-bold flex" color="primary">
						{user.isAnonymous || !user.role || user.role.length === 0 ? 'Log ind' : displayName}
					</Typography>
				</div>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
			>
				{user.isAnonymous || !user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Log Ind" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Opret Bruger" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/account" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="Min Side" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Log Ud" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
