import React from 'react';
import Root from './cmsComponents/Root';
import GridContainer from './cmsComponents/basic/GridContainer';
import GridItem from './cmsComponents/basic/GridItem';
import Text from './cmsComponents/basic/Text';
// eslint-disable-next-line import/no-cycle
import Content from './cmsComponents/custom/Content';
import Image from './cmsComponents/basic/Image';
import Paper from './cmsComponents/basic/Paper';
import TicketCard from './cmsComponents/custom/TicketCard';
import Button from './cmsComponents/basic/Button';
import ReadMore from './cmsComponents/custom/ReadMore';
import EventGroupContent from './cmsComponents/custom/EventGroupContent';
import ReadMore2 from './cmsComponents/custom/ReadMore2';
import Link from './cmsComponents/basic/Link';
import Icon from './cmsComponents/basic/Icon';
import Navigation from './cmsComponents/custom/Navigation';
import SocialMediaIcon from './cmsComponents/custom/SocialMediaIcon';
import MailchimpSubscribe from './cmsComponents/custom/MailchimpSubscribe';
import Hidden from './cmsComponents/basic/Hidden';
import DrawerMobile from './cmsComponents/custom/DrawerMobile';
import NavbarMobileToggleFab from './cmsComponents/custom/NavbarMobileToggleFab';
import ShoppingBasketButton from './cmsComponents/custom/ShoppingBasketButton';
import UserMenuButton from './cmsComponents/custom/UserMenuButton';
import NavbarMobileToggleButton from './cmsComponents/custom/navbarMobileToggleButton/NavbarMobileToggleButton';
import Scrollbar from './cmsComponents/custom/Scrollbar';
import Embedded from './cmsComponents/basic/Embedded';
import EventGroupDateTimeSelector from './cmsComponents/custom/eventGroupDateTimeSelector/EventGroupDateTimeSelector';
import FullWidth from './cmsComponents/custom/FullWidth';
import Accordion from './cmsComponents/basic/accordion/Accordion';
import AccordionSummary from './cmsComponents/basic/accordion/AccordionSummary';
import AccordionDetails from './cmsComponents/basic/accordion/AccordionDetails';
import ManualEventTable from './cmsComponents/custom/manualEventTable/ManualEventTable';
import TransparentHeader from './cmsComponents/custom/TransparentHeader';
import ListEventGroupDateSelector from './cmsComponents/custom/ListEventGroupDateSelector';
import ManualEventOverview from './cmsComponents/custom/ManualEventOverview';
// eslint-disable-next-line import/no-cycle
import Cart from './cmsComponents/custom/cart/Cart';
import ManualEventTicketSelector from './cmsComponents/custom/ManualEventTicketSelector';
import MultiLanguageText from './cmsComponents/basic/MultiLanguageText';
import MultiLanguageTextElement from './cmsComponents/basic/MultiLanguageTextElement';
import MultiLanguageImage from './cmsComponents/basic/MultiLanguageImage';
import MultiLanguageImageElement from './cmsComponents/basic/MultiLanguageImageElement';
import MultiLanguageButton from './cmsComponents/basic/MultiLanguageButton';
import MultiLanguageButtonElement from './cmsComponents/basic/MultiLanguageButtonElement';
import LanguageSwitcher from './cmsComponents/custom/LanguageSwitcher';
import MultiLanguageReadMore from './cmsComponents/custom/MultiLanguageReadMore';
import ProductAmountCart from './cmsComponents/custom/ProductAmountCart';
import SubscriptionPrice from './cmsComponents/custom/SubscriptionPrice';
import FlexVoucherValidator from './cmsComponents/custom/FlexVoucherValidator';
import UpsellModuleTimeslotSelector from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/upsellModule/UpsellModuleTimeslotSelector';
import UpsellModulePrice from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/upsellModule/UpsellModulePrice';
import UpsellModuleTicketSelector from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/upsellModule/UpsellModuleTicketSelector';
import ListOfProductsCart from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/listOfProductsCart/ListOfProductsCart';
import Account from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/Account';
import FAQ from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/faq/FAQ';
import Register from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/Register';
import Login from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/Login';
import Rebooking from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/rebooking/Rebooking';
import SubscriptionCart from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/subscriptionCart/SubscriptionCart';
import OldAccount from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/oldAccount/AccountPage';
import RequestControlCode from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/requestControlCode/RequestControlCode';

function CmsContentElement(props) {
	const { contentId, content, dataFromExecutor } = props;
	const contentElementProps = content && content[contentId] && content[contentId].props;
	const contentElementNodes = content && content[contentId] && content[contentId].nodes;
	const contentElementLinkedNodes = content && content[contentId] && content[contentId].linkedNodes;
	const contentElementType = content && content[contentId] && content[contentId].type.resolvedName;
	const contentElementParentId = content && content[contentId] && content[contentId].parent;
	const contentElementParentProps =
		content && content[contentElementParentId] && content[contentElementParentId].props;

	function getCmsContentElement(_contentId, _content) {
		if (_content) {
			return (
				<CmsContentElement
					key={_contentId}
					contentId={_contentId}
					content={_content}
					dataFromExecutor={dataFromExecutor}
				/>
			);
		}
		return (
			<CmsContentElement
				key={_contentId}
				contentId={_contentId}
				content={content}
				dataFromExecutor={dataFromExecutor}
			/>
		);
	}

	return (
		<>
			{content && content[contentId] && (
				<>
					{contentId === 'ROOT' ? (
						<Root contentElementNodes={contentElementNodes} getCmsContentElement={getCmsContentElement} />
					) : (
						<>
							{contentElementType === 'GridContainer' ? (
								<GridContainer
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'GridItem' ? (
								<GridItem
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Paper' ? (
								<Paper
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Text' ? (
								<Text key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'MultiLanguageText' ? (
								<MultiLanguageText
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'MultiLanguageTextElement' ? (
								<MultiLanguageTextElement
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementParentProps={contentElementParentProps}
								/>
							) : contentElementType === 'Accordion' ? (
								<Accordion
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'AccordionSummary' ? (
								<AccordionSummary
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'AccordionDetails' ? (
								<AccordionDetails
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Content' ? (
								<Content key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Image' ? (
								<Image key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'MultiLanguageImage' ? (
								<MultiLanguageImage
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'MultiLanguageImageElement' ? (
								<MultiLanguageImageElement
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementParentProps={contentElementParentProps}
								/>
							) : contentElementType === 'Button' ? (
								<Button key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'MultiLanguageButton' ? (
								<MultiLanguageButton
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'MultiLanguageButtonElement' ? (
								<MultiLanguageButtonElement
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementParentProps={contentElementParentProps}
								/>
							) : contentElementType === 'TicketCard' ? (
								<TicketCard key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ReadMore' ? (
								<ReadMore
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'ReadMore2' ? (
								<ReadMore2
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'MultiLanguageReadMore' ? (
								<MultiLanguageReadMore
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'EventGroupContent' ? (
								<EventGroupContent key={contentId} getCmsContentElement={getCmsContentElement} />
							) : contentElementType === 'Link' ? (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<Link
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Icon' ? (
								<Icon key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Navigation' ? (
								<Navigation key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'SocialMediaIcon' ? (
								<SocialMediaIcon key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'MailchimpSubscribe' ? (
								<MailchimpSubscribe key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Hidden' ? (
								<Hidden
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Embedded' ? (
								<Embedded
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementLinkedNodes={contentElementLinkedNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'DrawerMobile' ? (
								<DrawerMobile
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'Scrollbar' ? (
								<Scrollbar
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'NavbarMobileToggleFab' ? (
								<NavbarMobileToggleFab key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'NavbarMobileToggleButton' ? (
								<NavbarMobileToggleButton key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ShoppingBasketButton' ? (
								<ShoppingBasketButton key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'UserMenuButton' ? (
								<UserMenuButton key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'EventGroupDateTimeSelector' ? (
								<EventGroupDateTimeSelector key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'LocationGroupDateTimeSelector' ? (
								<ListEventGroupDateSelector key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ManualEventTable' ? (
								<ManualEventTable key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ManualEventOverview' ? (
								<ManualEventOverview key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ManualEventTicketSelector' ? (
								<ManualEventTicketSelector key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Cart' ? (
								<Cart key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'FullWidth' ? (
								<FullWidth
									key={contentId}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'TransparentHeader' ? (
								<TransparentHeader
									key={contentId}
									contentElementProps={contentElementProps}
									contentElementNodes={contentElementNodes}
									getCmsContentElement={getCmsContentElement}
								/>
							) : contentElementType === 'LanguageSwitcher' ? (
								<LanguageSwitcher key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ProductAmountCart' ? (
								<ProductAmountCart key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'ListOfProductsCart' ? (
								<ListOfProductsCart key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'SubscriptionPrice' ? (
								<SubscriptionPrice key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'FlexVoucherValidator' ? (
								<FlexVoucherValidator key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'UpsellModuleTimeslotSelector' ? (
								<UpsellModuleTimeslotSelector
									key={contentId}
									contentElementProps={contentElementProps}
									dataFromExecutor={dataFromExecutor}
								/>
							) : contentElementType === 'UpsellModulePrice' ? (
								<UpsellModulePrice
									key={contentId}
									contentElementProps={contentElementProps}
									dataFromExecutor={dataFromExecutor}
								/>
							) : contentElementType === 'UpsellModuleTicketSelector' ? (
								<UpsellModuleTicketSelector
									key={contentId}
									contentElementProps={contentElementProps}
									dataFromExecutor={dataFromExecutor}
								/>
							) : contentElementType === 'Account' ? (
								<Account key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'FAQ' ? (
								<FAQ key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Register' ? (
								<Register key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Login' ? (
								<Login key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'Rebooking' ? (
								<Rebooking key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'SubscriptionCart' ? (
								<SubscriptionCart key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'OldAccount' ? (
								<OldAccount key={contentId} contentElementProps={contentElementProps} />
							) : contentElementType === 'RequestControlCode' ? (
								<RequestControlCode key={contentId} contentElementProps={contentElementProps} />
							) : (
								<></>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}

export default CmsContentElement;
