import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionRebooking({ subscriptionCreateUrl, ticketNumber, controlNumber, subscriptionTypeUid, email }) {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(
			`${subscriptionCreateUrl}?t=${subscriptionTypeUid}&tn=${ticketNumber}&cn=${controlNumber}&email=${email}`
		);
	}, [subscriptionCreateUrl, ticketNumber, controlNumber, navigate, email, subscriptionTypeUid]);

	return <></>;
}

export default SubscriptionRebooking;
