import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { navbarToggleMobile } from '@ameroservices-platform/attraction-frontend/app/store/fuse/navbarSlice';

function NavbarMobileToggleButton(props) {
	const dispatch = useDispatch();

	return (
		<IconButton
			className={props.className}
			onClick={(ev) => dispatch(navbarToggleMobile())}
			color="inherit"
			disableRipple
		>
			{props.children}
		</IconButton>
	);
}

NavbarMobileToggleButton.defaultProps = {
	children: <Icon>menu</Icon>,
};

export default NavbarMobileToggleButton;
