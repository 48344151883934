import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100vw',
		maxWidth: '100vw',
		marginLeft: '-50vw',
		left: '50%',
		right: '50%',
		position: 'relative'
	}
}));

function FullWidth(props) {
	const { contentElementNodes } = props;
	const classes = useStyles(props);

	return (
		<div className={`${clsx(classes.fullWidth)}`}>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</div>
	);
}

export default FullWidth;
