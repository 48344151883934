const sortByPrice = (a, b) => {
	const priceA = a.prices.find(price => price.type === 'Sale').price;
	const priceB = b.prices.find(price => price.type === 'Sale').price;
	if (priceA === priceB) {
		return 0;
	}
	if (priceA > priceB) {
		return 1;
	}
	return -1;
};

export default sortByPrice;
