const orderLineUpsellState = {
	ACCEPTED: 'accepted',
	DENIED: 'denied',
	WAITING: 'waiting'
};
export const orderLineUpsellStateTranslate = {
	[orderLineUpsellState.ACCEPTED]: 'Accepteret',
	[orderLineUpsellState.DENIED]: 'Afvist',
	[orderLineUpsellState.WAITING]: 'Afventer'
};

export default orderLineUpsellState;
