import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { checkCustomer, registerWithFirebase, sendConfirmationMail } from '../../../../../../auth/store/registerSlice';
import LoadingButton from '../../../../../../ui-components/LoadingButton';

function Register() {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(undefined);
	const {
		control,
		watch,
		formState,
		handleSubmit,
		setError: setFormError,
		handleChange,
		setValue
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			displayName: '',
			email: '',
			password: '',
			passwordConfirm: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid } = formState;
	const [sent, setSent] = useState(false);
	const register = useSelector(({ auth }) => auth.register);
	const user = useSelector(({ auth }) => auth.user);
	const formRef = useRef(null);
	const { t } = useTranslation();

	async function checkEmail(forcedEmail) {
		const alreadyExists = await checkCustomer(forcedEmail || form.email);
		if (alreadyExists && alreadyExists.origin === 'flexpos' && !alreadyExists.email_verified) {
			setError('already-exists-flexpos');
		} else if (alreadyExists && alreadyExists.origin !== 'flexpos' && !alreadyExists.email_verified) {
			setError('already-exists-other');
		} else if (alreadyExists) {
			setFormError('email', { type: 'custom', message: 'Emailen er allerede i brug' });
		} else {
			setError(null);
			dispatch(registerWithFirebase(form));
		}
	}

	async function onSubmit() {
		setLoading(true);
		checkEmail();
		setLoading(false);
	}

	useEffect(() => {
		if (searchParams.has('email')) {
			setValue('email', searchParams.get('email'));
			checkEmail(searchParams.get('email'));
		}
	}, [searchParams, setValue]);

	useEffect(() => {
		if (user && user.isAnonymous === false) {
			navigate('/account');
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (register.error && (register.error.displayName || register.error.email || register.error.password)) {
			if (register.error.displayName) {
				setFormError('displayName', { type: 'custom', message: t(register.error.displayName) });
			}
			if (register.error.email) {
				setFormError('email', { type: 'custom', message: t(register.error.email) });
			}
			if (register.error.password) {
				setFormError('password', { type: 'custom', message: t(register.error.password) });
			}
		}
	}, [register.error, t]);

	async function handleSendConfirmation() {
		setLoading(true);
		await sendConfirmationMail(form.email);
		setSent(true);
		setLoading(false);
	}

	return (
		<div className={clsx('flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimateAdapter animation="transition.expandIn" className={'flex justify-center w-full'}>
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<Typography variant="h6" className="mt-16 mb-32">
								{t('CREATE_ACCOUNT')}
							</Typography>

							<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center w-full">
								{sent && (
									<Alert severity="success" className="mb-20">
										<strong>{t('MAIL_SENT')}</strong>
										<br />
										{t('CONFIRMATION_MAIL_TO')}
										{form.email}
									</Alert>
								)}
								<TextFieldFormsyAdapter
									control={control}
									className="mb-16"
									label="Email"
									type="email"
									name="email"
									value={form.email}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
									disabled={sent || error === 'already-exists-flexpos'}
									enableTrim
								/>

								{error && (error === 'already-exists-flexpos' || error === 'already-exists-other') && (
									<>
										{error === 'already-exists-flexpos' && (
											<>
												<Typography className="text-center mb-10 w-full" color="textSecondary">
													{t('ALREADY_EXISTS')}
												</Typography>
												<Typography className="text-center mb-10 w-full" color="textSecondary">
													{t('TO_ACTIVE_ACCOUNT')}
												</Typography>
											</>
										)}
										{error === 'already-exists-other' && (
											<>
												<Typography className="text-center mb-10 w-full" color="textSecondary">
													Overstående email er ikke bekræftet endnu
												</Typography>
											</>
										)}
										<Typography className="text-center mb-10 w-full" color="textSecondary">
											{t('ACTIVE_ACCOUNT_BY')}
										</Typography>
										<LoadingButton
											variant="contained"
											color="primary"
											classes={{ wrapper: 'w-224 mx-auto mt-16', button: 'w-full' }}
											aria-label="Register"
											onClick={handleSendConfirmation}
											disableLoadingOnClick
											loading={loading}
											disabled={sent}
										>
											{t('SEND_CONFIRMATION_MAIL')}
										</LoadingButton>
									</>
								)}
								{error === null && (
									<>
										<TextFieldFormsyAdapter
											control={control}
											className="mb-16"
											label={t('FULL_NAME')}
											autoFocus
											type="name"
											name="displayName"
											value={form.displayName}
											variant="outlined"
											required
											fullWidth
										/>

										<TextFieldFormsyAdapter
											control={control}
											className="mb-16"
											label={t('PASSWORD')}
											type="password"
											name="password"
											value={form.password}
											variant="outlined"
											required
											fullWidth
										/>

										<TextFieldFormsyAdapter
											control={control}
											className="mb-16"
											label={t('CONFIRM_PASSWORD')}
											type="password"
											name="passwordConfirm"
											value={form.passwordConfirm}
											variant="outlined"
											required
											fullWidth
										/>
									</>
								)}

								{!error && (
									<LoadingButton
										variant="contained"
										color="primary"
										classes={{ wrapper: 'w-full mx-auto mt-16', button: 'w-full' }}
										aria-label="Register"
										disabled={
											(error === null && !isFormValid) || (error === undefined && !form.email)
										}
										type="submit"
										disableLoadingOnClick
										loading={loading}
									>
										{error === undefined ? t('CONTINUE') : t('CREATE_ACCOUNT')}
									</LoadingButton>
								)}
							</form>

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								<span className="font-medium">{t('ACCOUNT_EXISTS')}</span>
								<Link className="font-medium" to="/login">
									{t('LOGIN')}
								</Link>
							</div>
						</CardContent>
					</Card>
				</FuseAnimateAdapter>
			</div>
		</div>
	);
}

export default Register;
