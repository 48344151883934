import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import { Button, Paper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	applyCode,
	deleteOrderLine,
	removeCode,
	selectCodeLoading,
	selectOrder,
	selectOrderLines
} from '../../../../../../../store/shared/cartSlice';
import { selectModules } from '../../../../../../../store/shared/frontendSlice';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
import codeTypes from '../../../../../../../ui-components/states/codeTypes';

export default function RedeemCode() {
	const { t } = useTranslation();
	const flags = useFlags();
	const dispatch = useDispatch();
	const modules = useSelector(selectModules);
	const [code, setCode] = useState('');
	const order = useSelector(selectOrder);
	const orderLines = useSelector(selectOrderLines);
	const codeLoading = useSelector(selectCodeLoading);
	const [addAnotherCode, setAddAnotherCode] = useState(false);

	const codeType = useMemo(() => {
		if (
			flags.discountCodes &&
			modules &&
			modules.discountCodes &&
			flags.integrationToFlexVoucher &&
			modules &&
			modules.giftCards
		) {
			return codeTypes.DISCOUNT_CODE_AND_GIFT_CARD;
		}
		if (
			flags.discountCodes &&
			modules &&
			modules.discountCodes &&
			!(flags.integrationToFlexVoucher && modules && modules.giftCards)
		) {
			return codeTypes.DISCOUNT_CODE;
		}
		if (
			!(flags.discountCodes && modules && modules.discountCodes) &&
			flags.integrationToFlexVoucher &&
			modules &&
			modules.giftCards
		) {
			return codeTypes.GIFT_CARD;
		}
		return null;
	}, [flags.discountCodes, modules, flags.integrationToFlexVoucher]);

	const codesUsed = useMemo(() => {
		const _codes = [];
		if (flags.discountCodes && order && order.discountCodes && order.discountCodes.length > 0) {
			_codes.push(...order.discountCodes.map(_code => ({ ..._code, type: 'discountCode' })));
		}
		if (flags.integrationToFlexVoucher && order && order.giftCards && order.giftCards.length > 0) {
			_codes.push(...order.giftCards.map(_code => ({ ..._code, type: 'giftCard' })));
		}
		return _codes;
	}, [order, flags.discountCodes, flags.integrationToFlexVoucher]);

	function handleApply() {
		dispatch(applyCode(code));
		setCode('');
	}
	function handleRemove(codeToRemove) {
		const codeOrderLine = orderLines.find(
			ol =>
				(ol.lineType === 'discountCode' || ol.lineType === 'giftCard') &&
				((ol.discountCode && ol.discountCode.code === codeToRemove.code) ||
					(ol.giftCard && ol.giftCard.code === codeToRemove.code))
		);
		if (!codeOrderLine) {
			dispatch(removeCode(codeToRemove.code));
		} else {
			dispatch(deleteOrderLine(codeOrderLine.id, 'code'));
		}
		setCode('');
	}
	async function handleApplyRemove() {
		if (code) {
			const codeUsed = codesUsed.find(c => c.code === code);
			if (codeUsed) {
				await handleRemove(codeUsed);
			} else {
				await handleApply();
			}
		}
		setAddAnotherCode(false);
	}

	return (
		<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
			{codeType === codeTypes.DISCOUNT_CODE && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_DISCOUNT_CODE')}
				</Typography>
			)}
			{codeType === codeTypes.GIFT_CARD && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_GIFT_CARD_CODE')}
				</Typography>
			)}
			{codeType === codeTypes.DISCOUNT_CODE_AND_GIFT_CARD && (
				<Typography variant="h6" className="p-24 pb-20">
					{t('APPLY_DISCOUNT_OR_GIFT_CARD_CODE')}
				</Typography>
			)}
			{codesUsed.map(codeUsed => (
				<div className="flex flex-col md:flex-row pb-16 px-16">
					<TextField
						label={t('CODE')}
						variant="outlined"
						className="w-full"
						size="small"
						InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
						value={codeUsed.code}
						disabled
					/>
					<LoadingButton
						title={t('REMOVE')}
						variant="contained"
						color="primary"
						classes={{ button: 'w-full md:min-w-256' }}
						style={{ padding: '7px 16px' }}
						onClick={() => handleRemove(codeUsed)}
						loading={codeLoading}
					/>
				</div>
			))}
			{codesUsed.length > 0 && !addAnotherCode && (
				<div className="flex flex-col md:flex-row pb-16 px-16 -mt-6">
					<Button onClick={() => setAddAnotherCode(true)}>Tilføj endnu et gavekort / rabatkode</Button>
				</div>
			)}
			{(codesUsed.length <= 0 || addAnotherCode) && (
				<div className="flex flex-col md:flex-row pb-16 px-16">
					<TextField
						label={t('CODE')}
						variant="outlined"
						className="w-full"
						size="small"
						InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
						value={code}
						onChange={e => {
							const val = e.currentTarget.value;
							setCode(val);
						}}
					/>
					<LoadingButton
						title={
							!code && codesUsed.length > 0
								? 'ANNULLER'
								: codesUsed.some(c => c.code === code)
								? t('REMOVE')
								: t('APPLY')
						}
						endIcon={
							(code || codesUsed.length <= 0) && !codesUsed.some(c => c.code === code) && <NavigateNext />
						}
						variant="contained"
						color="primary"
						classes={{ button: 'w-full md:min-w-256' }}
						style={{ padding: '7px 16px' }}
						// disabled={!code}
						onClick={handleApplyRemove}
						loading={codeLoading}
					/>
				</div>
			)}
		</Paper>
	);
}
