import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, darken, TableCell } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { orderLinesListener, ordersListener } from '../../../../../../../store/shared/userSlice';
import StorageImage from '../../../../../../../ui-components/StorageImage';
import { selectLogos, selectSettings } from '../../../../../../../store/shared/frontendSlice';
import AccountVerificationNeeded from './AccountVerificationNeeded';

const useStyles = makeStyles(theme => ({
	header: {
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.primary.contrastText
	},
	panel: {
		marginBottom: '1rem',
		borderRadius: '16px 16px 16px 16px',
		'&$expanded': {
			marginBottom: '1rem'
		},
		'&:before': {
			backgroundColor: 'unset'
		}
	},
	panelDetail: {
		display: 'block'
	},
	expanded: {},
	root: {
		background: `radial-gradient(${darken(theme.palette.background.paper, 0.05)} 0%, ${
			theme.palette.background.paper
		} 60%)`
	},
	divider: {
		backgroundColor: theme.palette.divider
	}
}));

const formatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

const completeStates = ['complete', 'processing'];

function AccountOrdersPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const logos = useSelector(selectLogos);
	const settings = useSelector(selectSettings);
	const orders = useSelector(({ shared }) => shared.user.orders);
	const orderLines = useSelector(({ shared }) => shared.user.orderLines);
	const user = useSelector(({ shared }) => shared.user.user);
	const userAuth = useSelector(({ auth }) => auth.user);
	const [expanded, setExpanded] = useState(null);
	const [verified, setVerified] = useState(false);
	const { t } = useTranslation();

	const states = {
		complete: { color: 'bg-green-800 text-white', name: t('COMPLETED') },
		pendingPayment: { color: 'bg-yellow-800 text-white', name: t('AWAITING_PAYMENT') },
		refunded: { color: 'bg-blue text-white', name: t('REFUNDED') },
		canceled: { color: 'bg-red text-white', name: t('CANCELLED') },
		error: { color: 'bg-red text-white', name: t('ERROR') }
	};

	useEffect(() => {
		if (userAuth && userAuth.claims) {
			setVerified(userAuth.claims.email_verified);
		}
	}, [userAuth]);

	useEffect(() => {
		if (user && user.uid) {
			const unsub = dispatch(ordersListener(user.uid));
			return () => unsub();
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (user && user.uid && orders && orders.length > 0) {
			const unsubList = orders.map(order => dispatch(orderLinesListener(user.uid, order.orderUid)));
			return () => unsubList.forEach(func => func());
		}
		return () => {};
	}, [dispatch, user, orders]);

	const toggleAccordion = panel => (event, _expanded) => {
		setExpanded(_expanded ? panel : false);
	};

	if (verified) {
		return (
			<>
				<div className="flex items-center justify-between p-20">
					<table className="w-full">
						<thead>
							<tr>
								<td className="w-400">
									<Typography variant="subtitle1">{t('ORDER')}</Typography>
								</td>
								<td>
									<Typography variant="subtitle1">Status</Typography>
								</td>
								<td className="w-76 pr-64">
									<Typography variant="subtitle1">{t('PRICE')}</Typography>
								</td>
								<td />
							</tr>
						</thead>
					</table>
				</div>
				{orders.map(order => (
					<Accordion
						classes={{
							root: clsx(classes.panel, 'w-xl'),
							expanded: classes.expanded
						}}
						key={order.id}
						expanded={expanded === order.id}
						onChange={toggleAccordion(order.id)}
						elevation={1}
						square
					>
						<AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
							{expanded !== order.id && (
								<table className="w-full">
									<tbody>
										<tr>
											<td className="w-400">
												<div>
													<Typography variant="h6" className="mb-8">
														{order.orderNumber}
													</Typography>
													<Typography color="textSecondary">
														{moment(order.createdAt).format('DD-MM-YYYY, HH:mm')}
													</Typography>
												</div>
											</td>
											<td>
												<Typography variant="subtitle1">
													{completeStates.includes(order.state) || !states[order.state] ? (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states.complete.color
															)}
														>
															{states.complete.name}
														</span>
													) : (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states[order.state].color
															)}
														>
															{states[order.state].name}
														</span>
													)}
												</Typography>
											</td>
											<td className="w-76">
												<Typography variant="subtitle1" className="mr-16">
													{order.totals && order.totals.total
														? formatter.format(order.totals.total / 100.0)
														: '-'}
												</Typography>
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</AccordionSummary>

						<AccordionDetails classes={{ root: clsx(classes.root, 'block p-88') }}>
							<div className="flex flex-row justify-between items-start">
								<div className="flex flex-col">
									<div className="flex items-center mb-80 print:mb-0">
										<StorageImage className="max-w-160" src={logos.header} />

										<div className={clsx(classes.divider, 'mx-48 w-px h-128 print:mx-16')} />

										<div className="max-w-160">
											<Typography color="textSecondary">{settings.title}</Typography>

											{/* {invoice.from.address && ( */}
											{/*	<Typography color="textSecondary">{invoice.from.address}</Typography> */}
											{/* )} */}
											{/* {invoice.from.phone && ( */}
											{/*	<Typography color="textSecondary"> */}
											{/*		<span>Phone:</span> */}
											{/*		{invoice.from.phone} */}
											{/*	</Typography> */}
											{/* )} */}
											{/* {invoice.from.email && ( */}
											{/*	<Typography color="textSecondary"> */}
											{/*		<span>Email:</span> */}
											{/*		{invoice.from.email} */}
											{/*	</Typography> */}
											{/* )} */}
											{/* {invoice.from.website && ( */}
											{/*	<Typography color="textSecondary"> */}
											{/*		<span>Web:</span> */}
											{/*		{invoice.from.website} */}
											{/*	</Typography> */}
											{/* )} */}
										</div>
									</div>

									<div className="flex items-center">
										<div className="flex justify-end items-center w-160 print:w-60">
											<Typography
												variant="h5"
												className="font-light print:text-16"
												color="textSecondary"
											>
												{t('CUSTOMER')}
											</Typography>
										</div>

										<div className={clsx(classes.divider, 'mx-48 w-px h-128 print:mx-16')} />

										<div className="max-w-160">
											{order.contactInfo && order.contactInfo.billing && (
												<>
													<Typography color="textSecondary">
														{order.contactInfo.billing.firstname}{' '}
														{order.contactInfo.billing.lastname}
													</Typography>
													{order.contactInfo.billing.address && (
														<Typography color="textSecondary">
															{order.contactInfo.billing.address}
														</Typography>
													)}
													{order.contactInfo.billing.city &&
														order.contactInfo.billing.postalCode && (
															<Typography color="textSecondary">
																{order.contactInfo.billing.postalCode}{' '}
																{order.contactInfo.billing.city}
															</Typography>
														)}
													{order.contactInfo.billing.phone && (
														<Typography color="textSecondary">
															<span>{t('PHONE')}: </span>
															{order.contactInfo.billing.phone}
														</Typography>
													)}
													{order.contactInfo.billing.email && (
														<Typography color="textSecondary">
															<span>Email: </span>
															{order.contactInfo.billing.email}
														</Typography>
													)}
												</>
											)}
										</div>
									</div>
								</div>

								<table>
									<tbody>
										<tr>
											<td className="pb-32">
												<Typography className="font-light" variant="h4" color="textSecondary">
													{t('ORDER')}
												</Typography>
											</td>
											<td className="pb-32 px-16">
												<Typography className="font-light" variant="h4">
													{order.orderNumber}
												</Typography>
											</td>
										</tr>

										<tr>
											<td className="text-right">
												<Typography color="textSecondary">STATUS</Typography>
											</td>
											<td className="px-16">
												<Typography>
													{completeStates.includes(order.state) || !states[order.state] ? (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states.complete.color
															)}
														>
															{states.complete.name}
														</span>
													) : (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states[order.state].color
															)}
														>
															{states[order.state].name}
														</span>
													)}
												</Typography>
											</td>
										</tr>

										<tr>
											<td className="text-right">
												<Typography color="textSecondary">{t('DATO')}</Typography>
											</td>
											<td className="px-16">
												<Typography>
													{moment(order.createdAt).format('DD-MM-YYYY, HH:mm')}
												</Typography>
											</td>
										</tr>

										<tr>
											<td className="text-right">
												<Typography color="textSecondary">TOTAL</Typography>
											</td>
											<td className="px-16">
												<Typography>
													{formatter.format(
														order.totals && order.totals.total
															? order.totals.total / 100.0
															: 0
													)}
												</Typography>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mt-96 print:mt-0 w-full">
								<Table className="simple">
									<TableHead>
										<TableRow>
											<TableCell>{t('PRODUCT')}</TableCell>
											<TableCell align="right">{t('PRICE_EA')}</TableCell>
											<TableCell align="right">{t('AMOUNT')}</TableCell>
											<TableCell align="right">TOTAL</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{orderLines[order.id] &&
											orderLines[order.id].map(orderLine => (
												<TableRow key={orderLine.id}>
													<TableCell>
														<Typography className="mb-8" variant="subtitle1">
															{orderLine.name}
														</Typography>
													</TableCell>
													<TableCell align="right">
														{formatter.format(orderLine.unitPrice / 100)}
													</TableCell>
													<TableCell align="right">{orderLine.qty}</TableCell>
													<TableCell align="right">
														{formatter.format(orderLine.linePrice / 100)}
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>

								<Table className="simple">
									<TableBody>
										<TableRow>
											<TableCell>
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													SUBTOTAL
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{formatter.format(
														order.totals && order.totals.subtotal
															? order.totals.subtotal / 100.0
															: 0
													)}{' '}
												</Typography>
											</TableCell>
										</TableRow>
										{order.totals && order.totals.getByTaxPercent ? (
											Object.keys(order.totals.getByTaxPercent).map((key, index) => (
												<TableRow key={`tax-index-${key}`}>
													<TableCell>
														<Typography
															className="font-medium"
															variant="subtitle1"
															color="textSecondary"
														>
															{t('TAX')} ({key / 100}%)
														</Typography>
													</TableCell>
													<TableCell align="right">
														<Typography
															className="font-medium"
															variant="subtitle1"
															color="textSecondary"
														>
															{formatter.format(order.totals.getByTaxPercent[key] / 100)}
														</Typography>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell>
													<Typography
														className="font-medium"
														variant="subtitle1"
														color="textSecondary"
													>
														{t('TAX')}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography
														className="font-medium"
														variant="subtitle1"
														color="textSecondary"
													>
														{formatter.format(
															order.totals && order.totals.tax
																? order.totals.tax / 100.0
																: 0
														)}
													</Typography>
												</TableCell>
											</TableRow>
										)}
										<TableRow>
											<TableCell>
												<Typography className="font-light" variant="h4" color="textSecondary">
													TOTAL
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography className="font-light" variant="h4" color="textSecondary">
													{formatter.format(
														order.totals && order.totals.total
															? order.totals.total / 100.0
															: 0
													)}
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</div>
						</AccordionDetails>
					</Accordion>
				))}
			</>
		);
	}
	return <AccountVerificationNeeded type="ordrer" />;
}

export default AccountOrdersPage;
