import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';

function TransparentHeader(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		header: {
			backgroundColor: `${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}`
		},
		headerScrollAtTop: {
			background: `linear-gradient(${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}, transparent)`
		}
	}));

	const classes = useStyles();

	const [scrollAtTop, setScrollAtTop] = useState(true);

	const handleScroll = e => {
		setScrollAtTop(e.target.scrollTop === 0);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={`fixed z-10 w-full  ${scrollAtTop ? classes.headerScrollAtTop : classes.header}`}>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</div>
	);
}

export default TransparentHeader;
