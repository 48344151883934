import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpinnerLoading from './SpinnerLoading';

function ImageLoading({ src, fullWidth }) {
	const [error, setError] = useState(false);
	const [showImg, setShowImg] = useState(true);
	const [errorTimeout, setErrorTimeout] = useState(null);
	const { t } = useTranslation();
	return src ? (
		<div className="relative h-216 flex justify-center">
			{error &&
				(fullWidth ? (
					<div className="flex flex-col justify-center items-center">
						<div>
							<Typography className="text-white font-bold mb-16">{t('LOADING')}</Typography>
							<SpinnerLoading />
						</div>
					</div>
				) : (
					<div className="absolute left-0 top-0 bg-black flex flex-col justify-center items-center w-216 h-216 z-50">
						<div>
							<Typography className="text-white font-bold mb-16">{t('LOADING')}</Typography>
							<SpinnerLoading />
						</div>
					</div>
				))}
			{showImg && (
				<img
					className={!fullWidth && 'absolute z-40'}
					src={src}
					style={fullWidth ? {} : { maxWidth: '200px', maxHeight: '200px' }}
					alt="Billede af kunde"
					onError={() => {
						if (errorTimeout) {
							clearTimeout(errorTimeout);
						}
						setError(true);
						setShowImg(false);
						setErrorTimeout(setTimeout(() => setError(false), 200));
						setTimeout(() => setShowImg(true), 50);
					}}
				/>
			)}
		</div>
	) : (
		<div className="bg-black flex justify-center items-center w-216 h-216">
			<Typography className="text-white font-bold">{t('NO_IMAGE')}</Typography>
		</div>
	);
}

export default ImageLoading;
