import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';

const useStyles = makeStyles(theme => ({
	content: {
		overflowX: 'hidden',
		overflowY: 'auto',
		'-webkit-overflow-scrolling': 'touch',
		background:
			'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll'
	}
}));

function Scrollbar(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const classes = useStyles(props);

	return (
		<FuseScrollbars
			className={`${clsx(classes.content)} ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</FuseScrollbars>
	);
}

export default Scrollbar;
