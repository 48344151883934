import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Add, ArrowForward, Remove } from '@mui/icons-material';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { eventGroupListener, productsListener, groupSummaryListener } from './ManualEventDetailFirestore';
import StorageImage from '../../../../../ui-components/StorageImage';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 }
}));

const sortByTime = (a, b) => {
	return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
};

function ManualEventDetailPage({ location, data, queries }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [products, setProducts] = useState([]);
	const [groupSummaries, setGroupSummaries] = useState([]);
	const [events, setEvents] = useState([]);
	const [eventGroup, setEventGroup] = useState(null);
	const [ticketCount, setTicketCount] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		if (queries.e) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(queries.e, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [queries.e]);

	useEffect(() => {
		if (eventGroup) {
			const summariesListenerUnsubFunc = groupSummaryListener(eventGroup.id, o => setGroupSummaries(o));
			return () => summariesListenerUnsubFunc();
		}
		return () => {};
	}, [eventGroup]);

	useEffect(() => {
		if (groupSummaries) {
			const now = moment().toDate();
			const tempEvents = [];
			groupSummaries.forEach(summary => {
				Object.values(summary.dates).forEach(date => {
					Object.values(date.times).forEach(time => {
						const start = moment(time.start.toDate()).toDate();
						if (start > now) {
							tempEvents.push({ ...time, start, end: moment(time.end.toDate()).toDate() });
						}
					});
				});
			});
			tempEvents.sort(sortByTime);
			tempEvents.splice(30);
			setEvents(tempEvents);
		}
	}, [groupSummaries]);

	useEffect(() => {
		if (eventGroup && eventGroup.ticketTypes.length > 0) {
			const productsListenerUnsubFunc = productsListener(eventGroup.ticketTypes, o => setProducts(o));
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] =
				location &&
				location.state &&
				location.state.productsWithAmount &&
				location.state.productsWithAmount[product.id]
					? location.state.productsWithAmount[product.id]
					: 0;
		});
		setProductsWithAmount(pro);
	}, [products, location]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	function isAllowedToBuy(event) {
		return Object.values(productsWithAmount).filter(p => p > 0).length === 0 || event.available >= ticketCount;
	}

	function goToCheckOut(event) {
		navigate('/cart', {
			state: {
				event: event.eventUid,
				products: productsWithAmount
			}
		});
	}
	function showAvailable(max, amount) {
		const hideAbove = max / (100 / eventGroup.hideTicketAmountAbovePercent);
		return hideAbove >= amount;
	}

	return (
		<div className={clsx(classes.grid, 'max-w-screen py-48 sm:py-48 lg:min-w-2xl')}>
			<Grid container spacing={0}>
				{/* ---------------Billede og beskrivelse start-----------------*/}
				{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
					<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16 mb-24">
						<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
							{eventGroup && eventGroup.content && !eventGroup.content.ROOT
								? eventGroup.content.title
								: ''}
						</Typography>
						<div className="flex flex-col md:flex-row">
							<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
								<StorageImage
									src={
										eventGroup && eventGroup.content && !eventGroup.content.ROOT
											? eventGroup.content.image
											: ''
									}
									alt=""
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
								<div className="text-left text-16 pb-20">
									{eventGroup &&
										eventGroup.content &&
										eventGroup.content.content &&
										!eventGroup.content.ROOT &&
										eventGroup.content.content.map((c, index) => {
											if (c.type === 'heading') {
												return <HeadingComponent key={index}>{c.content}</HeadingComponent>;
											}
											if (c.type === 'text') {
												return <TextComponent key={index}>{c.content}</TextComponent>;
											}
											if (c.type === 'number_list') {
												return (
													<ListComponent
														key={index}
														type="number"
														list={c.content.array}
														heading={c.content.heading}
													/>
												);
											}
											if (c.type === 'markdown') {
												return <MarkdownComponent key={index} data={c.content} />;
											}
											return <span key={index} />;
										})}
								</div>
							</Grid>
						</div>
					</Paper>
				)}
				{/* ---------------Billede og beskrivelse end-----------------*/}
				{/* ---------------Vælg billetter start-----------------*/}
				<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16 w-full">
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						className="flex flex-col whitespace-no-wrap px-10 pt-0"
					>
						<Typography variant="h6" className="font-bold whitespace-no-wrap pt-10 pb-4">
							Vælg dine billetter
						</Typography>
						{/* {openBox1 && ( */}
						{/*	<> */}
						{products.map(product => (
							<div className="flex justify-between items-center" key={product.id}>
								<div className="flex justify-between w-full">
									<Typography className="font-bold whitespace-normal">{product.name}</Typography>
									<Typography className="px-8 font-bold flex items-center">
										{product.prices.find(f => f.type === 'Sale').price / 100},-
									</Typography>
								</div>
								<div className="flex ButtonDiv h-56 items-center">
									<Button
										variant="outlined"
										onClick={() => {
											setProductsWithAmount(oldState => {
												const p = { ...oldState };
												if (p[product.id] !== 0) {
													p[product.id] -= 1;
												}
												return p;
											});
										}}
										className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
									>
										<Remove />
									</Button>
									<div className="flex items-center text-14 font-bold px-10">
										{productsWithAmount[product.id]}
									</div>
									<Button
										variant="outlined"
										onClick={() => {
											setProductsWithAmount(oldState => {
												const p = { ...oldState };
												p[product.id] += 1;
												return p;
											});
										}}
										className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
									>
										<Add />
									</Button>
								</div>
							</div>
						))}

						<hr />
						<div className="flex justify-between w-full">
							<Typography className="font-bold text-20">{t('TOTAL')}</Typography>
							<Typography className="font-bold text-20">{`${totalAmount / 100} kr.`}</Typography>
						</div>
						{/* </> */}
					</Grid>
				</Paper>

				{/* ---------------Vælg billetter end-----------------*/}

				{/* ---------------Liste over events start-----------------*/}
				<Grid className="py-24" item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Paper className="p-10 md:p-28 mx-12 md:mx-16">
						<Typography variant="h6" className="pb-16">
							{eventGroup ? eventGroup.name : ''}
						</Typography>
						{events
							.map(event => ({ ...event, start: moment(event.start), end: moment(event.end) }))
							.map(event => (
								<React.Fragment key={event.eventUid}>
									<div className="flex flex-col py-12 md:py-0 md:flex-row md:items-center md:justify-between">
										<div className="flex flex-row items-center">
											<div
												className={clsx(
													classes.number,
													'flex w-64 h-64 justify-center items-center flex-col'
												)}
											>
												<Typography className="font-bold">
													{event.start.format('MMM').toUpperCase()}
												</Typography>
												<Typography className="font-bold text-18">
													{event.start.format('DD')}
												</Typography>
												<Typography className="font-bold">
													{event.start.format('YYYY')}
												</Typography>
											</div>
											<div className="flex flex-col pl-12">
												<Typography>{event.start.format('dddd [-] HH:mm')}</Typography>
												<Typography className="font-bold">{event.name}</Typography>
											</div>
										</div>
										<div className="w-full md:w-256 flex flex-col items-center">
											<Button
												className="w-full mt-6 rounded-none max-h-44"
												variant="contained"
												color="primary"
												disabled={!isAllowedToBuy(event)}
												onClick={() => goToCheckOut(event)}
											>
												<div className="flex flex-col">
													<span className="text-14 font-bold">
														Køb billet <ArrowForward />
													</span>

													{showAvailable(event.maxTickets, event.available) && (
														<span className="text-12 whitespace-no-wrap font-bold">
															{eventGroup.showTextOnPercentage ? (
																<>
																	{eventGroup.textOnTicketAmount &&
																	event.available > 0
																		? eventGroup.textOnTicketAmount
																		: t('SOLD_OUT')}
																</>
															) : (
																<>
																	{event.available > 0
																		? `${event.available} ${t('TICKETS_LEFT')}`
																		: t('SOLD_OUT')}
																</>
															)}
														</span>
													)}
												</div>
											</Button>
										</div>
									</div>
									<hr className="my-0 md:my-10" />
								</React.Fragment>
							))}
					</Paper>
				</Grid>
				{/* ---------------Liste over events end-----------------*/}
			</Grid>
		</div>
	);
}

export default ManualEventDetailPage;
