import React from 'react';

function Vimeo(props) {
	return (
		<svg
			id="Bold"
			enableBackground="new 0 0 24 24"
			height="50"
			viewBox="0 0 24 24"
			width="50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				style={{ fill: props.color }}
				d="m20.497 1.505c-3.328-.121-5.576 1.787-6.762 5.694.61-.273 1.203-.395 1.777-.395 1.219 0 1.758.697 1.614 2.082-.072.839-.608 2.059-1.612 3.664-1.009 1.605-1.763 2.409-2.265 2.409-1.522 0-2.437-7.284-2.747-9.273-.431-2.765-1.58-4.058-3.447-3.877-1.714.163-4.705 3.249-7.055 5.341l1.129 1.472c1.075-.762 1.702-1.144 1.881-1.144 1.871 0 2.777 6.367 4.23 11.102.975 2.613 2.144 3.92 3.553 3.92 2.264 0 5.022-2.144 8.29-6.434 3.155-4.107 4.789-7.344 4.894-9.705l.013-.01c.134-3.165-1.021-4.785-3.493-4.846z"
			/>
		</svg>
	);
}
export default Vimeo;
