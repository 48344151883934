import React from 'react';
// eslint-disable-next-line import/no-cycle
import CartContent from './CartContent';

function Cart(props) {
	return (
		<div className="flex-none mx-auto pt-40 sm:px-48">
			<CartContent data={props.data} />
		</div>
	);
}

export default Cart;
