const countries = {
	AF: { DA_dk: { name: 'Afghanistan' } },
	AL: { DA_dk: { name: 'Albanien' } },
	DZ: { DA_dk: { name: 'Algeriet' } },
	AS: { DA_dk: { name: 'Amerikansk Samoa' } },
	UM: { DA_dk: { name: 'Amerikanske overs\u00f8iske \u00f8er' } },
	AD: { DA_dk: { name: 'Andorra' } },
	AO: { DA_dk: { name: 'Angola' } },
	AI: { DA_dk: { name: 'Anguilla' } },
	AQ: { DA_dk: { name: 'Antarktis' } },
	AG: { DA_dk: { name: 'Antigua og Barbuda' } },
	AR: { DA_dk: { name: 'Argentina' } },
	AM: { DA_dk: { name: 'Armenien' } },
	AW: { DA_dk: { name: 'Aruba' } },
	AZ: { DA_dk: { name: 'Aserbajdsjan' } },
	AU: { DA_dk: { name: 'Australien' } },
	BS: { DA_dk: { name: 'Bahamas' } },
	BH: { DA_dk: { name: 'Bahrain' } },
	BD: { DA_dk: { name: 'Bangladesh' } },
	BB: { DA_dk: { name: 'Barbados' } },
	BE: { DA_dk: { name: 'Belgien' } },
	BZ: { DA_dk: { name: 'Belize' } },
	BJ: { DA_dk: { name: 'Benin' } },
	BM: { DA_dk: { name: 'Bermuda' } },
	BT: { DA_dk: { name: 'Bhutan' } },
	BO: { DA_dk: { name: 'Bolivia' } },
	BA: { DA_dk: { name: 'Bosnien-Hercegovina' } },
	BW: { DA_dk: { name: 'Botswana' } },
	BV: { DA_dk: { name: 'Bouvet\u00f8en' } },
	BR: { DA_dk: { name: 'Brasilien' } },
	BN: { DA_dk: { name: 'Brunei' } },
	BG: { DA_dk: { name: 'Bulgarien' } },
	BF: { DA_dk: { name: 'Burkina Faso' } },
	BI: { DA_dk: { name: 'Burundi' } },
	KH: { DA_dk: { name: 'Cambodja' } },
	CM: { DA_dk: { name: 'Cameroun' } },
	CA: { DA_dk: { name: 'Canada' } },
	KY: { DA_dk: { name: 'Cayman\u00f8erne' } },
	CL: { DA_dk: { name: 'Chile' } },
	CC: { DA_dk: { name: 'Cocos\u00f8erne' } },
	CO: { DA_dk: { name: 'Colombia' } },
	KM: { DA_dk: { name: 'Comorerne' } },
	CG: { DA_dk: { name: 'Congo-Brazzaville' } },
	CD: { DA_dk: { name: 'Congo-Kinshasa' } },
	CK: { DA_dk: { name: 'Cook\u00f8erne' } },
	CR: { DA_dk: { name: 'Costa Rica' } },
	CU: { DA_dk: { name: 'Cuba' } },
	CW: { DA_dk: { name: 'Cura\u00e7ao' } },
	CY: { DA_dk: { name: 'Cypern' } },
	DK: { DA_dk: { name: 'Danmark' } },
	VI: { DA_dk: { name: 'De Amerikanske Jomfru\u00f8er' } },
	VG: { DA_dk: { name: 'De Britiske Jomfru\u00f8er' } },
	AE: { DA_dk: { name: 'De Forenede Arabiske Emirater' } },
	TF: { DA_dk: { name: 'De Franske Besiddelser i Det Sydlige Indiske Ocean og Antarktis' } },
	PS: { DA_dk: { name: 'De pal\u00e6stinensiske omr\u00e5der' } },
	BQ: { DA_dk: { name: 'De tidligere Nederlandske Antiller' } },
	CF: { DA_dk: { name: 'Den Centralafrikanske Republik' } },
	DO: { DA_dk: { name: 'Den Dominikanske Republik' } },
	IO: { DA_dk: { name: 'Det Britiske Territorium i Det Indiske Ocean' } },
	DJ: { DA_dk: { name: 'Djibouti' } },
	DM: { DA_dk: { name: 'Dominica' } },
	EC: { DA_dk: { name: 'Ecuador' } },
	EG: { DA_dk: { name: 'Egypten' } },
	SV: { DA_dk: { name: 'El Salvador' } },
	CI: { DA_dk: { name: 'Elfenbenskysten' } },
	ER: { DA_dk: { name: 'Eritrea' } },
	EE: { DA_dk: { name: 'Estland' } },
	SZ: { DA_dk: { name: 'Eswatini' } },
	ET: { DA_dk: { name: 'Etiopien' } },
	FK: { DA_dk: { name: 'Falklands\u00f8erne' } },
	FJ: { DA_dk: { name: 'Fiji' } },
	PH: { DA_dk: { name: 'Filippinerne' } },
	FI: { DA_dk: { name: 'Finland' } },
	FR: { DA_dk: { name: 'Frankrig' } },
	GF: { DA_dk: { name: 'Fransk Guyana' } },
	PF: { DA_dk: { name: 'Fransk Polynesien' } },
	FO: { DA_dk: { name: 'F\u00e6r\u00f8erne' } },
	GA: { DA_dk: { name: 'Gabon' } },
	GM: { DA_dk: { name: 'Gambia' } },
	GE: { DA_dk: { name: 'Georgien' } },
	GH: { DA_dk: { name: 'Ghana' } },
	GI: { DA_dk: { name: 'Gibraltar' } },
	GD: { DA_dk: { name: 'Grenada' } },
	GR: { DA_dk: { name: 'Gr\u00e6kenland' } },
	GL: { DA_dk: { name: 'Gr\u00f8nland' } },
	GP: { DA_dk: { name: 'Guadeloupe' } },
	GU: { DA_dk: { name: 'Guam' } },
	GT: { DA_dk: { name: 'Guatemala' } },
	GG: { DA_dk: { name: 'Guernsey' } },
	GN: { DA_dk: { name: 'Guinea' } },
	GW: { DA_dk: { name: 'Guinea-Bissau' } },
	GY: { DA_dk: { name: 'Guyana' } },
	HT: { DA_dk: { name: 'Haiti' } },
	HM: { DA_dk: { name: 'Heard Island og McDonald Islands' } },
	NL: { DA_dk: { name: 'Holland' } },
	HN: { DA_dk: { name: 'Honduras' } },
	BY: { DA_dk: { name: 'Hviderusland' } },
	IN: { DA_dk: { name: 'Indien' } },
	ID: { DA_dk: { name: 'Indonesien' } },
	IQ: { DA_dk: { name: 'Irak' } },
	IR: { DA_dk: { name: 'Iran' } },
	IE: { DA_dk: { name: 'Irland' } },
	IS: { DA_dk: { name: 'Island' } },
	IM: { DA_dk: { name: 'Isle of Man' } },
	IL: { DA_dk: { name: 'Israel' } },
	IT: { DA_dk: { name: 'Italien' } },
	JM: { DA_dk: { name: 'Jamaica' } },
	JP: { DA_dk: { name: 'Japan' } },
	JE: { DA_dk: { name: 'Jersey' } },
	JO: { DA_dk: { name: 'Jordan' } },
	CX: { DA_dk: { name: 'Jule\u00f8en' } },
	CV: { DA_dk: { name: 'Kap Verde' } },
	KZ: { DA_dk: { name: 'Kasakhstan' } },
	KE: { DA_dk: { name: 'Kenya' } },
	CN: { DA_dk: { name: 'Kina' } },
	KG: { DA_dk: { name: 'Kirgisistan' } },
	KI: { DA_dk: { name: 'Kiribati' } },
	HR: { DA_dk: { name: 'Kroatien' } },
	KW: { DA_dk: { name: 'Kuwait' } },
	LA: { DA_dk: { name: 'Laos' } },
	LS: { DA_dk: { name: 'Lesotho' } },
	LV: { DA_dk: { name: 'Letland' } },
	LB: { DA_dk: { name: 'Libanon' } },
	LR: { DA_dk: { name: 'Liberia' } },
	LY: { DA_dk: { name: 'Libyen' } },
	LI: { DA_dk: { name: 'Liechtenstein' } },
	LT: { DA_dk: { name: 'Litauen' } },
	LU: { DA_dk: { name: 'Luxembourg' } },
	MG: { DA_dk: { name: 'Madagaskar' } },
	MW: { DA_dk: { name: 'Malawi' } },
	MY: { DA_dk: { name: 'Malaysia' } },
	MV: { DA_dk: { name: 'Maldiverne' } },
	ML: { DA_dk: { name: 'Mali' } },
	MT: { DA_dk: { name: 'Malta' } },
	MA: { DA_dk: { name: 'Marokko' } },
	MH: { DA_dk: { name: 'Marshall\u00f8erne' } },
	MQ: { DA_dk: { name: 'Martinique' } },
	MR: { DA_dk: { name: 'Mauretanien' } },
	MU: { DA_dk: { name: 'Mauritius' } },
	YT: { DA_dk: { name: 'Mayotte' } },
	MX: { DA_dk: { name: 'Mexico' } },
	FM: { DA_dk: { name: 'Mikronesien' } },
	MD: { DA_dk: { name: 'Moldova' } },
	MC: { DA_dk: { name: 'Monaco' } },
	MN: { DA_dk: { name: 'Mongoliet' } },
	ME: { DA_dk: { name: 'Montenegro' } },
	MS: { DA_dk: { name: 'Montserrat' } },
	MZ: { DA_dk: { name: 'Mozambique' } },
	MM: { DA_dk: { name: 'Myanmar (Burma)' } },
	NA: { DA_dk: { name: 'Namibia' } },
	NR: { DA_dk: { name: 'Nauru' } },
	NP: { DA_dk: { name: 'Nepal' } },
	NZ: { DA_dk: { name: 'New Zealand' } },
	NI: { DA_dk: { name: 'Nicaragua' } },
	NE: { DA_dk: { name: 'Niger' } },
	NG: { DA_dk: { name: 'Nigeria' } },
	NU: { DA_dk: { name: 'Niue' } },
	KP: { DA_dk: { name: 'Nordkorea' } },
	MK: { DA_dk: { name: 'Nordmakedonien' } },
	MP: { DA_dk: { name: 'Nordmarianerne' } },
	NF: { DA_dk: { name: 'Norfolk Island' } },
	NO: { DA_dk: { name: 'Norge' } },
	NC: { DA_dk: { name: 'Ny Kaledonien' } },
	OM: { DA_dk: { name: 'Oman' } },
	PK: { DA_dk: { name: 'Pakistan' } },
	PW: { DA_dk: { name: 'Palau' } },
	PA: { DA_dk: { name: 'Panama' } },
	PG: { DA_dk: { name: 'Papua Ny Guinea' } },
	PY: { DA_dk: { name: 'Paraguay' } },
	PE: { DA_dk: { name: 'Peru' } },
	PN: { DA_dk: { name: 'Pitcairn' } },
	PL: { DA_dk: { name: 'Polen' } },
	PT: { DA_dk: { name: 'Portugal' } },
	PR: { DA_dk: { name: 'Puerto Rico' } },
	QA: { DA_dk: { name: 'Qatar' } },
	RE: { DA_dk: { name: 'R\u00e9union' } },
	RO: { DA_dk: { name: 'Rum\u00e6nien' } },
	RU: { DA_dk: { name: 'Rusland' } },
	RW: { DA_dk: { name: 'Rwanda' } },
	BL: { DA_dk: { name: 'Saint Barth\u00e9lemy' } },
	KN: { DA_dk: { name: 'Saint Kitts og Nevis' } },
	LC: { DA_dk: { name: 'Saint Lucia' } },
	MF: { DA_dk: { name: 'Saint Martin' } },
	PM: { DA_dk: { name: 'Saint Pierre og Miquelon' } },
	VC: { DA_dk: { name: 'Saint Vincent og Grenadinerne' } },
	SB: { DA_dk: { name: 'Salomon\u00f8erne' } },
	WS: { DA_dk: { name: 'Samoa' } },
	SM: { DA_dk: { name: 'San Marino' } },
	ST: { DA_dk: { name: 'S\u00e3o Tom\u00e9 og Pr\u00edncipe' } },
	HK: { DA_dk: { name: 'SAR Hongkong' } },
	MO: { DA_dk: { name: 'SAR Macao' } },
	SA: { DA_dk: { name: 'Saudi-Arabien' } },
	CH: { DA_dk: { name: 'Schweiz' } },
	SN: { DA_dk: { name: 'Senegal' } },
	RS: { DA_dk: { name: 'Serbien' } },
	SC: { DA_dk: { name: 'Seychellerne' } },
	SL: { DA_dk: { name: 'Sierra Leone' } },
	SG: { DA_dk: { name: 'Singapore' } },
	SX: { DA_dk: { name: 'Sint Maarten' } },
	SK: { DA_dk: { name: 'Slovakiet' } },
	SI: { DA_dk: { name: 'Slovenien' } },
	SO: { DA_dk: { name: 'Somalia' } },
	GS: { DA_dk: { name: 'South Georgia og De Sydlige Sandwich\u00f8er' } },
	ES: { DA_dk: { name: 'Spanien' } },
	LK: { DA_dk: { name: 'Sri Lanka' } },
	SH: { DA_dk: { name: 'St. Helena' } },
	GB: { DA_dk: { name: 'Storbritannien' } },
	SD: { DA_dk: { name: 'Sudan' } },
	SR: { DA_dk: { name: 'Surinam' } },
	SJ: { DA_dk: { name: 'Svalbard og Jan Mayen' } },
	SE: { DA_dk: { name: 'Sverige' } },
	ZA: { DA_dk: { name: 'Sydafrika' } },
	KR: { DA_dk: { name: 'Sydkorea' } },
	SS: { DA_dk: { name: 'Sydsudan' } },
	SY: { DA_dk: { name: 'Syrien' } },
	TJ: { DA_dk: { name: 'Tadsjikistan' } },
	TW: { DA_dk: { name: 'Taiwan' } },
	TZ: { DA_dk: { name: 'Tanzania' } },
	TD: { DA_dk: { name: 'Tchad' } },
	TH: { DA_dk: { name: 'Thailand' } },
	TL: { DA_dk: { name: 'Timor-Leste' } },
	CZ: { DA_dk: { name: 'Tjekkiet' } },
	TG: { DA_dk: { name: 'Togo' } },
	TK: { DA_dk: { name: 'Tokelau' } },
	TO: { DA_dk: { name: 'Tonga' } },
	TT: { DA_dk: { name: 'Trinidad og Tobago' } },
	TN: { DA_dk: { name: 'Tunesien' } },
	TM: { DA_dk: { name: 'Turkmenistan' } },
	TC: { DA_dk: { name: 'Turks- og Caicos\u00f8erne' } },
	TV: { DA_dk: { name: 'Tuvalu' } },
	TR: { DA_dk: { name: 'Tyrkiet' } },
	DE: { DA_dk: { name: 'Tyskland' } },
	UG: { DA_dk: { name: 'Uganda' } },
	UA: { DA_dk: { name: 'Ukraine' } },
	HU: { DA_dk: { name: 'Ungarn' } },
	UY: { DA_dk: { name: 'Uruguay' } },
	US: { DA_dk: { name: 'USA' } },
	UZ: { DA_dk: { name: 'Usbekistan' } },
	VU: { DA_dk: { name: 'Vanuatu' } },
	VA: { DA_dk: { name: 'Vatikanstaten' } },
	VE: { DA_dk: { name: 'Venezuela' } },
	EH: { DA_dk: { name: 'Vestsahara' } },
	VN: { DA_dk: { name: 'Vietnam' } },
	WF: { DA_dk: { name: 'Wallis og Futuna' } },
	YE: { DA_dk: { name: 'Yemen' } },
	ZM: { DA_dk: { name: 'Zambia' } },
	ZW: { DA_dk: { name: 'Zimbabwe' } },
	GQ: { DA_dk: { name: '\u00c6kvatorialguinea' } },
	AT: { DA_dk: { name: '\u00d8strig' } },
	AX: { DA_dk: { name: '\u00c5land' } },
};

export default countries;
