import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { navbarCloseMobile } from '../../../../../../store/fuse/navbarSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';

function DrawerMobile(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const dispatch = useDispatch();
	const navbar = useSelector(({ fuse }) => fuse.navbar);

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		navbarMobile: {
			display: 'flex',
			overflow: 'hidden',
			flexDirection: 'column',
			width: 280,
			minWidth: 280,
			height: '100%',
			zIndex: 4,
			transition: _theme.transitions.create(['width', 'min-width'], {
				easing: _theme.transitions.easing.sharp,
				duration: _theme.transitions.duration.shorter
			}),
			boxShadow: _theme.shadows[3],
			background: `${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}`
		}
	}));

	const classes = useStyles(props);

	return (
		<MuiDrawer
			anchor={contentElementProps.anchor}
			variant={contentElementProps.variant}
			open={navbar.mobileOpen}
			classes={{
				paper: classes.navbarMobile
			}}
			onClose={ev => dispatch(navbarCloseMobile())}
			ModalProps={{
				keepMounted: true // Better open performance on mobile.
			}}
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiDrawer>
	);
}

export default DrawerMobile;
