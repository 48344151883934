import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTerms } from '../../../../../../store/shared/frontendSlice';
// eslint-disable-next-line import/no-cycle
import TypesSettings from '../../../TypesConfig';
import { pageListener } from '../../../ContentFirestore';
// eslint-disable-next-line import/no-cycle
import CmsContentElement from '../../../cmsContent/CmsContentElement';

function CartTermsDialog({ open, handleClose }) {
	const terms = useSelector(selectTerms);
	const [heading, setHeading] = useState('');
	const [realTerms, setRealTerms] = useState([]);
	const [termsPage, setTermsPage] = useState({});
	const [embeddedContentElementId, setEmbeddedContentElementId] = useState({});

	useEffect(() => {
		const termsPageListenerUnsubFunc = pageListener('/handelsbetingelser', o => {
			setTermsPage(o);
			if (o.content) {
				Object.entries(o.content).map(([key, value]) => {
					if (value.props.embeddedId && value.props.embeddedId === 'cartTermsDialog') {
						setEmbeddedContentElementId(key);
					}
					return true;
				});
			}
		});
		return () => termsPageListenerUnsubFunc();
	}, []);

	useEffect(() => {
		if (terms.length > 0) {
			const nTerms = [...terms];
			nTerms.splice(0, 1);
			setRealTerms(nTerms);
			setHeading(terms[0]);
		}
	}, [terms]);

	return (
		<Dialog open={open} onClose={handleClose} scroll="paper">
			{termsPage && !termsPage.deleted && termsPage.content && embeddedContentElementId ? (
				<DialogContent dividers>
					<CmsContentElement contentId={embeddedContentElementId} content={termsPage.content} />
				</DialogContent>
			) : (
				<>
					<DialogTitle>{heading.data}</DialogTitle>
					<DialogContent dividers>
						<DialogContentText tabIndex={-1}>
							{realTerms &&
								realTerms.map(c => {
									if (!TypesSettings[c.type]) {
										throw new Error(
											`Type '${c.type}' does not exist in apps/content/TypesConfig.js`
										);
									}
									if (c.data instanceof Object) {
										const nData = { ...c.data };
										nData.small = true;
										return <div key={c.id}>{TypesSettings[c.type](nData)}</div>;
									}
									return <div key={c.id}>{TypesSettings[c.type](c.data)}</div>;
								})}
						</DialogContentText>
					</DialogContent>
				</>
			)}
			<DialogActions>
				<Button onClick={handleClose}>Luk</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CartTermsDialog;
