import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
	forgotPasswordWithFireBase,
	submitLoginWithFireBase,
	forgotPasswordWithFunction,
	submitLoginWithToken
} from '../../../../../../auth/store/loginSlice';
import LoadingButton from '../../../../../../ui-components/LoadingButton';
import { tagManagerLogin } from '../../../../../../services/googleTagManagerService/googleTagManagerService';
import { getParameterByName } from '../../../../../../utilities';

const useStyles = makeStyles(theme => ({
	loginButton: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.primary.contrastText}!important`
	},
	createUserButton: {
		backgroundColor: `${theme.palette.primary.contrastText}!important`,
		color: `${theme.palette.primary.main}!important`
	}
}));

function LoginPage(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const login = useSelector(({ auth }) => auth.login);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [alert, setAlert] = useState(null);
	const formRef = useRef(null);
	const user = useSelector(({ auth }) => auth.user);
	const { t } = useTranslation();
	const classes = useStyles(props);
	const flags = useFlags();
	const { control, handleSubmit, watch, formState, setError } = useForm({
		mode: 'onChange',
		defaultValues: {
			username: '',
			password: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid } = formState;

	useEffect(() => {
		if (user && user.isAnonymous === false) {
			navigate('/account');
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (login.error && (login.error.username || login.error.password)) {
			if (login.error.username) {
				setError('username', { type: 'custom', message: login.error.username });
			}
			if (login.error.password) {
				setError('password', { type: 'custom', message: login.error.password });
			}
		}
	}, [login.error]);

	useEffect(() => {
		if (login.forgotPasswordError && login.forgotPasswordError.username) {
			if (login.forgotPasswordError.username) {
				setError('username', { type: 'custom', message: login.forgotPasswordError.username });
			}
		}
	}, [login.forgotPasswordError]);

	useEffect(() => {
		if (login.forgotPasswordSuccess) {
			setAlert(
				<Alert severity="success">
					<AlertTitle>{t('EMAIL_SENT')}</AlertTitle>
					{t('IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL')}
				</Alert>
			);
		}
	}, [login.forgotPasswordSuccess, t]);

	useEffect(() => {
		if (login.success) {
			setAlert(
				<Alert severity="success">
					<AlertTitle>{t('PLEASE_WAIT')}</AlertTitle>
					{t('CORRECT_PASSWORD')}
				</Alert>
			);
		}
	}, [login.success, t]);

	const token = React.useMemo(() => getParameterByName('token', location.search), [location]);
	useEffect(() => {
		if (location && location.search && token) {
			dispatch(submitLoginWithToken({ token }));
		}
	}, [location, token, dispatch]);

	function onSubmit() {
		if (forgotPassword) {
			dispatch(forgotPasswordWithFunction(form));
		} else {
			tagManagerLogin('account_login');
			dispatch(submitLoginWithFireBase(form));
		}
	}

	const handleOnClickOnCreateUser = () => {
		navigate('/register');
	};

	return (
		<div className={clsx('flex flex-col flex-auto flex-shrink-0 items-center justify-center md:p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimateAdapter animation="transition.expandIn" className={'flex justify-center w-full'}>
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<Typography variant="h6" className="mt-16 mb-32">
								{forgotPassword ? t('FORGOT_PASSWORD') : t('LOGIN')}
							</Typography>

							{alert || (
								<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center w-full">
									<TextFieldFormsyAdapter
										control={control}
										className="mb-16"
										label="Email"
										autoFocus
										type="email"
										name="username"
										variant="outlined"
										required
										fullWidth
										enableTrim
									/>

									{!forgotPassword && (
										<TextFieldFormsyAdapter
											control={control}
											className="mb-16"
											label={t('PASSWORD')}
											type="password"
											name="password"
											variant="outlined"
											required
											fullWidth
										/>
									)}

									<div className="flex items-center justify-between">
										<Button
											className="font-medium"
											onClick={() => {
												setForgotPassword(oldState => !oldState);
												setAlert(null);
											}}
										>
											{forgotPassword ? t('LOGIN_INSTEAD') : t('FORGOT_PASSWORD_QUESTIONMARK')}
										</Button>
										{forgotPassword && alert && (
											<Button
												className="font-medium"
												onClick={() => {
													setForgotPassword(oldState => !oldState);
													setAlert(null);
												}}
											>
												{t('LOGIN_INSTEAD')}
											</Button>
										)}
									</div>

									<LoadingButton
										fullWidth
										variant="contained"
										color="primary"
										classes={{ wrapper: 'w-224 mx-auto mt-16', button: 'w-full' }}
										aria-label="LOG IN"
										disabled={!isFormValid}
										type="submit"
										loading={login.forgotPasswordLoading}
										disableLoadingOnClick
									>
										{forgotPassword ? 'SEND EMAIL' : t('LOGIN')}
									</LoadingButton>
								</form>
							)}
							{flags.loginCreateUserButton && (
								<div className="w-224 mx-auto mt-16">
									<Button
										fullWidth
										variant="contained"
										classes={{ root: classes.createUserButton }}
										aria-label="CREATE USER"
										type="button"
										onClick={handleOnClickOnCreateUser}
									>
										{t('CREATE_USER')}
									</Button>
								</div>
							)}

							{!flags.loginCreateUserButton && (
								<div className="flex flex-col items-center justify-center pt-32 pb-24">
									<span className="font-medium">{t('HAVE_NO_USER')}</span>
									<Link className="font-medium" to="/register">
										{t('CREATE_USER')}
									</Link>
								</div>
							)}
						</CardContent>
					</Card>
				</FuseAnimateAdapter>
			</div>
		</div>
	);
}

export default LoginPage;
