import FuseDialog from '@ameroservices-platform/attraction-frontend/fuse/core/FuseDialog';
import FuseMessage from '@ameroservices-platform/attraction-frontend/fuse/core/FuseMessage';
import FuseSuspense from '@ameroservices-platform/attraction-frontend/fuse/core/FuseSuspense';
import AppContext from '@ameroservices-platform/attraction-frontend/app/AppContext';
import clsx from 'clsx';
import { memo, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import FooterLayout2 from './components/FooterLayout2';
import LeftSideLayout2 from './components/LeftSideLayout2';
import NavbarWrapperLayout2 from './components/NavbarWrapperLayout2';
import RightSideLayout2 from './components/RightSideLayout2';
import {
	selectDefaultLanguage,
	selectFooter,
	selectHeader
} from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import CmsContentElement from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/CmsContentElement';
import ToolbarWrapperLayout2 from '@ameroservices-platform/attraction-frontend/app/fuse-layouts/layout2/components/ToolbarWrapperLayout2';
import { Hidden } from '@mui/material';
import CmsLayoutElement from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsLayoutElement/CmsLayoutElement';
import layoutElementTypes from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsLayoutElement/layoutElementTypes';
import { useTranslation } from 'react-i18next';
import allLanguages from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/allLanguages';
import ToolbarLayout2 from '@ameroservices-platform/attraction-frontend/app/fuse-layouts/layout2/components/toolbars/ToolbarLayout2';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
// import useCookieAccept from '@ameroservices-platform/shared/ui-components/useCookieAccept';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		'&.boxed': {
			maxWidth: 1280,
			margin: '0 auto',
			boxShadow: theme.shadows[3]
		},
		'&.container': {
			'& .container': {
				maxWidth: 1120,
				width: '100%',
				margin: '0 auto'
			},
			'& .navigation': {}
		}
	},
	content: {
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 4
	},
	toolbarWrapper: {
		display: 'flex',
		position: 'relative',
		zIndex: 5
	},
	toolbar: {
		display: 'flex',
		flex: '1 0 auto'
	},
	footerWrapper: {
		position: 'relative',
		zIndex: 5
	},
	footer: {
		display: 'flex',
		flex: '1 0 auto'
	}
}));

function Layout2(props) {
	const flags = useFlags();

	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);

	const classes = useStyles(props);
	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const header = useSelector(selectHeader);
	const footer = useSelector(selectFooter);
	const { pathname } = useLocation();
	const scrollRef = useRef();

	const { i18n } = useTranslation();
	const defaultLanguage = useSelector(selectDefaultLanguage);
	// const cookieAccept = useCookieAccept();

	useEffect(() => {
		// if (
		// 	cookieAccept.includes('preferences') ||
		// 	cookieAccept.includes('functionality') ||
		// 	cookieAccept.includes('unclassified')
		// ) {
		const currentLanguageLocalStorage = localStorage.getItem('language')
			? JSON.parse(localStorage.getItem('language'))
			: { isoCode: 'da' };
		if (currentLanguageLocalStorage) {
			i18n.changeLanguage(currentLanguageLocalStorage.isoCode).catch();
		} else if (defaultLanguage) {
			localStorage.setItem('language', JSON.stringify(defaultLanguage));
			i18n.changeLanguage(defaultLanguage.isoCode).catch();
		} else {
			localStorage.setItem('language', JSON.stringify(allLanguages.da));
			i18n.changeLanguage(allLanguages.da.isoCode).catch();
		}
		// }
	}, [defaultLanguage, i18n]);

	useEffect(() => {
		scrollRef.current.scrollTop = 0;
	}, [pathname]);

	return (
		<div id="fuse-layout" className={clsx(classes.root, config.mode)}>
			{config.leftSidePanel.display && <LeftSideLayout2 />}

			<main id="fuse-main" className="flex flex-1 flex-col overflow-hidden relative">
				{config.toolbar.display && config.toolbar.position === 'above' && (
					<>
						{flags.multiLanguageCmsFrontend ? (
							<CmsLayoutElement layoutElementType={layoutElementTypes.HEADER} layoutElementOld={header} />
						) : (
							<>
								{header && header.content ? (
									<CmsContentElement contentId="ROOT" content={header.content} />
								) : (
									<ToolbarLayout2 />
								)}
							</>
						)}
					</>
				)}
				{config.navbar.display && (
					<NavbarWrapperLayout2 /> // className={clsx(config.navbar.style === 'fixed' && 'sticky top-0 z-50')}
				)}

				{config.toolbar.display && config.toolbar.position === 'below' && (
					<Hidden lgUp>
						<>
							{flags.multiLanguageCmsFrontend ? (
								<CmsLayoutElement
									layoutElementType={layoutElementTypes.HEADER}
									layoutElementOld={header}
								/>
							) : (
								<>
									{header && header.content ? (
										<CmsContentElement contentId="ROOT" content={header.content} />
									) : (
										<ToolbarWrapperLayout2 />
									)}
								</>
							)}
						</>
					</Hidden>
				)}
				<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
					<FuseDialog />
					<div className="flex flex-auto flex-col relative min-h-full overflow-x-hidden" ref={scrollRef}>
						<FuseSuspense>
							<div className="max-w-3xl w-full flex-grow mx-auto">{useRoutes(routes)}</div>
						</FuseSuspense>

						{props.children}

						{config.footer.display && config.footer.style === 'static' && (
							<>
								{flags.multiLanguageCmsFrontend ? (
									<CmsLayoutElement
										layoutElementType={layoutElementTypes.FOOTER}
										layoutElementOld={footer}
									/>
								) : (
									<>
										{footer && footer.content ? (
											<CmsContentElement contentId="ROOT" content={footer.content} />
										) : (
											<FooterLayout2 />
										)}
									</>
								)}
							</>
						)}
					</div>
				</FuseScrollbars>
				{config.footer.display && config.footer.style === 'fixed' && (
					<>
						{flags.multiLanguageCmsFrontend ? (
							<CmsLayoutElement layoutElementType={layoutElementTypes.FOOTER} layoutElementOld={footer} />
						) : (
							<>
								{footer && footer.content ? (
									<CmsContentElement contentId="ROOT" content={footer.content} />
								) : (
									<FooterLayout2 />
								)}
							</>
						)}
					</>
				)}
			</main>

			{config.rightSidePanel.display && <RightSideLayout2 />}
			<FuseMessage />
		</div>
	);
}

export default memo(Layout2);
