import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function ReadMore(props) {
	const { contentElementProps } = props;
	const { contentElementLinkedNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const { t } = useTranslation();

	const useStyles = makeStyles(() => ({
		gradient: {
			background: `linear-gradient(0deg, ${getColor(
				contentElementProps.linearGradientColor,
				contentElementProps.customColor,
				mergedTheme
			)} 2%, ${getColor(
				contentElementProps.linearGradientColor,
				contentElementProps.customColor,
				mergedTheme
			)} 5%, rgba(255,255,255,0) 30%)`
		}
	}));

	const classes = useStyles();
	const [showMore, setShowMore] = useState(false);

	return (
		<div
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{showMore ? (
				<>
					{contentElementLinkedNodes &&
						contentElementLinkedNodes.textFull &&
						props.getCmsContentElement(contentElementLinkedNodes.textFull)}
				</>
			) : (
				<>
					<div className="relative">
						{contentElementLinkedNodes &&
							contentElementLinkedNodes.textHalf &&
							props.getCmsContentElement(contentElementLinkedNodes.textHalf)}
						<div className={clsx(classes.gradient, 'h-full w-full absolute bottom-0')} />
					</div>
					<Button className="normal-case px-0" onClick={() => setShowMore(true)}>
						{t('READ_MORE')}
					</Button>
				</>
			)}
		</div>
	);

	/* return (
		<MuiPaper
			elevation={contentElementProps.elevation}
			square={contentElementProps.square}
			variant={contentElementProps.variant}
			className={`${contentElementProps.tailwindClasses} ${margin(
				contentElementProps.ml,
				contentElementProps.mt,
				contentElementProps.mr,
				contentElementProps.mb
			)} ${padding(
				contentElementProps.pl,
				contentElementProps.pt,
				contentElementProps.pr,
				contentElementProps.pb
			)}`}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiPaper>
	); */
}

export default ReadMore;
