import React from 'react';
import MuiIcon from '@mui/material/Icon';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function Icon(props) {
	const { contentElementProps } = props;
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<div
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			style={{
				color: `${getColor(contentElementProps.color, contentElementProps.customColor, mergedTheme)}`
			}}
		>
			<MuiIcon>{contentElementProps.icon}</MuiIcon>
		</div>
	);
}

export default Icon;
