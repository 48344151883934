import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDefaultPageUrl } from '../../store/shared/frontendSlice';

function RedirectPage() {
	const navigate = useNavigate();
	const defaultPage = useSelector(selectDefaultPageUrl);

	useEffect(() => {
		if (defaultPage) {
			navigate(defaultPage);
		}
	}, [defaultPage, navigate]);

	return <Typography>Omdirigerer, vent venligst...</Typography>;
}

export default RedirectPage;
