import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Paper, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Remove, Add } from '@mui/icons-material';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Autocomplete } from '@mui/material';
import {
	eventGroupListener,
	groupSummaryListener,
	productGroupsListener,
	productsListener
} from './EventGroupDateTimeSelectorFirestore';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 },

	calendarPicker: {
		width: '100%',
		overflow: 'hidden',
		// backgroundColor: theme.palette.background,
		// color: theme.palette.text.primary,

		// All days as a group
		// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
		'& .react-calendar__navigation__arrow': { width: '70px', height: '40px' },

		// Weekdays - man, tir, ons...
		'& .react-calendar__month-view__weekdays__weekday abbr': { textDecoration: 'none' },

		// All days individual
		'& .react-calendar__month-view__days__day': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			padding: '10px',
			outline: '2px solid #FDFBF7'
		},

		// Active button (selected date)
		'& .react-calendar__tile.react-calendar__tile--active': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},

		// Disabled buttons (previous dates)
		'& button:disabled.react-calendar__month-view__days__day': {
			opacity: '0.3'
		},

		// Todays date
		'& .react-calendar__tile--now': {}
	}
}));

function overdueEvent(now, end) {
	return (
		now.getFullYear() === end.getFullYear() &&
		now.getMonth() === end.getMonth() &&
		now.getDate() === end.getDate() &&
		end <= now
	);
}

function EventGroupDateTimeSelectorComponentCMS({ location, data, queries }) {
	const flags = useFlags();
	const now = moment().toDate();
	const [openBox1, setOpenBox1] = useState(true);
	const [openBox2, setOpenBox2] = useState(false);
	const [openBox3, setOpenBox3] = useState(false);
	const [openSlotCat, setOpenSlotCat] = useState(null);
	const [slotCategories, setSlotCategories] = useState([]);
	const [ticketCount, setTicketCount] = useState(0);
	const [eventGroup, setEventGroup] = useState(null);
	const [groupSummary, setGroupSummary] = useState(null);
	const [year, setYear] = useState(now.getFullYear());
	const [month, setMonth] = useState(now.getMonth() + 1);
	const [productGroups, setProductGroups] = useState({});
	const [products, setProducts] = useState([]);
	const [showingProducts, setShowingProducts] = useState([]);
	const [date, setDate] = useState(null);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [slots, setSlots] = useState({});
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [gridAttr, setGridAttr] = useState({});
	const [disclaimers, setDisclaimers] = useState({});
	const [selectedGroupBy, setSelectedGroupBy] = useState({});

	const forceShowTime = data.forceShowTime === false ? data.forceShowTime : true;
	const [calculateShowTime, setCalculateShowTime] = useState(false);

	const classes = useStyles();
	const navigate = useNavigate();
	const defaultTimesplitMargin = data.minimumValueForSplitTime ? data.minimumValueForSplitTime : 2;
	const groupByRefs = useRef();

	// const googleTagManager = useSelector(selectGoogleTagManager);
	//
	// useEffect(() => {
	// 	if (googleTagManager && products.length > 0) {
	// 		tagManagerViewItem(products);
	// 	}
	// }, [googleTagManager, products]);

	useEffect(() => {
		if (data && date && groupSummary) {
			const todaysGroupSummary = groupSummary.dates[date.getDate()];
			if (todaysGroupSummary && Object.values(todaysGroupSummary.times).length <= defaultTimesplitMargin) {
				setSlotCategories([{ title: 'Heldag', from: moment('00:00', 'HH:mm'), to: moment('23:59', 'HH:mm') }]);
			} else {
				setSlotCategories([
					{ title: 'Nat', from: moment('00:00', 'HH:mm'), to: moment('05:59', 'HH:mm') },
					{ title: 'Morgen', from: moment('06:00', 'HH:mm'), to: moment('08:59', 'HH:mm') },
					{ title: 'Formiddag', from: moment('09:00', 'HH:mm'), to: moment('11:59', 'HH:mm') },
					{ title: 'Eftermiddag', from: moment('12:00', 'HH:mm'), to: moment('17:59', 'HH:mm') },
					{ title: 'Aften', from: moment('18:00', 'HH:mm'), to: moment('23:59', 'HH:mm') }
				]);
			}
		}
	}, [data, groupSummary, date, defaultTimesplitMargin]);

	useEffect(() => {
		let attr = {
			xs: 12,
			sm: 12,
			md: 4,
			lg: 4,
			xl: 4
		};
		if (forceShowTime === false || calculateShowTime === false) {
			attr = {
				xs: 12,
				sm: 12,
				md: 6,
				lg: 6,
				xl: 6
			};
		}
		setGridAttr(attr);
	}, [forceShowTime, calculateShowTime]);

	// This useEffect will true to auto select

	useEffect(() => {
		if (forceShowTime === false && groupSummary) {
			const dateForUse = date || new Date();
			const timeslotsForToday = groupSummary.dates[dateForUse.getDate()]
				? groupSummary.dates[dateForUse.getDate()].times
				: findDefaultGroupSummaryDates(groupSummary.dates);

			const isAvaiableInTimeslot = Object.values(timeslotsForToday).map(value => {
				return value.available >= ticketCount;
			});

			const showPickTime = isAvaiableInTimeslot.some(slot => slot === true);
			if (Object.keys(timeslotsForToday).length > 1 && showPickTime) {
				setCalculateShowTime(true);
				setSelectedEvent(null);
			} else {
				setCalculateShowTime(false);
				if (timeslotsForToday && date) {
					const [event] = Object.values(timeslotsForToday);
					const eventStartTime = event.start.toDate();
					const isSameDay = moment(date).isSame(eventStartTime, 'day');
					if (isSameDay) {
						setSelectedEvent(event.eventUid);
					} else {
						setSelectedEvent(null);
					}
				}
			}
		} else if (date) {
			setSelectedEvent(null);
		}
	}, [forceShowTime, groupSummary, date, ticketCount]);

	useEffect(() => {
		if (date && groupSummary) {
			const s = {};
			let first = null;
			slotCategories.forEach(slotCat => {
				s[slotCat.title] = _.orderBy(
					groupSummary.dates[date.getDate()]
						? Object.values(groupSummary.dates[date.getDate()].times).map(time => {
								const st = time.start.toDate();
								return {
									...time,
									start: st,
									end: time.end.toDate(),
									startFilter: moment(`${st.getHours()}:${st.getMinutes()}`, 'HH:mm'),
									showTextOnPercentage: eventGroup.showTextOnPercentage,
									textOnTicketAmount: eventGroup.textOnTicketAmount
								};
						  })
						: [],
					['start']
				).filter(
					slot =>
						slot.startFilter.isBetween(slotCat.from, slotCat.to) ||
						slot.startFilter.isSame(slotCat.from) ||
						slot.startFilter.isSame(slotCat.to)
				);
				if (s[slotCat.title].length > 0 && !first) {
					first = slotCat.title;
				}
			});
			setSlots(s);
			setOpenSlotCat(first);
		}
	}, [groupSummary, date, slotCategories, eventGroup]);

	useEffect(() => {
		if (data && data.eventGroupUid) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(data.eventGroupUid, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}
		if (queries && queries.e) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(queries.e, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [data, queries]);

	useEffect(() => {
		if (eventGroup && eventGroup.ticketTypes.length > 0 && data !== undefined && data !== null) {
			const productsListenerUnsubFunc = productsListener(
				eventGroup.ticketTypes,
				data.productType,
				productList => {
					const copyOfProducts = productList;
					const productsWithDisclaimer = productList.filter(filterForDisclaimers);
					const indexes = {};
					let index = 0;
					productsWithDisclaimer.forEach((product, i) => {
						if (
							product.disclaimer &&
							(indexes[product.disclaimer] === undefined || indexes[product.disclaimer] === null)
						) {
							indexes[product.disclaimer] = index;
							index += 1;
						}
					});
					productsWithDisclaimer.forEach((product, i) => {
						if (product.disclaimer) {
							product.disclaimerIndex = indexes[product.disclaimer];
						}
					});
					setDisclaimers(indexes);
					setProducts(copyOfProducts);
				}
			);
			const productGroupsListenerUnsubFuncs = productGroupsListener(eventGroup.ticketTypes, (id, item) => {
				setProductGroups(oldState => {
					return { ...oldState, [id]: item };
				});
			});
			return () => {
				productsListenerUnsubFunc();
				productGroupsListenerUnsubFuncs.forEach(f => f());
			};
		}

		return () => {};
	}, [eventGroup, data]);

	useEffect(() => {
		if (eventGroup && year && month) {
			const productsListenerUnsubFunc = groupSummaryListener(eventGroup.id, month, year, o => setGroupSummary(o));
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, year, month]);

	useEffect(() => {
		setProductsWithAmount(oldState => {
			const newState = {};
			products.forEach(product => {
				if (oldState[product.id] !== null && oldState[product.id] !== undefined) {
					newState[product.id] = oldState[product.id];
				} else {
					newState[product.id] = 0;
				}
			});
			return newState;
		});
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		if (ticketCount > 0) {
			setOpenBox2(true);
		}
		if (ticketCount === 0 && date) {
			setDate(null);
			setSelectedEvent(null);
		}
	}, [ticketCount, date]);

	useEffect(() => {
		const groupByProductGroups = Object.values(productGroups).filter(pg => {
			return pg.groupBy && pg.groupBy.name;
		});
		if (groupByProductGroups.length > 0) {
			const newProducts = products.filter(p => !groupByProductGroups.some(pg => pg.id === p.ticketTypeUid));
			const productsWithGroupBy = products.filter(p =>
				groupByProductGroups.some(pg => pg.id === p.ticketTypeUid)
			);
			const groupBy = productsWithGroupBy.reduce((accProducts, curProduct) => {
				if (!accProducts[curProduct.ticketTypeUid]) {
					accProducts[curProduct.ticketTypeUid] = [];
				}
				accProducts[curProduct.ticketTypeUid].push(curProduct);
				return accProducts;
			}, {});
			Object.entries(groupBy).forEach(([key, value]) => {
				const prices = value.reduce((acc, cur) => {
					const p = cur.prices.find(pr => pr.type === 'Sale');
					if (p) {
						acc.push(p.price);
					} else {
						acc.push(0);
					}
					return acc;
				}, []);
				prices.sort((a, b) => a - b);
				const lowestPrice = prices[0];
				const highestPrice = prices[prices.length - 1];
				const options = [...value].sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
				newProducts.push({
					id: key,
					name: productGroups[key].groupBy.name,
					lowestPrice,
					highestPrice,
					samePrice: lowestPrice === highestPrice,
					type: 'productGroup',
					items: value.reduce((acc, cur) => {
						acc[cur.id] = cur;
						return acc;
					}, {}),
					options,
					order: 9999
				});
			});
			newProducts.sort((p1, p2) => p1.order - p2.order);
			setShowingProducts(newProducts);
		} else {
			setShowingProducts(products);
		}
	}, [products, productGroups]);

	function getTimeOnEvent(start, end) {
		const s = moment(start);
		const e = moment(end);
		if (s.minutes() !== 0 || e.minutes() !== 0) {
			return `${s.format('HH:mm')} - ${e.format('HH:mm')}`;
		}
		return `${s.format('HH')} - ${e.format('HH')}`;
	}

	function goToCheckOut() {
		navigate('/cart', {
			state: {
				event: selectedEvent,
				products: productsWithAmount
			}
		});
	}

	function findDefaultGroupSummaryDates(groupSummaryDates) {
		const dates = Object.keys(groupSummaryDates);
		if (dates.length > 0) {
			return groupSummaryDates[dates[0]].times;
		}
		return {};
	}

	function isAllowedToBuy() {
		return !selectedEvent || Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	function showAvailable(max, amount) {
		const hideAbove = max / (100 / eventGroup.hideTicketAmountAbovePercent);
		return hideAbove >= amount;
	}

	return (
		<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto')}>
			<Grid container spacing={0}>
				<Paper elevation={3} className="px-4 py-28 md:px-28 w-full">
					<div className="flex flex-col md:flex-row w-full ">
						<Grid item {...gridAttr} className="flex flex-col px-10 pt-0">
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								1
							</div>

							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvor mange kommer I?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox1(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg antal <Icon>{openBox1 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox1 && (
								<>
									{showingProducts.map(product =>
										product.type === 'productGroup' ? (
											<div className="w-full" key={product.id}>
												<div className="flex justify-between items-center w-full">
													<div className="flex justify-between w-full items-center h-56">
														<Typography
															className="font-bold whitespace-normal cursor-default"
															onMouseOver={() => {
																if (
																	product.disclaimerIndex !== null &&
																	product.disclaimerIndex !== undefined
																) {
																	setHighlightedDisclaimer(product.disclaimerIndex);
																}
															}}
															onMouseLeave={() => {
																if (
																	product.disclaimerIndex !== null &&
																	product.disclaimerIndex !== undefined
																) {
																	setHighlightedDisclaimer(null);
																}
															}}
														>
															{/* className="font-bold text-14 md:text-base"> */}
															{product.name}
															&nbsp;
															{product.disclaimer
																? '*'.repeat(product.disclaimerIndex + 1)
																: ''}
														</Typography>
														<Typography className="px-8 font-bold flex items-center">
															{selectedGroupBy[product.id] &&
															product.items[selectedGroupBy[product.id].id]
																? `${
																		product.items[
																			selectedGroupBy[product.id].id
																		].prices.find(f => f.type === 'Sale').price /
																		100
																  },-`
																: product.samePrice
																? `${product.lowestPrice / 100},-`
																: `${product.lowestPrice / 100} - ${
																		product.highestPrice / 100
																  },-`}
														</Typography>
													</div>
													<div className="flex ButtonDiv h-56 items-center">
														{/* eslint-disable-next-line */}
														<div
															className="flex items-center w-full"
															onClick={() => {
																if (
																	(!selectedGroupBy[product.id] ||
																		!selectedGroupBy[product.id].id) &&
																	groupByRefs.current[product.id]
																) {
																	groupByRefs.current[product.id].focus();
																}
															}}
														>
															<Button
																variant="outlined"
																disabled={
																	!selectedGroupBy[product.id] ||
																	!selectedGroupBy[product.id].id
																}
																onClick={() => {
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		if (p[selectedGroupBy[product.id].id] !== 0) {
																			p[selectedGroupBy[product.id].id] -= 1;
																		}
																		return p;
																	});
																}}
																className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
															>
																<Remove />
															</Button>
															<div className="flex items-center text-14 font-bold px-10">
																{selectedGroupBy[product.id] &&
																productsWithAmount[selectedGroupBy[product.id].id]
																	? productsWithAmount[selectedGroupBy[product.id].id]
																	: '0'}
															</div>

															<Tooltip
																title={
																	flags.maxAmountOfProductPerProductGroupInCart &&
																	selectedGroupBy[product.id] &&
																	selectedGroupBy[product.id].ticketTypeUid &&
																	productGroups[
																		selectedGroupBy[product.id].ticketTypeUid
																	] &&
																	productGroups[
																		selectedGroupBy[product.id].ticketTypeUid
																	].maxTickets &&
																	productGroups[
																		selectedGroupBy[product.id].ticketTypeUid
																	].maxTickets.amount &&
																	productsWithAmount[product.id] >=
																		productGroups[
																			selectedGroupBy[product.id].ticketTypeUid
																		].maxTickets.amount
																		? `Du kan maksimalt købe ${
																				productGroups[
																					selectedGroupBy[product.id]
																						.ticketTypeUid
																				].maxTickets.amount
																		  } af dette produkt`
																		: ''
																}
															>
																<Button
																	variant="outlined"
																	disabled={
																		!selectedGroupBy[product.id] ||
																		!selectedGroupBy[product.id].id
																	}
																	onClick={() => {
																		if (
																			flags.maxAmountOfProductPerProductGroupInCart
																		) {
																			const { maxTickets } =
																				productGroups[
																					selectedGroupBy[product.id]
																						.ticketTypeUid
																				];
																			setProductsWithAmount(oldState => {
																				const p = { ...oldState };
																				if (
																					maxTickets &&
																					maxTickets.amount &&
																					maxTickets.amount <=
																						p[
																							selectedGroupBy[product.id]
																								.id
																						]
																				) {
																					p[selectedGroupBy[product.id].id] =
																						maxTickets.amount;
																				} else {
																					p[
																						selectedGroupBy[product.id].id
																					] += 1;
																				}
																				return p;
																			});
																		} else {
																			setProductsWithAmount(oldState => {
																				const p = { ...oldState };
																				p[selectedGroupBy[product.id].id] += 1;
																				return p;
																			});
																		}
																	}}
																	className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
																>
																	<Add />
																</Button>
															</Tooltip>
														</div>
													</div>
												</div>
												<div className="mr-92 pr-8 -mt-8 mb-10">
													<Autocomplete
														className="w-full"
														fullWidth
														id="groupBySelected"
														name="groupBySelected"
														options={product.options}
														value={selectedGroupBy[product.id] || null}
														onChange={async (event, newValue) => {
															if (
																selectedGroupBy[product.id] &&
																selectedGroupBy[product.id].id
															) {
																setProductsWithAmount(oldState => ({
																	...oldState,
																	[selectedGroupBy[product.id].id]: 0
																}));
															}
															setSelectedGroupBy(oldState => ({
																...oldState,
																[product.id]: newValue
															}));
														}}
														getOptionLabel={option => {
															return option && option.name ? option.name : '-';
														}}
														size="small"
														renderInput={params => (
															<TextField
																{...params}
																label="Vælg fra listen"
																variant="outlined"
																inputRef={r => {
																	if (r) {
																		groupByRefs.current = {
																			...groupByRefs.current,
																			[product.id]: r
																		};
																	}
																}}
															/>
														)}
														openOnFocus
														required
													/>
												</div>
											</div>
										) : (
											<div className="flex justify-between items-center" key={product.id}>
												<div className="flex justify-between w-full">
													<Typography
														className="font-bold whitespace-normal cursor-default"
														onMouseOver={() => {
															if (
																product.disclaimerIndex !== null &&
																product.disclaimerIndex !== undefined
															) {
																setHighlightedDisclaimer(product.disclaimerIndex);
															}
														}}
														onMouseLeave={() => {
															if (
																product.disclaimerIndex !== null &&
																product.disclaimerIndex !== undefined
															) {
																setHighlightedDisclaimer(null);
															}
														}}
													>
														{/* className="font-bold text-14 md:text-base"> */}
														{product.name}
														&nbsp;
														{product.disclaimer
															? '*'.repeat(product.disclaimerIndex + 1)
															: ''}
													</Typography>
													<Typography className="px-8 font-bold flex items-center">
														{product.prices.find(f => f.type === 'Sale').price / 100},-
													</Typography>
												</div>
												<div className="flex ButtonDiv h-56 items-center">
													<Button
														variant="outlined"
														onClick={() => {
															setProductsWithAmount(oldState => {
																const p = { ...oldState };
																if (p[product.id] !== 0) {
																	p[product.id] -= 1;
																}
																return p;
															});
														}}
														className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
													>
														<Remove />
													</Button>
													<div className="flex items-center text-14 font-bold px-10">
														{productsWithAmount[product.id]}
													</div>
													<Tooltip
														title={
															flags.maxAmountOfProductPerProductGroupInCart &&
															product.ticketTypeUid &&
															productGroups[product.ticketTypeUid] &&
															productGroups[product.ticketTypeUid].maxTickets &&
															productGroups[product.ticketTypeUid].maxTickets.amount &&
															productsWithAmount[product.id] >=
																productGroups[product.ticketTypeUid].maxTickets.amount
																? `Du kan maksimalt købe ${
																		productGroups[product.ticketTypeUid].maxTickets
																			.amount
																  } af dette produkt`
																: ''
														}
													>
														<Button
															variant="outlined"
															onClick={() => {
																if (flags.maxAmountOfProductPerProductGroupInCart) {
																	const { maxTickets } =
																		productGroups[product.ticketTypeUid];
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		if (
																			maxTickets &&
																			maxTickets.amount &&
																			maxTickets.amount <= p[product.id]
																		) {
																			p[product.id] = maxTickets.amount;
																		} else {
																			p[product.id] += 1;
																		}
																		return p;
																	});
																} else {
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		p[product.id] += 1;
																		return p;
																	});
																}
															}}
															className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
														>
															<Add />
														</Button>
													</Tooltip>
												</div>
											</div>
										)
									)}
									<hr />
									<div className="flex justify-between w-full my-6">
										<Typography className="font-bold text-20">I alt</Typography>
										<Typography className="font-bold text-20">{`${
											totalAmount / 100
										} kr.`}</Typography>
									</div>
									<hr />
									<div className="flex flex-col mt-10">
										{Object.entries(disclaimers).map(([disclaimer, index]) => (
											<Typography
												key={index}
												className={`${
													highlightedDisclaimer === index ? 'text-14 font-bold' : 'text-12'
												} leading-6`}
												variant="body1"
												style={{ wordWrap: 'break-word' }}
											>
												<span className="mr-4">{'*'.repeat(index + 1)}</span>
												{disclaimer}
											</Typography>
										))}
									</div>
								</>
							)}
						</Grid>
						<Grid item {...gridAttr} className="flex flex-col text-left px-10 pt-32 md:pt-0">
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								2
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at besøge?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox2(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg dato <Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox2 &&
								(ticketCount > 0 ? (
									<Calendar
										locale="da-DK"
										next2Label={null}
										prev2Label={null}
										prevLabel={<ArrowBackIcon />}
										nextLabel={<ArrowForwardIcon />}
										minDetail="month"
										onChange={value => {
											setDate(value);
											setOpenBox3(true);
										}}
										value={date}
										showNeighboringMonth={false}
										minDate={new Date()}
										className={clsx(classes.calendarPicker, 'text-center')}
										tileDisabled={tileData => {
											if (ticketCount === 0) {
												return true;
											}
											const d = tileData.date.getDate();
											if (groupSummary && groupSummary.dates[d]) {
												if (groupSummary.dates[d].availableAtSameTime >= ticketCount) {
													return false;
												}
											}
											if (
												date &&
												tileData.date.getFullYear() === date.getFullYear() &&
												tileData.date.getMonth() === date.getMonth() &&
												tileData.date.getDate() === date.getDate()
											) {
												setDate(null);
												setSelectedEvent(null);
											}
											return true;
										}}
										onActiveStartDateChange={dateData => {
											setMonth(dateData.activeStartDate.getMonth() + 1);
											setYear(dateData.activeStartDate.getFullYear());
										}}
									/>
								) : (
									<Typography className="font-bold whitespace-no-wrap text-center mt-12">
										Vælg antal billetter først
									</Typography>
								))}
						</Grid>
						{(forceShowTime || calculateShowTime) && (
							<Grid item {...gridAttr} className="flex flex-col w-full px-10 pt-32 md:pt-0">
								<div
									className={clsx(
										classes.number,
										'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
									)}
								>
									3
								</div>
								<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
									Hvornår ønsker du at ankomme?
								</Typography>

								<div>
									<Button
										onClick={() => {
											setOpenBox3(oldstate => !oldstate);
										}}
										color="secondary"
										variant="contained"
										className="text-center rounded-none normal-case h-28 w-full"
									>
										Vælg tidspunkt <Icon>{openBox3 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
									</Button>
								</div>
								{openBox3 &&
									(groupSummary && date && groupSummary.dates[date.getDate()] ? (
										<Grid className="mt-4" spacing={1} container>
											<EventGroupTimeSlot
												openSlotCat={openSlotCat}
												selectedEvent={selectedEvent}
												setOpenSlotCat={setOpenSlotCat}
												setSelectedEvent={setSelectedEvent}
												showAvailable={showAvailable}
												slotCategories={slotCategories}
												slots={slots}
												ticketCount={ticketCount}
												getTimeOnEvent={getTimeOnEvent}
												now={now}
											/>
											{/* <Typography className="text-10 pl-6 pt-4"> */}
											{/*	For at minimere Covid-19 smittespredning bestilles billetter i */}
											{/*	tidsintervaller. Køber du for eksempel en billet i tidsintervallet */}
											{/*	13-14, vil din entre kun være gyldig i det givne tidsinterval. Herefter */}
											{/*	kan du blive så længe du ønsker. */}
											{/* </Typography> */}
										</Grid>
									) : (
										<Typography className="font-bold whitespace-no-wrap text-center mt-12">
											Vælg dato først
										</Typography>
									))}
							</Grid>
						)}
					</div>
					<Grid container direction="row" justify="flex-end" className="">
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="px-10 pt-20">
							<Button
								onClick={goToCheckOut}
								className="w-full rounded-none"
								variant="contained"
								endIcon={<ArrowForwardIcon />}
								color="primary"
								disabled={isAllowedToBuy()}
							>
								KØB BILLET
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
}

export default EventGroupDateTimeSelectorComponentCMS;

function EventGroupTimeSlot({
	openSlotCat,
	setOpenSlotCat,
	slots,
	selectedEvent,
	ticketCount,
	setSelectedEvent,
	getTimeOnEvent,
	showAvailable,
	now,
	slotCategories
}) {
	// Dette er meget fy fy!! :o
	slotCategories = slotCategories.filter(category => slots[category.title] && slots[category.title].length !== 0);

	if (slotCategories.length === 1) {
		const timeslots = Object.values(slots).reduce((acc, cur) => {
			return acc.concat(cur);
		}, []);
		return (
			<>
				{timeslots &&
					timeslots.map((time, index2) => (
						<Grid item xs={6} xl={6} key={`-${index2}`}>
							<Button
								color={selectedEvent === time.eventUid ? 'primary' : 'secondary'}
								variant="contained"
								className="w-full rounded-none normal-case block h-52"
								disabled={time.available < ticketCount || overdueEvent(now, time.end)}
								onClick={() => {
									setSelectedEvent(time.eventUid);
								}}
							>
								<span className="text-14 font-bold whitespace-no-wrap">
									{getTimeOnEvent(time.start, time.end)}
								</span>
								<br />
								{showAvailable(time.maxTickets, time.available) && (
									<span className="text-12 whitespace-no-wrap font-bold">
										{time.showTextOnPercentage ? (
											<>
												{time.textOnTicketAmount && time.available > 0
													? time.textOnTicketAmount
													: 'Udsolgt'}
											</>
										) : (
											<>
												{time.available > 0 ? `${time.available} billetter tilbage` : 'Udsolgt'}
											</>
										)}
									</span>
								)}
							</Button>
						</Grid>
					))}
			</>
		);
	}
	return (
		<>
			{slotCategories.map((slotCat, slotIndex) => (
				<React.Fragment key={slotIndex}>
					<Button
						key={slotIndex}
						onClick={() => {
							setOpenSlotCat(oldState => (oldState === slotCat.title ? null : slotCat.title));
						}}
						endIcon={<Icon>{openSlotCat === slotCat.title ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>}
						color="secondary"
						variant="contained"
						className="flex justify-between rounded-none normal-case h-28 w-full mx-4 mb-4"
					>
						{slotCat.title}
					</Button>
					{openSlotCat === slotCat.title &&
						slots[slotCat.title].map((time, slotIndex2) => (
							<Grid item xs={6} xl={6} key={`${slotIndex}-${slotIndex2}`}>
								<Button
									color={selectedEvent === time.eventUid ? 'primary' : 'secondary'}
									variant="contained"
									className="w-full rounded-none normal-case block h-52"
									disabled={time.available < ticketCount || overdueEvent(now, time.end)}
									onClick={() => {
										setSelectedEvent(time.eventUid);
									}}
								>
									<span className="text-14 font-bold whitespace-no-wrap">
										{getTimeOnEvent(time.start, time.end)}
									</span>
									<br />
									{showAvailable(time.maxTickets, time.available) && (
										<span className="text-12 whitespace-no-wrap font-bold">
											{time.available > 0 ? `${time.available} billetter tilbage` : 'Udsolgt'}
										</span>
									)}
								</Button>
							</Grid>
						))}
				</React.Fragment>
			))}
		</>
	);
}
