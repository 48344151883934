import React from 'react';
import Typography from '@mui/material/Typography';

function HeaderContentComponent(props) {
	return (
		<>
			<Typography variant="h5">{props.data.header}</Typography>
			<Typography
				className="pb-12"
				dangerouslySetInnerHTML={{ __html: props.data.content.replaceAll('\\n', '</br>') }}
			/>
		</>
	);
}

export default HeaderContentComponent;
