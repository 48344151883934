import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faqsGroupListener, faqsQuestionsListener, faqGroupsListener } from './FaqFirestore';
import MarkdownComponent from '@ameroservices-platform/attraction-frontend/app/ui-components/content-components/MarkdownComponent';
import { getLocaleFromData } from '@ameroservices-platform/attraction-frontend/app/utilities';

const useStyles = makeStyles(theme => ({
	panel: {
		margin: 0,
		borderWidth: '1px 1px 0 1px',
		borderStyle: 'solid',
		borderColor: theme.palette.divider,
		'&.MuiAccordion-root:first-child': {
			borderTopLeftRadius: '16px',
			borderTopRightRadius: '16px'
		},
		'&.MuiAccordion-root:last-child': {
			borderBottomLeftRadius: '16px',
			borderBottomRightRadius: '16px',
			borderWidth: '0 1px 1px 1px'
		},
		'&$expanded': {
			margin: 'auto'
		}
	},
	expanded: {}
}));

function FAQ({ contentElementProps }) {
	const { questionGroupUids } = contentElementProps;
	const classes = useStyles();
	const [faqGroups, setFaqGroups] = useState([]);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [expanded, setExpanded] = useState(null);
	const [searchText, setSearchText] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		if (questionGroupUids) {
			const faqsListenerUnsubFunc = faqsGroupListener(questionGroupUids.split(','), o => setData(o));
			return () => faqsListenerUnsubFunc();
		}
		// const faqsListenerUnsubFunc = faqsQuestionsListener(props.data.questions, o => setData(o));
		// return () => faqsListenerUnsubFunc();
	}, [questionGroupUids]);

	useEffect(() => {
		const faqGroupsListenerUnsubFunc = faqGroupsListener(o => setFaqGroups(o));
		return () => faqGroupsListenerUnsubFunc();
	}, []);

	useEffect(() => {
		function getFilteredArray(arr, _searchText) {
			if (_searchText.length === 0) {
				return arr;
			}
			return FuseUtils.filterArrayByString(arr, _searchText);
		}

		setFilteredData(getFilteredArray(data, searchText));
	}, [data, searchText]);

	const toggleAccordion = panel => (event, _expanded) => {
		setExpanded(_expanded ? panel : false);
	};

	function handleSearch(event) {
		setSearchText(event.target.value);
	}

	return (
		<div className="w-full flex flex-col flex-auto pb-48 mb-48 max-w-xl mx-auto">
			<div
				className={clsx(
					classes.header,
					'flex flex-col flex-shrink-0 items-center justify-center text-center p-16 sm:p-24 h-200 sm:h-360'
				)}
			>
				<FuseAnimateAdapter duration={400} delay={600}>
					<Typography
						variant="subtitle1"
						color="inherit"
						className="opacity-75 mt-8 sm:mt-16 mx-auto max-w-512"
					>
						{t('FAQ_HEADER')}
					</Typography>
				</FuseAnimateAdapter>

				<FuseAnimateAdapter animation="transition.slideUpIn" duration={400} delay={100}>
					<Typography color="inherit" className="text-36 sm:text-56 font-light">
						{t('FAQ_SUBTITLE')}
					</Typography>
				</FuseAnimateAdapter>

				<Paper className="flex items-center h-56 w-full mt-16 sm:mt-32 rounded-8" elevation={1}>
					<Icon color="action" className="mx-16">
						search
					</Icon>
					<Input
						placeholder={t('SEARCH_QUESTIONS')}
						className="w-full"
						disableUnderline
						fullWidth
						inputProps={{
							'aria-label': t('SEARCH')
						}}
						value={searchText}
						onChange={handleSearch}
					/>
				</Paper>
			</div>

			<div className="flex flex-col flex-1 flex-shrink-0 max-w-xl w-full mx-auto px-16 sm:px-24 py-24 sm:py-32">
				{filteredData.length === 0 && (
					<div className="flex flex-auto items-center justify-center w-full h-full">
						<Typography color="textSecondary" variant="h5">
							{t('NO_QUESTIONS')}
						</Typography>
					</div>
				)}

				{faqGroups
					.filter(f => filteredData.find(d => d.parent === f.id))
					.map(group => (
						<div key={group.id}>
							<div className="flex flex-auto items-center justify-center w-full h-full my-12">
								<Typography color="textPrimary" variant="h5">
									{group.name}
								</Typography>
							</div>
							<div>
								{filteredData
									.filter(f => f.parent === group.id)
									.map(faq => (
										<Accordion
											classes={{
												root: classes.panel,
												expanded: classes.expanded
											}}
											key={faq.id}
											expanded={expanded === faq.id}
											onChange={toggleAccordion(faq.id)}
											elevation={1}
										>
											<AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
												<div className="flex items-center">
													<Icon color="action">help_outline</Icon>
													<Typography className="px-8">
														{getLocaleFromData(faq, 'question')}
													</Typography>
												</div>
											</AccordionSummary>

											<AccordionDetails>
												<MarkdownComponent data={getLocaleFromData(faq, 'answer')} />
											</AccordionDetails>
										</Accordion>
									))}
							</div>
						</div>
					))}
			</div>
		</div>
	);
}

export default FAQ;
