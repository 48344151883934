import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Add, Remove } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { AppBar, ButtonGroup, Tooltip } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../store/shared/frontendSlice';
import { productsListener } from '../../../../types/manual-event-detail/ManualEventDetailFirestore';
import getColor from '../../shared/getColor';
import { productGroupsListener } from '../../../../types/event-group-date-time-selector/EventGroupDateTimeSelectorFirestore';
import ProductGroupRow from '../../shared/ProductGroupRow';
import ProductRow from '../../shared/ProductRow';
import { findPricesForProduct, getLocaleFromData } from '../../../../../../../utilities';
import ProductTotalSection from '../../shared/ProductSection';

function ManualEventTableProductAmount({ open, handleClose, event, readMoreLinkTo, contentElementProps, free }) {
	const flags = useFlags();
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [products, setProducts] = useState([]);
	const [showingProducts, setShowingProducts] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [error, setError] = useState(null);
	const [productGroups, setProductGroups] = useState({});
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [selectedGroupBy, setSelectedGroupBy] = useState({});
	const [disclaimers, setDisclaimers] = useState({});

	const groupByRefs = useRef();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		let productGroupsListenerUnsubFuncs = [];
		if (event && event.ticketTypes) {
			productGroupsListenerUnsubFuncs = productGroupsListener(event.ticketTypes, (id, item) => {
				setProductGroups(oldState => {
					return { ...oldState, [id]: item };
				});
			});
		}
		return () => {
			productGroupsListenerUnsubFuncs.forEach(f => f());
		};
	}, [event]);

	const useStyles = makeStyles(_theme => ({
		buyButton: {
			color: `${getColor(
				contentElementProps.productAmountBuyButtonTextColor,
				contentElementProps.customProductAmountBuyButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.productAmountBuyButtonBackgroundColor,
				contentElementProps.customProductAmountBuyButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.productAmountBuyButtonTextColorHover,
					contentElementProps.customProductAmountBuyButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.productAmountBuyButtonBackgroundColorHover,
					contentElementProps.customProductAmountBuyButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	const classes = useStyles();

	// const googleTagManager = useSelector(selectGoogleTagManager);
	// useEffect(() => {
	// 	if (googleTagManager && products.length > 0) {
	// 		tagManagerViewItem(products);
	// 	}
	// }, [googleTagManager, products]);

	useEffect(() => {
		if (open && event && event.ticketTypes.length > 0) {
			const productsListenerUnsubFunc = productsListener(event.ticketTypes, productList => {
				const copyOfProducts = productList;
				if (!flags.disclaimerAndSortOnGroupedProducts) {
					const productsWithDisclaimer = productList.filter(filterForDisclaimers);
					const indexes = {};
					let index = 0;
					productsWithDisclaimer.forEach((product, i) => {
						if (
							product.disclaimer &&
							(indexes[
								flags.multiLanguageFunctions
									? getLocaleFromData(product, 'disclaimer')
									: product.disclaimer
							] === undefined ||
								indexes[product.disclaimer] === null)
						) {
							indexes[product.disclaimer] = index;
							index += 1;
						}
					});
					productsWithDisclaimer.forEach((product, i) => {
						if (product.disclaimer) {
							product.disclaimerIndex = indexes[product.disclaimer];
						}
					});
					setDisclaimers(indexes);
				}
				setProducts(copyOfProducts);
			});
			return () => productsListenerUnsubFunc();
		}
		return () => {};
	}, [open, event, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	const disclaimersForShowingProducts = useMemo(() => {
		if (!flags.disclaimerAndSortOnGroupedProducts) {
			return null;
		}
		const productsWithDisclaimer = showingProducts.filter(filterForDisclaimers);
		const indexes = {};
		let index = 0;
		productsWithDisclaimer.forEach((product, i) => {
			if (
				product.disclaimer &&
				(indexes[
					flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
				] === undefined ||
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					] === null)
			) {
				indexes[product.disclaimer] = index;
				index += 1;
			}
		});
		productsWithDisclaimer.forEach((product, i) => {
			if (product.disclaimer) {
				product.disclaimerIndex =
					indexes[
						flags.multiLanguageFunctions ? getLocaleFromData(product, 'disclaimer') : product.disclaimer
					];
			}
		});
		return indexes;
	}, [showingProducts, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] = 0;
		});
		setProductsWithAmount(pro);
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const location = event && event.locations ? event.locations : null;
				const date = event && event.startRaw ? event.startRaw : null;
				const price = findPricesForProduct(product, location, date, flags.displayPeriodePrices);
				totals += price * productsWithAmount[pKey];
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products, event, flags.displayPeriodePrices]);

	useEffect(() => {
		let amount = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			amount += productsWithAmount[pKey];
		});
		if (event && amount > event.available) {
			setError(flags.disableBuyButtonIfNoTicketsIsSelected ? 'event' : true);
		} else if (flags.disableBuyButtonIfNoTicketsIsSelected && event && amount <= 0) {
			setError('minimum');
		} else {
			setError(flags.disableBuyButtonIfNoTicketsIsSelected ? null : false);
		}
	}, [productsWithAmount, event, flags.disableBuyButtonIfNoTicketsIsSelected]);

	useEffect(() => {
		const groupByProductGroups = Object.values(productGroups).filter(pg => {
			return pg.groupByName;
		});
		if (groupByProductGroups.length > 0) {
			const newProducts = products.filter(p => !groupByProductGroups.some(pg => pg.id === p.ticketTypeUid));
			const productsWithGroupBy = products.filter(p =>
				groupByProductGroups.some(pg => pg.id === p.ticketTypeUid)
			);
			const groupBy = productsWithGroupBy.reduce((accProducts, curProduct) => {
				if (!accProducts[curProduct.ticketTypeUid]) {
					accProducts[curProduct.ticketTypeUid] = [];
				}
				accProducts[curProduct.ticketTypeUid].push(curProduct);
				return accProducts;
			}, {});
			Object.entries(groupBy).forEach(([key, value]) => {
				const prices = value.reduce((acc, cur) => {
					const p = cur.prices.find(pr => pr.type === 'Sale');
					if (p) {
						acc.push(p.price);
					} else {
						acc.push(0);
					}
					return acc;
				}, []);
				prices.sort((a, b) => a - b);
				const lowestPrice = prices[0];
				const highestPrice = prices[prices.length - 1];
				const options = [...value].sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
				newProducts.push({
					id: key,
					name: flags.multiLanguageFunctions
						? getLocaleFromData(productGroups[key], 'groupByName')
						: productGroups[key].groupByName,
					lowestPrice,
					highestPrice,
					samePrice: lowestPrice === highestPrice,
					type: 'productGroup',
					items: value.reduce((acc, cur) => {
						acc[cur.id] = cur;
						return acc;
					}, {}),
					options,
					order: flags.disclaimerAndSortOnGroupedProducts ? productGroups[key].groupBySort : 9999,
					disclaimer: flags.disclaimerAndSortOnGroupedProducts
						? flags.multiLanguageFunctions
							? getLocaleFromData(productGroups[key], 'groupByDisclaimer')
							: productGroups[key].groupByDisclaimer
						: null
				});
			});
			newProducts.sort((p1, p2) => p1.order - p2.order);
			setShowingProducts(newProducts);
		} else {
			setShowingProducts(products);
		}
	}, [products, productGroups, flags.disclaimerAndSortOnGroupedProducts, flags.multiLanguageFunctions]);

	const updateProductAmount = (id, amount) => {
		setProductsWithAmount(oldState => ({
			...oldState,
			[id]: amount
		}));
	};

	const toolTipTitleAdd = (product, variant) => {
		if (variant) {
			if (
				flags.maxAmountOfProductPerProductGroupInCart &&
				selectedGroupBy[product.id] &&
				selectedGroupBy[product.id].ticketTypeUid &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid] &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount &&
				productsWithAmount[selectedGroupBy[product.id].id] >=
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
			) {
				return `Du kan maksimalt købe ${
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
				} af dette produkt`;
			}
		} else if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			product.ticketTypeUid &&
			productGroups[product.ticketTypeUid] &&
			productGroups[product.ticketTypeUid].maxTickets &&
			productGroups[product.ticketTypeUid].maxTickets.amount &&
			productsWithAmount[product.id] >= productGroups[product.ticketTypeUid].maxTickets.amount
		) {
			return `Du kan maksimalt købe ${productGroups[product.ticketTypeUid].maxTickets.amount} af dette produkt`;
		}
		return '';
	};

	const toolTipTitleRemove = product => {
		if (flags.minimumAmountOfProduct && product.minimumAmount) {
			return `Du skal minimum købe ${product.minimumAmount} af dette produkt`;
		}
		return '';
	};

	return (
		<ThemeProvider theme={mergedTheme}>
			<Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
				<AppBar position="static">
					<Toolbar className="flex w-full">
						<Typography variant="subtitle1" color="inherit">
							{t('CHOOSE_TICKETS')}
						</Typography>
					</Toolbar>
				</AppBar>
				{flags.productsOnManualEvensRefactor && (
					<DialogContent>
						<ProductTotalSection
							classes={classes}
							totalAmount={totalAmount}
							disclaimers={
								(flags.disclaimerAndSortOnGroupedProducts
									? disclaimersForShowingProducts
									: disclaimers) || null
							}
							highlightedDisclaimer={highlightedDisclaimer}
						>
							{event &&
								showingProducts.map(product => {
									if (product.type === 'productGroup') {
										return (
											<ProductGroupRow
												key={product.id}
												product={product}
												productGroups={productGroups}
												productsWithAmount={productsWithAmount}
												setProductsWithAmount={setProductsWithAmount}
												groupByRefs={groupByRefs}
												setHighlightedDisclaimer={setHighlightedDisclaimer}
												updateProductAmount={updateProductAmount}
												selectedGroupBy={selectedGroupBy}
												setSelectedGroupBy={setSelectedGroupBy}
												addButtonToolTip={toolTipTitleAdd(product, true)}
												removeButtonToolTip={toolTipTitleRemove(product)}
											/>
										);
									}
									return (
										<ProductRow
											key={product.id}
											productGroups={productGroups}
											product={product}
											classes={classes}
											amount={productsWithAmount[product.id]}
											setHighlightedDisclaimer={setHighlightedDisclaimer}
											priceForProduct={findPricesForProduct(
												product,
												event.locations,
												event.startRaw,
												flags.displayPeriodePrices
											)}
											addButtonToolTip={toolTipTitleAdd(product, false)}
											removeButtonToolTip={toolTipTitleRemove(product)}
											updateProductAmount={updateProductAmount}
											showDisclaimer
										/>
									);
								})}
						</ProductTotalSection>
					</DialogContent>
				)}

				{!flags.productsOnManualEvensRefactor && (
					<DialogContent>
						{event &&
							products &&
							products.map(product => (
								<div className="flex justify-between items-center" key={product.id}>
									<div className="flex justify-between w-full">
										<Typography className="font-bold whitespace-normal">{product.name}</Typography>
										<Typography className="px-8 font-bold flex items-center">
											{product.prices.find(f => f.type === 'Sale').price / 100},-
										</Typography>
									</div>
									<div className="flex ButtonDiv h-56 items-center">
										<Button
											variant="outlined"
											onClick={() => {
												setProductsWithAmount(oldState => {
													const p = { ...oldState };
													if (p[product.id] !== 0) {
														p[product.id] -= 1;
													}
													return p;
												});
											}}
											className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
										>
											<Remove />
										</Button>
										<div className="flex items-center text-14 font-bold px-10">
											{productsWithAmount[product.id]}
										</div>

										<Tooltip
											title={
												flags.maxAmountOfProductPerProductGroupInCart &&
												product.ticketTypeUid &&
												productGroups[product.ticketTypeUid] &&
												productGroups[product.ticketTypeUid].maxTickets &&
												productGroups[product.ticketTypeUid].maxTickets.amount &&
												productsWithAmount[product.id] >=
													productGroups[product.ticketTypeUid].maxTickets.amount
													? `Du kan maksimalt købe ${
															productGroups[product.ticketTypeUid].maxTickets.amount
													  } af dette produkt`
													: ''
											}
										>
											<Button
												variant="outlined"
												onClick={() => {
													if (flags.maxAmountOfProductPerProductGroupInCart) {
														const { maxTickets } = productGroups[product.ticketTypeUid];
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															if (
																maxTickets &&
																maxTickets.amount &&
																maxTickets.amount <= p[product.id]
															) {
																p[product.id] = maxTickets.amount;
															} else {
																p[product.id] += 1;
															}
															return p;
														});
													} else {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															p[product.id] += 1;
															return p;
														});
													}
												}}
												className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
											>
												<Add />
											</Button>
										</Tooltip>
									</div>
								</div>
							))}

						<hr />
						<div className="flex justify-between w-full mt-12">
							<Typography className="font-bold text-20">{t('TOTAL')}</Typography>
							<Typography className="font-bold text-20">{`${totalAmount / 100} kr.`}</Typography>
						</div>
					</DialogContent>
				)}

				<DialogActions className="justify-between p-8">
					<div>
						<Typography color="error">
							{(flags.disableBuyButtonIfNoTicketsIsSelected ? error === 'event' : error) && event && (
								<>
									{t('MAX_ORDER')}
									{event.available}
									{t('THIS_DAY')}
								</>
							)}
						</Typography>
					</div>
					<ButtonGroup>
						<Button variant="outlined" color="secondary" onClick={handleClose}>
							{t('CANCEL')}
						</Button>
						{event && !event.useLinkToExternalSite && (
							<Button
								variant={
									(flags.disableBuyButtonIfNoTicketsIsSelected ? error === 'event' : error)
										? 'contained'
										: 'outlined'
								}
								color="primary"
								onClick={() => {
									navigate(readMoreLinkTo.replace(':eventGroupUid', event.eventGroupUid), {
										productsWithAmount
									});
									handleClose();
								}}
							>
								{t('MORE_DATES')}
							</Button>
						)}
						<Button
							className={`${classes.buyButton}`}
							variant="contained"
							color="primary"
							disabled={!!error}
							onClick={() =>
								navigate('/cart', {
									state: {
										event: event.eventUid,
										products: productsWithAmount
									}
								})
							}
						>
							{flags.customTextWhenEventIsFreeOnManualEventTable && (
								<>
									{free
										? contentElementProps.freeButtonText || t('BUY_TICKET_BUTTON_FREE')
										: contentElementProps.buyButtonText || t('BUY_TICKET_BUTTON')}
								</>
							)}
							{!flags.customTextWhenEventIsFreeOnManualEventTable && t('BUY_TICKET_BUTTON')}
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}

export default ManualEventTableProductAmount;
