const Rules = {
	required: {
		required: 'Feltet skal udfyldes',
	},
	firstname: {
		required: 'Fornavn skal udfyldes',
		minLength: {
			value: 2,
			message: 'Fornavnet skal være mindst 2 tegn',
		},
	},
	lastname: {
		required: 'Efternavn skal udfyldes',
		minLength: {
			value: 2,
			message: 'Efternavnet skal være mindst 2 tegn',
		},
	},
	email: {
		required: 'Email skal udfyldes',
		minLength: {
			value: 5,
			message: 'Email skal være mindst 5 tegn',
		},
		pattern: {
			value: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
			message: 'Email skal være gyldig',
		},
	},
	phone: {
		required: 'Telefonnummer skal udfyldes',
		minLength: {
			value: 8,
			message: 'Telefonnummer skal være 8 tegn',
		},
		maxLength: {
			value: 8,
			message: 'Telefonnummer skal være 8 tegn',
		},
		pattern: {
			value: /^(0|[1-9]\d*)(\.\d+)?$/,
			message: 'Telefonnummer kan kun være tal',
		},
	},
	address: {
		required: 'Adresse skal udfyldes',
		minLength: {
			value: 2,
			message: 'Adresse skal være mindst 2 tegn',
		},
	},
	postal: {
		required: 'Postnummer skal udfyldes',
		minLength: {
			value: 3,
			message: 'Postnummer skal være mindst 3 tegn',
		},
		maxLength: {
			value: 4,
			message: 'Postnummer skal være maks. 4 tegn',
		},
		pattern: {
			value: /^(0|[1-9]\d*)(\.\d+)?$/,
			message: 'Postnummer kan kun være tal',
		},
	},
	city: {
		required: 'By skal udfyldes',
		minLength: {
			value: 2,
			message: 'Bynavn skal være mindst 2 tegn',
		},
	},
	regi: {
		required: 'Registreringsnummer skal udfyldes',
		minLength: {
			value: 4,
			message: 'Registreringsnummer skal være 4 tegn',
		},
		maxLength: {
			value: 4,
			message: 'Registreringsnummer skal være 4 tegn',
		},
		pattern: {
			value: /^(0|[0-9]\d*)(\.\d+)?$/,
			message: 'Registreringsnummer kan kun være tal',
		},
	},
	accNumber: {
		required: 'Kontonummer skal udfyldes',
		minLength: {
			value: 7,
			message: 'Kontonummer skal være mellem 7-10 tegn',
		},
		maxLength: {
			value: 10,
			message: 'Kontonummer skal være mellem 7-10 tegn',
		},
		pattern: {
			value: /^(0|[0-9]\d*)(\.\d+)?$/,
			message: 'Kontonummer kan kun være tal',
		},
	},
	number: {
		required: 'Skal udfyldes',
		pattern: {
			value: /^[0-9]+$/,
			message: 'Skal være et helt tal',
		},
	},
	decimal: {
		required: 'Skal udfyldes',
		pattern: {
			value: /^[0-9]+[,.]?[0-9]{0,2}?$/,
			message: 'Må kun indeholde tal og komma med maksimalt 2 decimaler',
		},
	},
	itemName: {
		required: 'Skal udfyldes',
		maxLength: {
			value: 20,
			message: 'Varenavn må ikke være mere end 20 tegn',
		},
	},
};

module.exports.GetRuleByName = (name) => {
	return Rules[name];
};
