import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectModules, selectTheme } from '../../../../../../store/shared/frontendSlice';
import { logoutUser } from '../../../../../../auth/store/userSlice';
import getColor from '../shared/getColor';

function UserMenuButton(props) {
	const { contentElementProps } = props;
	const modules = useSelector(selectModules);

	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const { t } = useTranslation();

	const useStyles = makeStyles(_theme => ({
		avatar: {
			backgroundColor: `${getColor(
				contentElementProps.avatarBackgroundColor,
				contentElementProps.customAvatarBackgroundColor,
				mergedTheme
			)}`
		},
		icon: {
			color: `${getColor(contentElementProps.iconColor, contentElementProps.customIconColor, mergedTheme)}`
		},
		text: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
		}
	}));
	const classes = useStyles();

	const [userMenu, setUserMenu] = useState(null);
	const [displayName, setDisplayName] = useState('');

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	useEffect(() => {
		if (user.data && user.data.displayName) return setDisplayName(user.data.displayName);
		if (user.data && user.data.email) return setDisplayName(user.data.email);

		return setDisplayName('Gæst');
	}, [user]);

	return (
		<>
			{modules.frontendUsers && (
				<>
					<Button className="min-h-40" onClick={userMenuClick}>
						{user.data.photoURL ? (
							<Avatar className="mx-4" alt="user photo" src={user.data.photoURL} />
						) : (
							<Avatar className={`mx-4 ${classes.avatar}`}>
								<Icon className={`${classes.icon}`}>person</Icon>
							</Avatar>
						)}
						<div className="hidden md:flex flex-col mx-4 items-end ml-10">
							<Typography component="span" className={`normal-case font-bold flex ${classes.text}`}>
								{user.isAnonymous || !user.role || user.role.length === 0 ? t('LOGIN') : displayName}
							</Typography>
						</div>
					</Button>

					<Popover
						open={Boolean(userMenu)}
						anchorEl={userMenu}
						onClose={userMenuClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						classes={{
							paper: 'py-8'
						}}
					>
						{user.isAnonymous || !user.role || user.role.length === 0 ? (
							<>
								<MenuItem component={Link} to="/login" role="button">
									<ListItemIcon className="min-w-40">
										<Icon>lock</Icon>
									</ListItemIcon>
									<ListItemText primary={t('LOGIN')} />
								</MenuItem>
								<MenuItem component={Link} to="/register" role="button">
									<ListItemIcon className="min-w-40">
										<Icon>person_add</Icon>
									</ListItemIcon>
									<ListItemText primary={t('CREATE_USER')} />
								</MenuItem>
							</>
						) : (
							<>
								<MenuItem component={Link} to="/account" onClick={userMenuClose} role="button">
									<ListItemIcon className="min-w-40">
										<Icon>account_circle</Icon>
									</ListItemIcon>
									<ListItemText primary={t('MY_PAGE')} />
								</MenuItem>
								<MenuItem
									onClick={() => {
										dispatch(logoutUser());
										userMenuClose();
									}}
								>
									<ListItemIcon className="min-w-40">
										<Icon>exit_to_app</Icon>
									</ListItemIcon>
									<ListItemText primary={t('LOGOUT')} />
								</MenuItem>
							</>
						)}
					</Popover>
				</>
			)}
		</>
	);
}

export default UserMenuButton;
