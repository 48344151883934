import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import LoadingButton from '../../../../../../ui-components/LoadingButton';
import { updateCustomerFunction, updateUser } from '../../../../../../store/shared/userSlice';
import AvatarWebcam from '../../../../../../ui-components/AvatarWebcam';
import {
	selectCountries,
	selectDisableProfilePictureConfirmation,
	selectHideProfilePicture,
	selectModules
} from '../../../../../../store/shared/frontendSlice';

function AccountPage() {
	const flags = useFlags();
	const dispatch = useDispatch();
	const formRef = useRef(null);
	const { watch, control, handleSubmit, formState, reset } = useForm({
		mode: 'onChange',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			postalCode: '',
			city: '',
			address: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid } = formState;
	const user = useSelector(({ shared }) => shared.user.user);
	const loading = useSelector(({ shared }) => shared.user.loading);
	const countries = useSelector(selectCountries);
	const modules = useSelector(selectModules);
	const [countryCode, setCountryCode] = useState('DK');
	const [newsletterOptIn, setNewsletterOptIn] = useState(false);
	const hideProfilePicture = useSelector(selectHideProfilePicture);
	const disableProfilePictureConfirmation = useSelector(selectDisableProfilePictureConfirmation);
	const { t } = useTranslation();

	useEffect(() => {
		if (user && user.contactInfo && user.contactInfo.billing) {
			reset(user.contactInfo.billing);
			if (flags.salesToOtherCountries) {
				setCountryCode(user.contactInfo.billing.countryCode || 'DK');
			}
			if (flags.emailSubscription) {
				setNewsletterOptIn(user.newsletterOptIn || false);
			}
		}
		// eslint-disable-next-line
	}, [user, flags.salesToOtherCountries, flags.emailSubscription, modules]);

	function onSubmit(data) {
		if (isFormValid && user && user.id) {
			const d = { contactInfo: {} };
			Object.entries(data).forEach(([key, value]) => {
				d.contactInfo[key] = value;
			});

			if (flags.emailSubscription) {
				d.newsletterOptIn = newsletterOptIn;
			}
			if (flags.salesToOtherCountries) {
				d.contactInfo.countryCode = countryCode;
			}
			dispatch(updateCustomerFunction(user.id, d));
		}
	}

	return (
		<Paper className="rounded-0 shadow-none lg:rounded-8 lg:shadow-1 p-24">
			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
				<Typography variant="h6" className="mb-16 text-center md:text-left">
					{t('YOUR_INFORMATIONS')}
				</Typography>
				<div className="flex justify-between flex-col md:flex-row">
					<div className={`w-full md:w-2/6 md:flex-grow ${hideProfilePicture ? ' hidden' : null}`}>
						<div className="flex justify-center items-center flex-col w-full h-full flex-grow mb-20 md:mb-0">
							<AvatarWebcam
								onAvatarChange={imageUrl => {
									const d = {};
									if (disableProfilePictureConfirmation) {
										d['data.photoURL'] = imageUrl;
									} else {
										d['data.photoWaiting'] = imageUrl;
									}
									dispatch(updateUser(user.id, d));
								}}
								src={user && user.data ? user.data.photoURL || user.data.photoWaiting : null}
								disabled={user && user.data && !!user.data.photoURL}
							/>
						</div>
					</div>

					<Grid
						container
						spacing={0}
						className={`sm:max-w-screen w-full ${hideProfilePicture ? null : ' md:w-4/6'}`}
					>
						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="firstname"
								className="mt-8 mb-16 pr-6"
								type="text"
								name="firstname"
								label={t('FIRST_NAME')}
								value={form.firstname}
								validations={{
									minLength: 2
								}}
								validationErrors={{
									minLength: t('FIRST_NAME_MIN_2_CHAR')
								}}
								variant="standard"
								fullWidth
								required
								disabled
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="lastname"
								className="mt-8 mb-16"
								type="text"
								name="lastname"
								label={t('LAST_NAME')}
								value={form.lastname}
								validations={{
									minLength: 2
								}}
								validationErrors={{
									minLength: t('LAST_NAME_MIN_2_CHAR')
								}}
								variant="standard"
								fullWidth
								required
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextFieldFormsyAdapter
								control={control}
								id="address"
								className="mt-8 mb-16"
								type="text"
								name="address"
								label={t('ADDRESS')}
								value={form.address}
								validations={{
									minLength: 2
								}}
								validationErrors={{
									minLength: t('ADDRESS_MIN_2_CHAR')
								}}
								variant="standard"
								fullWidth
								required
							/>
						</Grid>

						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="postalCode"
								className="mt-8 mb-16 pr-6"
								type="text"
								name="postalCode"
								label={t('POSTAL_CODE')}
								value={form.postalCode}
								validations={
									!flags.salesToOtherCountries || countryCode === 'DK'
										? {
												minLength: 3,
												maxLength: 4
										  }
										: {
												minLength: 3,
												maxLength: 8
										  }
								}
								validationErrors={
									!flags.salesToOtherCountries || countryCode === 'DK'
										? {
												minLength: t('POSTAL_CODE_LENGTH'),
												maxLength: t('POSTAL_CODE_LENGTH')
										  }
										: {
												minLength: t('POSTAL_CODE_LENGTH'),
												maxLength: t('POSTAL_CODE_NUMERIC')
										  }
								}
								variant="standard"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="city"
								className="mt-8 mb-16"
								type="text"
								name="city"
								label={t('CITY')}
								value={form.city}
								validations={{
									minLength: 1
								}}
								validationErrors={{
									minLength: t('CITY_LENGTH')
								}}
								variant="standard"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="email"
								className="mt-8 mb-16 pr-6"
								type="text"
								name="email"
								label="Email"
								value={form.email}
								validations={{
									isEmail: true
								}}
								validationErrors={{
									isEmail: t('NOT_VALID_EMAIL')
								}}
								variant="standard"
								fullWidth
								disabled
								enableTrim
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<TextFieldFormsyAdapter
								control={control}
								id="phone"
								className="mt-8 mb-16"
								type="text"
								name="phone"
								label={t('PHONE_NUMBER')}
								value={form.phone}
								validations={
									!flags.salesToOtherCountries || countryCode === 'DK'
										? {
												minLength: 8,
												maxLength: 11
										  }
										: {
												minLength: 6,
												maxLength: 20
										  }
								}
								validationErrors={
									!flags.salesToOtherCountries || countryCode === 'DK'
										? {
												minLength: t('PHONE_LENGTH'),
												maxLength: t('PHONE_LENGTH')
										  }
										: {
												minLength: t('PHONE_LENGTH'),
												maxLength: t('PHONE_LENGTH')
										  }
								}
								variant="standard"
								fullWidth
								required
							/>
						</Grid>

						{flags.emailSubscription && modules && (modules.mailchimp || modules.newsletterSignup) && (
							<FormControlLabel
								className="pl-24 whitespace-no-wrap"
								control={
									<Checkbox
										name="newsletterOptIn"
										checked={newsletterOptIn}
										onChange={async event => {
											setNewsletterOptIn(event.target.checked);
										}}
										color="primary"
									/>
								}
								label={
									<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
										{t('NEWSLETTER_SIGNUP')}
									</Typography>
								}
							/>
						)}

						{flags.salesToOtherCountries && (
							<Grid item xs={12}>
								<Autocomplete
									className="mt-8 mb-16 w-full"
									fullWidth
									id="countryCode"
									name="countryCode"
									options={Object.keys(countries)}
									value={countryCode}
									onChange={async (event, newValue) => {
										setCountryCode(newValue);
									}}
									getOptionLabel={option => {
										if (!countries[option]) {
											return '-';
										}
										const { name } = countries[option];
										return name || '-';
									}}
									renderInput={params => (
										<TextField
											className="w-full"
											fullWidth
											{...params}
											label="Land *"
											error={!countryCode}
										/>
									)}
									openOnFocus
									required
								/>
							</Grid>
						)}
					</Grid>
				</div>
				<div className="flex justify-end">
					<LoadingButton type="submit" disableLoadingOnClick loading={loading}>
						{t('UPDATE_PROFILE')}
					</LoadingButton>
				</div>
			</form>
		</Paper>
	);
}

export default AccountPage;
