import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import NavbarMobileToggleButton from '../../../shared-components/NavbarMobileToggleButton';
import { selectOrderLines } from '../../../../store/shared/cartSlice';
import { selectLogos, selectModules } from '../../../../store/shared/frontendSlice';
import UserMenu from '../../../shared-components/UserMenu';

function DarkToolbarLayout2() {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const modules = useSelector(selectModules);
	const orderLines = useSelector(selectOrderLines);
	const logos = useSelector(selectLogos);

	return (
		<Toolbar className="container p-0 lg:px-24 justify-between">
			{config.navbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-64 h-64 p-0 text-white" />
				</Hidden>
			)}
			<Hidden lgUp>
				<div className="mx-8 flex-1">
					<Link to="/" className="flex">
						<StorageImage alt="logo" className="w-full max-w-96" src={logos.header} />
					</Link>
				</div>
			</Hidden>

			<div className="flex">
				<Button component={Link} to="/cart">
					<Badge color="secondary" max={99} badgeContent={orderLines ? orderLines.length : 0}>
						<ShoppingBasketIcon className="text-white" />
					</Badge>
				</Button>
				{modules.frontendUsers && <UserMenu />}
			</div>
		</Toolbar>
	);
}

export default React.memo(DarkToolbarLayout2);
