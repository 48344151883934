import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';

export default function ReadMore(props) {
	const useStyles = makeStyles(theme => ({
		gradient: {
			background: `linear-gradient(0deg, ${props.color} 2%, ${props.color} 5%, rgba(255,255,255,0) 30%)`
		}
	}));

	const classes = useStyles();
	const [show, setShow] = useState(false);

	if (show) {
		return props.showFull;
	}
	return (
		<>
			<div className="relative">
				{props.showHalf}
				<div className={clsx(classes.gradient, 'h-full w-full absolute bottom-0')} />
			</div>
			<Button className="normal-case px-0" onClick={() => setShow(true)}>
				Læs mere...
			</Button>
		</>
	);
}
