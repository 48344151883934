const settingsConfig = {
	layout: {
		style: 'layout2', // layout-1 layout-2 layout-3
		config: {
			mode: 'fullwidth',
			scroll: 'content',
			navbar: {
				display: true,
				folded: false,
				position: 'left',
			},
			toolbar: {
				display: true,
				style: 'fixed',
				position: 'below',
			},
			footer: {
				display: true,
				style: 'static',
				position: 'below',
			},
		}, // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
	},
	customScrollbars: true,
	direction: 'ltr', // rtl, ltr
	theme: {
		main: 'amero',
		navbar: 'mainThemeLight',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeLight',
	},
	loginRedirectUrl: '/', // Default redirect url for the logged-in user
};

export default settingsConfig;
