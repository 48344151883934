import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Calendar from 'react-calendar';
import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';
import LoadingButton from '../../../../../ui-components/LoadingButton';
import { eventGroupListener, groupSummaryListener } from './RebookingFirestore';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	header: {
		background: 'none',
		color: theme.palette.text.primary
	},
	grid: { flexGrow: 1 },

	calendarPicker: {
		width: '100%',
		overflow: 'hidden',
		// backgroundColor: theme.palette.background,
		// color: theme.palette.text.primary,

		// All days as a group
		// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
		'& .react-calendar__navigation__arrow': { width: '70px', height: '40px' },

		// Weekdays - man, tir, ons...
		'& .react-calendar__month-view__weekdays__weekday abbr': { textDecoration: 'none' },

		// All days individual
		'& .react-calendar__month-view__days__day': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			padding: '10px',
			outline: '2px solid #FDFBF7'
		},

		// Active button (selected date)
		'& .react-calendar__tile.react-calendar__tile--active': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},

		// Disabled buttons (previous dates)
		'& button:disabled.react-calendar__month-view__days__day': {
			opacity: '0.3'
		},

		// Todays date
		'& .react-calendar__tile--now': {}
	}
}));
const slotCats = [
	{ title: 'Morgen', from: moment('06:00', 'HH:mm'), to: moment('08:59', 'HH:mm') },
	{ title: 'Formiddag', from: moment('09:00', 'HH:mm'), to: moment('11:59', 'HH:mm') },
	{ title: 'Eftermiddag', from: moment('12:00', 'HH:mm'), to: moment('17:59', 'HH:mm') },
	{ title: 'Aften', from: moment('18:00', 'HH:mm'), to: moment('23:59', 'HH:mm') },
	{ title: 'Nat', from: moment('00:00', 'HH:mm'), to: moment('05:59', 'HH:mm') }
];

function overdueEvent(now, end) {
	return (
		now.getFullYear() === end.getFullYear() &&
		now.getMonth() === end.getMonth() &&
		now.getDate() === end.getDate() &&
		end <= now
	);
}
function fixNum(num) {
	if (num > 9) {
		return num;
	}
	return `0${num}`;
}
function getTimeOnEvent(start, end) {
	if (start.getMinutes() !== 0 || end.getMinutes() !== 0) {
		return `${fixNum(start.getHours())}:${fixNum(start.getMinutes())} - ${fixNum(end.getHours())}:${fixNum(
			end.getMinutes()
		)}`;
	}
	return `${fixNum(start.getHours())} - ${fixNum(end.getHours())}`;
}
function TicketRebooking({
	data,
	handleRebooking,
	eventGroupUid,
	ticketCount,
	loading,
	selectedEvent,
	setSelectedEvent
}) {
	const now = new Date();

	const classes = useStyles();
	const [openBox2, setOpenBox2] = useState(true);
	const [openBox3, setOpenBox3] = useState(false);
	const [openSlotCat, setOpenSlotCat] = useState(null);
	const [eventGroup, setEventGroup] = useState(null);
	const [groupSummary, setGroupSummary] = useState(null);
	const [year, setYear] = useState(now.getFullYear());
	const [month, setMonth] = useState(now.getMonth() + 1);
	const [date, setDate] = useState(null);
	const [slots, setSlots] = useState({});

	useEffect(() => {
		if (eventGroupUid) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(eventGroupUid, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroupUid]);

	useEffect(() => {
		if (eventGroup && year && month) {
			const groupSummaryListenerUnsubFunc = groupSummaryListener(eventGroup.id, month, year, o =>
				setGroupSummary(o)
			);
			return () => groupSummaryListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, year, month]);

	useEffect(() => {
		if (date && groupSummary) {
			const s = {};
			let first = null;
			slotCats.forEach(slotCat => {
				s[slotCat.title] = _.orderBy(
					groupSummary.dates[date.getDate()]
						? Object.values(groupSummary.dates[date.getDate()].times).map(time => {
								const st = time.start.toDate();
								return {
									...time,
									start: st,
									end: time.end.toDate(),
									startFilter: moment(`${st.getHours()}:${st.getMinutes()}`, 'HH:mm')
								};
						  })
						: [],
					['start']
				).filter(
					slot =>
						slot.startFilter.isBetween(slotCat.from, slotCat.to) ||
						slot.startFilter.isSame(slotCat.from) ||
						slot.startFilter.isSame(slotCat.to)
				);
				if (s[slotCat.title].length > 0 && !first) {
					first = slotCat.title;
				}
			});
			setSlots(s);
			setOpenSlotCat(first);
		}
	}, [groupSummary, date]);
	function showAvailable(max, amount) {
		const hideAbove = max / (100 / eventGroup.hideTicketAmountAbovePercent);
		return hideAbove >= amount;
	}

	return (
		<div className={clsx(classes.grid, 'min-w-xl max-w-xl py-48 sm:py-48 mx-auto my-48')}>
			<Grid container spacing={0}>
				<Paper elevation={3} className="p-4 pb-28 pt-28 md:p-28 w-full">
					<div className="mb-24 min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
						<div className="relative mx-8">
							<Icon className="text-72" color="primary">
								book
							</Icon>
						</div>

						<div className="mx-8">
							<Typography variant="h6" className="mb-8">
								Indløsning
							</Typography>
							<Typography color="textSecondary">
								Vælg en dato og et tidspunkt du gerne vil ankomme
							</Typography>
						</div>
					</div>
					<div className="flex flex-col md:flex-row w-full ">
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col text-left px-10 pt-32 md:pt-0"
						>
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								1
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at besøge?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox2(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg dato <Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox2 &&
								(ticketCount > 0 ? (
									<Calendar
										locale="da-DK"
										next2Label={null}
										prev2Label={null}
										minDetail="month"
										onChange={value => {
											setDate(value);
											setOpenBox3(true);
										}}
										value={date}
										showNeighboringMonth={false}
										minDate={new Date()}
										className={clsx(classes.calendarPicker, 'text-center')}
										tileDisabled={tempData => {
											if (ticketCount === 0) {
												return true;
											}
											const d = tempData.date.getDate();
											if (groupSummary && groupSummary.dates[d]) {
												if (groupSummary.dates[d].availableAtSameTime >= ticketCount) {
													return false;
												}
											}
											if (
												date &&
												tempData.date.getFullYear() === date.getFullYear() &&
												tempData.date.getMonth() === date.getMonth() &&
												tempData.date.getDate() === date.getDate()
											) {
												setDate(null);
												setSelectedEvent(null);
											}
											return true;
										}}
										onActiveStartDateChange={tempData => {
											setMonth(tempData.activeStartDate.getMonth() + 1);
											setYear(tempData.activeStartDate.getFullYear());
										}}
									/>
								) : (
									<Typography className="font-bold whitespace-no-wrap text-center mt-12">
										Vælg antal billetter først
									</Typography>
								))}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col w-full px-10 pt-32 md:pt-0"
						>
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								2
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at ankomme?
							</Typography>

							<div>
								<Button
									onClick={() => {
										setOpenBox3(oldstate => !oldstate);
									}}
									color="secondary"
									variant="contained"
									className="text-center rounded-none normal-case h-28 w-full"
								>
									Vælg tidspunkt <Icon>{openBox3 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
								</Button>
							</div>
							{openBox3 &&
								(groupSummary && date && groupSummary.dates[date.getDate()] ? (
									<Grid className="mt-4" spacing={1} container>
										{slotCats
											.filter(
												slotCat => slots[slotCat.title] && slots[slotCat.title].length !== 0
											)
											.map((slotCat, index) => (
												<React.Fragment key={index}>
													<Button
														key={index}
														onClick={() => {
															setOpenSlotCat(oldState =>
																oldState === slotCat.title ? null : slotCat.title
															);
														}}
														endIcon={
															<Icon>
																{openSlotCat === slotCat.title
																	? 'arrow_drop_up'
																	: 'arrow_drop_down'}
															</Icon>
														}
														color="secondary"
														variant="contained"
														className="flex justify-between rounded-none normal-case h-28 w-full mx-4 mb-4"
													>
														{slotCat.title}
													</Button>
													{openSlotCat === slotCat.title &&
														slots[slotCat.title].map((time, index2) => (
															<Grid item xs={6} xl={6} key={`${index}-${index2}`}>
																<Button
																	color={
																		selectedEvent === time.eventUid
																			? 'primary'
																			: 'secondary'
																	}
																	variant="contained"
																	className="w-full rounded-none normal-case block h-52"
																	disabled={
																		time.available < ticketCount ||
																		overdueEvent(now, time.end)
																	}
																	onClick={() => {
																		setSelectedEvent(time.eventUid);
																	}}
																>
																	<span className="text-14 font-bold whitespace-no-wrap">
																		{getTimeOnEvent(time.start, time.end)}
																	</span>
																	<br />
																	{showAvailable(time.maxTickets, time.available) && (
																		<span className="text-12 whitespace-no-wrap font-bold">
																			{time.available > 0
																				? `${time.available} billetter tilbage`
																				: 'Udsolgt'}
																		</span>
																	)}
																</Button>
															</Grid>
														))}
												</React.Fragment>
											))}
										<Typography className="text-10 pl-6 pt-4">
											For at minimere Covid-19 smittespredning bestilles billetter i
											tidsintervaller. Køber du for eksempel en billet i tidsintervallet 13-14,
											vil din entre kun være gyldig i det givne tidsinterval. Herefter kan du
											blive så længe du ønsker.
										</Typography>
									</Grid>
								) : (
									<Typography className="font-bold whitespace-no-wrap text-center mt-12">
										Vælg dato først
									</Typography>
								))}
						</Grid>
					</div>
					<Grid container direction="row" justify="flex-end" className="">
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="px-10 pt-20 text-right">
							<LoadingButton
								className="w-full rounded-none"
								variant="contained"
								endIcon={<ArrowForwardIcon />}
								color="primary"
								onClick={handleRebooking}
								loading={loading}
								disabled={!selectedEvent}
							>
								Indløs Gave
							</LoadingButton>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
}

export default TicketRebooking;
