import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingButton from '../../../../../ui-components/LoadingButton';
import { sendSubscriptionConfirmationEmail, userListener } from '../../../../../store/shared/userSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { getParameterByName } from '../../../../../utilities';
import SubscriptionStepper from './SubscriptionStepper';
import { useTranslation } from 'react-i18next';

function SubscriptionContentVerificationNeeded() {
	const flags = useFlags();
	const [loading, setLoading] = useState(false);
	const [confirmationDisabled, setConfirmationDisabled] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(({ auth }) => auth.user);
	const customer = useSelector(({ shared }) => shared.user.user);
	const { t } = useTranslation();

	const calledOnce = useRef(false);

	useEffect(() => {
		if (user && user.claims && user.claims.organisationId && !user.claims.email_verified) {
			console.log('sub-conf', { user });
			const token = getParameterByName('verify', location.search);
			if (!calledOnce.current && !token) {
				calledOnce.current = true;
				setTimeout(() => handleConfirmationMail(), 2000);
			}
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (user && user.uid) {
			const unsubCustomer = dispatch(userListener(user.uid));
			return () => unsubCustomer();
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (customer && customer.email_verified === true && user && user.claims && !user.claims.email_verified) {
			firebaseService.refreshToken();
		}
	}, [customer, user]);

	function handleConfirmationMail() {
		setLoading(true);
		setConfirmationDisabled(true);
		dispatch(sendSubscriptionConfirmationEmail(location.pathname + location.search)).then(() => {
			setLoading(false);
			setTimeout(() => {
				setConfirmationDisabled(false);
			}, 10000);
		});
	}

	return (
		<div className="max-w-screen flex flex-col">
			<div className="w-md mx-auto py-16">
				<SubscriptionStepper activeStep={1} />
			</div>
			<Card className={`max-w-md mx-auto mb-48`}>
				<CardContent className="flex flex-col items-center justify-center p-32">
					<div className="min-w-full flex flex-col justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
						<div className="relative mx-8">
							<Icon className="text-72" color="primary">
								email
							</Icon>
						</div>

						<div className="mx-8">
							<Typography variant="h6" className="mb-8">
								{t('EMAIL_MUST_BE_CONFIRMED')}
							</Typography>
							<Typography color="textSecondary">{t('EMAIL_MUST_CONFIRMED_EXPLAINER')}</Typography>
							<Typography color="textSecondary">{t('EMAIL_SENT')}</Typography>
							<Typography color="textSecondary">{t('CHECK_SPAM')}</Typography>
						</div>
					</div>
					<div className="flex flex-col justify-center w-full">
						<div className="mx-auto mt-16">
							<LoadingButton
								variant="contained"
								color="primary"
								className="w-224"
								onClick={handleConfirmationMail}
								loading={loading}
								disabled={confirmationDisabled}
							>
								{t('RESEND_CONFIRMATION_EMAIL')}
							</LoadingButton>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

export default SubscriptionContentVerificationNeeded;
