import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useSelector } from 'react-redux';
import getColor from '../../shared/getColor';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../store/shared/frontendSlice';
import { sharedTailwindClasses } from '../../shared/sharedTailwindClasses';

function AccordionSummary(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<MuiAccordionSummary
			className={`min-h-32 ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			expandIcon={
				<ExpandMoreIcon
					className="text-14"
					style={{
						color: `${getColor(
							contentElementProps.expandIconColor,
							contentElementProps.expandIconCustomColor,
							mergedTheme
						)}`
					}}
				/>
			}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiAccordionSummary>
	);
}

export default AccordionSummary;
