import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Calendar from 'react-calendar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import subscriptionState from '@ameroservices-platform/shared/enums/subscriptionState';
import { getLocaleFromData } from '@ameroservices-platform/attraction-frontend/app/utilities';
import moment from 'moment-timezone';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { groupSummaryListener } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/event-group-date-selector/EventGroupDateSelectorFirestore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { subscriptionTypesListener } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';

let locale = 'da-DK';
const language = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { isoCode: 'da' };
if (language && language.isoCode && language.isoCode !== 'da') {
	locale = language.isoCode;
}

export default function AccountSubscriptionUpsell({ contentElementProps }) {
	const flags = useFlags();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const useStyles = makeStyles(_theme => ({
		button: {
			color: `${getColor(
				contentElementProps.buttonTextColor,
				contentElementProps.customButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buttonBackgroundColor,
				contentElementProps.customButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buttonTextColorHover,
					contentElementProps.customButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buttonBackgroundColorHover,
					contentElementProps.customButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		calendarPicker: {
			width: '100%',
			overflow: 'hidden',
			'& .react-calendar__navigation': {
				display: 'flex'
			},
			'& .react-calendar__navigation button': {
				height: '40px'
			},

			// All days as a group
			// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
			'& .react-calendar__navigation__arrow': {
				width: '70px',
				height: '40px',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: '#f0f0f0'
			},
			'& .react-calendar__navigation__label': {
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: 'transparent'
			},

			// Weekdays - man, tir, ons...
			'& .react-calendar__month-view__weekdays__weekday abbr': {
				textDecoration: 'none',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
			},

			// All days individual
			'& .react-calendar__month-view__days__day': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`,
				outline: '2px solid #FDFBF7',
				'& abbr': {
					display: 'none'
				},
				'& div': {
					padding: '10px'
				}
			},

			// Active button (selected date)
			'& .react-calendar__tile.react-calendar__tile--active': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColorActive,
					contentElementProps.customDateTimePickerBackgroundColorActive,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColorActive,
					contentElementProps.customDateTimePickerTextColorActive,
					mergedTheme
				)}`
			},

			// Disabled buttons (previous dates)
			'& button:disabled.react-calendar__month-view__days__day': {
				opacity: '0.3'
			},

			// Todays date
			'& .react-calendar__tile--now:enabled:hover': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`
			}
		}
	}));
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const allSubscriptions = useSelector(({ shared }) => shared.user.subscriptions);
	const [upsellProductCount, setUpsellProductCount] = useState(0); // State to track active subscriptions count
	const [date, setDate] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const [month, setMonth] = useState(new Date().getMonth() + 1);
	const [groupSummary, setGroupSummary] = useState(null);
	const navigate = useNavigate();
	const subscriptionTypes = useSelector(({ shared }) => shared.user.subscriptionTypes);
	const dispatch = useDispatch();

	useEffect(() => {
		const unsub = dispatch(subscriptionTypesListener());
		return () => unsub();
	}, [dispatch]);

	useEffect(() => {
		if (year && month) {
			const productsListenerUnsubFunc = groupSummaryListener(contentElementProps.eventGroupUid, month, year, o =>
				setGroupSummary(o)
			);
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [year, month, contentElementProps.eventGroupUid]);

	const activeSubscriptionsCount = useMemo(() => {
		return allSubscriptions?.filter(subscription =>
			[subscriptionState.ACTIVE, subscriptionState.PAYMENT, subscriptionState.RENEWAL].includes(
				subscription.state
			)
		).length;
	}, [allSubscriptions]);

	useEffect(() => {
		setUpsellProductCount(activeSubscriptionsCount);
	}, [activeSubscriptionsCount]);

	function handleDialogClose() {
		setOpen(false);
	}

	function handleActivitiesSubmit() {
		const products = subscriptionTypes.reduce((acc, subType) => {
			const { addonProductUid } = subType;
			if (addonProductUid) {
				acc[addonProductUid] = upsellProductCount;
			}
			return acc;
		}, {});

		if (Object.keys(products).length === 0) {
			console.error('No valid addonProductUid found');
			return;
		}

		const eventUid = Object.values(groupSummary.dates[date.getDate()].times)[0].eventUid;

		navigate('/cart', { state: { products, event: eventUid } });
		handleDialogClose();
	}

	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
				startIcon={<Icon>local_activity</Icon>}
				className={clsx('mt-10 sm:mt-0', classes.button)}
			>
				{t('BUY_ACTIVITIES')}
			</Button>
			<Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
				<DialogTitle>{t('SELECT_ACTIVITIES')}</DialogTitle>
				<DialogContent>
					<div>
						<Typography>{t('HOW_MANY_ACTIVITIES')}</Typography>
						<Select
							fullWidth
							value={upsellProductCount}
							onChange={e => setUpsellProductCount(e.target.value)}
						>
							{Array.from({ length: activeSubscriptionsCount }, (_, i) => (
								<MenuItem key={i + 1} value={i + 1}>
									{i + 1}
								</MenuItem>
							))}
						</Select>
					</div>
					{/* Calendar Integration */}
					<div className="mt-16">
						<Typography className="mb-8">{t('SELECT_DATE')}</Typography>
						<Calendar
							locale={locale || 'da-DK'}
							next2Label={null}
							prev2Label={null}
							prevLabel={<ArrowBackIcon />}
							nextLabel={<ArrowForwardIcon />}
							minDetail="month"
							onChange={value => {
								setDate(value);
							}}
							value={date}
							showNeighboringMonth={false}
							minDate={new Date()}
							className={clsx(classes.calendarPicker, 'text-center')}
							tileDisabled={tileData => {
								if (upsellProductCount === 0) {
									return true;
								}
								const d = tileData.date.getDate();
								if (groupSummary && groupSummary.dates[d]) {
									if (groupSummary.dates[d].availableAtSameTime >= upsellProductCount) {
										return false;
									}
								}
								if (
									date &&
									tileData.date.getFullYear() === date.getFullYear() &&
									tileData.date.getMonth() === date.getMonth() &&
									tileData.date.getDate() === date.getDate()
								) {
									setDate(null);
									setSelectedEvent(null);
								}
								return true;
							}}
							onActiveStartDateChange={dateData => {
								setDate(null);
								setMonth(dateData.activeStartDate.getMonth() + 1);
								setYear(dateData.activeStartDate.getFullYear());
							}}
							tileContent={d => {
								let tooltip = '';
								if (groupSummary && groupSummary.dates && groupSummary.dates[d.date.getDate()]) {
									tooltip = flags.multiLanguageFunctions
										? getLocaleFromData(groupSummary.dates[d.date.getDate()], 'displayName')
										: groupSummary.dates[d.date.getDate()].displayName || '';
								}
								return (
									<Tooltip title={tooltip}>
										<div aria-label={moment(d.date).format('DD[.] MMMM YYYY')}>
											{d.date.getDate()}
										</div>
									</Tooltip>
								);
							}}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color="primary" className={classes.button}>
						{t('CANCEL')}
					</Button>
					<Button onClick={handleActivitiesSubmit} color="primary" className={classes.button}>
						{t('CONFIRM')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
