import React from 'react';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import CustomImage from '@ameroservices-platform/shared/ui-components/CustomImage';

function MultiLanguageImageElement(props) {
	const { contentElementProps, contentElementParentProps } = props;

	return (
		<CustomImage
			src={contentElementProps.src}
			color="transparent"
			className={`${contentElementParentProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementParentProps
			})} ${contentElementParentProps.cover && 'bg-cover'}`}
			aspectRatio={Number(contentElementParentProps.aspectRatioWidth) / Number(contentElementParentProps.aspectRatioHeight)}
			cover={contentElementParentProps.cover}
			alt={''}
		/>
	);
}

export default MultiLanguageImageElement;
