import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

const faqsGroupListener = (groups, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('questions')
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.onSnapshot(
			query => {
				const faqs = [];
				query.forEach(doc => {
					const docData = doc.data();
					if (groups.includes(doc.ref.parent.parent.id) && !docData.deleted) {
						faqs.push({
							...docData,
							id: doc.id,
							parent: doc.ref.parent.parent.id
						});
					}
				});
				callback(faqs);
			},
			error => {
				console.log(error);
			}
		);
};
const faqsQuestionsListener = (questions, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('questions')
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.onSnapshot(
			query => {
				const faqs = [];
				query.forEach(doc => {
					const docData = doc.data();
					if (questions.includes(doc.id) && !docData.deleted) {
						faqs.push({
							...docData,
							id: doc.id,
							parent: doc.ref.parent.parent.id
						});
					}
				});
				callback(faqs);
			},
			error => {
				console.log(error);
			}
		);
};
const faqGroupsListener = callback => {
	const db = firebaseService.getContentRootDB();
	return db.collection('faqGroups').onSnapshot(
		query => {
			const groups = [];
			query.forEach(doc => {
				const docData = doc.data();
				if (!docData.deleted) {
					groups.push({
						...docData,
						id: doc.id
					});
				}
			});
			callback(groups);
		},
		error => {
			console.log(error);
		}
	);
};

export { faqsGroupListener, faqsQuestionsListener, faqGroupsListener };
