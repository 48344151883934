const allLanguages = Object.freeze({
	da: {
		isoCode: 'da',
		title: 'Dansk',
		flag: 'DK'
	},
	en: {
		isoCode: 'en',
		title: 'Engelsk',
		flag: 'GB'
	},
	de: {
		isoCode: 'de',
		title: 'Tysk',
		flag: 'DE'
	},
	nb: {
		isoCode: 'no',
		title: 'Norsk',
		flag: 'NO'
	},
	sv: {
		isoCode: 'sv',
		title: 'Svensk',
		flag: 'SE'
	}
});

module.exports = allLanguages;
