const requiredContactInfoOnOrder = {
	ALL: 'all',
	ALL_SHOWN_FIRST_LAST_EMAIL_REQUIRED: 'allShownFirstLastEmailRequired',
	ALL_SHOWN_FIRST_LAST_EMAIL_POSTAL_REQUIRED: 'allShownFirstLastEmailPostalRequired',
	ONLY_FIRST_LAST_EMAIL_SHOWN_AND_REQUIRED: 'onlyFirstLastEmailShownAndRequired'
};

export const requiredContactInfoOnOrderTranslate = {
	[requiredContactInfoOnOrder.ALL]: 'Alle påkrævet og vist',
	[requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_REQUIRED]:
		'Alle vist, men fornavn, efternavn og email er påkrævet',
	[requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_POSTAL_REQUIRED]:
		'Alle vist, men fornavn, efternavn, email og postnr. er påkrævet',
	[requiredContactInfoOnOrder.ONLY_FIRST_LAST_EMAIL_SHOWN_AND_REQUIRED]:
		'Kun fornavn, efternavn og email er vist og påkrævet'
};

export default requiredContactInfoOnOrder;
