const getColor = (color, customColor, theme) => {
	let returnColor = '#FFFFFF';

	switch (color) {
		case 'background - default':
			if (theme.palette.background && theme.palette.background.default) {
				returnColor = theme.palette.background.default;
			}
			break;
		case 'background - paper':
			if (theme.palette.background && theme.palette.background.paper) {
				returnColor = theme.palette.background.paper;
			}
			break;
		case 'error - contrast text':
			if (theme.palette.error && theme.palette.error.contrastText) {
				returnColor = theme.palette.error.contrastText;
			}
			break;
		case 'error - dark':
			if (theme.palette.error && theme.palette.error.dark) {
				returnColor = theme.palette.error.dark;
			}
			break;
		case 'error - light':
			if (theme.palette.error && theme.palette.error.light) {
				returnColor = theme.palette.error.light;
			}
			break;
		case 'error - main':
			if (theme.palette.error && theme.palette.error.main) {
				returnColor = theme.palette.error.main;
			}
			break;
		case 'primary - contrast text':
			if (theme.palette.primary && theme.palette.primary.contrastText) {
				returnColor = theme.palette.primary.contrastText;
			}
			break;
		case 'primary - dark':
			if (theme.palette.primary && theme.palette.primary.dark) {
				returnColor = theme.palette.primary.dark;
			}
			break;
		case 'primary - light':
			if (theme.palette.primary && theme.palette.primary.light) {
				returnColor = theme.palette.primary.light;
			}
			break;
		case 'primary - main':
			if (theme.palette.primary && theme.palette.primary.main) {
				returnColor = theme.palette.primary.main;
			}
			break;
		case 'secondary - contrast text':
			if (theme.palette.secondary && theme.palette.secondary.contrastText) {
				returnColor = theme.palette.secondary.contrastText;
			}
			break;
		case 'secondary - dark':
			if (theme.palette.secondary && theme.palette.secondary.dark) {
				returnColor = theme.palette.secondary.dark;
			}
			break;
		case 'secondary - light':
			if (theme.palette.secondary && theme.palette.secondary.light) {
				returnColor = theme.palette.secondary.light;
			}
			break;
		case 'secondary - main':
			if (theme.palette.secondary && theme.palette.secondary.main) {
				returnColor = theme.palette.secondary.main;
			}
			break;
		case 'text - primary':
			if (theme.palette.text && theme.palette.text.primary) {
				returnColor = theme.palette.text.primary;
			}
			break;
		case 'text - secondary':
			if (theme.palette.text && theme.palette.text.secondary) {
				returnColor = theme.palette.text.secondary;
			}
			break;
		case 'transparent':
			returnColor = 'transparent';
			break;
		case 'custom':
			returnColor = customColor;
			break;
		// Deprecated values---------------------------
		case 'primary':
			if (theme.palette.text && theme.palette.text.primary) {
				returnColor = theme.palette.text.primary;
			}
			break;
		case 'secondary':
			if (theme.palette.text && theme.palette.text.secondary) {
				returnColor = theme.palette.text.secondary;
			}
			break;
		case 'default':
			if (theme.palette.background && theme.palette.background.default) {
				returnColor = theme.palette.background.default;
			}
			break;
		case 'paper':
			if (theme.palette.background && theme.palette.background.paper) {
				returnColor = theme.palette.background.paper;
			}
			break;
		//---------------------------------------------
		default:
			returnColor = '#FFFFFF';
	}

	return returnColor;
};

export default getColor;
