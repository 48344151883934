import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { productsListener } from '../PricingFirestore';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	header: {
		height: 600,
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.primary.contrastText
	},
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	}
}));

function Pricing3Page(props) {
	const classes = useStyles();
	const navigate = useNavigate();

	const [data, setData] = useState([]);

	useEffect(() => {
		const productsListenerUnsubFunc = productsListener(props.data.products, o => setData(o));
		return () => productsListenerUnsubFunc();
	}, [props.data]);

	function addToCart(productId) {
		navigate('/cart', {
			state: {
				productToAdd: productId
			},
		})
	}

	return (
		<div>
			<div className={clsx(classes.header, 'flex')}>
				<div className="p-24 w-full max-w-2xl mx-auto">
					<div className="text-center my-128 mx-24">
						<FuseAnimateAdapter animation="transition.slideUpIn" duration={400} delay={100}>
							<Typography variant="h2" color="inherit" className="font-light">
								{props.data.title || ''}
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter duration={400} delay={600}>
							<Typography
								variant="subtitle1"
								color="inherit"
								className="opacity-75 mt-16 mx-auto max-w-512"
							>
								{props.data.subtitle || ''}
							</Typography>
						</FuseAnimateAdapter>
					</div>
				</div>
			</div>

			<div className="-mt-192">
				<div className="w-full max-w-2xl mx-auto">
					<div
						className="flex items-center justify-center flex-wrap"
					>
						{data.map(product => (
							<div className="w-full max-w-320 sm:w-1/3 p-12" key={product.id}>
								<Card className="relative rounded-8">
									<div className="pt-48 pb-24 text-center">
										<Typography variant="subtitle1" color="inherit" className="text-20 font-medium">
											{product.name}
										</Typography>
									</div>

									<CardContent className="text-center p-0">
										<div className="flex flex-col">
											<div className="flex justify-center mb-8">
												<Typography className="text-56 mx-4 font-light leading-none">
													{product.prices.find(p => p.type === 'Sale').price / 100}
												</Typography>
												<Typography variant="h5" color="textSecondary" className="font-medium">
													,-
												</Typography>
											</div>
											<Typography color="textSecondary" className="font-medium text-16">
												PR. PERSON
											</Typography>
										</div>
									</CardContent>

									<div className="flex justify-center py-32">
										<Button
											variant="contained"
											color="secondary"
											className="w-128"
											onClick={() => addToCart(product.id)}
										>
											KØB
										</Button>
									</div>
								</Card>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Pricing3Page;
