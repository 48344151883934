import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const eventGroupListener = (eventGroupId, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.doc(eventGroupId)
		.onSnapshot(
			q => {
				const eventGroup = q.data();
				if (eventGroup.content && typeof eventGroup.content === 'string') {
					eventGroup.content = JSON.parse(eventGroup.content);
				}
				if (eventGroup && eventGroup.state === 'active') {
					callback({
						...eventGroup,
						id: q.id
					});
				} else {
					callback(null);
				}
			},
			error => {
				console.log(error);
			}
		);
};

export const eventGroupsListener = callback => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.where('state', '==', 'active')
		.onSnapshot(
			q => {
				const eventGroups = {};
				q.forEach(doc => {
					const eventGroup = doc.data();
					if (eventGroup.content && typeof eventGroup.content === 'string') {
						eventGroup.content = JSON.parse(eventGroup.content);
					}
					eventGroups[doc.id] = {
						...eventGroup,
						id: doc.id
					};
				});
				callback(eventGroups);
			},
			error => {
				console.log(error);
			}
		);
};

export const groupSummaryListener = (eventGroupUid, month, year, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('eventGroups')
		.doc(eventGroupUid)
		.collection('groupSummaryByMonth')
		.where('month', '==', `${month}-${year}`)
		.onSnapshot(
			query => {
				const summarys = [];
				query.forEach(doc => {
					const docData = doc.data();
					summarys.push({
						...docData,
						id: doc.id
					});
				});

				if (summarys.length > 0) {
					callback(summarys[0]);
				} else {
					callback(null);
				}
			},
			error => {
				console.log(error);
			}
		);
};

export const productsListener = (ticketTypeIds, productType, callback) => {
	const db = firebaseService.getOrganisationRootDB();

	let collection = db
		.collection('products')
		.where('ticketTypeUid', 'in', ticketTypeIds)
		.where('deleted', '==', false)
		.where('state', '==', 'active')
		.where('showOnWeb', '==', true);
	if (productType) {
		collection = collection.where('type', '==', productType);
	}
	return collection.onSnapshot(
		query => {
			const products = [];
			query.forEach(doc => {
				const docData = doc.data();
				products.push({
					...docData,
					id: doc.id
				});
			});
			products.sort((p1, p2) => p1.order - p2.order);
			callback(products);
		},
		error => {
			console.log(error);
		}
	);
};

export const getEventByEventUid = async (eventGroupUid, eventUid) => {
	const db = firebaseService.getOrganisationRootDB();
	return db.collection('eventGroups').doc(eventGroupUid).collection('events').doc(eventUid).get();
};
