import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ListItem, ListItemText, Paper } from '@mui/material';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FusePageSimple from '@ameroservices-platform/attraction-frontend/fuse/core/FusePageSimple';
import { userListener } from '../../../../../../store/shared/userSlice';

const useStyles = makeStyles(theme => ({
	header: {
		background: 'none',
		color: theme.palette.text.primary
	},
	listItem: {
		color: 'inherit!important',
		textDecoration: 'none!important',
		height: 40,
		width: 'calc(100% - 16px)',
		borderRadius: '0 20px 20px 0',
		paddingLeft: 24,
		paddingRight: 12,
		'&.active': {
			backgroundColor: theme.palette.secondary.main,
			color: `${theme.palette.secondary.contrastText}!important`,
			pointerEvents: 'none',
			'& .list-item-icon': {
				color: 'inherit'
			}
		},
		'& .list-item-icon': {
			marginRight: 16
		}
	}
}));

const StyledTabs = withStyles(theme => ({
	root: {
		width: '100%',
		justifyContent: 'center'
	},
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > span': {
			width: '100%',
			height: '2px',
			backgroundColor: theme.palette.primary.main
		}
	},
	scroller: {
		flexGrow: '0'
	}
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const CustomTabScaleFontSize = withStyles(theme => ({
	root: {
		textTransform: 'none',
		color: theme.palette.primary.main,
		fontSize: '2vw'
	}
}))(Tab);

const CustomTabStaticFontSize = withStyles(theme => ({
	root: {
		textTransform: 'none',
		color: theme.palette.primary.main,
		fontSize: '14px'
	}
}))(Tab);

function AccountLayout(props) {
	const { tab, setTab } = props;
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const navigate = useNavigate();
	const user = useSelector(({ auth }) => auth.user);
	const pageLayout = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (user && user.uid) {
			const unsubCustomer = dispatch(userListener(user.uid));
			return () => unsubCustomer();
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (user && (user.isAnonymous === undefined || user.isAnonymous === true)) {
			navigate('/login');
		}
		// eslint-disable-next-line
	}, [user]);

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot,
				header: classes.header,
				leftSidebar: 'border-0'
			}}
			contentToolbar={
				<>
					<Hidden mdUp className="max-w-screen py-4">
						<StyledTabs value={tab} variant="scrollable" scrollButtons="auto" centered>
							<CustomTabScaleFontSize
								label="Informationer"
								value={0}
								onClick={() => setTab(0)}
								icon={<Icon>account_circle</Icon>}
							/>
							<CustomTabScaleFontSize
								label="Medlemskort"
								value={3}
								onClick={() => setTab(3)}
								icon={<Icon>confirmation_number</Icon>}
							/>
							<CustomTabScaleFontSize
								label="Medlemskaber"
								value={4}
								onClick={() => setTab(4)}
								icon={<Icon>loyalty</Icon>}
							/>
						</StyledTabs>
					</Hidden>
					<Hidden lgUp smDown className="max-w-screen py-4">
						<Paper square className="max-w-screen w-full shadow-none lg:shadow-1 pt-16">
							<StyledTabs value={tab} variant="scrollable" scrollButtons="auto" centered>
								{
									<CustomTabStaticFontSize
										label="Informationer"
										value={0}
										onClick={() => setTab(0)}
										icon={<Icon>account_circle</Icon>}
									/>
								}
								{/* { */}
								{/*	<CustomTabStaticFontSize */}
								{/*		label="Ordrer" */}
								{/*		value={1} */}
								{/*		onClick={() => setTab(1)} */}
								{/*		icon={<Icon>store</Icon>} */}
								{/*	/> */}
								{/* } */}
								{/* { */}
								{/*	<CustomTabStaticFontSize */}
								{/*		label="Billetter" */}
								{/*		value={2} */}
								{/*		onClick={() => setTab(2)} */}
								{/*		icon={<Icon>confirmation_number</Icon>} */}
								{/*	/> */}
								{/* } */}
								{
									<CustomTabStaticFontSize
										label="Medlemskort"
										value={3}
										onClick={() => setTab(3)}
										icon={<Icon>confirmation_number</Icon>}
									/>
								}
								{
									<CustomTabStaticFontSize
										label="Medlemskaber"
										value={4}
										onClick={() => setTab(4)}
										icon={<Icon>loyalty</Icon>}
									/>
								}
							</StyledTabs>
						</Paper>
					</Hidden>
				</>
			}
			content={
				<>
					<Hidden mdDown>
						<div className="-mt-64" />
					</Hidden>
					<div className="p-24">{props.children}</div>
				</>
			}
			leftSidebarContent={
				<div className="p-0 lg:p-24 lg:ltr:pr-4 lg:rtl:pl-4">
					<Paper className="rounded-0 shadow-none lg:rounded-8 lg:shadow-1">
						<div className="p-24 flex items-center">
							<Avatar
								alt={user && user.data && user.data.displayName ? user.data.displayName : undefined}
								src={user.data.photoURL}
							>
								{user && user.data && user.data.displayName
									? user.data.displayName.substring(0, 1)
									: ''}
							</Avatar>
							<Typography className="mx-12">{user.data.displayName}</Typography>
						</div>
						<Divider />
						<List>
							<ListItem
								button
								onClick={() => props.setTab(0)}
								className={clsx(classes.listItem, props.tab === 0 ? 'active' : '')}
							>
								<Icon className="list-item-icon text-16" color="action">
									account_circle
								</Icon>
								<ListItemText className="truncate" primary={t('YOUR_INFORMATIONS')} disableTypography />
							</ListItem>
							<ListItem
								button
								onClick={() => props.setTab(1)}
								className={clsx(classes.listItem, props.tab === 1 ? 'active' : '')}
							>
								<Icon className="list-item-icon text-16" color="action">
									store
								</Icon>
								<ListItemText className="truncate" primary={t('YOUR_ORDERS')} disableTypography />
							</ListItem>
							<ListItem
								button
								onClick={() => props.setTab(2)}
								className={clsx(classes.listItem, props.tab === 2 ? 'active' : '')}
							>
								<Icon className="list-item-icon text-16" color="action">
									confirmation_number
								</Icon>
								<ListItemText className="truncate" primary={t('YOUR_TICKETS')} disableTypography />
							</ListItem>
							<ListItem
								button
								onClick={() => props.setTab(3)}
								className={clsx(classes.listItem, props.tab === 3 ? 'active' : '')}
							>
								<Icon className="list-item-icon text-16" color="action">
									confirmation_number
								</Icon>
								<ListItemText
									className="truncate"
									primary={t('YOUR_MEMBERSHIP_CARDS')}
									disableTypography
								/>
							</ListItem>
							<ListItem
								button
								onClick={() => props.setTab(4)}
								className={clsx(classes.listItem, props.tab === 4 ? 'active' : '')}
							>
								<Icon className="list-item-icon text-16" color="action">
									loyalty
								</Icon>
								<ListItemText className="truncate" primary={t('YOUR_MEMBERSHIPS')} disableTypography />
							</ListItem>
						</List>
					</Paper>
				</div>
			}
			ref={pageLayout}
		/>
	);
}

export default AccountLayout;
