import React from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';

function overdueEvent(now, end) {
	return (
		now.getFullYear() === end.getFullYear() &&
		now.getMonth() === end.getMonth() &&
		now.getDate() === end.getDate() &&
		end <= now
	);
}

function EventGroupTimeSlot({
	openSlotCat,
	setOpenSlotCat,
	slots,
	selectedEvent,
	ticketCount,
	setSelectedEvent,
	getTimeOnEvent,
	showAvailable,
	now,
	slotCategories,
	parentClasses
}) {
	const { t } = useTranslation();

	// Dette er meget fy fy!! :o
	slotCategories = slotCategories.filter(category => slots[category.title] && slots[category.title].length !== 0);

	if (slotCategories.length === 1) {
		const timeslots = Object.values(slots).reduce((acc, cur) => {
			return acc.concat(cur);
		}, []);
		return (
			<>
				{timeslots &&
					timeslots.map((time, index2) => (
						<Grid item xs={6} xl={6} key={`-${index2}`}>
							<Button
								variant="contained"
								className={`w-full rounded-none normal-case block h-52 ${
									selectedEvent === time.eventUid
										? parentClasses.timePickerButtonActive
										: parentClasses.timePickerButton
								}`}
								disabled={time.available < ticketCount || overdueEvent(now, time.end)}
								onClick={() => {
									setSelectedEvent(time.eventUid);
								}}
							>
								<span className="text-14 font-bold whitespace-no-wrap">
									{getTimeOnEvent(time.start, time.end)}
								</span>
								<br />
								{showAvailable(time.maxTickets, time.available) && (
									<span className="text-12 whitespace-no-wrap font-bold">
										{time.showTextOnPercentage ? (
											<>
												{time.textOnTicketAmount && time.available > 0
													? time.textOnTicketAmount
													: t('SOLD_OUT')}
											</>
										) : (
											<>
												{time.available > 0
													? `${time.available} ${t('TICKETS_LEFT')}`
													: t('SOLD_OUT')}
											</>
										)}
									</span>
								)}
							</Button>
						</Grid>
					))}
			</>
		);
	}
	return (
		<>
			{slotCategories.map((slotCat, slotIndex) => (
				<React.Fragment key={slotIndex}>
					<Button
						key={slotIndex}
						onClick={() => {
							setOpenSlotCat(oldState => (oldState === slotCat.title ? null : slotCat.title));
						}}
						endIcon={<Icon>{openSlotCat === slotCat.title ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>}
						variant="contained"
						className={`flex justify-between rounded-none normal-case h-28 w-full mx-4 mb-4 ${parentClasses.button}`}
					>
						{slotCat.title}
					</Button>
					{openSlotCat === slotCat.title &&
						slots[slotCat.title].map((time, slotIndex2) => (
							<Grid item xs={6} xl={6} key={`${slotIndex}-${slotIndex2}`}>
								<Button
									variant="contained"
									className={`w-full rounded-none normal-case block h-52 ${
										selectedEvent === time.eventUid
											? parentClasses.timePickerButtonActive
											: parentClasses.timePickerButton
									}`}
									disabled={time.available < ticketCount || overdueEvent(now, time.end)}
									onClick={() => {
										setSelectedEvent(time.eventUid);
									}}
								>
									<span className="text-14 font-bold whitespace-no-wrap">
										{getTimeOnEvent(time.start, time.end)}
									</span>
									<br />
									{showAvailable(time.maxTickets, time.available) && (
										<span className="text-12 whitespace-no-wrap font-bold">
											{time.available > 0
												? `${time.available} ${t('TICKETS_LEFT')}`
												: t('SOLD_OUT')}
										</span>
									)}
								</Button>
							</Grid>
						))}
				</React.Fragment>
			))}
		</>
	);
}

export default EventGroupTimeSlot;
