import Typography from '@mui/material/Typography';
import { Button, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLocaleFromData } from '../../../../../../utilities';

const ProductRow = ({
	product,
	classes,
	addButtonToolTip,
	removeButtonToolTip,
	priceForProduct,
	setHighlightedDisclaimer,
	amount,
	updateProductAmount,
	productGroups
}) => {
	const flags = useFlags();

	const handleMouseOver = _product => {
		if (_product.disclaimerIndex !== null && _product.disclaimerIndex !== undefined) {
			setHighlightedDisclaimer(_product.disclaimerIndex);
		}
	};
	const handleMouseLeave = _product => {
		if (_product.disclaimerIndex !== null && _product.disclaimerIndex !== undefined) {
			setHighlightedDisclaimer(null);
		}
	};

	const handleClickOnRemoveProductAmount = _product => {
		const productAmount = amount || 0;
		let newAmount = productAmount - 1;
		if (productAmount === 0) {
			return;
		}
		if (flags.minimumAmountOfProduct && _product.minimumAmount && productAmount <= _product.minimumAmount) {
			newAmount = 0;
		}
		updateProductAmount(_product.id, newAmount);
	};

	const handleClickOnAddProductAmount = _product => {
		const productAmount = amount || 0;
		let newAmount = productAmount + 1;
		// first calculate min
		if (flags.minimumAmountOfProduct && _product.minimumAmount) {
			if (productAmount < _product.minimumAmount) {
				newAmount = _product.minimumAmount;
			}
		}

		if (flags.maxAmountOfProductPerProductGroupInCart) {
			const { maxTickets } = productGroups[_product.ticketTypeUid];
			if (maxTickets && maxTickets.amount && maxTickets.amount <= productAmount) {
				newAmount = maxTickets.amount;
			}
		}
		if (flags.maximumAmountOfProduct && product.maximumAmount && productAmount >= product.maximumAmount) {
			newAmount = product.maximumAmount;
		}
		updateProductAmount(_product.id, newAmount);
	};

	return (
		<div className="flex justify-between items-center">
			<div className="flex justify-between w-full">
				<Typography
					className={`font-bold whitespace-normal cursor-default ${classes.text}`}
					onMouseOver={() => handleMouseOver(product)}
					onMouseLeave={() => handleMouseLeave(product)}
				>
					{/* className="font-bold text-14 md:text-base"> */}
					{!flags.multiLanguageFunctions && <>{product.name} &nbsp;</>}
					{flags.multiLanguageFunctions && <>{getLocaleFromData(product, 'name')} &nbsp;</>}
					{product.disclaimer ? '*'.repeat(product.disclaimerIndex + 1) : ''}
				</Typography>
				<Typography className={`px-8 font-bold flex items-center ${classes.text}`}>
					{priceForProduct / 100},-
				</Typography>
			</div>
			<div className="flex ButtonDiv h-56 items-center">
				<Tooltip title={removeButtonToolTip}>
					<Button
						variant="outlined"
						onClick={() => handleClickOnRemoveProductAmount(product)}
						className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none ${classes.text}`}
					>
						<Remove />
					</Button>
				</Tooltip>
				<div className={`flex items-center text-14 font-bold px-10 ${classes.text}`}>{amount}</div>

				<Tooltip title={addButtonToolTip}>
					<Button
						variant="outlined"
						onClick={() => handleClickOnAddProductAmount(product)}
						className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none ${classes.text}`}
					>
						<Add />
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export default ProductRow;
