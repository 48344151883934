import React, { useState, useEffect } from 'react';
import { Button, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Remove, Add } from '@mui/icons-material';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { productsListener } from './EventGroupDateTimeSelectorFirestore';
import { getList, selectLists } from '../../../../../store/shared/frontendSlice';
import { getEventGroups, getEventGroupSummarys } from '../manual-event-overview/ManualEventOverviewFirestore';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 },
	location: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText
	},
	calendarPicker: {
		width: '100%',
		overflow: 'hidden',
		// backgroundColor: theme.palette.background,
		// color: theme.palette.text.primary,

		// All days as a group
		// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
		'& .react-calendar__navigation__arrow': { width: '70px', height: '40px' },

		// Weekdays - man, tir, ons...
		'& .react-calendar__month-view__weekdays__weekday abbr': { textDecoration: 'none' },

		// All days individual
		'& .react-calendar__month-view__days__day': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			padding: '10px',
			outline: '2px solid #FDFBF7'
		},

		// Active button (selected date)
		'& .react-calendar__tile.react-calendar__tile--active': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},

		// Disabled buttons (previous dates)
		'& button:disabled.react-calendar__month-view__days__day': {
			opacity: '0.3'
		},

		// Todays date
		'& .react-calendar__tile--now': {}
	}
}));

function LocationSelectorComponentCMS({ data }) {
	const [openBox1, setOpenBox1] = useState(true);
	const [openBox2, setOpenBox2] = useState(false);
	const [openBox3, setOpenBox3] = useState(false);
	const [ticketCount, setTicketCount] = useState(0);
	const [eventGroups, setEventGroups] = useState(null);
	const lists = useSelector(selectLists);
	const [groupSummary, setGroupSummary] = useState(null);
	const [locations, setLocations] = useState([]);
	const [products, setProducts] = useState([]);
	const [date, setDate] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [disclaimers, setDisclaimers] = useState({});

	const classes = useStyles();
	const navigate = useNavigate();
	const gridAttr = { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 };

	useEffect(() => {
		const ids = getList(lists, data.eventGroups).eventGroup;
		getEventGroups(ids, async eventGroupsData => {
			const eventGroupSummaries = await Promise.all(
				Object.values(eventGroupsData).map(async eg => getEventGroupSummarys(eg.id))
			);

			const onlyEventGroupsWithEvents = eventGroupSummaries.filter(group => {
				return group.length > 0;
			});

			const parentUidsOfEventGroupsWithEvents = onlyEventGroupsWithEvents
				.map(_eventGroup => {
					const value = _eventGroup.map(eg => {
						return eg.parent;
					});
					return [...new Set(value)];
				})
				.reduce((acc, cur) => {
					return acc.concat(cur);
				}, []);

			const hello = parentUidsOfEventGroupsWithEvents.reduce((acc, cur) => {
				acc[cur] = eventGroupsData[cur];
				return acc;
			}, {});

			const _eventGroups = { ...hello };
			onlyEventGroupsWithEvents.forEach(_eventGroupsSummaries => {
				const firstSummary = _eventGroupsSummaries[0];
				const lastSummary = _eventGroupsSummaries[_eventGroupsSummaries.length - 1];

				const eventGroupUid = firstSummary.parent;
				const startMonth = firstSummary.month.split('-');
				const endMonth = lastSummary.month.split('-');
				// eslint-disable-next-line prefer-destructuring
				_eventGroups[eventGroupUid].startDate = moment({
					year: Number.parseInt(startMonth[1], 10),
					month: Number.parseInt(startMonth[0], 10) - 1,
					day: Number.parseInt(Object.keys(firstSummary.dates)[0], 10),
					hour: 0,
					minute: 0,
					second: 0
				});
				const endDates = Object.keys(lastSummary.dates);
				_eventGroups[eventGroupUid].endDate = moment({
					year: Number.parseInt(endMonth[1], 10),
					month: Number.parseInt(endMonth[0], 10) - 1,
					day: Number.parseInt(endDates[endDates.length - 1], 10),
					hour: 0,
					minute: 0,
					second: 0
				});

				_eventGroups[eventGroupUid].locations = firstSummary.locations;
				_eventGroups[eventGroupUid].attributes = firstSummary.attributes;
				_eventGroups[eventGroupUid].groupSummaries = _eventGroupsSummaries;
			});

			const allLocations = [].concat(
				...Object.values(_eventGroups).map(eg => {
					return eg.locations.map(location => {
						return {
							...location,
							eventGroupUid: eg.id
						};
					});
				})
			);

			setLocations(allLocations);
			setEventGroups(Object.values(_eventGroups));
		}).catch(err => console.error(err));
	}, [lists, data.eventGroups]);

	useEffect(() => {
		if (eventGroups && selectedLocation !== null && data !== undefined && data !== null) {
			const filteredEventGroups = eventGroups
				.filter(eg => eg.id === selectedLocation.eventGroupUid)
				.map(eg => eg.ticketTypes);
			const allTicketTypes = [].concat(...filteredEventGroups);
			const productsListenerUnsubFunc = productsListener(allTicketTypes, false, productList => {
				const copyOfProducts = productList;
				const productsWithDisclaimer = productList.filter(filterForDisclaimers);
				const indexes = {};
				let index = 0;
				productsWithDisclaimer.forEach(product => {
					if (
						product.disclaimer &&
						(indexes[product.disclaimer] === undefined || indexes[product.disclaimer] === null)
					) {
						indexes[product.disclaimer] = index;
						index += 1;
					}
				});
				productsWithDisclaimer.forEach(product => {
					if (product.disclaimer) {
						product.disclaimerIndex = indexes[product.disclaimer];
					}
				});
				setDisclaimers(indexes);
				setProducts(copyOfProducts);
			});
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroups, data, selectedLocation]);

	useEffect(() => {
		if (groupSummary) {
			const dateForUse = date || new Date();
			const timeslotsForToday = groupSummary.dates[dateForUse.getDate()]
				? groupSummary.dates[dateForUse.getDate()].times
				: findDefaultGroupSummaryDates(groupSummary.dates);

			const isAvaiableInTimeslot = Object.values(timeslotsForToday).map(value => {
				return value.available >= ticketCount;
			});

			const showPickTime = isAvaiableInTimeslot.some(slot => slot === true);
			if (Object.keys(timeslotsForToday).length > 1 && showPickTime) {
				setSelectedEvent(null);
			} else if (timeslotsForToday && date) {
				const [event] = Object.values(timeslotsForToday);
				const eventStartTime = event.start.toDate();
				const isSameDay = moment(date).isSame(eventStartTime, 'day');
				if (isSameDay) {
					setSelectedEvent(event.eventUid);
				} else {
					setSelectedEvent(null);
				}
			}
		} else if (date) {
			setSelectedEvent(null);
		}
	}, [groupSummary, date, ticketCount]);

	useEffect(() => {
		setProductsWithAmount(oldState => {
			const newState = {};
			products.forEach(product => {
				if (oldState[product.id] !== null && oldState[product.id] !== undefined) {
					newState[product.id] = oldState[product.id];
				} else {
					newState[product.id] = 0;
				}
			});
			return newState;
		});
	}, [products]);

	useEffect(() => {
		if (selectedLocation) {
			const eventGroup = eventGroups.filter(eg => eg.id === selectedLocation.eventGroupUid);
			if (eventGroup.length > 0) {
				setGroupSummary(eventGroup[0].groupSummaries[0]);
			}
		}
	}, [selectedLocation, eventGroups]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		if (selectedLocation) {
			setOpenBox2(true);
		}
		if (ticketCount > 0) {
			setOpenBox3(true);
		}
		if (ticketCount === 0 && date) {
			setDate(null);
			setSelectedEvent(null);
		}
	}, [ticketCount, date, selectedLocation]);

	function goToCheckOut() {
		navigate('/cart', {
			state: {
				event: selectedEvent, products: productsWithAmount
			},
		})
	}

	function findDefaultGroupSummaryDates(groupSummaryDates) {
		const dates = Object.keys(groupSummaryDates);
		if (dates.length > 0) {
			return groupSummaryDates[dates[0]].times;
		}
		return {};
	}

	function isAllowedToBuy() {
		return !selectedEvent || Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	return (
		<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto')}>
			<Grid container spacing={0}>
				<Paper elevation={3} className="px-4 py-28 md:px-28 w-full">
					<div className="flex flex-col md:flex-row w-full ">
						<Grid item {...gridAttr} className="flex flex-col px-10 pt-0">
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								1
							</div>

							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvilken lokation vil I gerne besøge?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox1(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg lokation <Icon>{openBox1 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox1 && (
								<>
									{locations.length > 0 &&
										locations.map(location => (
											<Button
												className={`flex justify-between items-center h-56 px-16 ${
													selectedLocation && selectedLocation.id === location.id
														? classes.location
														: ''
												}`}
												key={location.id}
												onClick={() => setSelectedLocation(location)}
											>
												<div className="flex justify-between w-full">
													<Typography className="font-bold whitespace-normal cursor-default">
														{location.name}
													</Typography>
												</div>
											</Button>
										))}
								</>
							)}
						</Grid>
						<Grid item {...gridAttr} className="flex flex-col text-left px-10 pt-32 md:pt-0">
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								2
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at besøge?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox2(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg dato <Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox2 && (
								<>
									{products.map(product => (
										<div className="flex justify-between items-center" key={product.id}>
											<div className="flex justify-between w-full">
												<Typography
													className="font-bold whitespace-normal cursor-default"
													onMouseOver={() => {
														if (
															product.disclaimerIndex !== null &&
															product.disclaimerIndex !== undefined
														) {
															setHighlightedDisclaimer(product.disclaimerIndex);
														}
													}}
													onMouseLeave={() => {
														if (
															product.disclaimerIndex !== null &&
															product.disclaimerIndex !== undefined
														) {
															setHighlightedDisclaimer(null);
														}
													}}
												>
													{/* className="font-bold text-14 md:text-base"> */}
													{product.name}
													&nbsp;
													{product.disclaimer ? '*'.repeat(product.disclaimerIndex + 1) : ''}
												</Typography>
												<Typography className="px-8 font-bold flex items-center">
													{product.prices.find(f => f.type === 'Sale').price / 100},-
												</Typography>
											</div>
											<div className="flex ButtonDiv h-56 items-center">
												<Button
													variant="outlined"
													onClick={() => {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															if (p[product.id] !== 0) {
																p[product.id] -= 1;
															}
															return p;
														});
													}}
													className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
												>
													<Remove />
												</Button>
												<div className="flex items-center text-14 font-bold px-10">
													{productsWithAmount[product.id]}
												</div>
												<Button
													variant="outlined"
													onClick={() => {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															p[product.id] += 1;
															return p;
														});
													}}
													className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
												>
													<Add />
												</Button>
											</div>
										</div>
									))}
									<hr />
									<div className="flex justify-between w-full my-6">
										<Typography className="font-bold text-20">I alt</Typography>
										<Typography className="font-bold text-20">{`${
											totalAmount / 100
										} kr.`}</Typography>
									</div>
									<hr />
									<div className="flex flex-col mt-10">
										{Object.entries(disclaimers).map(([disclaimer, index]) => (
											<Typography
												key={index}
												className={`${
													highlightedDisclaimer === index ? 'text-14 font-bold' : 'text-12'
												} leading-6`}
												variant="body1"
												style={{ wordWrap: 'break-word' }}
											>
												<span className="mr-4">{'*'.repeat(index + 1)}</span>
												{disclaimer}
											</Typography>
										))}
									</div>
								</>
							)}
						</Grid>
						<Grid item {...gridAttr} className="flex flex-col w-full px-10 pt-32 md:pt-0">
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								3
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at ankomme?
							</Typography>

							<div>
								<Button
									onClick={() => {
										setOpenBox3(oldstate => !oldstate);
									}}
									color="secondary"
									variant="contained"
									className="text-center rounded-none normal-case h-28 w-full"
								>
									Vælg tidspunkt <Icon>{openBox3 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
								</Button>
							</div>
							{openBox3 &&
								(ticketCount > 0 ? (
									<Calendar
										locale="da-DK"
										next2Label={null}
										prev2Label={null}
										prevLabel={<ArrowBackIcon />}
										nextLabel={<ArrowForwardIcon />}
										minDetail="month"
										onChange={value => {
											setDate(value);
											setOpenBox3(true);
										}}
										value={date}
										showNeighboringMonth={false}
										minDate={new Date()}
										className={clsx(classes.calendarPicker, 'text-center')}
										tileDisabled={tileData => {
											if (ticketCount === 0) {
												return true;
											}
											const d = tileData.date.getDate();
											if (groupSummary && groupSummary.dates[d]) {
												if (groupSummary.dates[d].availableAtSameTime >= ticketCount) {
													return false;
												}
											}
											if (
												date &&
												tileData.date.getFullYear() === date.getFullYear() &&
												tileData.date.getMonth() === date.getMonth() &&
												tileData.date.getDate() === date.getDate()
											) {
												setDate(null);
												setSelectedEvent(null);
											}
											return true;
										}}
									/>
								) : (
									<Typography className="font-bold whitespace-no-wrap text-center mt-12">
										Vælg antal billetter først
									</Typography>
								))}
						</Grid>
					</div>
					<Grid container direction="row" justify="flex-end" className="">
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="px-10 pt-20">
							<Button
								onClick={goToCheckOut}
								className="w-full rounded-none"
								variant="contained"
								endIcon={<ArrowForwardIcon />}
								color="primary"
								disabled={isAllowedToBuy()}
							>
								KØB BILLET
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
}

export default LocationSelectorComponentCMS;
