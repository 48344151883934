import React from 'react';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import CustomImage from '@ameroservices-platform/shared/ui-components/CustomImage';

function Image(props) {
	const { contentElementProps } = props;

	return (
		<CustomImage
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			aspectRatio={Number(contentElementProps.aspectRatioWidth) / Number(contentElementProps.aspectRatioHeight)}
			cover={contentElementProps.cover}
			src={contentElementProps.src}
			color="transparent"
		/>
	);
}

export default Image;
