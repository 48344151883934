import React from 'react';
import MuiPaper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function Paper(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<MuiPaper
			elevation={contentElementProps.elevation}
			square={contentElementProps.square}
			variant={contentElementProps.variant}
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			style={{
				backgroundColor: `${getColor(
					contentElementProps.backgroundColor,
					contentElementProps.customColor,
					mergedTheme
				)}`
			}}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiPaper>
	);
}

export default Paper;
