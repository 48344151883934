import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';

function TextFieldFormsyAdapter({
	className,
	type,
	name,
	label,
	control,
	validations,
	variant,
	fullWidth,
	required,
	disabled,
	size,
	onBlur,
	multiline
}) {
	const rules = useMemo(() => {
		if (disabled) {
			return {};
		}
		let rules = validations ? validations : {};
		if (required) {
			rules.required = 'Dette felt er påkrævet';
		}
		if (rules.isEmail) {
			rules.pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		}

		return rules;
	}, [validations, required, disabled]);

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field, fieldState: { invalid, error } }) => {
				return (
					<TextField
						value={field.value || ''}
						{...field}
						error={invalid || error}
						helperText={error && error.message ? error.message : null}
						className={className}
						type={type}
						name={name}
						label={label}
						variant={variant || 'outlined'}
						fullWidth={fullWidth}
						required={required}
						disabled={disabled}
						size={size}
						onBlur={onBlur}
						multiline={multiline}
					/>
				);
			}}
		/>
	);
}

export default TextFieldFormsyAdapter;
