import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import StorageImage from '../../../../../ui-components/StorageImage';
import ReadMore from '../../../../../ui-components/ReadMore';
import HeadingComponent from '../../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../../ui-components/content-components/MarkdownComponent';
import { eventGroupListener } from '../event-group-date-selector/EventGroupDateSelectorFirestore';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';

function PapirCenteredEventContent(props) {
	const [eventGroup, setEventGroup] = useState(null);

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	useEffect(() => {
		if (props.data) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(props.data, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [props.data]);

	return (
		<>
			{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
				<div className="max-w-3xl w-full mx-16 my-48 md:mx-auto">
					<Grid container spacing={0}>
						<Paper elevation={3} className="mx-12 md:mx-16 px-4 py-28 md:px-28 w-full">
							{eventGroup && eventGroup.content && (
								<>
									<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
										{eventGroup.content.title}
									</Typography>
									<div className="flex flex-col md:flex-row">
										<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
											<StorageImage src={eventGroup.content.image} alt="Jorn udstilling" />
										</Grid>
										<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
											<div className="text-left text-16 pb-20">
												<ReadMore
													color={mergedTheme.palette.background.paper}
													showFull={
														<>
															{eventGroup.content.content.map((c, i) => {
																if (c.type === 'heading') {
																	return (
																		<HeadingComponent key={i}>
																			{c.content}
																		</HeadingComponent>
																	);
																}
																if (c.type === 'text') {
																	return (
																		<TextComponent key={i}>
																			{c.content}
																		</TextComponent>
																	);
																}
																if (c.type === 'number_list') {
																	return (
																		<ListComponent
																			key={i}
																			type="number"
																			list={c.content.array}
																			heading={c.content.heading}
																		/>
																	);
																}
																if (c.type === 'markdown') {
																	return (
																		<MarkdownComponent key={i} data={c.content} />
																	);
																}
																return <span key={i} />;
															})}
														</>
													}
													showHalf={
														<>
															{eventGroup.content.content
																.filter(c => !c.collapsed)
																.map((c, i) => {
																	if (c.type === 'heading') {
																		return (
																			<HeadingComponent key={i}>
																				{c.content}
																			</HeadingComponent>
																		);
																	}
																	if (c.type === 'text') {
																		return (
																			<TextComponent key={i}>
																				{c.content}
																			</TextComponent>
																		);
																	}
																	if (c.type === 'number_list') {
																		return (
																			<ListComponent
																				key={i}
																				type="number"
																				list={c.content.array.filter(
																					a => !a.collapsed
																				)}
																				heading={c.content.heading}
																			/>
																		);
																	}
																	if (c.type === 'markdown') {
																		return (
																			<MarkdownComponent
																				key={i}
																				data={c.content}
																			/>
																		);
																	}
																	return <span key={i} />;
																})}
														</>
													}
												/>
											</div>
										</Grid>
									</div>
								</>
							)}
						</Paper>
					</Grid>
				</div>
			)}
		</>
	);
}

export default PapirCenteredEventContent;
