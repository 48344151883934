import { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { sharedTailwindClasses } from '../../shared/sharedTailwindClasses';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { findPricesForProduct } from '../../../../../../../utilities';
import { getList, selectLists } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { useSelector } from 'react-redux';
import ListOfProductsCartProductRow from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/listOfProductsCart/ListOfProductsCartProductRow';
import { useTranslation } from 'react-i18next';

function ListOfProductsCart(props) {
	const navigate = useNavigate();
	const flags = useFlags();
	const { contentElementProps } = props;
	const [products, setProducts] = useState({});
	const [productGroups, setProductGroups] = useState({});
	const [amounts, setAmounts] = useState({});
	const [subscriptionAmounts, setSubscriptionAmounts] = useState({});
	const lists = useSelector(selectLists);
	const { t } = useTranslation();

	const productUids = useMemo(() => {
		if (!contentElementProps.listUid) {
			return [];
		}
		return getList(lists, `lists.${contentElementProps.listUid}`)?.product;
	}, [lists, contentElementProps]);

	useEffect(() => {
		if (productUids) {
			const unsubFuncs = productUids.map(productUid =>
				firebaseService
					.getOrganisationRootDB()
					.collection('products')
					.doc(productUid)
					.onSnapshot(doc => {
						const data = { ...doc.data(), id: doc.id };
						setProducts(oldState => ({ ...oldState, [productUid]: data }));
					})
			);
			return () => unsubFuncs.forEach(func => func());
		}
		return () => {};
	}, [productUids]);

	const productsWithCorrectProductUids = useMemo(() => {
		if (!products || !productUids) {
			return [];
		}
		const _products = Object.values(products).filter(product => productUids.includes(product.id));
		_products.sort((a, b) => a.order - b.order);
		return _products.map(product => {
			product.currentPrice = findPricesForProduct(product, null, null, flags.displayPeriodePrices) / 100;
			return product;
		});
	}, [products, productUids, flags.displayPeriodePrices]);

	useEffect(() => {
		if (productsWithCorrectProductUids) {
			const ticketTypeUids = productsWithCorrectProductUids.map(product => product.ticketTypeUid);
			const uniqueTicketTypeUids = ticketTypeUids.filter((item, index, self) => self.indexOf(item) === index);
			const unsubFuncs = uniqueTicketTypeUids.map(ticketTypeUid =>
				firebaseService
					.getOrganisationRootDB()
					.collection('ticketTypes')
					.doc(ticketTypeUid)
					.onSnapshot(doc => {
						const data = { ...doc.data(), id: doc.id };
						setProductGroups(oldState => ({ ...oldState, [ticketTypeUid]: data }));
					})
			);
			return () => unsubFuncs.forEach(func => func());
		}
		return () => {};
	}, [productsWithCorrectProductUids]);

	function handleAdd(product) {
		const productGroup = productGroups[product.ticketTypeUid];
		if (flags.moveCartInformationToNewPage && product.subscriptionTypeUid) {
			setSubscriptionAmounts(oldState => {
				const oldAmount = oldState[product.subscriptionTypeUid] || 0;
				if (
					productGroup &&
					productGroup.maxTickets &&
					productGroup.maxTickets.amount &&
					oldAmount >= productGroup.maxTickets.amount
				) {
					return { ...oldState, [product.subscriptionTypeUid]: oldAmount };
				}
				const newAmount = oldAmount + 1;
				return { ...oldState, [product.subscriptionTypeUid]: newAmount };
			});
		} else {
			setAmounts(oldState => {
				const oldAmount = oldState[product.id] || 0;
				if (
					productGroup &&
					productGroup.maxTickets &&
					productGroup.maxTickets.amount &&
					oldAmount >= productGroup.maxTickets.amount
				) {
					return { ...oldState, [product.id]: oldAmount };
				}
				const newAmount = oldAmount + 1;
				return { ...oldState, [product.id]: newAmount };
			});
		}
	}

	function handleRemove(product) {
		if (flags.moveCartInformationToNewPage && product.subscriptionTypeUid) {
			setSubscriptionAmounts(oldState => {
				const oldAmount = oldState[product.subscriptionTypeUid] || 0;
				if (oldAmount <= 0) {
					return { ...oldState, [product.subscriptionTypeUid]: oldAmount };
				}
				const newAmount = oldAmount - 1;
				return { ...oldState, [product.subscriptionTypeUid]: newAmount };
			});
		} else {
			setAmounts(oldState => {
				const oldAmount = oldState[product.id] || 0;
				if (oldAmount <= 0) {
					return { ...oldState, [product.id]: oldAmount };
				}
				const newAmount = oldAmount - 1;
				return { ...oldState, [product.id]: newAmount };
			});
		}
	}

	function handleBuy() {
		if (flags.moveCartInformationToNewPage) {
			navigate('/cart', { state: { products: amounts, subscriptions: subscriptionAmounts } });
		} else {
			navigate('/cart', { state: { products: amounts } });
		}
	}

	return (
		<div
			className={`min-w-320 ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{productsWithCorrectProductUids.map(product => (
				<ListOfProductsCartProductRow
					product={product}
					productGroup={productGroups[product.ticketTypeUid]}
					amount={
						flags.moveCartInformationToNewPage
							? amounts[product.id] ?? subscriptionAmounts[product.subscriptionTypeUid]
							: amounts[product.id]
					}
					contentElementProps={contentElementProps}
					onAdd={handleAdd}
					onRemove={handleRemove}
				/>
			))}
			<div className={'flex justify-end p-20 pt-10'}>
				<Button
					onClick={handleBuy}
					disabled={
						flags.moveCartInformationToNewPage
							? (Object.values(amounts).length <= 0 && Object.values(subscriptionAmounts).length <= 0) ||
							  (!Object.values(amounts).some(amount => amount > 0) &&
									!Object.values(subscriptionAmounts).some(amount => amount > 0))
							: Object.values(amounts).length <= 0 || !Object.values(amounts).some(amount => amount > 0)
					}
					color={'primary'}
					variant={'contained'}
				>
					{t('BUY')}
					<ArrowForward />
				</Button>
			</div>
		</div>
	);
}

export default ListOfProductsCart;
