import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function ReadMore2(props) {
	const { contentElementProps } = props;
	const { contentElementLinkedNodes } = props;
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(() => ({
		gradient: {
			background: `linear-gradient(0deg, ${getColor(
				contentElementProps.linearGradientColor,
				contentElementProps.customColor,
				mergedTheme
			)} 2%, ${getColor(
				contentElementProps.linearGradientColor,
				contentElementProps.customColor,
				mergedTheme
			)} 5%, rgba(255,255,255,0) 30%)`
		}
	}));

	const classes = useStyles();
	const [showMore, setShowMore] = useState(false);

	const muiTheme = useTheme();
	const screenSizeBiggerThanAndIncludingText2Breakpoint = useMediaQuery(
		muiTheme.breakpoints.up(contentElementProps.text2Breakpoint)
	);

	return (
		<div
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{!showMore ? (
				<>
					<div className="relative">
						{contentElementLinkedNodes &&
							contentElementLinkedNodes.text1 &&
							props.getCmsContentElement(contentElementLinkedNodes.text1)}
						{contentElementLinkedNodes &&
							contentElementLinkedNodes.text2 &&
							screenSizeBiggerThanAndIncludingText2Breakpoint &&
							props.getCmsContentElement(contentElementLinkedNodes.text2)}
						<div className={clsx(classes.gradient, 'h-full w-full absolute bottom-0')} />
					</div>
					<Button className="normal-case px-0" onClick={() => setShowMore(true)}>
						{t('READ_MORE')}
					</Button>
				</>
			) : (
				<>
					{contentElementLinkedNodes &&
						contentElementLinkedNodes.text1 &&
						props.getCmsContentElement(contentElementLinkedNodes.text1)}
					{contentElementLinkedNodes &&
						contentElementLinkedNodes.text2 &&
						props.getCmsContentElement(contentElementLinkedNodes.text2)}
					{contentElementLinkedNodes &&
						contentElementLinkedNodes.text3 &&
						props.getCmsContentElement(contentElementLinkedNodes.text3)}
				</>
			)}
		</div>
	);
}

export default ReadMore2;
