import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { mergeThemes, selectTheme } from '../../../store/shared/frontendSlice';
import DefaultFooter from './footers/DefaultFooter';
import Linkedin from '@ameroservices-platform/shared/ui-components/social-media/Linkedin';
import Whatsapp from '@ameroservices-platform/shared/ui-components/social-media/Whatsapp';
import Vimeo from '@ameroservices-platform/shared/ui-components/social-media/Vimeo';
import Youtube from '@ameroservices-platform/shared/ui-components/social-media/Youtube';
import Messenger from '@ameroservices-platform/shared/ui-components/social-media/Messenger';
import Instagram from '@ameroservices-platform/shared/ui-components/social-media/Instagram';
import Twitter from '@ameroservices-platform/shared/ui-components/social-media/Twitter';
import Facebook from '@ameroservices-platform/shared/ui-components/social-media/Facebook';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';

function FooterLayout2(props) {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	console.log(mergedTheme);
	const useStyles = makeStyles((_theme) => ({
		horizontalNavigation: {
			'& .list-item': {
				'& .list-item-text': {
					padding: '0',
					'& .list-item-text-primary': { fontWeight: 'bold' },
				},
				'&.active': {
					backgroundColor: mergedTheme.palette.primary.main,
					color: `${mergedTheme.palette.primary.contrastText}!important`,
				},
			},
		},
		verticalNavigation: {
			'& .list-item': {
				paddingLeft: '0',
				'& .list-item-text': {
					paddingLeft: '0',
					'& .list-item-text-primary': {
						color: `${mergedTheme.palette.footer.text}!important`,
						textAlign: 'center',
						[_theme.breakpoints.down('sm')]: {
							textAlign: 'left',
						},
					},
				},
			},
			'& .active': {
				backgroundColor: mergedTheme.palette.footer.background,
				color: `${mergedTheme.palette.footer.text}!important`,
			},
		},
	}));
	const classes = useStyles(props);
	function getSocialMediaSvg(type) {
		if (type === 'facebook') {
			return <Facebook color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'twitter') {
			return <Twitter color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'instagram') {
			return <Instagram color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'messenger') {
			return <Messenger color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'youtube') {
			return <Youtube color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'vimeo') {
			return <Vimeo color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'whatsapp') {
			return <Whatsapp color={mergedTheme.palette.footer.text} />;
		}
		if (type === 'linkedin') {
			return <Linkedin color={mergedTheme.palette.footer.text} />;
		}
		return null;
	}
	return <DefaultFooter parentClasses={classes} getSocialMediaSvg={getSocialMediaSvg} className={props.className} />;
}

export default React.memo(FooterLayout2);
