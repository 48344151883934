import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { mergeThemes, selectTheme } from '../../../store/shared/frontendSlice';
import DefaultHeader from './headers/DefaultHeader';
import DarkHeader from './headers/Dark header/DarkHeader';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';

const navbarWidth = 280;

const useStyles = makeStyles((theme) => ({
	navbar: {
		display: 'flex',
		overflow: 'hidden',
		height: 64,
		minHeight: 64,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		zIndex: 6,
	},
	navbarMFS: {
		display: 'flex',
		overflow: 'hidden',
		height: 80,
		minHeight: 80,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		zIndex: 6,
	},
	navbarLarge: {
		display: 'flex',
		overflow: 'hidden',
		height: 80,
		minHeight: 80,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		zIndex: 6,
	},
	navbarDark: {
		backgroundColor: '#000000',
		color: '#FFFFFF',
		display: 'flex',
		overflow: 'hidden',
		height: 80,
		minHeight: 80,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		zIndex: 6,
	},
	navbarMobile: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		width: navbarWidth,
		minWidth: navbarWidth,
		height: '100%',
		zIndex: 4,
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter,
		}),
		boxShadow: theme.shadows[3],
	},
	checkOutNav: {
		paddingTop: '20px',
		paddingBottom: '20px',
		height: '200px',
	},
}));

function NavbarWrapperLayout2(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const classes = useStyles(props);

	if (theme && theme.customStyling && theme.customStyling.header) {
		// TODO - Kopier hele IF blocken for at lave en ny custom styling
		if (theme.customStyling.header === 'dark') {
			return <DarkHeader parentClasses={classes} mergedTheme={mergedTheme} config={config} />;
		}
	}

	return <DefaultHeader parentClasses={classes} mergedTheme={mergedTheme} config={config} />;
}

export default React.memo(NavbarWrapperLayout2);
