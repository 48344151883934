const followProductVisualView = {
	NONE: 'none',
	AT_TOTAL: 'atTotal',
	AS_PRODUCT: 'asProduct'
};
export const followProductVisualViewTranslate = {
	none: 'Som normalt produkt',
	atTotal: 'Ved Total',
	asProduct: 'Som produkt med visning til tilhørende fulgte produkt'
};

export default followProductVisualView;
