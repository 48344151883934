import React, { useState } from 'react';
import AccountLayout from './AccountLayout';
import AccountInfoPage from './AccountInfoPage';
import AccountOrdersPage from './AccountOrdersPage';
import AccountTicketsPage from './AccountTicketsPage';
import AccountSubscriptionsPage from './AccountSubscriptionsPage';

function OldAccount({ data }) {
	const [tab, setTab] = useState(0);

	function getComponentByRoute() {
		if (tab === 1) {
			return <AccountOrdersPage data={data} />;
		}
		if (tab === 2) {
			return <AccountTicketsPage data={data} />;
		}
		if (tab === 3) {
			return <AccountTicketsPage data={data} subscription />;
		}
		if (tab === 4) {
			return <AccountSubscriptionsPage data={data} />;
		}
		return <AccountInfoPage data={data} />;
	}

	return (
		<AccountLayout tab={tab} setTab={t => setTab(t)}>
			{getComponentByRoute()}
		</AccountLayout>
	);
}

export default OldAccount;
