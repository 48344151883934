import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import LoadingButton from '../../../../../ui-components/LoadingButton';
import { useForm } from 'react-hook-form';
import { registerWithFirebase } from '../../../../../auth/store/registerSlice';
import { verifyCustomer } from '../../../../../store/shared/userSlice';
import {
	forgotPasswordWithFireBase,
	forgotPasswordWithFunction,
	submitLoginWithFireBase
} from '../../../../../auth/store/loginSlice';
// eslint-disable-next-line import/no-cycle
import SubscriptionContentLoggedIn from './SubscriptionContentLoggedIn';
import SubscriptionContentVerificationNeeded from './SubscriptionContentVerificationNeeded';
import SubscriptionContentVerification from './SubscriptionContentVerification';
import SubscriptionStepper from './SubscriptionStepper';

function SubscriptionContent({ data, queries }) {
	const flags = useFlags();
	const [alreadyExists, setAlreadyExists] = useState(null);
	const [loading, setLoading] = useState(false);
	const formRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const navigate = useNavigate();
	const login = useSelector(({ auth }) => auth.login);
	const register = useSelector(({ auth }) => auth.register);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [alert, setAlert] = useState(null);
	const { t } = useTranslation();

	const {
		formState,
		watch,
		control,
		handleSubmit,
		setValue: setInForm,
		setError,
		handleChange
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			displayName: '',
			email: '',
			password: '',
			passwordConfirm: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid } = formState;

	useEffect(() => {
		if (login.error && (login.error.username || login.error.password) && formRef && formRef.current) {
			if (login.error.username) {
				setError('email', { type: 'custom', message: login.error.username });
			}
			if (login.error.password) {
				setError('password', { type: 'custom', message: login.error.password });
			}
		}
	}, [login.error, setError]);

	useEffect(() => {
		if (register.error && (register.error.username || register.error.password || register.error.passwordConfirm)) {
			if (register.error.displayName) {
				setError('displayName', { type: 'custom', message: t(register.error.displayName) });
			}
			if (register.error.email) {
				setError('email', { type: 'custom', message: t(register.error.email) });
			}
			if (register.error.password) {
				setError('password', { type: 'custom', message: t(register.error.password) });
			}
			if (register.error.passwordConfirm) {
				setError('passwordConfirm', { type: 'custom', message: t(register.error.passwordConfirm) });
			}
		}
	}, [register.error, setError]);

	useEffect(() => {
		if (login.forgotPasswordError && login.forgotPasswordError.username) {
			setError('displayName', { type: 'custom', message: t(login.forgotPasswordError.username) });
		}
	}, [login.forgotPasswordError, setError]);

	useEffect(() => {
		if (login.forgotPasswordSuccess) {
			setAlert(
				<Alert severity="success">
					<AlertTitle>{t('EMAIL_SENT')}</AlertTitle>
					{t('IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL')}
				</Alert>
			);
		}
	}, [login.forgotPasswordSuccess, t]);

	const onSubmit = useCallback(async () => {
		setLoading(true);
		if (alreadyExists === null) {
			const resp = await verifyCustomer(form.email);
			setAlreadyExists(resp.alreadyExists);
		} else if (alreadyExists === true) {
			if (forgotPassword) {
				await dispatch(forgotPasswordWithFunction({ username: form.email }));
			} else {
				await dispatch(submitLoginWithFireBase({ username: form.email, password: form.password }));
			}
		} else {
			await dispatch(registerWithFirebase(form));
		}
		setLoading(false);
	}, [form, dispatch, alreadyExists, forgotPassword]);

	const runSubmitOnce = useRef(false);

	useEffect(() => {
		if (flags.subscriptionGiftTicket && queries && queries.email && !runSubmitOnce.current) {
			setInForm('email', queries.email);
			verifyCustomer(queries.email).then(resp => setAlreadyExists(resp.alreadyExists));
			runSubmitOnce.current = true;
		}
	}, [queries, flags.subscriptionGiftTicket, setInForm, handleSubmit]);

	if (!queries.t && !queries.s) {
		return (
			<div className="max-w-screen flex flex-col">
				<Card className="max-w-md mx-auto my-48">
					<CardContent className="flex flex-col items-center justify-center p-32">
						<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
							<div className="relative mx-8">
								<Icon className="text-72" color="primary">
									repeat
								</Icon>
							</div>

							<div className="mx-8">
								<Typography variant="h6" className="mb-8">
									{t('MEMBERSHIP')}
								</Typography>
								<Typography color="textSecondary">{t('MEMBERSHIP_TYPE_DOES_NOT_EXIST')}</Typography>
							</div>
						</div>

						<div className="flex flex-col justify-center w-full mt-32">
							<div className="flex flex-col justify-center w-full">
								<div className="mx-auto mt-16">
									<LoadingButton
										variant="contained"
										color="primary"
										className="w-224"
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('GO_BACK')}
									</LoadingButton>
								</div>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		);
	}

	if (queries && queries.verify) {
		return <SubscriptionContentVerification queries={queries} />;
	}

	if (user && user.isAnonymous === false) {
		if (user.claims.email_verified) {
			return <SubscriptionContentLoggedIn data={data} queries={queries} />;
		}
		return <SubscriptionContentVerificationNeeded queries={queries} />;
	}

	return (
		<div className="max-w-screen flex flex-col">
			<div className="mx-auto py-16">
				<SubscriptionStepper activeStep={0} />
			</div>
			<Card className={`mx-auto max-w-md mb-48`}>
				<CardContent className="flex flex-col items-center justify-center p-32">
					<div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center text-center sm:text-left -mx-8">
						<div className="relative mx-8">
							<Icon className="text-72" color="primary">
								repeat
							</Icon>
						</div>

						<div className="mx-8">
							<Typography variant="h6" className="mb-8">
								{t('CREATION_OF_MEMBERSHIP')}
							</Typography>
							<Typography color="textSecondary">{t('TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER')}</Typography>
						</div>
					</div>

					{alreadyExists === true && (
						<Alert severity="info" className="mt-24 w-full" fullWidth>
							<AlertTitle>{t('EXISTING_ACCOUNT')}</AlertTitle>
							{t('YOUR_EMAIL_ADDRESS')}
							<strong>{form.email}</strong>
							{t('ALREADY_EXISTS')}
						</Alert>
					)}
					{alreadyExists === false && (
						<Alert severity="info" className="mt-24 w-full" fullWidth>
							<AlertTitle>{t('MISSING_ACCOUNT')}</AlertTitle>
							{t('YOUR_EMAIL_ADDRESS')} <strong>{form.email}</strong> {t('EMAIL_DOES_NOT_EXIST')}
							<br />
							{t('ENTER_DETAILS')}
						</Alert>
					)}

					<div className="flex flex-col justify-center w-full mt-32">
						<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center w-full">
							{!alert && (
								<TextFieldFormsyAdapter
									control={control}
									label="Email"
									placeholder={t('MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER')}
									name="email"
									variant="outlined"
									required
									fullWidth
									value={form.email}
									onChange={handleChange}
									disabled={alreadyExists === true || alreadyExists === false}
									enableTrim
								/>
							)}
							{alreadyExists === true && (
								<>
									{alert || (
										<>
											{!forgotPassword && (
												<TextFieldFormsyAdapter
													control={control}
													className="mt-16"
													label={t('PASSWORD')}
													type="password"
													name="password"
													value={form.password}
													onChange={handleChange}
													variant="outlined"
													required
													fullWidth
												/>
											)}
											<Button
												className="mt-16 font-medium"
												onClick={() => {
													setForgotPassword(oldState => !oldState);
												}}
											>
												{forgotPassword ? t('LOGIN_INSTEAD') : t('FORGOT_PASSWORD')}
											</Button>
										</>
									)}
								</>
							)}
							{alreadyExists === false && (
								<>
									<TextFieldFormsyAdapter
										control={control}
										className="mb-16 mt-16"
										label={t('FULL_NAME')}
										autoFocus
										type="name"
										name="displayName"
										value={form.displayName}
										onChange={handleChange}
										variant="outlined"
										required
										fullWidth
									/>

									<TextFieldFormsyAdapter
										control={control}
										className="mb-16"
										label={t('PASSWORD')}
										type="password"
										name="password"
										value={form.password}
										onChange={handleChange}
										variant="outlined"
										required
										fullWidth
									/>

									<TextFieldFormsyAdapter
										control={control}
										className="mb-16"
										label={t('CONFIRM_PASSWORD')}
										type="password"
										name="passwordConfirm"
										value={form.passwordConfirm}
										onChange={handleChange}
										variant="outlined"
										required
										fullWidth
									/>
								</>
							)}

							<div className="flex flex-col justify-center w-full">
								<div className="mx-auto mt-16">
									<LoadingButton
										variant="contained"
										color="primary"
										className="w-224"
										onClick={() => {
											if (alert && forgotPassword) {
												setAlert(null);
												setForgotPassword(false);
											} else {
												handleSubmit(onSubmit)();
											}
										}}
										disableLoadingOnClick
										loading={loading}
										disabled={!isFormValid}
									>
										{alreadyExists === null
											? 'Start'
											: alreadyExists === true
											? forgotPassword
												? alert
													? t('LOGIN_AGAIN')
													: 'Send email'
												: t('LOGIN')
											: t('CREATE_ACCOUNT')}
									</LoadingButton>
								</div>
							</div>
						</form>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

export default SubscriptionContent;
