import React from 'react';

const UserResetPasswordPage = React.lazy(() => import('./UserResetPasswordPage'))

const UserResetPasswordPageConfig = {
	routes: [
		{
			path: '/account/reset-password',
			element: <UserResetPasswordPage />
		}
	]
};

export default UserResetPasswordPageConfig;
