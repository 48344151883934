import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React from 'react';
import StorageImage from '../../../../ui-components/StorageImage';
import ReadMore from '../../../../ui-components/ReadMore';
import HeadingComponent from '../../../../ui-components/content-components/HeadingComponent';
import TextComponent from '../../../../ui-components/content-components/TextComponent';
import ListComponent from '../../../../ui-components/content-components/ListComponent';
import MarkdownComponent from '../../../../ui-components/content-components/MarkdownComponent';

function DefaultContent({ eventGroup, mergedTheme }) {
	return (
		<>
			{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
				<Paper elevation={3} className="p-4 md:p-28 mx-12 md:mx-16">
					<Typography variant="h1" className="font-bold text-28 px-10 pb-20">
						{eventGroup.content.title}
					</Typography>
					<div className="flex flex-col md:flex-row">
						<Grid className="px-10 pb-20" item xs={12} sm={12} md={4} lg={4} xl={5}>
							<StorageImage src={eventGroup.content.image} alt="Jorn udstilling" />
						</Grid>
						<Grid item xs={12} sm={12} md={8} lg={8} xl={7} className="px-10 flex flex-col">
							<div className="text-left text-16 pb-20">
								<ReadMore
									color={mergedTheme.palette.background.paper}
									showFull={
										<>
											{eventGroup.content.content.map((c, index) => {
												if (c.type === 'heading') {
													return <HeadingComponent key={index}>{c.content}</HeadingComponent>;
												}
												if (c.type === 'text') {
													return <TextComponent key={index}>{c.content}</TextComponent>;
												}
												if (c.type === 'number_list') {
													return (
														<ListComponent
															key={index}
															type="number"
															list={c.content.array}
															heading={c.content.heading}
														/>
													);
												}
												if (c.type === 'markdown') {
													return (
														<MarkdownComponent
															key={index}
															data={c.content}
															linkColor={eventGroup.content.linkColor}
														/>
													);
												}
												return <span key={index} />;
											})}
										</>
									}
									showHalf={
										<>
											{eventGroup.content.content
												.filter(c => !c.collapsed)
												.map((c, index) => {
													if (c.type === 'heading') {
														return (
															<HeadingComponent key={index}>{c.content}</HeadingComponent>
														);
													}
													if (c.type === 'text') {
														return <TextComponent key={index}>{c.content}</TextComponent>;
													}
													if (c.type === 'number_list') {
														return (
															<ListComponent
																key={index}
																type="number"
																list={c.content.array.filter(a => !a.collapsed)}
																heading={c.content.heading}
															/>
														);
													}
													if (c.type === 'markdown') {
														return (
															<MarkdownComponent
																key={index}
																data={c.content}
																linkColor={eventGroup.content.linkColor}
															/>
														);
													}
													return <span key={index} />;
												})}
										</>
									}
								/>
							</div>
						</Grid>
					</div>
				</Paper>
			)}
		</>
	);
}

export default DefaultContent;
