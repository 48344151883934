function Embedded(props) {
	const { contentElementLinkedNodes } = props;

	return (
		contentElementLinkedNodes &&
		contentElementLinkedNodes.embedded &&
		props.getCmsContentElement(contentElementLinkedNodes.embedded)
	);
}

export default Embedded;
