import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedUpsellProducts } from '@ameroservices-platform/attraction-frontend/app/store/shared/cartSlice';
import { findPricesForProduct } from '@ameroservices-platform/shared/utility/index';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2,
});

// Can only be used on upsell module in cart
function UpsellModulePrice({ dataFromExecutor }) {
	const { eventGroup, upsellProducts } = dataFromExecutor;
	const selectedUpsellProducts = useSelector(selectSelectedUpsellProducts);

	const price = useMemo(() => {
		return Object.entries(selectedUpsellProducts?.[eventGroup?.id] || {}).reduce((acc, [productId, amount]) => {
			const product = upsellProducts.find(({ id }) => id === productId);
			const price = findPricesForProduct(product, null, null, true) / 100;
			return acc + amount * price;
		}, 0);
	}, [selectedUpsellProducts, upsellProducts]);


	return <>{moneyFormatter.format(price)}</>;
}

export default UpsellModulePrice;
