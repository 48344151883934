import Hidden from '@mui/material/Hidden';
import { Link, useLocation } from 'react-router-dom';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavigateNext } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Alert, Autocomplete } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
// eslint-disable-next-line import/no-cycle
import StorageImage from '../../../../../../../ui-components/StorageImage';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import { useForm } from 'react-hook-form';
import { selectCountries, selectLogos, selectModules } from '../../../../../../../store/shared/frontendSlice';
import {
	associatedCustomersListener,
	subscriptionsListener,
	updateUser,
	userListener
} from '../../../../../../../store/shared/userSlice';
import SpinnerLoading from '../../../../../../../ui-components/SpinnerLoading';
import {
	confirmSubscription,
	createSubscription,
	getProduct,
	getSubscriptionTypeCurrentEvent,
	orderListener,
	applyCode,
	validateRebooking,
	removeCode
} from './SubscriptionFirestore';
import SubscriptionStepper from './SubscriptionStepper';
import { getLocaleFromData } from '../../../../../../../utilities';
import codeTypes from '../../../../../../../ui-components/states/codeTypes';
import { useTranslation } from 'react-i18next';
import CartTermsDialog from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/cart/cart/CartTermsDialog';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

const defaultValues = {
	firstname: '',
	lastname: '',
	email: '',
	phone: '',
	postalCode: '',
	city: '',
	address: ''
};

function SubscriptionContentLoggedIn({ data, queries }) {
	const flags = useFlags();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const modules = useSelector(selectModules);
	const countries = useSelector(selectCountries);
	const location = useLocation();
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [paymentSubscription, setPaymentSubscription] = useState(false);
	const {
		watch,
		formState,
		setValue: setInForm,
		reset,
		control
	} = useForm({
		mode: 'onChange',
		defaultValues
	});
	const form = watch();
	const { isValid: isFormValid } = formState;
	const formRef = useRef(null);
	const logos = useSelector(selectLogos);
	const [subscription, setSubscription] = useState(undefined);
	const [products, setProducts] = useState({});
	const [open, setOpen] = useState(false);
	const [created, setCreated] = useState(false);
	const descriptionElementRef = useRef(null);
	const authUser = useSelector(({ auth }) => auth.user);
	const user = useSelector(({ shared }) => shared.user.user);
	const subscriptions = useSelector(({ shared }) => shared.user.subscriptions);
	const associatedCustomers = useSelector(({ shared }) => shared.user.associatedCustomers);
	const [associatedCustomerOptions, setAssociatedCustomerOptions] = useState([]);
	const [finished, setFinished] = useState(false);
	const [orderUid, setOrderUid] = useState(null);
	const [order, setOrder] = useState(null);
	const [free, setFree] = useState(false);
	const [countryCode, setCountryCode] = useState(null);
	const [currentEvent, setCurrentEvent] = useState(undefined);
	const [code, setCode] = useState('');
	const [codeError, setCodeError] = useState(null);
	const [codeLoading, setCodeLoading] = useState(false);
	const [addAnotherCode, setAddAnotherCode] = useState(false);
	const [newsletterOptIn, setNewsletterOptIn] = useState(false);
	const { t } = useTranslation();

	const codeType = useMemo(() => {
		if (
			flags.discountCodes &&
			modules &&
			modules.discountCodes &&
			flags.integrationToFlexVoucher &&
			modules &&
			modules.giftCards
		) {
			return codeTypes.DISCOUNT_CODE_AND_GIFT_CARD;
		}
		if (
			flags.discountCodes &&
			modules &&
			modules.discountCodes &&
			!(flags.integrationToFlexVoucher && modules && modules.giftCards)
		) {
			return codeTypes.DISCOUNT_CODE;
		}
		if (
			!(flags.discountCodes && modules && modules.discountCodes) &&
			flags.integrationToFlexVoucher &&
			modules &&
			modules.giftCards
		) {
			return codeTypes.GIFT_CARD;
		}
		return null;
	}, [flags.discountCodes, modules, flags.integrationToFlexVoucher]);

	const discountCodeUsed = useMemo(() => {
		if (
			flags.discountCodesForSubscriptions &&
			subscription &&
			subscription.discountCodes &&
			subscription.discountCodes.length > 0
		) {
			return subscription.discountCodes[0];
		}
		if (
			flags.discountCodesForSubscriptions &&
			subscription &&
			subscription.usedDiscountCodes &&
			subscription.usedDiscountCodes.length > 0
		) {
			return subscription.usedDiscountCodes[0];
		}
		return false;
	}, [flags.discountCodesForSubscriptions, subscription]);

	useEffect(() => {
		setAssociatedCustomerOptions([...(associatedCustomers || []), user]);
	}, [associatedCustomers, user]);

	useEffect(() => {
		if (subscription && subscription.selectedProducts) {
			Promise.all(
				[
					...Object.values(subscription.selectedProducts).reduce((acc, cur) => {
						acc.add(cur.product);
						return acc;
					}, new Set())
				].map(productUid => {
					return getProduct(productUid);
				})
			).then(productList => {
				setProducts(
					productList
						.filter(product => product)
						.reduce((acc, cur) => {
							acc[cur.id] = cur;
							return acc;
						}, {})
				);
			});
		}
	}, [subscription]);

	useEffect(() => {
		if (queries && queries.s && subscriptions && subscriptions.length > 0) {
			setSubscription(subscriptions.find(s => s.id === queries.s));
		}
	}, [subscriptions, queries]);

	useEffect(() => {
		if (subscription && subscription.subscriptionTypeUid) {
			getSubscriptionTypeCurrentEvent(subscription.subscriptionTypeUid).then(event => {
				if (event) {
					setCurrentEvent(event);
				} else {
					setCurrentEvent(null);
				}
			});
		}
	}, [subscription]);

	useEffect(() => {
		const f = {};
		if (authUser && authUser.contactInfo && authUser.contactInfo.billing) {
			Object.entries(authUser.contactInfo.billing).forEach(([key, value]) => {
				f[key] = value;
			});

			if (flags.salesToOtherCountries && authUser.contactInfo.billing.countryCode) {
				setCountryCode(authUser.contactInfo.billing.countryCode);
			}
			Object.keys(defaultValues).forEach(key => {
				if (!f[key]) {
					f[key] = defaultValues[key];
				}
			});
			reset(f);
		}
	}, [authUser, reset, flags.salesToOtherCountries]);

	useEffect(() => {
		if (authUser && authUser.uid && orderUid) {
			const unsubOrder = orderListener(authUser.uid, orderUid, o => setOrder(o));
			return () => {
				unsubOrder();
			};
		}
		return () => {};
	}, [dispatch, authUser, orderUid]);

	useEffect(() => {
		if (authUser && authUser.uid) {
			const unsubCustomer = dispatch(userListener(authUser.uid));
			const unsubSubscriptions = dispatch(subscriptionsListener(authUser.uid));
			const unsubAssociatedCustomers = dispatch(associatedCustomersListener(authUser.uid));
			return () => {
				unsubCustomer();
				unsubSubscriptions();
				unsubAssociatedCustomers();
			};
		}
		return () => {};
	}, [dispatch, authUser]);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	useEffect(() => {
		if (flags.pensoPaySubscription) {
			if (
				subscription &&
				subscription.paymentType !== 'subscription-automatic' &&
				order &&
				order.state === 'pendingPayment' &&
				order.payments &&
				order.payments.length > 0 &&
				order.payments[0].prepData &&
				order.payments[0].prepData
			) {
				if (order.payments[0].prepData.state === 'pending' && order.payments[0].prepData.link) {
					window.location.href = order.payments[0].prepData.link;
				} else if (order.payments[0].state === 'processed') {
					window.location.href = `${window.location.origin}/cart/complete/${order.orderUid}`;
				}
			} else if (
				subscription &&
				subscription.paymentType === 'subscription-automatic' &&
				subscription.payment &&
				subscription.payment.mandates &&
				subscription.payment.mandates[0] &&
				subscription.payment.mandates[0].prepData
			) {
				if (
					subscription.payment.mandates[0].state === 'pending' &&
					subscription.payment.mandates[0].prepData.link
				) {
					window.location.href = subscription.payment.mandates[0].prepData.link;
				} else if (subscription.payment.mandates[0].state === 'active') {
					window.location.href = `${window.location.origin}/cart/complete/${order.orderUid}`;
				}
			}
		} else if (
			order &&
			order.state === 'pendingPayment' &&
			order.payments &&
			order.payments.length > 0 &&
			order.payments[0].prepData &&
			order.payments[0].prepData
		) {
			if (order.payments[0].prepData.state === 'pending' && order.payments[0].prepData.link) {
				window.location.href = order.payments[0].prepData.link;
			} else if (order.payments[0].state === 'processed') {
				window.location.href = `${window.location.origin}/cart/complete/${order.orderUid}`;
			}
		}
	}, [flags.pensoPaySubscription, order, subscription]);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function getFullName(cus) {
		return `${
			cus && cus.contactInfo && cus.contactInfo && cus.contactInfo.billing.firstname
				? cus.contactInfo.billing.firstname
				: 'Ukendt'
		} ${
			cus && cus.contactInfo && cus.contactInfo && cus.contactInfo.billing.lastname
				? cus.contactInfo.billing.lastname
				: ''
		}`;
	}

	async function goToPayment() {
		setFinished(true);
		let confirmedSubscription;
		if (flags.subscriptionGiftTicket && free && queries.tn && queries.cn) {
			confirmedSubscription = await confirmSubscription(
				queries.s,
				false,
				{
					ticketNumber: queries.tn,
					controlNumber: queries.cn
				},
				flags.newsLetterOptInOnSubscription
					? {
							newsletterOptIn
					  }
					: undefined
			);
		} else {
			confirmedSubscription = await confirmSubscription(
				queries.s,
				paymentSubscription,
				undefined,
				flags.newsLetterOptInOnSubscription
					? {
							newsletterOptIn
					  }
					: undefined
			);
		}

		const { status } = confirmedSubscription;
		const uid = confirmedSubscription.orderUid;
		if (status === 'success') {
			setOrderUid(uid);
		} else {
			setFinished(false);
		}
	}

	const codesUsed = useMemo(() => {
		const _codes = [];
		if (
			flags.discountCodes &&
			subscription &&
			subscription.discountCodes &&
			subscription.discountCodes.length > 0
		) {
			_codes.push(...subscription.discountCodes.map(_code => ({ ..._code, type: 'discountCode' })));
		}
		if (
			flags.discountCodes &&
			subscription &&
			subscription.usedDiscountCodes &&
			subscription.usedDiscountCodes.length > 0
		) {
			_codes.push(...subscription.usedDiscountCodes.map(_code => ({ ..._code, type: 'discountCode' })));
		}
		if (
			flags.integrationToFlexVoucher &&
			subscription &&
			subscription.giftCards &&
			subscription.giftCards.length > 0
		) {
			_codes.push(...subscription.giftCards.map(_code => ({ ..._code, type: 'giftCard' })));
		}
		if (
			flags.integrationToFlexVoucher &&
			subscription &&
			subscription.usedGiftCards &&
			subscription.usedGiftCards.length > 0
		) {
			_codes.push(...subscription.usedGiftCards.map(_code => ({ ..._code, type: 'giftCard' })));
		}
		return _codes;
	}, [subscription, flags.discountCodes, flags.integrationToFlexVoucher]);

	async function handleApplyCode() {
		setCodeLoading(true);
		setCodeError(null);
		try {
			await applyCode(subscription.id, code);
		} catch (e) {
			setCodeError('Rabatkoden er ugyldig');
		}
		setCode('');
		setCodeLoading(false);
	}

	async function handleRemoveCode(codeToRemove) {
		setCodeLoading(true);
		setCodeError(null);
		await removeCode(subscription.id, codeToRemove.code);
		setCode('');
		setCodeLoading(false);
	}

	async function handleApplyRemoveCode() {
		if (code) {
			const codeUsed = codesUsed.find(c => c.code === code);
			if (codeUsed) {
				await handleRemoveCode(codeUsed);
			} else {
				await handleApplyCode();
			}
		}
		setAddAnotherCode(false);
	}

	function handleNewsletterOptIn(event) {
		setNewsletterOptIn(event.target.checked);
	}

	useEffect(() => {
		if (user && user.contactInfo && user.contactInfo.billing) {
			if (flags.emailSubscription) {
				setNewsletterOptIn(user.newsletterOptIn || false);
			}
		}
	}, [user, flags.emailSubscription]);

	useEffect(() => {
		if (
			flags.subscriptionGiftTicket &&
			queries &&
			queries.tn &&
			queries.cn &&
			subscription &&
			subscription.subscriptionTypeUid
		) {
			validateRebooking([queries.tn], queries.cn).then(resp => {
				if (
					resp.validated &&
					resp.subscriptionTypeUid &&
					resp.subscriptionTypeUid === subscription.subscriptionTypeUid
				) {
					setFree(true);
				} else {
					navigate('/');
				}
			});
		}
	}, [flags.subscriptionGiftTicket, queries, subscription, navigate]);

	const subscriptionSubTotal = useMemo(() => {
		if (!subscription || !subscription.subscriptionPrice) {
			return 0;
		}
		let total = subscription.subscriptionPrice;
		if (flags.discountCodesForSubscriptions && discountCodeUsed && discountCodeUsed.amount) {
			total -= discountCodeUsed.amount;
		}
		return total;
	}, [subscription, flags.discountCodesForSubscriptions, discountCodeUsed]);

	const giftCardSum = useMemo(() => {
		const sum = codesUsed.reduce((acc, cur) => acc + (cur.type === 'giftCard' ? cur.amount : 0), 0);
		return Math.min(subscriptionSubTotal, sum);
	}, [codesUsed, subscriptionSubTotal]);

	const subscriptionTotal = useMemo(() => {
		if (!subscription || !subscription.subscriptionPrice) {
			return subscriptionSubTotal;
		}
		let total = subscriptionSubTotal;
		if (flags.integrationToFlexVoucher && modules && modules.giftCards) {
			const giftCardAmount = codesUsed.reduce((acc, cur) => acc + (cur.type === 'giftCard' ? cur.amount : 0), 0);
			if (total - giftCardAmount < 0) {
				total = 0;
			} else {
				total -= giftCardAmount;
			}
		}
		return total / 100;
	}, [subscription, subscriptionSubTotal, codesUsed, flags.integrationToFlexVoucher, modules]);

	if (!queries.s && !created) {
		createSubscription(queries.t).then(response => {
			if (response) {
				const { subscriptionUid } = response;
				if (flags.subscriptionGiftTicket) {
					let search = `?s=${subscriptionUid}`;
					if (queries.tn) {
						search += `&tn=${queries.tn}`;
					}
					if (queries.cn) {
						search += `&cn=${queries.cn}`;
					}
					navigate({ pathname: location.pathname, search });
				} else {
					navigate({ pathname: location.pathname, search: `?s=${subscriptionUid}` });
				}
			} else {
				navigate(location.pathname);
			}
		});
		setCreated(true);

		return <SpinnerLoading />;
	}

	return (
		<div className="flex-none mx-auto pt-40 sm:px-48">
			<div className="max-w-screen flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<Hidden only={['lg', 'xl']}>
						<Link to="/">
							<div className="flex justify-center items-start py-16">
								<StorageImage alt="logo" className="w-2/6 max-h-full" src={logos.headerCartMobile} />
							</div>
						</Link>
					</Hidden>
					<div className="w-full pb-16">
						<SubscriptionStepper activeStep={2} />
					</div>
					<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
						<div className="p-24">
							<form
								className="flex flex-col"
								onChange={() => {
									const d = {};
									Object.keys(form).forEach(key => {
										if (form[key]) {
											d[`contactInfo.billing.${key}`] = form[key];
										}
									});
									dispatch(updateUser(authUser.uid, d));
								}}
							>
								<div className="flex">
									<Grid container spacing={0} className="sm:max-w-screen">
										<Grid item sm={12} sl={12}>
											<Typography variant="h6" className="">
												{t('YOUR_INFORMATIONS')}
											</Typography>
										</Grid>
										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="firstname"
												className="mt-8 mb-16 pr-6"
												type="text"
												name="firstname"
												label={t('FIRST_NAME')}
												value={form.firstname}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('FIRST_NAME_MIN_2_CHAR')
												}}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>
										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="lastname"
												className="mt-8 mb-16"
												type="text"
												name="lastname"
												label={t('LAST_NAME')}
												value={form.lastname}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('LAST_NAME_MIN_2_CHAR')
												}}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextFieldFormsyAdapter
												control={control}
												id="address"
												className="mt-8 mb-16"
												type="text"
												name="address"
												label={t('ADDRESS')}
												value={form.address}
												validations={{
													minLength: 2
												}}
												validationErrors={{
													minLength: t('ADDRESS_MIN_2_CHAR')
												}}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>

										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="postalCode"
												className="mt-8 mb-16 pr-6"
												type="text"
												name="postalCode"
												label={t('POSTAL_CODE')}
												value={form.postalCode}
												validations={
													!flags.salesToOtherCountries || countryCode === 'DK'
														? {
																minLength: 3,
																maxLength: 4
														  }
														: {
																minLength: 3,
																maxLength: 8
														  }
												}
												validationErrors={
													!flags.salesToOtherCountries || countryCode === 'DK'
														? {
																minLength: t('POSTAL_CODE_LENGTH'),
																maxLength: t('POSTAL_CODE_LENGTH')
														  }
														: {
																minLength: t('POSTAL_CODE_LENGTH_INT'),
																maxLength: t('POSTAL_CODE_LENGTH_INT')
														  }
												}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>
										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="city"
												className="mt-8 mb-16"
												type="text"
												name="city"
												label={t('CITY')}
												value={form.city}
												validations={{
													minLength: 1
												}}
												validationErrors={{
													minLength: t('CITY_MIN_2_CHAR')
												}}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>
										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="email"
												className="mt-8 mb-16 pr-6"
												type="text"
												name="email"
												label={t('MAIL')}
												value={form.email}
												validations={{
													isEmail: true
												}}
												validationErrors={{
													isEmail: t('EMAIL_ERROR')
												}}
												variant="standard"
												fullWidth
												disabled
												required
												enableTrim
											/>
										</Grid>
										<Grid item xs={6} sm={6}>
											<TextFieldFormsyAdapter
												control={control}
												id="phone"
												className="mt-8 mb-16"
												type="text"
												name="phone"
												label={t('PHONE_NUMBER')}
												value={form.phone}
												validations={
													!flags.salesToOtherCountries || countryCode === 'DK'
														? {
																minLength: 8,
																maxLength: 11
														  }
														: {
																minLength: 6,
																maxLength: 20
														  }
												}
												validationErrors={
													!flags.salesToOtherCountries || countryCode === 'DK'
														? {
																minLength: t('PHONE_NR_MIN_2_CHAR'),
																maxLength: t('PHONE_NR_MIN_2_CHAR')
														  }
														: {
																minLength: t('PHONE_NR_MIN_2_CHAR_INT'),
																maxLength: t('PHONE_NR_MIN_2_CHAR_INT')
														  }
												}
												variant="standard"
												fullWidth
												required
												disabled={finished}
											/>
										</Grid>
										{flags.salesToOtherCountries && (
											<Grid item sm={12}>
												<Autocomplete
													className="mt-8 mb-16 w-full"
													fullWidth
													id="countryCode"
													name="countryCode"
													options={Object.keys(countries)}
													value={countryCode}
													onChange={async (event, newValue) => {
														setCountryCode(newValue);
														dispatch(
															updateUser(authUser.uid, {
																[`contactInfo.billing.countryCode`]: newValue
															})
														);
													}}
													getOptionLabel={option => {
														if (!countries[option]) {
															return '-';
														}
														const { name } = countries[option];
														return name || '-';
													}}
													renderInput={params => (
														<TextField
															{...params}
															variant={'standard'}
															label={t('COUNTRY')}
														/>
													)}
													openOnFocus
													required
												/>
											</Grid>
										)}
									</Grid>
									<Hidden only={['xs', 'sm']}>
										<Grid container className="pl-24">
											<Grid item sm={12} xl={12} className="m-auto">
												<img src={data.image} alt="Billede" />
											</Grid>
										</Grid>
									</Hidden>
								</div>
							</form>
						</div>
					</Paper>
					<Paper elevation={3} className="mb-48 mx-12 md:mx-0 pr-12 relative">
						{/* {(orderLoading || allOrderLinesLoading || idsLoading || orderDraftLoading) && ( */}
						{/*	<div className="absolute w-full h-full bg-gray-700 bg-opacity-25 z-50"> */}
						{/*		<div className="flex justify-center items-center h-full"> */}
						{/*			<SpinnerLoading title="Indlæser..." /> */}
						{/*		</div> */}
						{/*	</div> */}
						{/* )} */}
						<Typography variant="h6" className="p-24">
							{t('PRODUCTS_IN_SUBSCRIPTION')}
						</Typography>
						<Table className="simple">
							<TableHead>
								<TableRow>
									<TableCell colSpan={2}>{t('PRODUCT')}</TableCell>
									<TableCell align="center">{t('AMOUNT')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="">
								{subscription &&
									subscription.selectedProducts &&
									Object.values(subscription.selectedProducts).map(selectedProduct => (
										<TableRow key={selectedProduct.id}>
											<TableCell>
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{!flags.multiLanguageFunctions && (
														<>
															{products[selectedProduct.product]
																? products[selectedProduct.product].name
																: t('LOADING')}
														</>
													)}
													{flags.multiLanguageFunctions && (
														<>
															{products[selectedProduct.product]
																? getLocaleFromData(
																		products[selectedProduct.product],
																		'name'
																  )
																: t('LOADING')}
														</>
													)}
												</Typography>
											</TableCell>
											<TableCell></TableCell>
											<TableCell className="" align="center">
												{selectedProduct.amount}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<Table className="simple mt-32">
							<TableBody>
								{flags.subscriptionGiftTicket &&
									free &&
									subscription &&
									subscription.subscriptionPrice && (
										<TableRow>
											<TableCell>
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{t('SUBSCRIPTION_GIFT_CARD')}
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{moneyFormatter.format((subscription.subscriptionPrice / 100) * -1)}
												</Typography>
											</TableCell>
										</TableRow>
									)}
								<TableRow>
									<TableCell>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{t('SUBTOTAL')}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{flags.subscriptionGiftTicket && free
												? moneyFormatter.format(0)
												: moneyFormatter.format(
														subscription && subscription.subscriptionPrice
															? subscription.subscriptionPrice / 100
															: 0
												  )}
										</Typography>
									</TableCell>
								</TableRow>
								{flags.discountCodesForSubscriptions && !!discountCodeUsed && (
									<TableRow>
										<TableCell>
											<Typography
												className="font-medium"
												variant="subtitle1"
												color="textSecondary"
											>
												{discountCodeUsed.name}
											</Typography>
										</TableCell>
										<TableCell align="right">
											-{moneyFormatter.format(discountCodeUsed.amount / 100)}
										</TableCell>
									</TableRow>
								)}
								{flags.discountCodesForSubscriptions && giftCardSum > 0 && (
									<TableRow>
										<TableCell>
											<Typography
												className="font-medium"
												variant="subtitle1"
												color="textSecondary"
											>
												{t('GIFT_CARD')}
											</Typography>
										</TableCell>
										<TableCell align="right">-{moneyFormatter.format(giftCardSum / 100)}</TableCell>
									</TableRow>
								)}
								{/* {totals && totals.getByTaxPercent ? ( */}
								{/*	Object.keys(totals.getByTaxPercent).map(key => ( */}
								{/*		<TableRow key={key}> */}
								{/*			<TableCell className={disabled ? '' : classes.tdLeft}> */}
								{/*				<Typography */}
								{/*					className="font-medium" */}
								{/*					variant="subtitle1" */}
								{/*					color="textSecondary" */}
								{/*				> */}
								{/*					Moms ({key / 100}%) */}
								{/*				</Typography> */}
								{/*			</TableCell> */}
								{/*			<TableCell align="right"> */}
								{/*				<Typography */}
								{/*					className="font-medium" */}
								{/*					variant="subtitle1" */}
								{/*					color="textSecondary" */}
								{/*				> */}
								{/*					{totalsLoading ? ( */}
								{/*						<CircularProgress size={24} className={classes.spinner} /> */}
								{/*					) : ( */}
								{/*						moneyFormatter.format(totals.getByTaxPercent[key] / 100) */}
								{/*					)} */}
								{/*				</Typography> */}
								{/*			</TableCell> */}
								{/*		</TableRow> */}
								{/*	)) */}
								{/* ) : ( */}
								{/*	<TableRow> */}
								{/*		<TableCell className={disabled ? '' : classes.tdLeft}> */}
								{/*			<Typography */}
								{/*				className="font-medium" */}
								{/*				variant="subtitle1" */}
								{/*				color="textSecondary" */}
								{/*			> */}
								{/*				Moms */}
								{/*			</Typography> */}
								{/*		</TableCell> */}
								{/*		<TableCell align="right"> */}
								{/*			<Typography */}
								{/*				className="font-medium" */}
								{/*				variant="subtitle1" */}
								{/*				color="textSecondary" */}
								{/*			> */}
								{/*				{orderLinesLoading.includes('totals') ? ( */}
								{/*					<CircularProgress size={24} className={classes.spinner} /> */}
								{/*				) : ( */}
								{/*					moneyFormatter.format(totals ? totals.tax / 100.0 : 0) */}
								{/*				)} */}
								{/*			</Typography> */}
								{/*		</TableCell> */}
								{/*	</TableRow> */}
								{/* )} */}
								<TableRow>
									<TableCell>
										<Typography className="font-light" variant="h4" color="textSecondary">
											Pr. periode
										</Typography>
										{currentEvent && currentEvent.start && currentEvent.end && (
											<Typography className="font-light" variant="caption" color="textSecondary">
												{t('SUBSCRIPTION_VALID_FROM')}{' '}
												{moment(currentEvent.start).format('DD-MM-YYYY')} til{' '}
												{moment(currentEvent.end).format('DD-MM-YYYY')}
											</Typography>
										)}
										{currentEvent === null && (
											<Typography className="font-light" variant="caption" color="textSecondary">
												{t('SUBSCRIPTION_VALID_FROM_FIRST_TIME')}
											</Typography>
										)}
									</TableCell>
									<TableCell align="right">
										<Typography className="font-light" variant="h4" color="textSecondary">
											{flags.subscriptionGiftTicket && free
												? moneyFormatter.format(0)
												: moneyFormatter.format(subscriptionTotal)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
					{((flags.discountCodesForSubscriptions && modules && modules.discountCodes) ||
						(flags.integrationToFlexVoucher && modules && modules.giftCards)) && (
						<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
							{codeType === codeTypes.DISCOUNT_CODE && (
								<Typography variant="h6" className="p-24 pb-20">
									{t('APPLY_DISCOUNT_CODE')}
								</Typography>
							)}
							{codeType === codeTypes.GIFT_CARD && (
								<Typography variant="h6" className="p-24 pb-20">
									{t('APPLY_GIFT_CARD_CODE')}
								</Typography>
							)}
							{codeType === codeTypes.DISCOUNT_CODE_AND_GIFT_CARD && (
								<Typography variant="h6" className="p-24 pb-20">
									{t('APPLY_DISCOUNT_OR_GIFT_CARD_CODE')}
								</Typography>
							)}
							{codeError && (
								<Alert severity="error" className="mx-16 mb-20 -mt-10">
									{codeError}
								</Alert>
							)}
							{codesUsed.map(codeUsed => (
								<div className="flex flex-col md:flex-row pb-16 px-16 mb-6">
									<TextField
										label={t('CODE')}
										variant="outlined"
										className="w-full"
										size="small"
										InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
										value={codeUsed.code}
										disabled
									/>
									<LoadingButton
										title="Fjern"
										variant="contained"
										color="primary"
										classes={{ button: 'w-full md:min-w-256' }}
										style={{ padding: '7px 16px' }}
										onClick={() => handleRemoveCode(codeUsed)}
										loading={codeLoading}
									/>
								</div>
							))}
							{codesUsed.length > 0 && !addAnotherCode && (
								<div className="flex flex-col md:flex-row pb-16 px-16 -mt-6">
									<Button onClick={() => setAddAnotherCode(true)}>
										{t('ADD_ANOTHER_GIFT_CARD')}
									</Button>
								</div>
							)}
							{(codesUsed.length <= 0 || addAnotherCode) && (
								<div className="flex flex-col md:flex-row pb-16 px-16">
									<TextField
										label={t('CODE')}
										variant="outlined"
										className="w-full"
										size="small"
										InputProps={{ className: 'rounded-b-none md:rounded-bl-sm md:rounded-r-none' }}
										value={code}
										onChange={e => {
											const val = e.currentTarget.value;
											setCode(val);
										}}
									/>
									<LoadingButton
										title={
											!code && codesUsed.length > 0
												? t('CANCEL')
												: codesUsed.some(c => c.code === code)
												? t('REMOVE')
												: t('APPLY')
										}
										endIcon={
											(code || codesUsed.length <= 0) &&
											!codesUsed.some(c => c.code === code) && <NavigateNext />
										}
										variant="contained"
										color="primary"
										classes={{ button: 'w-full md:min-w-256' }}
										style={{ padding: '7px 16px' }}
										// disabled={!code}
										onClick={handleApplyRemoveCode}
										loading={codeLoading}
									/>
								</div>
							)}
						</Paper>
					)}
					<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
						<Typography variant="h6" className="p-24">
							{t('CONFIRM')}
						</Typography>
						<div className="flex flex-col md:flex-row">
							<div>
								{flags.emailSubscription &&
									flags.showNewsletterOptInCart &&
									flags.newsLetterOptInOnSubscription && (
										<FormControlLabel
											className="pl-24 whitespace-no-wrap"
											control={
												<Checkbox
													name="newsletterOptIn"
													id="newsletterOptIn"
													checked={newsletterOptIn}
													onChange={handleNewsletterOptIn}
													color="primary"
												/>
											}
											label={
												<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
													{t('NEWSLETTER_SIGNUP')}
												</Typography>
											}
										/>
									)}
								{!flags.disableAutomaticRenewalButtonOnFrontend &&
									flags.pensoPaySubscription &&
									!(flags.subscriptionGiftTicket && free) &&
									modules &&
									modules.subscriptions &&
									modules.subscriptions.automaticRenewal && (
										<FormControlLabel
											className="pl-24 whitespace-no-wrap"
											control={
												<Checkbox
													name="paymentSubscription"
													checked={paymentSubscription}
													onChange={event => {
														setPaymentSubscription(event.target.checked);
													}}
													color="primary"
													disabled={finished}
												/>
											}
											label={
												<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
													{t('I_WILL_RENEW_SUBSCRIPTION')}{' '}
													{flags.tempNoteForPaymentOnSubscription && '*'}
												</Typography>
											}
										/>
									)}
								<FormControlLabel
									className="pl-24 whitespace-no-wrap"
									control={
										<Checkbox
											name="acceptedTerms"
											checked={acceptedTerms}
											onChange={event => {
												setAcceptedTerms(event.target.checked);
											}}
											color="primary"
											disabled={finished}
										/>
									}
									label={
										<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
											{t('I_HAVE_READ_AND_ACCEPT')}
											<Button
												className="normal-case p-0 xs:ml-0 sm:ml-4 underline"
												onClick={handleClickOpen}
											>
												{flags.pensoPaySubscription ? 'abonnement- og ' : ''}
												{t('TERMS_OF_CONDITIONS')}
											</Button>
										</Typography>
									}
								/>
							</div>

							<div className="w-full flex flex-col justify-start md:justify-end items-start md:items-end">
								<div className="px-24">
									{/* <div> */}
									{/*	<Typography className="font-bold" color="textSecondary"> */}
									{/*		10 billetter, i alt: (pris for 10 billetter) */}
									{/*	</Typography> */}
									{/* </div> */}
								</div>

								<div className="px-24">
									<Typography className="font-bold text-24" color="textSecondary">
										I alt{' '}
										{flags.subscriptionGiftTicket && free
											? moneyFormatter.format(0)
											: moneyFormatter.format(subscriptionTotal)}
									</Typography>
								</div>
							</div>
						</div>
						<div
							className={`flex flex-col md:flex-row ${
								flags.tempNoteForPaymentOnSubscription ? 'justify-between' : 'justify-end'
							} pt-24 pb-6 px-16`}
						>
							{flags.tempNoteForPaymentOnSubscription && (
								<Typography>{t('CHARGE_IS_SEVEN_DAYS_BEFORE')}</Typography>
							)}
							<LoadingButton
								title={
									flags.subscriptionGiftTicket && free
										? t('CREATE_MEMBERSHIP')
										: subscriptionTotal === 0
										? t('CREATE_MEMBERSHIP')
										: t('GO_TO_PAYMENT')
								}
								endIcon={<NavigateNext />}
								variant="contained"
								color="primary"
								classes={{ button: 'w-full md:w-256' }}
								disabled={
									!isFormValid ||
									!acceptedTerms ||
									!subscription ||
									!subscription.selectedProducts ||
									Object.keys(subscription.selectedProducts).length === 0 ||
									(flags.salesToOtherCountries && !countryCode)
								}
								onClick={goToPayment}
								loading={finished}
							/>
						</div>
					</Paper>
				</FuseScrollbars>

				<CartTermsDialog open={open} handleClose={handleClose} />
			</div>
		</div>
	);
}

export default SubscriptionContentLoggedIn;
