import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles, withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

const styles = theme => ({
	listItem: {
		marginTop: theme.spacing(1)
	},
	header: {
		marginTop: theme.spacing(2)
	},
	linkColor: {
		color: `${theme.palette.primary.contrastText} !important` // '!Important' here because Material UI keeps overwriting the style!
	}
});

export default function Markdown(props) {
	const { cmsFontFamilyAndLetterSpacing } = useFlags();
	function MarkdownParagraph(_props) {
		return (
			<Typography
				className={`${props.isCmsComponent && props.fontSize ? `text-${props.fontSize}` : ``} ${
					props.isCmsComponent && props.lineHeight ? `leading-${props.lineHeight}` : ``
				} ${
					cmsFontFamilyAndLetterSpacing && props.isCmsComponent && props.letterSpacing
						? `tracking-${props.letterSpacing}`
						: ``
				}`}
				style={{
					fontFamily:
						cmsFontFamilyAndLetterSpacing && props.isCmsComponent && props.fontFamily
							? props.fontFamily
							: undefined
				}}
			>
				{_props.children}
			</Typography>
		);
	}

	const MarkdownHeading = withStyles(styles)(({ classes, ..._props }) => {
		let variant;
		switch (_props.level) {
			case 1:
				variant = 'h3';
				break;
			case 2:
				variant = 'h4';
				break;
			case 3:
				variant = 'h5';
				break;
			case 4:
				variant = 'h6';
				break;
			case 5:
				variant = props.isCmsComponent ? 'subtitle1' : 'h6';
				break;
			case 6:
				variant = 'subtitle2';
				break;
			default:
				variant = 'h6';
				break;
		}
		return (
			<Typography
				className={props.isCmsComponent ? '' : classes.header}
				variantMapping={{
					h1: 'h1',
					h2: 'h2',
					h3: 'h1',
					h4: 'h2',
					h5: 'h3',
					h6: props.isCmsComponent ? 'h4' : 'h6',
					subtitle1: 'h5',
					subtitle2: 'h6',
					body1: 'p',
					body2: 'p'
				}}
				gutterBottom
				variant={variant}
			>
				{_props.children}
			</Typography>
		);
	});

	function MarkdownList(_props) {
		return <>{_props.ordered ? <ol>{_props.children}</ol> : <ul>{_props.children}</ul>}</>;
	}

	function MarkdownLink(_props) {
		const useStyles = makeStyles({
			root: {
				color: props.linkColor ? `${props.linkColor} !important` : '',
				backgroundColor: `transparent !important`
			}
		});
		const classes = useStyles();
		return (
			<Link className={classes.root} color="primary" href={_props.href} target="_blank">
				{_props.children}
			</Link>
		);
	}

	const MarkdownListItem = withStyles(styles)(({ classes, ..._props }) => {
		return (
			<>
				{_props.ordered ? (
					<li className={classes.listItem}>
						<div className="flex">
							<Typography
								className={`mr-4 ${
									props.isCmsComponent && props.fontSize ? `text-${props.fontSize}` : ``
								} ${props.isCmsComponent && props.lineHeight ? `leading-${props.lineHeight}` : ``}`}
								component="span"
							>
								{`${_props.index + 1}.`}
							</Typography>
							<Typography component="span">{_props.children}</Typography>
						</div>
					</li>
				) : (
					<li className={classes.listItem}>
						<div className="flex">
							<Typography
								className={`mr-4 ${
									props.isCmsComponent && props.fontSize ? `text-${props.fontSize}` : ``
								} ${props.isCmsComponent && props.lineHeight ? `leading-${props.lineHeight}` : ``}`}
								component="span"
							>
								{'● '}
							</Typography>
							<Typography component="span">{_props.children}</Typography>
						</div>
					</li>
				)}
			</>
		);
	});

	function MarkdownTable(_props) {
		return (
			<TableContainer component={Paper}>
				<Table size="small" aria-label="a dense table">
					{_props.children}
				</Table>
			</TableContainer>
		);
	}

	function MarkdownTableCell(_props) {
		return (
			<TableCell>
				<Typography>{_props.children}</Typography>
			</TableCell>
		);
	}

	function MarkdownTableRow(_props) {
		return <TableRow>{_props.children}</TableRow>;
	}

	function MarkdownTableBody(_props) {
		return <TableBody>{_props.children}</TableBody>;
	}

	function MarkdownTableHead(_props) {
		return <TableHead>{_props.children}</TableHead>;
	}

	const renderers = {
		heading: MarkdownHeading,
		h1: (props) => <MarkdownHeading {...props} />,
		h2: (props) => <MarkdownHeading {...props} />,
		h3: (props) => <MarkdownHeading {...props} />,
		h4: (props) => <MarkdownHeading {...props} />,
		h5: (props) => <MarkdownHeading {...props} />,
		h6: (props) => <MarkdownHeading {...props} />,
		p: MarkdownParagraph,
		a: MarkdownLink,
		ul: MarkdownList,
		li: MarkdownListItem,
		table: MarkdownTable,
		thead: MarkdownTableHead,
		tbody: MarkdownTableBody,
		tr: MarkdownTableRow,
		td: MarkdownTableCell
	};

	return <ReactMarkdown escapeHtml={false} components={renderers} children={props.source} {...props} />;
}
