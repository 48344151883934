import React from 'react';
import Grid from '@mui/material/Grid';

function Root(props) {
	const { contentElementNodes } = props;

	return (
		<Grid container direction="column">
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</Grid>
	);
}

export default Root;
