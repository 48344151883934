import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { DeleteOutline } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const ProductTotalCell = ({ orderLine, onAmountChange, finished, orderLinesLoading, moneyFormatter, classes }) => {
	return (
		<div className="h-full flex flex-col relative">
			<Hidden only={['sm', 'md', 'lg', 'xl']}>
				<Tooltip title="Fjern fra kurv">
					<div className="h-24 flex justify-end items-start absolute top-0 right-0">
						<Button
							size="small"
							className="p-0 min-w-0"
							onClick={() => onAmountChange(orderLine, 0)}
							disabled={finished}
						>
							<DeleteOutline />
						</Button>
					</div>
				</Tooltip>
			</Hidden>
			<Typography className="my-auto font-medium my-auto" variant="subtitle1" color="textSecondary">
				{orderLinesLoading.includes(orderLine.id) ? (
					<CircularProgress size={24} className={classes.spinner} />
				) : (
					moneyFormatter.format(orderLine.linePrice / 100)
				)}
			</Typography>
		</div>
	);
};

export default ProductTotalCell;
