import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Button, ButtonGroup } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ManualEventTableProductAmount from './ManualEventTableProductAmount';

const useStyles = makeStyles(theme => ({
	number: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	readMoreButton: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
	eventName: {}
}));
function ManualEventTableRow({ event, readMoreLinkTo, readMoreButtonColor, buyButtonColor }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(null);
	const { t } = useTranslation();

	function showAvailable(max, amount) {
		const hideAbove = max / (100 / event.hideTicketAmountAbovePercent);
		return hideAbove >= amount;
	}

	return (
		<Grid item xs={12} md={12} className="px-8 max-w-full" key={event.eventUid}>
			<div className="grid grid-rows-2 grid-cols-1 md:grid-cols-3 md:grid-rows-1">
				<div className="col-span-2 grid grid-cols-10 col-gap-16 place-items-center">
					<div className={clsx(classes.number, 'px-20 w-64 mr-12 col-span-2 sm:col-span-1')}>
						<Typography className="font-bold">{event.start.format('MMM').toUpperCase()}</Typography>
						<Typography className="font-bold text-18">{event.start.format('DD')}</Typography>
						<Typography className="font-bold">{event.start.format('YYYY')}</Typography>
					</div>
					<div
						className={clsx(
							classes.eventName,
							'col-span-5 sm:col-span-6 flex flex-col justify-center w-full'
						)}
					>
						<Typography className="whitespace-no-wrap"> {event.start.format('dddd [-] HH:mm')}</Typography>
						<Typography className="font-bold truncate" title={event.name}>
							{event.name}
						</Typography>
					</div>
					<div className="ml-auto col-span-3 w-full flex items-center">
						<Typography className="font-bold truncate" title={event.locations}>
							{event.locations}
						</Typography>
					</div>
				</div>
				<div className="w-full md:w-320 col-span-1 md:ml-auto flex flex-col items-center">
					<ButtonGroup className="w-full">
						<Button
							className={`w-2/5 mt-6 rounded-none max-h-44 ${classes.readMoreButton}`}
							variant="outlined"
							color="primary"
							onClick={() => navigate(readMoreLinkTo.replace(':eventGroupUid', event.eventGroupUid))}
							style={readMoreButtonColor ? { backgroundColor: readMoreButtonColor } : {}}
						>
							{t('READ_MORE')}
						</Button>
						<Button
							className="w-3/5 mt-6 rounded-none max-h-44 "
							variant="contained"
							color="primary"
							onClick={() => setOpenDialog(event)}
							style={buyButtonColor ? { backgroundColor: buyButtonColor } : {}}
						>
							<div className="flex flex-col max-w-full min-h-28">
								<span className="text-14 font-bold">
									{t('BUY_TICKET')} <ArrowForward />
								</span>

								{showAvailable(event.maxTickets, event.available) && (
									<span className="text-12 whitespace-no-wrap font-bold">
										{event.showTextOnPercentage ? (
											<>
												{event.textOnTicketAmount && event.available > 0
													? event.textOnTicketAmount
													: t('SOLD_OUT')}
											</>
										) : (
											<>
												{event.available > 0
													? `${event.available} ${t('TICKETS_LEFT')}`
													: t('SOLD_OUT')}
											</>
										)}
									</span>
								)}
							</div>
						</Button>
					</ButtonGroup>
				</div>
			</div>
			<ManualEventTableProductAmount
				open={!!openDialog}
				event={openDialog}
				handleClose={() => setOpenDialog(null)}
				readMoreLinkTo={readMoreLinkTo}
			/>
			<hr className="my-0 md:my-10" />
		</Grid>
	);
}

export default ManualEventTableRow;
