import firebaseService from '@ameroservices-platform/shared/services/firebase';

const ticketTypeType = {
	TICKETS: 'tickets',
	ENTRANCE_TICKETS: 'entranceTickets',
	EVENT_TICKETS: 'eventTickets',
	FEES: 'fees',
	SUBSCRIPTION_GIFTS: 'subscriptionGifts',
	SUBSCRIPTION_TICKETS: 'subscriptionTickets',
	GIFT_CARDS: 'giftCards',
	VOUCHERS: 'vouchers',
	BOOTH_SALE: 'boothSale',
	BOOTH: 'booth',
};

export const ticketTypeTypeTranslate = {
	[ticketTypeType.TICKETS]: 'Billetter',
	[ticketTypeType.FEES]: 'Gebyrer',
	[ticketTypeType.SUBSCRIPTION_GIFTS]: 'Medlemskabsgaver',
	[ticketTypeType.SUBSCRIPTION_TICKETS]: 'Medlemskort',
	[ticketTypeType.ENTRANCE_TICKETS]: 'Entrébilletter',
	[ticketTypeType.EVENT_TICKETS]: 'Begivenhedsbilletter',
	[ticketTypeType.GIFT_CARDS]: 'Gavekort',
	[ticketTypeType.VOUCHERS]: 'Kuponer',
	[ticketTypeType.BOOTH_SALE]: 'Standsalg',
	[ticketTypeType.BOOTH]: 'Stand',
};

export default ticketTypeType;

export const ticketTypeTypeFilter = ([key, text], flags) => {
	if (key === ticketTypeType.SUBSCRIPTION_GIFTS) {
		return firebaseService.getOrganisationModule('giftSubscriptions');
	}
	if (key === ticketTypeType.SUBSCRIPTION_TICKETS) {
		return firebaseService.getOrganisationModule('subscriptions');
	}
	if (key === ticketTypeType.GIFT_CARDS) {
		return firebaseService.getOrganisationModule('giftCards');
	}
	if (key === ticketTypeType.VOUCHERS) {
		return firebaseService.getOrganisationModule('upsell');
	}
	if (flags && flags.productGroupTypes) {
		if (key === ticketTypeType.EVENT_TICKETS) {
			return (
				firebaseService.getOrganisationModule('automaticEvents') ||
				firebaseService.getOrganisationModule('manualEvents')
			);
		}
		if (key === ticketTypeType.ENTRANCE_TICKETS) {
			return true;
		}
		if (key === ticketTypeType.TICKETS) {
			return false;
		}
	} else if ([ticketTypeType.ENTRANCE_TICKETS, ticketTypeType.EVENT_TICKETS].includes(key)) {
		return false;
	}
	return true;
};
