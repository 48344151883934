import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, ThemeProvider } from '@mui/styles';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import LogoCheckout from '../../../../shared-components/LogoCheckout';
import { selectNavigation } from '../../../../../store/fuse/navigationSlice';
import DarkToolbarLayout2 from './DarkToolbarLayout2';
import FuseNavigation from '@ameroservices-platform/attraction-frontend/fuse/core/FuseNavigation';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import Logo from '../../../../shared-components/LogoCheckout';

const useStyles = makeStyles((theme) => ({
	horizontalNavigation: {
		'& .list-item': {
			color: '#FFFFFF',
			'& .list-item-text': {
				padding: '0',
				'& .list-item-text-primary': { fontWeight: 'bold' },
			},
			'&.active': {
				backgroundColor: '#FFFFFF',
				color: `#000000`,
			},
		},
	},
	verticalNavigation: {
		'& .list-item': {
			color: '#FFFFFF',
			'& .list-item-text': {
				padding: '0',
				'& .list-item-text-primary': { fontWeight: 'bold' },
			},
			'&.active': {
				backgroundColor: '#FFFFFF',
				color: `#000000`,
			},
		},
	},
}));

function DarkNavbarLayout2(props) {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const navigation = useSelector(selectNavigation);
	const classes = useStyles(props);

	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);

	if (config.hideNavigation) {
		return (
			<div className="flex flex-auto items-center w-full max-w-3xl mx-auto container p-0 lg:px-24">
				<div className="flex items-center justify-between w-full">
					<Link to="/">
						<div className="flex flex-shrink-0 items-center px-56 w-256 h-256">
							<LogoCheckout className="" />
						</div>
					</Link>
					<div className="flex px-56 items-center">
						<Typography className="font-bold">
							<LockIcon /> Denne side er krypteret og dine oplysninger vil blive behandlet fortroligt
						</Typography>
					</div>
				</div>
			</div>
		);
	}
	return (
		<ThemeProvider theme={mergedTheme}>
			<div className="flex flex-auto justify-between items-center w-full max-w-3xl mx-auto h-full container p-0 lg:px-10">
				<div className="flex items-center">
					<Link to="/">
						<div className="flex flex-shrink-0 pr-56 mt-10">
							<Logo />
						</div>
					</Link>

					<FuseScrollbars className="flex h-full items-center">
						<FuseNavigation
							className={clsx('navigation')}
							navigation={navigation}
							layout="horizontal"
							classes={{
								horizontalNavigation: classes.horizontalNavigation,
								verticalNavigation: classes.verticalNavigation,
							}}
						/>
					</FuseScrollbars>
				</div>

				<div className="flex items-center px-16">
					<DarkToolbarLayout2 />
				</div>
			</div>
		</ThemeProvider>
	);
}

export default React.memo(DarkNavbarLayout2);
