import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		if (error.message) {
			console.log(error.message);
		}
		// You can also log the error to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="flex flex-col flex-1 items-center justify-center p-16">
					<style>{`body { background-color: #ffffff !important; }`}</style>
					<div className="max-w-512 text-center">
						<FuseAnimateAdapter animation="transition.expandIn" delay={100}>
							<Typography variant="h1" color="inherit" className="font-medium mb-16">
								Fejl 500
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={500}>
							<Typography variant="h3" color="inherit" className="mb-16 text-black">
								Der skete en fejl
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={600}>
							<Typography variant="h5" color="inherit" className="mb-48 text-black">
								Prøv venligst igen om et par minutter
							</Typography>
						</FuseAnimateAdapter>

						<Link
							className="font-medium"
							to="/"
							onClick={() => {
								window.location.reload();
							}}
						>
							Genindlæs siden
						</Link>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}
