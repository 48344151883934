import { ThemeProvider } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavbarLayout2 from '../NavbarLayout2';
import { navbarCloseMobile } from '../../../../store/fuse/navbarSlice';
import NavbarMobileLayout2 from '../NavbarMobileLayout2';
import NavbarMobileToggleFab from '../../../shared-components/NavbarMobileToggleFab';
import {
	selectCheckoutHeader,
	selectCheckoutHeaderUrls,
	selectHeader,
	selectMobileNavigationMenu,
} from '../../../../store/shared/frontendSlice';
import CmsContentElement from '../../../../main/apps/content/cmsContent/CmsContentElement';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CmsLayoutElement
	from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsLayoutElement/CmsLayoutElement';
import layoutElementTypes
	from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsLayoutElement/layoutElementTypes';

function DefaultHeader({ parentClasses, mergedTheme, config }) {
	const flags = useFlags();

	const dispatch = useDispatch();
	const navbar = useSelector(({ fuse }) => fuse.navbar);

	const location = useLocation();

	const header = useSelector(selectHeader);
	const mobileNavigationMenu = useSelector(selectMobileNavigationMenu);
	const checkoutHeader = useSelector(selectCheckoutHeader);
	const checkoutHeaderUrls = useSelector(selectCheckoutHeaderUrls);

	return (
		<>
			<ThemeProvider theme={mergedTheme}>
				<Hidden lgDown>
					{config.hideNavigation || (checkoutHeaderUrls && checkoutHeaderUrls.includes(location.pathname)) ? (
						<>
							{flags.multiLanguageCmsFrontend ? (
								<CmsLayoutElement
									layoutElementType={layoutElementTypes.CHECKOUT_HEADER}
									layoutElementOld={checkoutHeader}
								/>
							) : (
								<>
									{checkoutHeader && checkoutHeader.content ? (
										<CmsContentElement contentId="ROOT" content={checkoutHeader.content} />
									) : (
										<Paper
											className={clsx(parentClasses.navbar, parentClasses.checkOutNav)}
											square
											elevation={2}
										>
											<NavbarLayout2 />
										</Paper>
									)}
								</>
							)}
						</>
					) : (
						<>
							{flags.multiLanguageCmsFrontend ? (
								<CmsLayoutElement
									layoutElementType={layoutElementTypes.HEADER}
									layoutElementOld={header}
								/>
							) : (
								<>
									{header && header.content ? (
										<CmsContentElement contentId="ROOT" content={header.content} />
									) : (
										<Paper className={clsx(parentClasses.navbar)} square elevation={2}>
											<NavbarLayout2 />
										</Paper>
									)}
								</>
							)}
						</>
					)}
				</Hidden>

				<Hidden lgUp>
					<>
						{flags.multiLanguageCmsFrontend ? (
							<CmsLayoutElement
								layoutElementType={layoutElementTypes.MOBILE_NAVIGATION_MENU}
								layoutElementOld={mobileNavigationMenu}
							/>
						) : (
							<>
								{mobileNavigationMenu && mobileNavigationMenu.content ? (
									<CmsContentElement contentId="ROOT" content={mobileNavigationMenu.content} />
								) : (
									<Drawer
										anchor="left"
										variant="temporary"
										open={navbar.mobileOpen}
										classes={{
											paper: parentClasses.navbarMobile
										}}
										onClose={ev => dispatch(navbarCloseMobile())}
										ModalProps={{
											keepMounted: true // Better open performance on mobile.
										}}
									>
										<NavbarMobileLayout2 />
									</Drawer>
								)}
							</>
						)}
					</>
				</Hidden>
			</ThemeProvider>

			{config.navbar.display && !config.toolbar.display && (
				<Hidden lgUp>
					<NavbarMobileToggleFab />
				</Hidden>
			)}
		</>
	);
}

export default DefaultHeader;
