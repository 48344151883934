import React from 'react';

function Facebook(props) {
	return (
		<svg
			id="Bold"
			enableBackground="new 0 0 24 24"
			height="50"
			viewBox="0 0 24 24"
			width="50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				style={{ fill: props.color }}
				d="m3 24h9v-8.25h-3v-3.75h3v-3c0-2.486 2.014-4.5 4.5-4.5h3v3.75h-1.5c-.828 0-1.5-.078-1.5.75v3h3.75l-1.5 3.75h-2.25v8.25h4.5c1.654 0 3-1.346 3-3v-18c0-1.655-1.346-3-3-3h-18c-1.655 0-3 1.345-3 3v18c0 1.654 1.345 3 3 3z"
			/>
		</svg>
	);
}
export default Facebook;
