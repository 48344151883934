import FuseDialog from '@ameroservices-platform/attraction-frontend/fuse/core/FuseDialog';
import { makeStyles } from '@mui/styles';
import FuseMessage from '@ameroservices-platform/attraction-frontend/fuse/core/FuseMessage';
import FuseSuspense from '@ameroservices-platform/attraction-frontend/fuse/core/FuseSuspense';
import { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import AppContext from '@ameroservices-platform/attraction-frontend/app/AppContext';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#ffffff !important',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		'&.boxed': {
			maxWidth: 1120,
			margin: '0 auto',
			boxShadow: theme.shadows[3]
		},
		'&.container': {
			'& .container': {
				maxWidth: 1120,
				width: '100%',
				margin: '0 auto'
			},
			'& .navigation': {}
		}
	},
	content: {
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 4
	},
	toolbarWrapper: {
		display: 'flex',
		position: 'relative',
		zIndex: 5
	},
	toolbar: {
		display: 'flex',
		flex: '1 0 auto'
	},
	footerWrapper: {
		position: 'relative',
		zIndex: 5
	},
	footer: {
		display: 'flex',
		flex: '1 0 auto'
	}
}));

function Layout3(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const classes = useStyles(props);
	const appContext = useContext(AppContext);
	const { routes } = appContext;

	return (
		<div id="fuse-layout" className={clsx(classes.root)}>
			<div className="flex flex-1 flex-col overflow-hidden relative">
				<main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative">
					<div className="flex flex-auto flex-col relative h-full overflow-y-scroll">
						<FuseSuspense>{useRoutes(routes)}</FuseSuspense>
						{props.children}
					</div>
				</main>
			</div>
			<FuseMessage />
		</div>
	);
}

export default memo(Layout3);
