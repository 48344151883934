import TagManager from 'react-gtm-module';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { GTMPriceFormatter } from '../../utilities';

export function tagMangerAddToDataLayer(objectWithItemAndEvent) {
	TagManager.dataLayer({ dataLayer: { ...objectWithItemAndEvent, _clear: true } });
}

async function changeOrderLineToTagManagerDataLayer(orderLines, tagEvent) {
	orderLines = await getFullEventInformationOnOrderLine(orderLines);
	tagMangerAddToDataLayer({
		event: tagEvent,
		ecommerce: {
			currency: 'DKK',
			value: GTMPriceFormatter.format(
				orderLines.reduce((acc, cur) => {
					acc += cur.linePrice / 100;
					return acc;
				}, 0)
			),
			items: orderLines.map(orderLine => ({
				item_id: orderLine.eventUid,
				item_name: orderLine.eventName || orderLine.name,
				product_id: orderLine.productId,
				item_variant: orderLine.name,
				price: GTMPriceFormatter.format(orderLine.linePrice / 100),
				quantity: orderLine.qty
			}))
		}
	});
}

let diffCartData;

export function setOrderLinesForGoogleTagManger(newOrderLines) {
	if (diffCartData === undefined) {
		diffCartData = JSON.parse(JSON.stringify(newOrderLines));
		return true;
	}

	const diffCount = diffCartData.reduce((acc, cur) => acc + cur.qty, 0);
	const newCount = newOrderLines.reduce((acc, cur) => acc + cur.qty, 0);

	if (diffCount === newCount) {
		return true;
	}

	const addToCart = [];
	const removeFromCart = [];

	// Checking for added or changed products
	newOrderLines.forEach(orderLine => {
		const oldOrderLine = diffCartData.find(ol => ol.id === orderLine.id);

		if (oldOrderLine && orderLine.qty > oldOrderLine.qty) {
			addToCart.push(orderLine);
		}
		if (oldOrderLine && orderLine.qty < oldOrderLine.qty) {
			removeFromCart.push({ ...orderLine, qty: orderLine.qty - oldOrderLine.qty });
		}
		if (!oldOrderLine) {
			addToCart.push(orderLine);
		}
	});

	// Checking for removed products
	diffCartData.forEach(oldOrderLine => {
		const newOrderLine = newOrderLines.find(ol => ol.id === oldOrderLine.id);

		if (!newOrderLine) {
			removeFromCart.push(oldOrderLine);
		}
	});

	if (removeFromCart.length > 0) {
		changeOrderLineToTagManagerDataLayer(removeFromCart, 'remove_from_cart').catch(e => console.error(e));
	}
	if (addToCart.length > 0) {
		changeOrderLineToTagManagerDataLayer(addToCart, 'add_to_cart').catch(e => console.error(e));
	}

	if (addToCart.length > 0 || removeFromCart.length > 0) {
		diffCartData = JSON.parse(JSON.stringify(newOrderLines));
	}
	return true;
}

export async function tagManagerViewCart(orderLines) {
	const items = [];
	let value = 0;

	orderLines = await getFullEventInformationOnOrderLine(orderLines);

	orderLines.forEach(orderLine => {
		value += orderLine.linePrice / 100;
		items.push({
			item_id: orderLine.eventUid,
			item_name: orderLine.eventName || orderLine.name,
			product_id: orderLine.productId,
			item_variant: orderLine.name,
			price: orderLine.linePrice / 100,
			quantity: orderLine.qty
		});
	});
	tagMangerAddToDataLayer({
		event: 'view_cart',
		ecommerce: {
			value,
			items
		}
	});
}
export function tagManagerViewItem(products) {
	const items = [];
	products.forEach(p => {
		const price = p.prices.find(pr => pr.type === 'Sale');
		items.push({
			item_id: p.id,
			item_name: p.name,
			price: price.price / 100,
			item_type: p.type
		});
	});
	tagMangerAddToDataLayer({
		event: 'view_products',
		ecommerce: {
			items
		}
	});
}
export function tagManagerViewItemList(items) {
	tagMangerAddToDataLayer({
		event: 'view_products',
		ecommerce: {
			items
		}
	});
}
export function tagManagerLogin(method) {
	tagMangerAddToDataLayer({
		event: 'login',
		method
	});
}

export function tagManagerBeginCheckOut(orderLines) {
	const items = [];
	let value = 0;
	orderLines.forEach(o => {
		value += o.linePrice / 100;
		items.push({
			item_id: o.eventUid,
			item_name: o.name,
			price: o.linePrice / 100,
			item_type: o.productType
		});
	});

	tagMangerAddToDataLayer({
		event: 'begin_checkout',
		ecommerce: {
			value,
			items
		}
	});
}

export async function tagManagerPurchased(order) {
	const db = firebaseService.getOrganisationRootDB();
	const orderLineDocs = await db
		.collection('customers')
		.doc(order.customerUid)
		.collection('orders')
		.doc(order.orderUid)
		.collection('orderLines')
		.get();

	let orderLines = orderLineDocs.docs.reduce((accOrderLines, curOrderLine) => {
		accOrderLines.push({ ...curOrderLine.data(), id: curOrderLine.id });
		return accOrderLines;
	}, []);

	orderLines = await getFullEventInformationOnOrderLine(orderLines);

	tagMangerAddToDataLayer({
		event: 'purchase',
		ecommerce: {
			purchase: {
				orderId: order.orderUid,
				orderNumber: order.orderNumber,
				customerId: order.customerUid,
				value: order.payments[0].amount / 100,
				currency: order.payments[0].currency,
				transaction_id: order.payments[0].transactionId.toString(),
				items: orderLines
					.filter(orderLine => !orderLine.deleted)
					.map(orderLine => ({
						item_id: orderLine.eventUid,
						order_line_id: orderLine.id,
						item_name: orderLine.eventName,
						item_variant: orderLine.name,
						price: orderLine.linePrice / 100,
						quantity: orderLine.qty
					}))
			}
		}
	});
}

async function getFullEventInformationOnOrderLine(orderLines) {
	const eventUids = orderLines.map(line => line.eventUid);
	const db = firebaseService.getRootDB();
	const eventDocs = await Promise.all(
		eventUids.map(eventUid => {
			return db
				.collectionGroup('events')
				.where('organisationUid', '==', firebaseService.getOrganisationId())
				.where('deleted', '==', false)
				.where('uid', '==', eventUid)
				.get();
		})
	);
	const es = {};
	eventDocs.forEach(eventDoc => {
		eventDoc.forEach(o => {
			const data = o.data();
			es[o.id] = { ...data, id: o.id, start: data.start.toMillis(), end: data.end.toMillis() };
		});
	});

	return orderLines.map(line => {
		return {
			...line,
			displayName: es[line.eventUid] && es[line.eventUid].displayName,
			eventName: es[line.eventUid] && es[line.eventUid].name
		};
	});
}
