import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';

function ListComponent(props) {
	return (
		<>
			<Typography className="font-bold">{props.heading}</Typography>

			<List className="py-4 px-0">
				{props.list.map((i, index) => (
					<ListItem className="list-none px-0">
						<Typography
							dangerouslySetInnerHTML={{
								__html: `${props.type === 'number' ? `${index + 1}. ` : ''}${i.text.replaceAll(
									'\\n',
									'</br>'
								)}`
							}}
						/>
					</ListItem>
				))}
			</List>
		</>
	);
}

export default ListComponent;
