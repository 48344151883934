import layout2 from './layout2/Layout2Config';
import layout3 from './layout3/Layout3Config';
import layout4 from './layout4/Layout4Config';

const FuseLayoutConfigs = {
	layout2,
	layout3,
	layout4,
};

export default FuseLayoutConfigs;
