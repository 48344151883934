import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { getList, selectGoogleTagManager, selectLists } from '../../../../../../store/shared/frontendSlice';
import {
	getEventGroups,
	getEventGroupSummarys,
	getProducts
} from '../../../types/manual-event-overview/ManualEventOverviewFirestore';
import StorageImage from '../../../../../../ui-components/StorageImage';
import EventFilter from './shared/EventFilter';
import MarkdownComponent from '../../../../../../ui-components/content-components/MarkdownComponent';
import SpinnerLoading from '../../../../../../ui-components/SpinnerLoading';
import sortByMonth from './shared/sortByMonth';
import sortByPrice from './shared/sortByPrice';
import { tagManagerViewItemList } from '../../../../../../services/googleTagManagerService/googleTagManagerService';

const useStyles = makeStyles({
	halfCircle: {
		bottom: '-0.3em'
	},
	cardTopImage: { height: '9em', objectFit: 'cover' },
	cardDescription: { height: '8em' }
});

function ManualEventOverview(props) {
	const { contentElementProps } = props;
	const navigate = useNavigate();
	const lists = useSelector(selectLists);
	const [eventGroups, setEventGroups] = useState(null);
	const [viewingEventGroups, setViewingEventGroups] = useState(null);
	const [categoriesOptions, setCategoriesOptions] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [locations, setLocations] = useState([]);
	const { t } = useTranslation();
	const [selectedDateRange, setSelectedDateRange] = useState([
		{
			startDate: null,
			endDate: null,
			key: 'selection'
		}
	]);
	const classes = useStyles();
	const googleTagManager = useSelector(selectGoogleTagManager);

	useEffect(() => {
		if (googleTagManager && viewingEventGroups && viewingEventGroups.length > 0) {
			const items = [];
			viewingEventGroups.forEach(e => {
				items.push({
					item_id: e.id,
					item_name: e.originalEventName || e.name,
					item_type: e.type
				});
			});
			tagManagerViewItemList(items);
		}
	}, [googleTagManager, viewingEventGroups]);

	useEffect(() => {
		const ids = getList(lists, `lists.${contentElementProps.eventGroupsListUid}`).eventGroup;
		if (ids) {
			getEventGroups(ids, async eventGroupsData => {
				const eventGroupSummaries = await Promise.all(
					Object.values(eventGroupsData).map(async eg => getEventGroupSummarys(eg.id))
				);

				const onlyEventGroupsWithEvents = eventGroupSummaries.filter(group => {
					return group.length > 0;
				});

				const parentUidsOfEventGroupsWithEvents = onlyEventGroupsWithEvents
					.map(eventGroup => {
						const value = eventGroup.map(eg => {
							return eg.parent;
						});
						return [...new Set(value)];
					})
					.reduce((acc, cur) => {
						return acc.concat(cur);
					}, []);

				const hello = parentUidsOfEventGroupsWithEvents.reduce((acc, cur) => {
					acc[cur] = eventGroupsData[cur];
					return acc;
				}, {});

				const _eventGroups = { ...hello };
				onlyEventGroupsWithEvents.forEach(_eventGroupsSummaries => {
					_eventGroupsSummaries.sort(sortByMonth);

					const firstSummary = _eventGroupsSummaries[0];
					const lastSummary = _eventGroupsSummaries[_eventGroupsSummaries.length - 1];

					const eventGroupUid = firstSummary.parent;
					const startMonth = firstSummary.month.split('-');
					const endMonth = lastSummary.month.split('-');
					// eslint-disable-next-line prefer-destructuring
					_eventGroups[eventGroupUid].startDate = moment({
						year: Number.parseInt(startMonth[1], 10),
						month: Number.parseInt(startMonth[0], 10) - 1,
						day: Number.parseInt(Object.keys(firstSummary.dates)[0], 10),
						hour: 0,
						minute: 0,
						second: 0
					});
					const endDates = Object.keys(lastSummary.dates);
					_eventGroups[eventGroupUid].endDate = moment({
						year: Number.parseInt(endMonth[1], 10),
						month: Number.parseInt(endMonth[0], 10) - 1,
						day: Number.parseInt(endDates[endDates.length - 1], 10),
						hour: 0,
						minute: 0,
						second: 0
					});

					_eventGroups[eventGroupUid].locations = firstSummary.locations;
					_eventGroups[eventGroupUid].attributes = firstSummary.attributes;
					_eventGroups[eventGroupUid].groupSummaries = _eventGroupsSummaries;
				});

				const products = await Promise.all(
					Object.values(_eventGroups).map(async eg => getProducts(eg.ticketTypes, eg.id))
				);
				products.forEach(_products => {
					if (_products.length > 0) {
						const firstProduct = _products[0];

						const { eventGroupUid } = firstProduct;
						_products.sort(sortByPrice);

						const minPrice = firstProduct.prices.find(p => p.type === 'Sale');

						_eventGroups[eventGroupUid].minPrice = minPrice ? minPrice.price : 0;
						_eventGroups[eventGroupUid].products = _products;
					}
				});

				setEventGroups(Object.values(_eventGroups));
			}).catch(err => console.error(err));
		}
	}, [lists, contentElementProps.eventGroupsListUid]);

	// Updating options for categories
	useEffect(() => {
		if (eventGroups) {
			const catOptions = eventGroups
				.filter(f => {
					const attributeGroup = f.groupSummaries[0].attributes.find(
						att => att.id === contentElementProps.attributeGroupUid
					);
					return !!attributeGroup;
				})
				.reduce((acc, cur) => {
					const attributeGroup = cur.groupSummaries[0].attributes.find(
						att => att.id === contentElementProps.attributeGroupUid
					);
					attributeGroup.values.forEach(attribute => {
						if (!acc.find(f => f.value === attribute.id)) {
							acc.push({ value: attribute.id, label: attribute.name });
						}
					});
					return acc;
				}, []);
			setCategoriesOptions([...catOptions]);
		}
	}, [eventGroups, contentElementProps.attributeGroupUid]);

	// Updating options for locations
	useEffect(() => {
		if (eventGroups) {
			const locOptions = eventGroups.reduce((acc, cur) => {
				cur.groupSummaries[0].locations.forEach(location => {
					if (!acc.find(f => f.value === location.id)) {
						acc.push({ value: location.id, label: location.name });
					}
				});
				return acc;
			}, []);
			setLocationOptions([...locOptions]);
		}
	}, [eventGroups]);

	// Filter eventGroup array by locations & categories
	useEffect(() => {
		if (eventGroups) {
			let tempEventGroups = eventGroups;
			if (locations.length > 0) {
				tempEventGroups = tempEventGroups.filter(eventGroup =>
					eventGroup.locations.find(loc => locations.find(l => l.value === loc.id))
				);
			}
			if (categories.length > 0) {
				tempEventGroups = tempEventGroups.filter(eventGroup => {
					const attributeGroup = eventGroup.attributes.find(
						a => a.id === contentElementProps.attributeGroupUid
					);
					if (attributeGroup) {
						return attributeGroup.values.find(attribute => categories.find(c => c.value === attribute.id));
					}
					return false;
				});
			}
			if (selectedDateRange && (selectedDateRange[0].startDate || selectedDateRange[0].endDate)) {
				const start = moment(selectedDateRange[0].startDate);
				const end = moment(selectedDateRange[0].endDate);
				tempEventGroups = tempEventGroups.filter(eventGroup => {
					const startDate = moment(eventGroup.startDate);
					const endDate = moment(eventGroup.endDate);
					return (
						start.isBetween(startDate, endDate) ||
						end.isBetween(startDate, endDate) ||
						(start.isBefore(endDate) && start.isAfter(startDate)) ||
						(start.isBefore(startDate) && end.isAfter(endDate)) ||
						startDate.isSame(start) ||
						startDate.isSame(end) ||
						endDate.isSame(start) ||
						endDate.isSame(end)
					);
				});
			}
			setViewingEventGroups(tempEventGroups);
		}
	}, [eventGroups, locations, categories, selectedDateRange, contentElementProps.attributeGroupUid]);

	const handleChangeFromDatePicker = dateRange => {
		setSelectedDateRange(dateRange);
	};

	return (
		<div className="min-h-screen max-w-screen lg:min-w-2xl mx-12 md:mx-16 py-48 sm:py-48">
			<Paper elevation={3} className="p-4 pb-28 pt-28 md:p-28 w-full flex flex-row">
				<Grid container className="">
					<Grid item xs={12} xl={12}>
						<Typography variant="h5" className="text-center">
							{contentElementProps.heading}
						</Typography>
					</Grid>
					{/* ------------------Kategori filter Start-----------------------*/}
					{!contentElementProps.hideFilter && (
						<EventFilter
							selectedDateRange={selectedDateRange}
							handleChangeFromDatePicker={handleChangeFromDatePicker}
							locations={locations}
							setLocations={setLocations}
							locationOptions={locationOptions}
							categories={categories}
							setCategories={setCategories}
							categoriesOptions={categoriesOptions}
						/>
					)}

					{/* ------------------Date picker end-----------------------*/}

					{/* ---------------------------Billetkort start---------------------*/}
					{eventGroups && viewingEventGroups && viewingEventGroups.length > 0 ? (
						viewingEventGroups.map(eventGroup => (
							<Grid className="my-44" item xs={12} sm={6} md={4} lg={4} xl={4} key={eventGroup.id}>
								<div className="flex flex-col md:flex-row w-full items-center justify-center">
									<Paper elevation={6} className="min-w-2 max-w-256 overflow-hidden flex-grow">
										<div className="relative">
											<StorageImage
												src={
													eventGroup.overviewContent && eventGroup.overviewContent.image
														? eventGroup.overviewContent.image
														: eventGroup.content && eventGroup.content.image
														? eventGroup.content.image
														: ''
												}
												className={`w-full max-w-full ${classes.cardTopImage}`}
												alt=""
											/>
											{/* <img */}
											{/*	className="object-contain w-full max-w-full" */}
											{/*	src="assets/images/event-site/stoleJorn.png" */}
											{/*	alt="" */}
											{/* /> */}

											{!contentElementProps.hideSemiCircle && (
												<img
													className={`overflow-hidden min-w-0 max-w-288 absolute -left-1 -right-1 ${classes.halfCircle}`}
													src="assets/images/event-site/halvcirkel.svg"
													alt=""
												/>
											)}
										</div>
										<div className={`${contentElementProps.hideSemiCircle ? 'pt-12' : ''} px-16`}>
											{!contentElementProps.hideDateOnCard && (
												<Typography variant="h6" className="text-center">
													{/* {ticketType.content.ticket.heading} */}
													{eventGroup.startDate.format('DD[.] MMM[.]')} -{' '}
													{eventGroup.endDate.format('DD[.] MMM[.]')}
												</Typography>
											)}

											<Typography
												variant="h6"
												className={`${
													contentElementProps.hideSemiCircle ? 'text-left' : 'text-center'
												} h-64`}
											>
												{eventGroup.overviewContent && eventGroup.overviewContent.title
													? eventGroup.overviewContent.title
													: eventGroup.content && eventGroup.content.title
													? eventGroup.content.title
													: eventGroup.name}
											</Typography>
											<Typography className={`py-16 ${classes.cardDescription}`}>
												<MarkdownComponent
													data={
														eventGroup.overviewContent &&
														eventGroup.overviewContent.description
															? eventGroup.overviewContent.description
															: eventGroup.content && eventGroup.content.description
															? eventGroup.content.description
															: ''
													}
												/>
											</Typography>
											{!contentElementProps.hidePrice && (
												<Typography variant="h6" className="pb-24 text-center">
													{t('FROM')} {eventGroup.minPrice / 100},-
												</Typography>
											)}

											<Button
												className="w-full mb-16"
												variant="contained"
												color="primary"
												endIcon={<ArrowForwardIcon />}
												onClick={() =>
													navigate(
														contentElementProps.linkTo.replace(
															':eventGroupUid',
															eventGroup.id
														)
													)
												}
											>
												{t('BUY_TICKET')}
											</Button>
										</div>
									</Paper>
								</div>
							</Grid>
						))
					) : eventGroups ? (
						<Typography className="text-center py-44 text-18 w-full block">{t('NO_EVENTS')}</Typography>
					) : (
						<SpinnerLoading />
					)}
					{/* ---------------------------Billetkort end---------------------*/}
				</Grid>
			</Paper>
		</div>
	);
}

export default ManualEventOverview;
