import moment from 'moment-timezone';
import { batchHandler } from './batchHandler';
import { lowerCaseFirstLetter } from './lowerCaseFirstLetter';
import { upperCaseFirstLetter } from './upperCaseFirstLetter';

export { moneyFormatter } from './numbers/digits';
export { GetRuleByName } from './formValidation/HookFormRules';

export { batchHandler, lowerCaseFirstLetter, upperCaseFirstLetter };

// eslint-disable-next-line import/prefer-default-export
export function getParameterByName(name, url = window.location.href) {
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function periodUnitTranslate(periodUnit, period) {
	let unit = '';
	if (periodUnit === 'day') {
		unit += 'dag';
		if (period.toString() !== '1') {
			unit += 'e';
		}
	}
	if (periodUnit === 'week') {
		unit += 'uge';
		if (period.toString() !== '1') {
			unit += 'r';
		}
	}
	if (periodUnit === 'month') {
		unit += 'måned';
		if (period.toString() !== '1') {
			unit += 'er';
		}
	}
	if (periodUnit === 'year') {
		unit += 'år';
	}
	return unit;
}

export const getSafeDate = date => {
	const tempDate = moment(date).hours(12).toDate();
	return tempDate.getTime();
};

export const getFieldNameAndLanguageFromMultiLanguageTextField = (textField, languages) => {
	let fieldName = '';
	let fieldLanguage = '';
	if (textField.includes('_') && textField.split('_').length === 2) {
		[fieldName, fieldLanguage] = textField.split('_');
		if (languages && Object.values(languages).find(language => language.isoCode === fieldLanguage)) {
			return { fieldName, fieldLanguage };
		}
	}
	return null;
};

const getDateString = date => {
	if (date && date.getTime) {
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
	}
	return new Date();
};

const getActivePeriodPrices = (periodPrices, date = null) => {
	return Object.values(periodPrices)
		.map(period => {
			return {
				...period,
				deleted: period.deleted,
				fromDate:
					period.fromDate && typeof period.fromDate.toDate === 'function' ? period.fromDate.toDate() : null,
				toDate: period.toDate && typeof period.toDate.toDate === 'function' ? period.toDate.toDate() : null,
				eventFromDate:
					period.eventFromDate && typeof period.eventFromDate.toDate === 'function'
						? period.eventFromDate.toDate()
						: null,
				eventToDate:
					period.eventToDate && typeof period.eventToDate.toDate === 'function'
						? period.eventToDate.toDate()
						: null,
				prices: period.prices
			};
		})
		.filter(period => {
			let isInActivePeriod = false;
			let isInActiveEventPeriod = false;
			if (period.deleted) return false;

			const defaultDate = new Date();
			if (period.fromDate) {
				const fromDateString = getDateString(period.fromDate);
				const defaultDateString = getDateString(defaultDate);

				if (!!period.toDate === false) {
					isInActivePeriod = moment(fromDateString).isSameOrBefore(defaultDateString);

					if (period.fromDate && isInActivePeriod === false) {
						return false;
					}
				} else {
					const toDateString = getDateString(period.toDate);
					isInActivePeriod =
						moment(fromDateString).isSameOrBefore(defaultDateString) &&
						moment(toDateString).isSameOrAfter(defaultDateString);

					if (period.fromDate && period.toDate && isInActivePeriod === false) {
						return false;
					}
				}
			}

			const eventDate = date || new Date();

			if (period.eventFromDate) {
				const eventFromDateString = getDateString(period.eventFromDate);
				const eventDateString = getDateString(eventDate);

				if (!!period.eventToDate === false) {
					isInActiveEventPeriod = moment(eventFromDateString).isSameOrBefore(eventDateString);

					if (period.eventFromDate && isInActiveEventPeriod === false) {
						return false;
					}
				} else {
					const eventToDateString = getDateString(period.eventToDate);

					isInActiveEventPeriod =
						moment(eventFromDateString).isSameOrBefore(eventDateString) &&
						moment(eventToDateString).isSameOrAfter(eventDateString);

					if (period.eventFromDate && period.eventToDate && isInActiveEventPeriod === false) {
						return false;
					}
				}
			}
			return true;
		});
};

export function returnActivePeriodPriceByLocationOrNull(product, location, date) {
	if (location && product.locations && product.locations[location] && product.locations[location].periodPrices) {
		const activePeriods = getActivePeriodPrices(product.locations[location].periodPrices, date);
		if (activePeriods.length > 0) {
			const { price } = activePeriods[0].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

export function returnActivePeriodPricesOrNull(product, date) {
	if (product.periodPrices) {
		const activePeriods = getActivePeriodPrices(product.periodPrices, date);
		if (activePeriods.length > 0) {
			const { price } = activePeriods[0].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

export function returnLocationPriceOrNull(product, location) {
	if (location && product.locations && product.locations[location] && product.locations[location].prices) {
		if (product.locations[location].pricesDeleted !== true && product.locations[location].prices.length > 0) {
			const { price } = product.locations[location].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

export const findPricesForProduct = (product, location, selectedDate = null, displayPeriodePrices) => {
	let price = null;

	// location period price
	if (displayPeriodePrices) {
		price = returnActivePeriodPriceByLocationOrNull(product, location, selectedDate);
		if (price !== 'undefined' && price !== null) {
			return price;
		}

		// period price
		price = returnActivePeriodPricesOrNull(product, selectedDate);

		if (price !== 'undefined' && price !== null) {
			return price;
		}

		// location price
		price = returnLocationPriceOrNull(product, location);
		if (price !== 'undefined' && price !== null) {
			return price;
		}
	}

	// normal price
	price = product.prices.find(f => f.type === 'Sale').price;
	return price;
};
