export const margin = ({ m, mx, my, ml, mt, mr, mb }) => {
	return `${m ? `m-${m}` : ``} ${mx ? `mx-${mx}` : ``} ${my ? `my-${my}` : ``} ${ml ? `ml-${ml}` : ``} ${
		mt ? `mt-${mt}` : ``
	} ${mr ? `mr-${mr}` : ``} ${mb ? `mb-${mb}` : ``}`;
};

export const smMargin = ({ smM, smMx, smMy, smMl, smMt, smMr, smMb }) => {
	return `${smM ? `sm:m-${smM}` : ``} ${smMx ? `sm:mx-${smMx}` : ``} ${smMy ? `sm:my-${smMy}` : ``} ${
		smMl ? `sm:ml-${smMl}` : ``
	} ${smMt ? `sm:mt-${smMt}` : ``} ${smMr ? `sm:mr-${smMr}` : ``} ${smMb ? `sm:mb-${smMb}` : ``}`;
};

export const mdMargin = ({ mdM, mdMx, mdMy, mdMl, mdMt, mdMr, mdMb }) => {
	return `${mdM ? `md:m-${mdM}` : ``} ${mdMx ? `md:mx-${mdMx}` : ``} ${mdMy ? `md:my-${mdMy}` : ``} ${
		mdMl ? `md:ml-${mdMl}` : ``
	} ${mdMt ? `md:mt-${mdMt}` : ``} ${mdMr ? `md:mr-${mdMr}` : ``} ${mdMb ? `md:mb-${mdMb}` : ``}`;
};

export const lgMargin = ({ lgM, lgMx, lgMy, lgMl, lgMt, lgMr, lgMb }) => {
	return `${lgM ? `lg:m-${lgM}` : ``} ${lgMx ? `lg:mx-${lgMx}` : ``} ${lgMy ? `lg:my-${lgMy}` : ``} ${
		lgMl ? `lg:ml-${lgMl}` : ``
	} ${lgMt ? `lg:mt-${lgMt}` : ``} ${lgMr ? `lg:mr-${lgMr}` : ``} ${lgMb ? `lg:mb-${lgMb}` : ``}`;
};

export const xlMargin = ({ xlM, xlMx, xlMy, xlMl, xlMt, xlMr, xlMb }) => {
	return `${xlM ? `xl:m-${xlM}` : ``} ${xlMx ? `xl:mx-${xlMx}` : ``} ${xlMy ? `xl:my-${xlMy}` : ``} ${
		xlMl ? `xl:ml-${xlMl}` : ``
	} ${xlMt ? `xl:mt-${xlMt}` : ``} ${xlMr ? `xl:mr-${xlMr}` : ``} ${xlMb ? `xl:mb-${xlMb}` : ``}`;
};

export const padding = ({ p, px, py, pl, pt, pr, pb }) => {
	return `${p ? `p-${p}` : ``} ${px ? `px-${px}` : ``} ${py ? `py-${py}` : ``} ${pl ? `pl-${pl}` : ``} ${
		pt ? `pt-${pt}` : ``
	} ${pr ? `pr-${pr}` : ``} ${pb ? `pb-${pb}` : ``}`;
};

export const smPadding = ({ smP, smPx, smPy, smPl, smPt, smPr, smPb }) => {
	return `${smP ? `sm:p-${smP}` : ``} ${smPx ? `sm:px-${smPx}` : ``} ${smPy ? `sm:py-${smPy}` : ``} ${
		smPl ? `sm:pl-${smPl}` : ``
	} ${smPt ? `sm:pt-${smPt}` : ``} ${smPr ? `sm:pr-${smPr}` : ``} ${smPb ? `sm:pb-${smPb}` : ``}`;
};

export const mdPadding = ({ mdP, mdPx, mdPy, mdPl, mdPt, mdPr, mdPb }) => {
	return `${mdP ? `md:p-${mdP}` : ``} ${mdPx ? `md:px-${mdPx}` : ``} ${mdPy ? `md:py-${mdPy}` : ``} ${
		mdPl ? `md:pl-${mdPl}` : ``
	} ${mdPt ? `md:pt-${mdPt}` : ``} ${mdPr ? `md:pr-${mdPr}` : ``} ${mdPb ? `md:pb-${mdPb}` : ``}`;
};

export const lgPadding = ({ lgP, lgPx, lgPy, lgPl, lgPt, lgPr, lgPb }) => {
	return `${lgP ? `lg:p-${lgP}` : ``} ${lgPx ? `lg:px-${lgPx}` : ``} ${lgPy ? `lg:py-${lgPy}` : ``} ${
		lgPl ? `lg:pl-${lgPl}` : ``
	} ${lgPt ? `lg:pt-${lgPt}` : ``} ${lgPr ? `lg:pr-${lgPr}` : ``} ${lgPb ? `lg:pb-${lgPb}` : ``}`;
};

export const xlPadding = ({ lgP, lgPx, lgPy, xlPl, xlPt, xlPr, xlPb }) => {
	return `${lgP ? `xl:p-${lgP}` : ``} ${lgPx ? `xl:px-${lgPx}` : ``} ${lgPy ? `xl:py-${lgPy}` : ``} ${
		xlPl ? `xl:pl-${xlPl}` : ``
	} ${xlPt ? `xl:pt-${xlPt}` : ``} ${xlPr ? `xl:pr-${xlPr}` : ``} ${xlPb ? `xl:pb-${xlPb}` : ``}`;
};

export const flexShared = ({ justify, direction, wrap, alignContent, alignItems }) => {
	const mappingJustify = {
		'flex-start': 'start',
		center: 'center',
		'flex-end': 'end',
		'space-between': 'between',
		'space-around': 'around',
		'space-evenly': 'evenly',
	};
	const mappingDirection = {
		row: 'row',
		'row-reverse': 'row-reverse',
		column: 'col',
		'column-reverse': 'col-reverse',
	};
	const mappingWrap = {
		wrap: 'wrap',
		'wrap-reverse': 'wrap-reverse',
		nowrap: 'nowrap',
	};
	const mappingAlignContent = {
		'flex-start': 'start',
		center: 'center',
		'flex-end': 'end',
		'space-between': 'between',
		'space-around': 'around',
		'space-evenly': 'evenly',
		stretch: 'stretch',
	};
	const mappingAlignItems = {
		'flex-start': 'start',
		'flex-end': 'end',
		center: 'center',
		stretch: 'stretch',
		baseline: 'baseline',
	};
	return `${justify ? `justify-${mappingJustify[justify]}` : ``} ${
		direction ? `flex-${mappingDirection[direction]}` : ``
	} ${wrap ? `flex-${mappingWrap[wrap]}` : ''} ${
		alignContent ? `content-${mappingAlignContent[alignContent]}` : ''
	} ${alignItems ? `items-${mappingAlignItems[alignItems]}` : ''} `;
};

export const sharedTailwindClasses = (props) => {
	return `${margin({ ...props })} ${padding({
		...props,
	})} ${smPadding({
		...props,
	})} ${mdPadding({
		...props,
	})} ${lgPadding({
		...props,
	})} ${xlPadding({
		...props,
	})} ${smMargin({
		...props,
	})} ${mdMargin({
		...props,
	})} ${lgMargin({
		...props,
	})} ${xlMargin({
		...props,
	})} ${flexShared({ ...props })}`;
};
