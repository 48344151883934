import React from 'react';
import Typography from '@mui/material/Typography';

function HeadingComponent(props) {
	return (
		<Typography
			variant="body1"
			className="font-bold text-left text-15 mb-8"
			dangerouslySetInnerHTML={{
				__html: props && props.children ? props.children.replaceAll('\\n', '</br>') : ''
			}}
		/>
	);
}

export default HeadingComponent;
