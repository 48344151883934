import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import { validateRebooking, rebookTicket } from './RebookingFirestore';
import LandingPage from './LandingPage';
import TicketRebooking from './TicketRebooking';
import SubscriptionRebooking from './SubscriptionRebooking';

function Rebooking({ contentElementProps }) {
	const flags = useFlags();

	const [loading, setLoading] = useState(false);
	const [validated, setValidated] = useState(null);
	const [success, setSuccess] = useState(null);
	const [email, setEmail] = useState(null);
	const [ticketCount, setTicketCount] = useState(0);
	const [ticketNumbers, setTicketNumbers] = useState(['']);
	const [controlNumber, setControlNumber] = useState('');
	const [eventGroupUid, setEventGroupUid] = useState(null);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [extraData, setExtraData] = useState({});

	async function handleValidate(_controlNumber, _ticketNumbers, e) {
		setLoading(true);
		await validateRebooking(_ticketNumbers, _controlNumber, response => {
			setTicketNumbers(_ticketNumbers);
			setControlNumber(_controlNumber);
			setTicketCount(_ticketNumbers.length);
			setValidated(response.validated);
			setEventGroupUid(response.eventGroupUid);
			setEmail(e);
			setLoading(false);
			if (flags.subscriptionGiftTicket) {
				setExtraData(response);
			}
		});
	}

	async function handleRebooking() {
		if (flags.subscriptionGiftTicket) {
			setLoading(true);
			if (extraData && extraData.type === 'subscription') {
				const response = await rebookTicket(ticketNumbers, controlNumber, email, selectedEvent);
				setSuccess(response.success);
				setEmail(response.email);
			} else if (extraData && extraData.type === 'ticket') {
				const response = await rebookTicket(ticketNumbers, controlNumber, email, selectedEvent);
				setSuccess(response.success);
				setEmail(response.email);
			}
			setLoading(false);
		} else {
			setLoading(true);
			const response = await rebookTicket(ticketNumbers, controlNumber, email, selectedEvent);
			setSuccess(response.success);
			setEmail(response.email);
			setLoading(false);
		}
	}

	if (success === true || success === false) {
		return (
			<>
				<FuseAnimateAdapter animation="transition.expandIn">
					<Card className="max-w-md my-48 mx-auto">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<div className="m-32">
								<Icon className="text-96" color="primary">
									{success === true ? 'check' : 'close'}
								</Icon>
							</div>

							{success === false && (
								<Alert severity="error" className="mt-24">
									<AlertTitle>Der skete en eller flere fejl</AlertTitle>{' '}
									{flags.subscriptionGiftTicket && extraData && extraData.type === 'subscription'
										? 'Medlemskabet er ikke længere gyldig'
										: 'Billetten er ikke længere gyldig, eller begivenheden er udsolgt'}
								</Alert>
							)}

							<Typography variant="h5" className="text-center mb-16">
								{success === true ? 'Gennemført' : 'Fejl'}
							</Typography>

							{success && (
								<Typography className="text-center mb-16 w-full" color="textSecondary">
									Du har modtaget{' '}
									{flags.subscriptionGiftTicket && extraData && extraData.type === 'subscription'
										? 'et nyt medlemskort'
										: 'en ny billet'}{' '}
									på mail <b>{email}</b>.
								</Typography>
							)}
						</CardContent>
					</Card>
				</FuseAnimateAdapter>
			</>
		);
	}
	return (
		<>
			{validated === true ? (
				flags.subscriptionGiftTicket ? (
					<>
						{extraData && extraData.type === 'ticket' && (
							<TicketRebooking
								handleRebooking={handleRebooking}
								eventGroupUid={eventGroupUid}
								loading={loading}
								ticketCount={ticketCount}
								selectedEvent={selectedEvent}
								setSelectedEvent={s => setSelectedEvent(s)}
							/>
						)}
						{extraData && extraData.type === 'subscription' && (
							<SubscriptionRebooking
								ticketNumber={ticketNumbers && ticketNumbers.length > 0 ? ticketNumbers[0] : null}
								controlNumber={controlNumber}
								subscriptionCreateUrl={contentElementProps.subscriptionCreateUrl}
								subscriptionTypeUid={extraData.subscriptionTypeUid}
								email={email}
							/>
						)}
					</>
				) : (
					<TicketRebooking
						handleRebooking={handleRebooking}
						eventGroupUid={eventGroupUid}
						loading={loading}
						ticketCount={ticketCount}
						selectedEvent={selectedEvent}
						setSelectedEvent={s => setSelectedEvent(s)}
					/>
				)
			) : (
				<LandingPage
					data={contentElementProps}
					loading={loading}
					validated={validated}
					onValidate={handleValidate}
				/>
			)}
		</>
	);
}

export default Rebooking;
