import React from 'react';
import Markdown from '../Markdown';

function MarkdownComponent(props) {
	return props.data
		? props.data.split(/\n|\\n/g).map((d, index) => (
				<React.Fragment key={index}>
					<Markdown isCmsComponent={props.isCmsComponent} linkColor={props.linkColor}>
						{d}
					</Markdown>
				</React.Fragment>
		  ))
		: null;
}

export default MarkdownComponent;
