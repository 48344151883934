import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useMemo, useState } from 'react';
import CmsContentElement from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/CmsContentElement';
import { pageListener } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/ContentFirestore';

function CartSubscriptionTermsDialog({ open, onClose }) {
	const [termsPage, setTermsPage] = useState({});

	useEffect(() => {
		const termsPageListenerUnsubFunc = pageListener('/abonnementsbetingelser', o => {
			setTermsPage(o);
		});
		return () => termsPageListenerUnsubFunc();
	}, []);

	const embeddedContentElementId = useMemo(() => {
		if (!termsPage?.content) {
			return null;
		}
		if (typeof termsPage.content !== 'object') {
			return null;
		}
		const [key] = Object.entries(termsPage.content).find(([key, value]) => {
			return value.props.embeddedId && value.props.embeddedId === 'cartTermsDialog';
		});
		return key;
	}, [termsPage]);

	if (!termsPage) {
		return null;
	}

	return (
		<Dialog open={open} onClose={onClose} scroll="paper">
			<DialogContent dividers>
				<CmsContentElement contentId={embeddedContentElementId} content={termsPage.content} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Luk</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CartSubscriptionTermsDialog;
