import { findPricesForProduct } from '@ameroservices-platform/attraction-frontend/app/utilities';
import { Button, Tooltip, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectOrderLines,
	selectSelectedUpsellProducts,
	setSelectedUpsellProduct
} from '@ameroservices-platform/attraction-frontend/app/store/shared/cartSlice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

// Can only be used on upsell module in cart
function UpsellModuleTicketSelector({ dataFromExecutor, contentElementProps }) {
	const { eventGroup, upsellProducts } = dataFromExecutor;
	const dispatch = useDispatch();
	const orderLines = useSelector(selectOrderLines);
	const selectedUpsellProducts = useSelector(selectSelectedUpsellProducts);
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		root: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
			borderColor: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
			backgroundColor: `${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.textColorHover,
					contentElementProps.customTextColorHover,
					mergedTheme
				)}`,
				borderColor: `${getColor(
					contentElementProps.textColorHover,
					contentElementProps.customTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.backgroundColorHover,
					contentElementProps.customBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();

	const maximumAmount = useMemo(() => {
		return orderLines.filter(orderLine => orderLine.upsellProducts).reduce((acc, cur) => acc + cur.qty, 0);
	}, [orderLines]);

	function handleRemoveProduct(upsellProduct) {
		const amount = selectedUpsellProducts?.[eventGroup.id]?.[upsellProduct.id] || 0;
		if (amount <= 0) {
			return;
		}
		dispatch(
			setSelectedUpsellProduct({ eventGroupUid: eventGroup.id, productUid: upsellProduct.id, amount: amount - 1 })
		);
	}

	function handleAddProduct(upsellProduct) {
		const amount = selectedUpsellProducts?.[eventGroup.id]?.[upsellProduct.id] || 0;
		dispatch(
			setSelectedUpsellProduct({ eventGroupUid: eventGroup.id, productUid: upsellProduct.id, amount: amount + 1 })
		);
	}

	return (
		<>
			{upsellProducts.map(upsellProduct => {
				const price = findPricesForProduct(upsellProduct, null, null, true) / 100;
				const amount = selectedUpsellProducts?.[eventGroup.id]?.[upsellProduct.id] || 0;

				let toolTipTitleAdd = '';
				if (amount >= maximumAmount) {
					toolTipTitleAdd = t('YOU_HAVE_ALREADY_ADDED_THE_MAX');
				}
				let toolTipTitleRemove = '';
				if (amount <= 0) {
					toolTipTitleRemove = t('YOU_HAVE_ALREADY_REMOVED_THE_MIN');
				}

				return (
					<div
						className="sm:flex items-center justify-between"
						key={`upsell-${eventGroup.id}-${upsellProduct.id}`}
					>
						<Typography className="font-bold">{upsellProduct.name}</Typography>
						<div className="flex items-center">
							<Typography className="mr-10 font-bold whitespace-normal cursor-default">
								{price % 1 === 0 && <>{price},-</>}
								{price % 1 !== 0 && <>{moneyFormatter.format(price)}</>}
							</Typography>
							<div className="flex h-56 items-center">
								<Tooltip title={toolTipTitleRemove}>
									<span>
										<Button
											variant="outlined"
											onClick={() => handleRemoveProduct(upsellProduct)}
											disabled={amount <= 0}
											className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
											classes={{
												root: classes.root
											}}
										>
											<Remove />
										</Button>
									</span>
								</Tooltip>
								<div className="flex items-center text-14 font-bold px-10">{amount}</div>
								<Tooltip title={toolTipTitleAdd}>
									<span>
										<Button
											variant="outlined"
											onClick={() => handleAddProduct(upsellProduct)}
											disabled={amount >= maximumAmount}
											className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
											classes={{
												root: classes.root
											}}
										>
											<Add />
										</Button>
									</span>
								</Tooltip>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}

export default UpsellModuleTicketSelector;
