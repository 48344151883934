import React from 'react';
import MuiHidden from '@mui/material/Hidden';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';

function Hidden(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	return (
		<MuiHidden
			xsDown={contentElementProps.xsDown}
			xsUp={contentElementProps.xsUp}
			smDown={contentElementProps.smDown}
			smUp={contentElementProps.smUp}
			mdDown={contentElementProps.mdDown}
			mdUp={contentElementProps.mdUp}
			lgDown={contentElementProps.lgDown}
			lgUp={contentElementProps.lgUp}
			xlDown={contentElementProps.xlDown}
			xlUp={contentElementProps.xlUp}
			className={`${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiHidden>
	);
}

export default Hidden;
