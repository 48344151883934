import TagManager from 'react-gtm-module';
import React, { useEffect, useState } from 'react';
import FuseSplashScreen from '@ameroservices-platform/attraction-frontend/fuse/core/FuseSplashScreen/FuseSplashScreen';

export default function CookieInit({ children }) {
	const [siteConfig, setSiteConfig] = useState(null);
	const [needs, setNeeds] = useState(null);
	const [finished, setFinished] = useState([]);

	useEffect(() => {
		if (window.location.pathname.startsWith('/flexpos')) {
			return;
		}
		fetch('/siteConfig?origin=ameroservices-platform').then(async response => {
			const config = await response.json();
			setSiteConfig(config);
			let _needs = [];
			if (config?.googleTagManager) {
				_needs.push('googleTagManager');
			}
			if (config?.cookieScriptId) {
				_needs.push('cookieScriptId', 'cookieScriptLoaded');
			}
			setNeeds(_needs);
		});
	}, []);

	useEffect(() => {
		if (window.location.pathname.startsWith('/flexpos')) {
			console.log('Ignorering CookieScript');
			return;
		}
		if (!siteConfig?.cookieScriptId) {
			console.log('Ignorering CookieScript 2');
			return;
		}
		console.log('Initialize CookieScript');
		const script = document.createElement('script');
		script.setAttribute('type', 'text/javascript');
		script.setAttribute('charSet', 'UTF-8');
		script.setAttribute('src', `//cdn.cookie-script.com/s/${siteConfig?.cookieScriptId}.js`);
		document.querySelector('head').append(script);
		setFinished(oldState => oldState.concat('cookieScriptId'));
	}, [siteConfig]);

	useEffect(() => {
		if (window.location.pathname.startsWith('/flexpos')) {
			console.log('Ignorering GTM');
			return;
		}
		if (!siteConfig?.googleTagManager) {
			console.log('Ignorering GTM 2');
			return;
		}
		console.log('Initialize GTM');
		TagManager.initialize({
			gtmId: siteConfig?.googleTagManager,
			events: {
				start: 'start'
			}
		});
		setFinished(oldState => oldState.concat('googleTagManager'));
	}, [siteConfig]);

	useEffect(() => {
		if (window.location.pathname.startsWith('/flexpos')) {
			return;
		}
		const unsub = (a, b, c) => {
			setFinished(oldState => oldState.concat('cookieScriptLoaded'));
			console.log('Loaded CookieScript');
		};

		window.addEventListener('CookieScriptLoaded', unsub);

		return () => window.removeEventListener('CookieScriptLoaded', unsub);
	}, []);

	if (window.location.pathname.startsWith('/flexpos')) {
		return children;
	}

	if (!needs || !siteConfig || !needs.every(need => finished.includes(need))) {
		return <FuseSplashScreen />;
	}

	console.log('Finished loading');

	return children;
}
