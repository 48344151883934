import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductTotalSection = ({ classes, totalAmount, disclaimers, highlightedDisclaimer, children }) => {
	const showDisclaimers = !!disclaimers;
	const { t } = useTranslation();
	return (
		<>
			{children}
			<hr />
			<div className="flex justify-between w-full my-6">
				<Typography className={`font-bold text-20 ${classes.text}`}>{t('TOTAL')}</Typography>
				<Typography className={`font-bold text-20 ${classes.text}`}>{`${totalAmount / 100} kr.`}</Typography>
			</div>
			<hr />
			<div className="flex flex-col mt-10">
				{showDisclaimers &&
					Object.entries(disclaimers).map(([disclaimer, index]) => (
						<Typography
							key={index}
							className={`${highlightedDisclaimer === index ? 'text-14 font-bold' : 'text-12'} leading-6`}
							variant="body1"
							style={{ wordWrap: 'break-word' }}
						>
							<span className="mr-4">{'*'.repeat(index + 1)}</span>
							{disclaimer}
						</Typography>
					))}
			</div>
		</>
	);
};

export default ProductTotalSection;
