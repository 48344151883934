import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';

export const productsListener = (ticketTypeId, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.where('ticketTypeUid', '==', ticketTypeId)
		.where('deleted', '==', false)
		.where('state', '==', 'active')
		.where('showOnWeb', '==', true)
		.onSnapshot(
			query => {
				const products = [];
				query.forEach(doc => {
					const docData = doc.data();
					products.push({
						...docData,
						id: doc.id
					});
				});
				products.sort((p1, p2) => p1.order - p2.order);
				callback(products);
			},
			error => {
				console.log(error);
			}
		);
};

export const ticketTypeListener = (ticketTypeId, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('ticketTypes')
		.doc(ticketTypeId)
		.onSnapshot(q => {
			callback({
				...q.data(),
				id: q.id
			});
		});
};

export const getEventByEventUid = async (eventGroupUid, eventUid) => {
	const db = firebaseService.getOrganisationRootDB();
	return db.collection('eventGroups').doc(eventGroupUid).collection('events').doc(eventUid).get();
};

export const getProductByProductUid = async productUid => {
	const db = firebaseService.getOrganisationRootDB();
	return db.collection('products').doc(productUid).get();
};
