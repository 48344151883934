import React, { lazy } from 'react';

const CartComplete = lazy(() => import('./CartComplete'));

const CartCompletePageConfig = {
	settings: {
		layout: {
			config: { hideNavigation: true },
		},
	},
	routes: [
		{
			path: '/cart/complete/:orderUid',
			element: <CartComplete />
		},
	],
};

export default CartCompletePageConfig;
