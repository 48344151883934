import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import subscriptionState, { subscriptionStateTranslate } from '@ameroservices-platform/shared/enums/subscriptionState';

export const subscriptionStatuses = [
	{
		key: subscriptionState.ACTIVE,
		name: subscriptionStateTranslate[subscriptionState.ACTIVE],
		color: 'bg-green text-white'
	},
	{
		key: subscriptionState.TERMINATED,
		name: subscriptionStateTranslate[subscriptionState.TERMINATED],
		color: 'bg-red text-white'
	},
	{
		key: subscriptionState.RESERVED,
		name: subscriptionStateTranslate[subscriptionState.RESERVED],
		color: 'bg-blue text-white'
	},
	{
		key: subscriptionState.DRAFT,
		name: subscriptionStateTranslate[subscriptionState.DRAFT],
		color: 'bg-gray text-white'
	},
	{
		key: subscriptionState.PAYMENT,
		name: subscriptionStateTranslate[subscriptionState.PAYMENT],
		color: 'bg-orange text-white'
	},
	{
		key: subscriptionState.RENEWAL,
		name: subscriptionStateTranslate[subscriptionState.RENEWAL],
		color: 'bg-orange text-white'
	},
	{
		key: subscriptionState.RENEWAL_FAILED,
		name: subscriptionStateTranslate[subscriptionState.RENEWAL_FAILED],
		color: 'bg-red text-white'
	},
	{
		key: subscriptionState.CANCELED,
		name: subscriptionStateTranslate[subscriptionState.CANCELED],
		color: 'bg-red-700 text-white'
	},
	{
		key: subscriptionState.UNKNOWN,
		name: subscriptionStateTranslate[subscriptionState.UNKNOWN],
		color: 'bg-red text-white'
	}
];

export function getOrderStatusFromKey(key) {
	return subscriptionStatuses.find(status => status.key === key) || subscriptionState.UNKNOWN; // default til "Unknown"
}

function SubscriptionStatus(props) {
	const orderStatusObj =
		_.find(subscriptionStatuses, { key: props.statusKey }) ||
		_.find(subscriptionStatuses, { key: subscriptionState.UNKNOWN });

	return (
		<span className={clsx('inline text-12 p-4 rounded truncate', orderStatusObj.color)}>{orderStatusObj.name}</span>
	);
}

export default SubscriptionStatus;
