import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { Alert } from '@mui/material';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectMainTheme } from '../store/fuse/settingsSlice';
import LoadingButton from './LoadingButton';

function ConfirmationDialog(props) {
	const {
		onClose,
		open,
		content,
		okButton,
		cancelButton,
		loading,
		maxWidth,
		fullWidth,
		buttons,
		icon,
		disableEscapeKeyDown,
		disableButtons,
		needToType,
		hintToType,
		bigButtons,
		disableOk,
		theme,
		...other
	} = props;
	const mainTheme = useSelector(selectMainTheme);
	const [confirmation, setConfirmation] = useState('');
	const { t } = useTranslation();

	const handleCancel = () => {
		onClose(false);
		setConfirmation('');
	};

	const handleOk = override => {
		if (needToType && !confirmation && needToType !== confirmation) {
			handleCancel();
		} else {
			onClose(override === undefined ? true : override);
		}
		setConfirmation('');
	};

	function handleSelect(id) {
		let sel;
		let range;
		const el = document.getElementById(id); // get element id
		if (window.getSelection && document.createRange) {
			// Browser compatibility
			sel = window.getSelection();
			if (sel.toString() === '') {
				// no text selection
				window.setTimeout(() => {
					range = document.createRange(); // range object
					range.selectNodeContents(el); // sets Range
					sel.removeAllRanges(); // remove all ranges from selection
					sel.addRange(range); // add Range to a Selection.
				}, 1);
			}
		} else if (document.selection) {
			// older ie
			sel = document.selection.createRange();
			if (sel.text === '') {
				// no text selection
				range = document.body.createTextRange(); // Creates TextRange object
				range.moveToElementText(el); // sets Range
				range.select(); // make selection.
			}
		}
	}

	return (
		<ThemeProvider theme={theme || mainTheme}>
			<Dialog
				disableEscapeKeyDown={
					disableEscapeKeyDown === undefined || disableEscapeKeyDown === null ? true : disableEscapeKeyDown
				}
				onClose={handleCancel}
				maxWidth={maxWidth || 'xs'}
				fullWidth={fullWidth || false}
				aria-labelledby="confirmation-dialog-title"
				open={!!open}
				{...other}
			>
				<DialogContent dividers>
					<div className="flex flex-col items-center justify-center p-32">
						{icon !== null && (
							<div className="m-32">
								<Icon className="text-96" color="action">
									{icon || 'warning'}
								</Icon>
							</div>
						)}

						{content instanceof String ? (
							<Typography className="text-center mb-16 text-16 w-full" color="textSecondary">
								{content}
							</Typography>
						) : (
							<div className="text-center mb-16 text-16 w-full">{content}</div>
						)}
						{needToType && (
							<Alert
								severity={needToType !== confirmation ? 'error' : 'success'}
								className="w-full"
								classes={{ message: 'w-full' }}
							>
								<Typography>
									{t('WRITE')}
									{/* eslint-disable-next-line */}
									{hintToType ? (
										hintToType
									) : (
										<span
											className="font-bold"
											id="security-text"
											onClick={() => handleSelect('security-text')}
										>
											{needToType}
										</span>
									)}{' '}
									{t('IN_THE_FIELD')}
								</Typography>
								<TextField
									className="mt-10 w-full"
									variant="outlined"
									label={t('CONFIRMATION')}
									size="small"
									fullWidth
									value={confirmation}
									onChange={e => setConfirmation(e.currentTarget.value)}
								/>
							</Alert>
						)}
					</div>
				</DialogContent>
				{buttons ? (
					<DialogActions className={bigButtons ? 'flex justify-between' : ''}>
						{buttons.map(button =>
							button.result !== undefined && button.result !== null && button.result !== false ? (
								<LoadingButton
									key={`btn-${button.result}`}
									onClick={() => handleOk(button.result)}
									variant={button.variant || 'contained'}
									color={button.color || 'primary'}
									loading={loading || false}
									disableLoadingOnClick
									disabled={!!disableButtons || button.disabled}
									fullWidth={bigButtons}
									classes={bigButtons ? { wrapper: 'w-full', button: 'h-48' } : undefined}
								>
									{button.label}
								</LoadingButton>
							) : (
								<LoadingButton
									key={`btn-${button.result}`}
									onClick={() => handleOk(button.result)}
									variant={button.variant || 'contained'}
									color={button.color || 'primary'}
									loading={loading || false}
									disableLoadingOnClick
									fullWidth={bigButtons}
									classes={bigButtons ? { wrapper: 'w-full', button: 'h-48' } : undefined}
								>
									{button.label}
								</LoadingButton>
							)
						)}
					</DialogActions>
				) : (
					<DialogActions className={bigButtons ? 'flex justify-between' : 'flex gap-5'}>
						{cancelButton && cancelButton !== false && (
							<Button
								autoFocus
								variant="contained"
								color={'inherit'}
								onClick={handleCancel}
								disabled={!!disableButtons}
								className={`rounded-none${bigButtons ? ' w-full h-48' : ''}`}
							>
								{cancelButton !== true && cancelButton !== false ? cancelButton : t('CANCEL')}
							</Button>
						)}
						<LoadingButton
							onClick={handleOk}
							variant="contained"
							color="primary"
							loading={loading || false}
							disableLoadingOnClick
							disabled={!!disableButtons || (needToType && needToType !== confirmation) || disableOk}
							classes={bigButtons ? { wrapper: 'w-full', button: 'h-48 w-full' } : undefined}
						>
							{okButton || t('YES')}
						</LoadingButton>
					</DialogActions>
				)}
			</Dialog>
		</ThemeProvider>
	);
}

export default ConfirmationDialog;
