import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	stepper: {
		backgroundColor: 'transparent'
	},
	button: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

function CustomStepper({ activeStep, steps, alternativeLabel }) {
	const classes = useStyles();
	const [skipped] = useState(new Set());

	const isStepSkipped = step => {
		return skipped.has(step);
	};

	return (
		<div className={classes.root}>
			<Stepper
				classes={{
					root: classes.stepper
				}}
				activeStep={activeStep}
				alternativeLabel={!!alternativeLabel}
			>
				{steps.map(({ optional, label }, index) => {
					const stepProps = {};
					const labelProps = {};
					if (optional) {
						labelProps.optional = <Typography variant="caption">Valgfri</Typography>;
					}
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
}

export default CustomStepper;
