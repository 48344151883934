import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

function CartAmountField({ className, type, disabled, onBlur, defaultValue }) {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<TextField
			className={className}
			type={type}
			disabled={disabled}
			onBlur={onBlur}
			value={value}
			onChange={e => {
				const val = e.currentTarget.value;
				setValue(val);
			}}
		/>
	);
}

export default CartAmountField;
