import layout2 from '@ameroservices-platform/attraction-frontend/app/fuse-layouts/layout2/Layout2';
import layout3 from '@ameroservices-platform/attraction-frontend/app/fuse-layouts/layout3/Layout3';
import layout4 from '@ameroservices-platform/attraction-frontend/app/fuse-layouts/layout4/Layout4';

const FuseLayouts = {
	layout2,
	layout3,
	layout4
};

export default FuseLayouts;
