import { motion } from 'framer-motion';

function FuseAnimateAdapter({ animation, delay, children, className }) {
	let initial = { opacity: 0, scale: 0.6 };
	let animate = { opacity: 1, scale: 1 };
	if (animation === 'transition.slideRightIn') {
		initial = { opacity: 0, x: 40 };
		animate = { opacity: 1, x: 0 };
	} else if (animation === 'transition.slideLeftIn') {
		initial = { opacity: 0, x: -40 };
		animate = { opacity: 1, x: 0 };
	} else if (animation === 'transition.expandIn') {
		initial = { opacity: 0, scale: 0.6 };
		animate = { opacity: 1, scale: 1 };
	}
	return (
		<motion.div initial={initial} animate={{ ...animate, transition: { delay: delay / 1000 } }} className={className}>
			{children}
		</motion.div>
	);
}

export default FuseAnimateAdapter;
