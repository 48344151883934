import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import List from '@mui/material/List';
import { Link as MUILink } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectMainTheme } from '../../../../store/fuse/settingsSlice';
import {
	mergeThemes,
	selectAbout,
	selectInformations,
	selectLogos,
	selectNavigation,
	selectSocialMedia,
	selectTheme,
} from '../../../../store/shared/frontendSlice';
import FuseNavigation from '@ameroservices-platform/attraction-frontend/fuse/core/FuseNavigation';

function DefaultFooter({ parentClasses, getSocialMediaSvg, className }) {
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const logos = useSelector(selectLogos);
	const informations = useSelector(selectInformations);
	const about = useSelector(selectAbout);
	const socialMedia = useSelector(selectSocialMedia);
	const navigation = useSelector(selectNavigation);
	const mergedTheme = mergeThemes(mainTheme, theme);
	return (
		<ThemeProvider theme={mergedTheme}>
			<AppBar
				id="fuse-footer"
				className="relative z-10"
				color="default"
				style={{
					backgroundColor: mergedTheme.palette.footer.background,
					color: mergedTheme.palette.footer.text,
				}}
			>
				<div className="p-24 w-full max-w-3xl mx-auto text-center">
					<Grid container spacing={5}>
						<Grid item xs={12} md={3} className="flex items-start justify-center md:justify-start">
							<Link to="/">
								<img
									className="w-1/2 sm:w-1/3 m-auto md:w-full mt-8"
									src={'https://storage.googleapis.com/' + logos.footer}
									alt="Jorn logo"
								/>
							</Link>
						</Grid>
						<Grid className="" item xs={12} md={3}>
							<Typography className="text-left md:text-center" variant="h6" gutterBottom>
								Menu
							</Typography>
							<FuseNavigation
								className={clsx('navigation', className)}
								navigation={navigation && navigation.footer ? navigation.footer : []}
								active="square"
								layout="vertical"
								classes={{
									horizontalNavigation: parentClasses.horizontalNavigation,
									verticalNavigation: parentClasses.verticalNavigation,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography className="text-left" variant="h6" gutterBottom>
								{about && about.heading ? about.heading : ''}
							</Typography>
							<Typography className="text-left py-8 pr-3/5">
								{about && about.text ? about.text : ''}
							</Typography>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography className="text-left" variant="h6" gutterBottom>
								Kontakt
							</Typography>
							<List className="py-0" component="nav">
								{informations &&
									informations.map((c) => {
										if (c.link) {
											return (
												<MUILink
													key={c.id}
													href={c.link}
													style={{
														color: mergedTheme.palette.footer.text,
													}}
												>
													<ListItem className="pl-0">
														<ListItemIcon>
															<Icon
																style={{
																	color: mergedTheme.palette.footer.text,
																}}
															>
																{c.icon}
															</Icon>
														</ListItemIcon>
														<ListItemText primary={c.text} />
													</ListItem>
												</MUILink>
											);
										}
										return (
											<ListItem className="pl-0">
												<ListItemIcon>
													<Icon
														style={{
															color: mergedTheme.palette.footer.text,
														}}
													>
														{c.icon}
													</Icon>
												</ListItemIcon>
												<ListItemText primary={c.text} />
											</ListItem>
										);
									})}
							</List>
						</Grid>
						<Grid item xs={12} xl={12}>
							{socialMedia &&
								socialMedia.map((c) => {
									return (
										<Tooltip title={c.type} placement="top">
											<IconButton
												className="w-48 h-48 mx-8"
												component="a"
												href={c.link}
												target="_blank"
												rel="noreferrer noopener"
												role="button"
											>
												{getSocialMediaSvg(c.type)}
											</IconButton>
										</Tooltip>
									);
								})}
						</Grid>
					</Grid>
				</div>
				<div className="bg-white w-full h-72 flex flex-col sm:flex-row items-center justify-around">
					<div>
						<img src={`assets/images/logos/${logos.payments}`} className="w-200 md:w-256" alt="" />
					</div>
					<div>
						<a href="https://amero.dk/" target="_blank" rel="noreferrer noopener">
							<Typography color="textPrimary" className="font-bold">
								Powered by Amero oplevelser
							</Typography>
						</a>
					</div>
				</div>
			</AppBar>
		</ThemeProvider>
	);
}

export default DefaultFooter;
