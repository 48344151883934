import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import { mergeThemes, selectTheme } from '../../../../../../store/shared/frontendSlice';
import getColor from '../shared/getColor';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';

function GridContainer(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	return (
		<Grid
			container
			spacing={contentElementProps.spacing}
			className={`${contentElementProps.tailwindClasses}  ${
				contentElementProps.smDirection && `sm:flex-${contentElementProps.smDirection}`
			} ${contentElementProps.mdDirection && `md:flex-${contentElementProps.mdDirection}`} ${
				contentElementProps.lgDirection && `lg:flex-${contentElementProps.lgDirection}`
			} ${contentElementProps.xlDirection && `xl:flex-${contentElementProps.xlDirection}`}  ${
				contentElementProps.maxWidth && `max-w-${contentElementProps.maxWidth}`
			} ${contentElementProps.minHeight && `min-h-${contentElementProps.minHeight}`} ${
				contentElementProps.smJustify && `sm:justify-${contentElementProps.smJustify}`
			} ${contentElementProps.mdJustify && `md:justify-${contentElementProps.mdJustify}`} ${
				contentElementProps.lgJustify && `lg:justify-${contentElementProps.lgJustify}`
			} ${contentElementProps.xlJustify && `xl:justify-${contentElementProps.xlJustify}`}
			 ${contentElementProps.backgroundRepeat && `bg-${contentElementProps.backgroundRepeat}`}
			 ${contentElementProps.backgroundSize && `bg-${contentElementProps.backgroundSize}`}
			 ${contentElementProps.backgroundPosition && `bg-${contentElementProps.backgroundPosition}`}
			 ${sharedTailwindClasses({
					...contentElementProps
				})}`}
			style={{
				backgroundColor:
					!contentElementProps.backgroundImageUrl &&
					`${getColor(contentElementProps.backgroundColor, contentElementProps.customColor, mergedTheme)}`,
				backgroundImage:
					contentElementProps.backgroundImageUrl && `url(${contentElementProps.backgroundImageUrl})`
			}}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</Grid>
	);
}

export default GridContainer;
