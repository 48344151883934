import React from 'react';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';

function Link(props) {
	const { contentElementProps } = props;
	const { contentElementNodes } = props;

	const useRouter = contentElementProps.href.startsWith('/');

	if (useRouter) {
		return (
			<MuiLink
				to={contentElementProps.href}
				component={RouterLink}
				className={`${!contentElementProps.underline ? `no-underline` : ``} ${
					contentElementProps.tailwindClasses
				} ${sharedTailwindClasses({
					...contentElementProps
				})}`}
				style={{ textDecoration: !contentElementProps.underline ? 'none' : '' }}
			>
				{contentElementNodes.length > 0 &&
					contentElementNodes.map(node => {
						return props.getCmsContentElement(node);
					})}
			</MuiLink>
		);
	}

	return (
		<MuiLink
			href={contentElementProps.href}
			className={`${!contentElementProps.underline ? `no-underline` : ``} ${
				contentElementProps.tailwindClasses
			} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
			style={{ textDecoration: !contentElementProps.underline ? 'none' : '' }}
		>
			{contentElementNodes.length > 0 &&
				contentElementNodes.map(node => {
					return props.getCmsContentElement(node);
				})}
		</MuiLink>
	);
}

export default Link;
