import React, { useState, useEffect } from 'react';
import { Button, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Remove, Add } from '@mui/icons-material';
import Calendar from 'react-calendar';
import Icon from '@mui/material/Icon';
import { useSelector } from 'react-redux';
import { eventGroupListener, groupSummaryListener, productsListener } from './EventGroupDateSelectorFirestore';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../store/shared/frontendSlice';
import MFSContent from '../../../../../fuse-layouts/layout2/components/content/MFSContent';
import DefaultContent from '../../../../../fuse-layouts/layout2/components/content/DefaultContent';

const useStyles = makeStyles(theme => ({
	badge: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.getContrastText(theme.palette.error.main)
	},
	number: { backgroundColor: theme.palette.primary.main },
	root: {
		position: 'relative'
	},
	grid: { flexGrow: 1 },

	calendarPicker: {
		width: '100%',
		overflow: 'hidden',
		// backgroundColor: theme.palette.background,
		// color: theme.palette.text.primary,

		// All days as a group
		// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
		'& .react-calendar__navigation__arrow': { width: '70px', height: '40px' },

		// Weekdays - man, tir, ons...
		'& .react-calendar__month-view__weekdays__weekday abbr': { textDecoration: 'none' },

		// All days individual
		'& .react-calendar__month-view__days__day': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			padding: '10px',
			outline: '2px solid #FDFBF7'
		},

		// Active button (selected date)
		'& .react-calendar__tile.react-calendar__tile--active': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},

		// Disabled buttons (previous dates)
		'& button:disabled.react-calendar__month-view__days__day': {
			opacity: '0.3'
		},

		// Todays date
		'& .react-calendar__tile--now': {}
	}
}));

function EventGroupViewPage(props) {
	const now = new Date();
	const [openBox1, setOpenBox1] = useState(true);
	const [openBox2, setOpenBox2] = useState(false);
	const classes = useStyles();
	const [ticketCount, setTicketCount] = useState(0);
	const [eventGroup, setEventGroup] = useState(null);
	const [groupSummary, setGroupSummary] = useState(null);
	const [year, setYear] = useState(now.getFullYear());
	const [month, setMonth] = useState(now.getMonth() + 1);
	const [products, setProducts] = useState([]);
	const [date, setDate] = useState(null);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedEvent, setSelectedEvent] = useState(null);

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);

	useEffect(() => {
		if (props.data.eventGroupUid) {
			const eventGroupsListenerUnsubFunc = eventGroupListener(props.data.eventGroupUid, o => setEventGroup(o));
			return () => eventGroupsListenerUnsubFunc();
		}

		return () => {};
	}, [props.data.eventGroupUid]);

	useEffect(() => {
		if (eventGroup && eventGroup.ticketTypes.length > 0) {
			const productsListenerUnsubFunc = productsListener(
				eventGroup.ticketTypes,
				props.data.productType,
				productList => {
					const copyOfProducts = productList;
					productList.filter(filterForDisclaimers).forEach((product, i) => {
						if (product.disclaimer) {
							product.disclaimerIndex = i + 1;
						}
					});
					setProducts(copyOfProducts);
				}
			);
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, props.data.productType]);

	useEffect(() => {
		if (eventGroup && year && month) {
			const productsListenerUnsubFunc = groupSummaryListener(eventGroup.id, month, year, o => setGroupSummary(o));
			return () => productsListenerUnsubFunc();
		}

		return () => {};
	}, [eventGroup, year, month]);

	useEffect(() => {
		const pro = {};
		products.forEach(product => {
			pro[product.id] = 0;
		});
		setProductsWithAmount(pro);
	}, [products]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = product.prices.find(f => f.type === 'Sale');
				if (price && price.price) {
					totals += price.price * productsWithAmount[pKey];
				}
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		if (ticketCount > 0) {
			setOpenBox2(true);
		}
		if (ticketCount === 0 && date) {
			setDate(null);
			setSelectedEvent(null);
		}
	}, [ticketCount, date]);

	useEffect(() => {
		if (groupSummary && date) {
			setSelectedEvent(groupSummary.dates[date.getDate()].times['0000'].eventUid);
		}
	}, [groupSummary, date]);

	const navigate = useNavigate();

	function goToCheckOut() {
		navigate('/cart', {
			state: {
				event: selectedEvent, products: productsWithAmount
			},
		})
	}

	function isAllowedToBuy() {
		return !selectedEvent || Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	return (
		<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto my-48')}>
			<Grid container spacing={0}>
				{eventGroup && eventGroup.content && !eventGroup.content.ROOT && (
					<>
						{theme &&
						theme.customStyling &&
						theme.customStyling.content &&
						theme.customStyling.content === 'mfs' ? (
							<MFSContent eventGroup={eventGroup} />
						) : (
							<DefaultContent eventGroup={eventGroup} mergedTheme={mergedTheme} />
						)}
					</>
				)}
				<Paper elevation={3} className="mt-48 mx-12 md:mx-16 px-4 py-28 md:px-28 w-full">
					<div className="flex flex-col md:flex-row w-full ">
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col whitespace-no-wrap px-10 pt-0"
						>
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								1
							</div>

							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvor mange kommer I?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox1(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg antal <Icon>{openBox1 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox1 && (
								<>
									{products.map(product => (
										<div className="flex justify-between items-center" key={product.id}>
											<div className="flex justify-between w-full">
												<Typography
													className="font-bold whitespace-normal cursor-default"
													onMouseOver={() => {
														if (product.disclaimerIndex) {
															setHighlightedDisclaimer(product.disclaimerIndex);
														}
													}}
													onMouseLeave={() => {
														if (product.disclaimerIndex) {
															setHighlightedDisclaimer(null);
														}
													}}
												>
													{/* className="font-bold text-14 md:text-base"> */}
													{product.name}
													&nbsp;
													{product.disclaimer ? '*'.repeat(product.disclaimerIndex) : ''}
												</Typography>
												<Typography className="px-8 font-bold flex items-center">
													{product.prices.find(f => f.type === 'Sale').price / 100},-
												</Typography>
											</div>
											<div className="flex ButtonDiv h-56 items-center">
												<Button
													variant="outlined"
													onClick={() => {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															if (p[product.id] !== 0) {
																p[product.id] -= 1;
															}
															return p;
														});
													}}
													className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
												>
													<Remove />
												</Button>
												<div className="flex items-center text-14 font-bold px-10">
													{productsWithAmount[product.id]}
												</div>
												<Button
													variant="outlined"
													onClick={() => {
														setProductsWithAmount(oldState => {
															const p = { ...oldState };
															p[product.id] += 1;
															return p;
														});
													}}
													className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
												>
													<Add />
												</Button>
											</div>
										</div>
									))}
									<hr />
									<div className="flex justify-between w-full my-6">
										<Typography className="font-bold text-20">I alt</Typography>
										<Typography className="font-bold text-20">{`${
											totalAmount / 100
										} kr.`}</Typography>
									</div>
									<hr />
									<div className="flex flex-col">
										{products.filter(filterForDisclaimers).map(product => (
											<Typography
												key={product.id}
												className={`${
													highlightedDisclaimer === product.disclaimerIndex
														? 'text-16 font-bold'
														: 'text-12'
												} flex items-center justify-start leading-6`}
												variant="body1"
											>
												<span className="mr-4">{'*'.repeat(product.disclaimerIndex)}</span>
												{product.disclaimer}
											</Typography>
										))}
									</div>
								</>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							className="flex flex-col text-left px-10 pt-32 md:pt-0"
						>
							<div
								className={clsx(
									classes.number,
									'font-bold text-white flex items-center justify-center rounded-full h-28 w-28'
								)}
							>
								2
							</div>
							<Typography className="font-bold whitespace-no-wrap pt-10 pb-4">
								Hvornår ønsker du at besøge?
							</Typography>
							<Button
								onClick={() => {
									setOpenBox2(oldstate => !oldstate);
								}}
								color="secondary"
								variant="contained"
								className="text-center rounded-none normal-case h-28 w-full"
							>
								Vælg dato <Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
							</Button>
							{openBox2 &&
								(ticketCount > 0 ? (
									<Calendar
										locale="da-DK"
										next2Label={null}
										prev2Label={null}
										minDetail="month"
										onChange={value => {
											setDate(value);
										}}
										value={date}
										showNeighboringMonth={false}
										minDate={new Date()}
										className={clsx(classes.calendarPicker, 'text-center')}
										tileDisabled={data => {
											if (ticketCount === 0) {
												return true;
											}
											const d = data.date.getDate();
											if (groupSummary && groupSummary.dates[d]) {
												if (groupSummary.dates[d].availableAtSameTime >= ticketCount) {
													return false;
												}
											}
											if (
												date &&
												data.date.getFullYear() === date.getFullYear() &&
												data.date.getMonth() === date.getMonth() &&
												data.date.getDate() === date.getDate()
											) {
												setDate(null);
												setSelectedEvent(null);
											}
											return true;
										}}
										onActiveStartDateChange={data => {
											setMonth(data.activeStartDate.getMonth() + 1);
											setYear(data.activeStartDate.getFullYear());
										}}
									/>
								) : (
									<Typography className="font-bold whitespace-no-wrap text-center mt-12">
										Vælg antal billetter først
									</Typography>
								))}
						</Grid>
					</div>
					<Grid container direction="row" justify="flex-end" className="">
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="px-10 pt-20">
							<Button
								onClick={goToCheckOut}
								className="w-full rounded-none"
								variant="contained"
								endIcon={<ArrowForwardIcon />}
								color="primary"
								disabled={isAllowedToBuy()}
							>
								KØB BILLET
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
}

export default EventGroupViewPage;
