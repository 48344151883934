import React, {lazy} from 'react';

const FlexposCustomerPage = lazy(() => import('./flexposCustomer/FlexposCustomerPage'));
const FlexposHome = lazy(() => import('./flexposHome/FlexposHome'));
const FlexposPage = lazy(() => import('./flexpos/FlexposPage'));

const FlexposPageConfig = {
	settings: {
		layout: {
			style: 'layout3',
			config: {
				navbar: {
					display: false,
					folded: false,
					position: 'left'
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false,
					style: 'static',
					position: 'below'
				}
			}
		}
	},
	routes: [
		{
			path: '/flexpos/customer',
			element: <FlexposCustomerPage />
		},
		{
			path: '/flexpos/home',
			element: <FlexposHome />
		},
		{
			path: '/flexpos',
			element: <FlexposPage />
		}
	]
};

export default FlexposPageConfig;
