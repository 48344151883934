import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, darken } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import AccordionSummary from '@mui/material/AccordionSummary';
import clsx from 'clsx';
import moment from 'moment-timezone';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import { ticketsListener } from '../../../../../../store/shared/userSlice';
import AccountVerificationNeeded from './AccountVerificationNeeded';

const useStyles = makeStyles(theme => ({
	header: {
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.primary.contrastText
	},
	panel: {
		marginBottom: '1rem',
		borderRadius: '16px 16px 16px 16px',
		'&$expanded': {
			marginBottom: '1rem'
		},
		'&:before': {
			backgroundColor: 'unset'
		}
	},
	panelDetail: {
		display: 'block'
	},
	expanded: {},
	root: {
		background: `radial-gradient(${darken(theme.palette.background.paper, 0.05)} 0%, ${
			theme.palette.background.paper
		} 60%)`
	},
	divider: {
		backgroundColor: theme.palette.divider
	}
}));

const formatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

const states = {
	issued: { color: 'bg-green-800 text-white', name: 'Aktiv' },
	used: { color: 'bg-green-800 text-white', name: 'Aktiv' },
	blocked: { color: 'bg-blue text-white', name: 'Blockeret' },
	inactive: { color: 'bg-red text-white', name: 'Inaktiv' }
};

function AccountTicketsPage({ subscription }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const tickets = useSelector(({ shared }) => shared.user.tickets);
	const user = useSelector(({ shared }) => shared.user.user);
	const userAuth = useSelector(({ auth }) => auth.user);
	const [expanded, setExpanded] = useState(null);
	const [verified, setVerified] = useState(false);
	const [qrCodes, setQrCodes] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		if (userAuth && userAuth.claims) {
			setVerified(userAuth.claims.email_verified);
		}
	}, [userAuth]);

	useEffect(() => {
		if (user && user.uid) {
			const unsub = dispatch(ticketsListener(user.uid));
			return () => unsub();
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		Promise.all(tickets.map(ticket => generateQrCode(ticket.ticketNumber))).then(qrs => {
			const qrCodesTemp = {};
			qrs.forEach(qr => {
				qrCodesTemp[qr.number] = qr.url;
			});
			setQrCodes(qrCodesTemp);
		});
	}, [tickets]);

	async function generateQrCode(number) {
		return new Promise(resolve => {
			QRCode.toDataURL(number, (err, url) => {
				resolve({ number, url });
			});
		});
	}

	const toggleAccordion = panel => (event, _expanded) => {
		setExpanded(_expanded ? panel : false);
	};

	if (verified) {
		return (
			<>
				<div className="hidden md:flex items-center justify-between p-20">
					<table className="w-full">
						<thead>
							<tr>
								<td className="w-400">
									<Typography variant="subtitle1">
										{subscription ? t('CARD') : t('TICKET')}
									</Typography>
								</td>
								<td>
									<Typography variant="subtitle1">Status</Typography>
								</td>
								<td>
									<Typography variant="subtitle1">
										{subscription ? t('VALIDITY_PERIOD') : t('EVENT_TIME')}
									</Typography>
								</td>
								<td className="w-76 pr-64">
									<Typography variant="subtitle1">{t('VALUE')}</Typography>
								</td>
								<td />
							</tr>
						</thead>
					</table>
				</div>
				{tickets
					.filter(
						ticket =>
							(!!subscription && !!ticket.subscriptionUid) || (!subscription && !ticket.subscriptionUid)
					)
					.map(ticket => (
						<Accordion
							classes={{
								root: clsx(classes.panel, 'md:w-xl'),
								expanded: classes.expanded
							}}
							key={ticket.id}
							expanded={expanded === ticket.id}
							onChange={toggleAccordion(ticket.id)}
							elevation={1}
							square
						>
							<AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
								<table className="w-full">
									<tbody>
										<tr className="md:table-row flex flex-col">
											<td className="md:w-400">
												<div>
													<Typography variant="h6" className="mb-8">
														{ticket.name}
													</Typography>
													<Typography color="textSecondary">
														{subscription ? t('CARD_NUM') : t('TICKET_NUM')}:{' '}
														{ticket.ticketNumber}
													</Typography>
													<Typography color="textSecondary">
														{moment(ticket.issueDate).format('DD-MM-YYYY, HH:mm')}
													</Typography>
												</div>
											</td>
											<td>
												<Typography variant="subtitle1">
													{states[ticket.state] ? (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states[ticket.state].color
															)}
														>
															{states[ticket.state].name}
														</span>
													) : (
														<span
															className={clsx(
																'inline text-12 p-4 rounded truncate',
																states.inactive.color
															)}
														>
															{states.inactive.name}
														</span>
													)}
												</Typography>
											</td>
											{ticket.event ? (
												<td>
													<Typography color="textSecondary">
														{subscription ? t('FROM') : 'Start'}:{' '}
														{moment(ticket.event.start).format('DD-MM-YYYY, HH:mm')}
													</Typography>
													<Typography color="textSecondary">
														{subscription ? t('TO') : t('END')}:{' '}
														{moment(ticket.event.end).format('DD-MM-YYYY, HH:mm')}
													</Typography>
												</td>
											) : (
												<td />
											)}
											<td className="md:w-76">
												<Typography variant="subtitle1" className="mr-16">
													{ticket.price ? formatter.format(ticket.price / 100) : '-'}
												</Typography>
											</td>
										</tr>
									</tbody>
								</table>
							</AccordionSummary>
							<AccordionDetails classes={{ root: clsx(classes.root, 'block md:p-88') }}>
								<div className="flex items-center justify-between md:flex-row flex-col">
									<div>
										<img
											src={qrCodes[ticket.ticketNumber] || 'http://placehold.it/128x128'}
											alt="QR Code"
										/>
									</div>

									<div
										className={clsx(
											classes.divider,
											'mx-48 w-px h-128 print:mx-16 md:block hidden'
										)}
									/>

									<div className="flex justify-end items-center md:mt-0 md:mb-0 mb-10 mt-20 md:w-160 print:w-60">
										<Typography
											variant="h5"
											className="font-light print:text-16"
											color="textSecondary"
										>
											{t('ISSUED_TO')}
										</Typography>
									</div>

									<div
										className={clsx(
											classes.divider,
											'mx-48 w-px h-128 print:mx-16 md:block hidden'
										)}
									/>

									<div className="md:max-w-160">
										{ticket.issuedTo && (
											<>
												<Typography color="textSecondary">
													{ticket.issuedTo.firstname} {ticket.issuedTo.lastname}
												</Typography>
												{ticket.issuedTo.address && (
													<Typography color="textSecondary">
														{ticket.issuedTo.address}
													</Typography>
												)}
												{ticket.issuedTo.city && ticket.issuedTo.postalCode && (
													<Typography color="textSecondary">
														{ticket.issuedTo.postalCode} {ticket.issuedTo.city}
													</Typography>
												)}
												{ticket.issuedTo.phone && (
													<Typography color="textSecondary">
														<span>{t('PHONE')}: </span>
														{ticket.issuedTo.phone}
													</Typography>
												)}
												{ticket.issuedTo.email && (
													<Typography color="textSecondary">
														<span>Email: </span>
														{ticket.issuedTo.email}
													</Typography>
												)}
											</>
										)}
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					))}
			</>
		);
	}
	return <AccountVerificationNeeded type="billetter" />;
}

export default AccountTicketsPage;
