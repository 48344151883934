import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import NavbarFoldedToggleButton from '../../../../shared-components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from '../../../../shared-components/NavbarMobileToggleButton';
import Navigation from '../../../../shared-components/Navigation';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { mergeThemes, selectLogos, selectTheme } from '../../../../../store/shared/frontendSlice';
import { selectMainTheme } from '../../../../../store/fuse/settingsSlice';
import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';

const useStyles = makeStyles((theme) => ({
	content: {
		overflowX: 'hidden',
		overflowY: 'auto',
		'-webkit-overflow-scrolling': 'touch',
		background:
			'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll',
	},

	navbg: {
		backgroundColor: theme.palette.background.default,
	},
	bgBlack: {
		backgroundColor: '#000000',
	},
}));

function NavbarMobileLayout2(props) {
	const classes = useStyles(props);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const logos = useSelector(selectLogos);
	const useStylesColors = makeStyles((_theme) => ({
		verticalNavigation: {
			'& .list-item': {
				color: '#FFFFFF',
				'& .list-item-text': {
					padding: '0',
					'& .list-item-text-primary': { fontWeight: 'bold' },
				},
				'&.active': {
					backgroundColor: '#FFFFFF',
					color: `#000000 !important`,
				},
			},
		},
	}));
	const classesColors = useStylesColors(props);

	return (
		<div className={clsx(classes.navbg, 'flex flex-col h-full overflow-hidden')}>
			<AppBar
				position="static"
				elevation={0}
				className={clsx(classes.bgBlack, 'flex flex-row items-center flex-shrink h-64 min-h-64 px-12')}
			>
				<div className="flex flex-1 mx-8">
					<Link to="/">
						<img alt="logo" className="p-36" src={'https://storage.googleapis.com/' + logos.header} />
					</Link>
				</div>

				<Hidden mdDown>
					<NavbarFoldedToggleButton className="w-40 h-40 p-0" />
				</Hidden>

				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-40 h-40 p-0">
						<Icon className="text-white">
							{mergedTheme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}"
						</Icon>
					</NavbarMobileToggleButton>
				</Hidden>
			</AppBar>

			<FuseScrollbars className={clsx(classes.content)}>
				<Navigation
					layout="vertical"
					classes={{
						verticalNavigation: classesColors.verticalNavigation,
					}}
				/>
			</FuseScrollbars>
		</div>
	);
}

export default React.memo(NavbarMobileLayout2);
