import { useTimeout } from '@ameroservices-platform/attraction-frontend/fuse/hooks';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function FuseLoading(props) {
	const [showLoading, setShowLoading] = useState(!props.delay);

	useTimeout(() => {
		setShowLoading(true);
	}, props.delay);

	if (!showLoading) {
		return null;
	}

	return (
		<div className="flex flex-1 flex-col items-center justify-center">
			{props.title ? (
				<Typography className="text-20 mb-16" color="textSecondary">
					{props.title}
				</Typography>
			) : (
				''
			)}

			<CircularProgress color="primary" />
		</div>
	);
}

FuseLoading.propTypes = {
	delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

FuseLoading.defaultProps = {
	delay: false
};

export default FuseLoading;
